import { getBasePath } from ".";

/**
 * Determine if the VM has expired or not.
 */
export async function getExpiration() {
  let res = await fetch(getBasePath() + `/deployment/expiration`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  if (!res.ok) {
    return "No expiration.";
  }
  let parsed = await res.json();
  return parsed;
}

/**
 * Returns a JSON object that says which features are enabled/disabled.
 */
export async function isFeatureEnabled() {
  let res = await fetch(getBasePath() + `/deployment/is_feature_enabled`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  if (!res.ok) {
    return {
      "OWASP 10:2021": false,
      "ISA/IEC 62443-3-3": false,
      "ISA/IEC 62443-4-2": false,
      "NIST 800-53": false,
      "NIST 800-82": false,
    };
  }
  let parsed = await res.json();
  return parsed;
}

/**
 * Get a JSON object representing the system stats of the host machine.
 */
export async function getSysStats() {
  let res = await fetch(getBasePath() + `/deployment/sys_stats`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  if (!res.ok) {
    throw "System stats are unavailable.";
  }
  let parsed = await res.json();
  return parsed;
}

/**
 * Apply a new license to the deployment as the SUPERADMIN.
 */
export async function applyLicense(file) {
  let body = new FormData();
  body.append("license", file);
  let res = await fetch(getBasePath() + "/deployment/license", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  let data = res.text();
  return data;
}

export async function getDeploymentType() {
  let res = await fetch(getBasePath() + `/deployment/type`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  if (!res.ok) {
    return "LOCAL";
  }
  let data = await res.text();
  return data;
}

export async function configELKLogger(protocol, host, port, ssl_enabled) {
  let body = new FormData();
  body.append("protocol", protocol);
  body.append("host", host);
  body.append("port", port);
  body.append("ssl_enabled", ssl_enabled);
  let res = await fetch(getBasePath() + "/deployment/logger/config/elk", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  let parsed = await res.text();
  return parsed;
}

export async function getELKLoggerConfig() {
  let res = await fetch(getBasePath() + "/deployment/logger/config/elk", {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  let parsed = await res.json();
  return parsed;
}

export async function disableELKLogger() {
  let res = await fetch(
    getBasePath() + "/deployment/logger/config/elk/disable",
    {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + window.localStorage.getItem("auth"),
      }),
    }
  );
  let parsed = await res.text();
  return parsed;
}

export async function getAssessorDBLastUpdate(assessor, assessor_file) {
  let res = await fetch(
    getBasePath() + `/deployment/db/${assessor}/${assessor_file}`,
    {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + window.localStorage.getItem("auth"),
      }),
    }
  );
  let parsed = await res.text();
  return parsed;
}

export async function updateAssessorDB(assessor, assessor_file, file) {
  let body = new FormData();
  body.append("cve_db", file);
  let res = await fetch(
    getBasePath() + `/deployment/db/${assessor}/${assessor_file}`,
    {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + window.localStorage.getItem("auth"),
      }),
      body,
    }
  );
  let data = await res.text();
  return data;
}
