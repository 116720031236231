import { useState } from "react";
import "../report_builder_component.css";

// icons
import { X } from "react-feather";

export default function ReportBuilderComponent({ item, setItems, asset_id }) {
  let [hovering, setHovering] = useState(false);
  return (
    <div
      className="report-builder-component"
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <X
        className="report-builder-component-close"
        style={{
          opacity: hovering ? 1 : 0,
        }}
        onClick={() =>
          setItems((items) => items.filter((i) => i.id !== item.id))
        }
      />
      <h3>Asset</h3>
      <h2>{item.title}</h2>
      <>
        <h3>Vulnerability Level</h3>
        <div className="cause-field">{item.severity}</div>
      </>
    </div>
  );
}
