import {
  getClamAV,
  getCVEs,
  getLineage,
  getCrypto,
  getBAP,
  getDangerousFunctions,
  getWeakPointers,
  getSymbexResults,
} from "../../../api";
import { PASS_FAIL_NAMES } from "../pass_fail";
import { dangerous_fn_likelhood_text } from "../../../helpers";

/**
 * Determines the reason why a score was given.
 * The "reason()" function returns component to display in
 * the <ScoreCause /> parent component.
 */
export default class ScoreReasoner {
  constructor(score, asset_id, binary, cwss) {
    this.score = score;
    this.asset_id = asset_id;
    this.binary = binary;
    this.cwss = cwss;

    this.clickable = false;
  }

  // RED CAUSES
  async hasMalware() {
    let clamav = await getClamAV(this.asset_id, this.binary);
    if (clamav && clamav.status !== "OK") {
      return (
        <div className="cause">
          <h2>Malware Detected</h2>
          <div className="cause-field">{clamav.message}</div>
          <h3>
            This binary contains malware and should be quarantined immediately.
          </h3>
        </div>
      );
    }
    return null;
  }

  async containsSymbexResults() {
    let symbexResults;
    try {
      symbexResults = await getSymbexResults(this.asset_id, this.binary);
    } catch {
      symbexResults = [];
    }
    if (symbexResults.length > 0) {
      this.clickable = "SymbEx";
      return (
        <div className="cause">
          <h2>Contains SymbEx Results</h2>
          <div className="cause-field">{symbexResults.length} Total</div>
          <h3>
            There exist binary vulnerabilities detected using Symbolic
            Execution.
          </h3>
        </div>
      );
    }
    return null;
  }

  async containsWeakPointers() {
    let weakPointers = await getWeakPointers(this.asset_id, this.binary);
    if (weakPointers && Object.values(weakPointers).length > 0) {
      this.clickable = "Weak Pointers";
      return (
        <div className="cause">
          <h2>Contains Weak Pointers</h2>
          <div className="cause-field">
            {Object.values(weakPointers).length} Total
          </div>
          <h3>
            This binary contains Weak Pointers. Weak Pointers is a novel binary
            code analysis science that automates identification of known code
            vulnerabilities and weaknesses, like buffer overflow.
          </h3>
        </div>
      );
    }
    return null;
  }

  async containsCVEs() {
    let cves = await getCVEs(this.asset_id, this.binary);
    if (cves && cves.length > 0) {
      this.clickable = "CVEs";
      return (
        <div className="cause">
          <h2>Contains CVEs</h2>
          <div className="cause-field">{cves.length} Total</div>
          <h3>
            This binary contains CVEs (known vulnerabilities). This means the
            binary can be exploited using known attack methods.
          </h3>
        </div>
      );
    }
    return null;
  }

  async hasSecretKey() {
    let crypto = await getCrypto(this.asset_id, this.binary);
    if (!crypto) return null;
    for (let loc in crypto) {
      let key_string = crypto[loc].value;
      if (key_string.includes("PRIVATE")) {
        this.clickable = "Crypto";
        return (
          <div className="cause">
            <h2>Contains Secret Key</h2>
            <h3>
              This binary contains an embedded cryptographic secret key. This
              may indicate malicious intent.
            </h3>
          </div>
        );
      }
    }
    return null;
  }

  async failsLineage() {
    let lineage = await getLineage(this.asset_id, this.binary);
    if (!lineage) {
      this.clickable = "ThreatAI";
      return (
        <div className="cause">
          <h2>
            Suspicious Code and/or Evasive Behavior Detected from Lineage
            Analysis
          </h2>
          <div className="cause-field">Trojan Detection Failure</div>
          <h3>
            This binary does not exhibit clearly benign behaviors - it exhibits
            characteristics that may be malicious.
          </h3>
        </div>
      );
    }
    return null;
  }

  // use-after-free
  async failsThreatAIRed() {
    let failure_conditions = ["use-after-free", "double-free", "heap-overflow"];
    let baps = await getBAP(this.asset_id, this.binary);
    if (!baps) return null;
    for (let bap of baps) {
      if (failure_conditions.includes(bap.NAME) && bap.STATUS === "FAIL") {
        this.clickable = "ThreatAI";
        return (
          <div className="cause">
            <h2>Failed Critical ThreatAI Test</h2>
            <div className="cause-field">{PASS_FAIL_NAMES[bap.NAME]}</div>
          </div>
        );
      }
    }
  }

  async failsTaintAnalysisRed() {
    // combine dangerous functions and fuzzy match into the same object
    let dangerous_functions = await getDangerousFunctions(
      this.asset_id,
      this.binary
    );

    for (let func in dangerous_functions) {
      for (let inst of dangerous_functions[func]) {
        if (
          (inst.severity >= 4 && parseFloat(inst.likelihood) >= 35) ||
          (inst.severity >= 3 && parseFloat(inst.likelihood) >= 65)
        ) {
          this.clickable = "Dangerous Functions";
          return (
            <div className="cause">
              <h2>Contains a Critically Dangerous Function</h2>
              <div className="cause-field">
                Position {inst.offset}:{" "}
                {dangerous_fn_likelhood_text(inst.likelihood)}
              </div>
              <h3>
                We have matched the signature of a critically dangerous
                function.
              </h3>
            </div>
          );
        }
      }
    }

    return null;
  }

  redCWSS() {
    if (this.cwss > 1.65) {
      this.clickable = "CWEs";
      return (
        <div className="cause">
          <h2>High Weakness Score</h2>
          <div className="cause-field">{this.cwss}</div>
          <h3>
            This binary has a high weakness score. This means it contains many
            CWEs who's compounded vulnerability considerations exceed the
            threshold for a red score.
          </h3>
        </div>
      );
    }
    return null;
  }

  // YELLOW CAUSES
  async hasNonSecretKey() {
    let crypto = await getCrypto(this.asset_id, this.binary);
    if (!crypto) return null;
    for (let loc in crypto) {
      let key_string = crypto[loc].value;
      if (key_string.includes("PRIVATE")) {
        this.clickable = "Crypto";
        return (
          <div className="cause">
            <h2>Contains Key</h2>
            <h3>
              This binary contains an embedded cryptographic key. This may
              indicate malicious intent.
            </h3>
          </div>
        );
      }
    }
    return null;
  }

  // av-rule-22, av-rule-23, primus-checks, restrictness-check
  async failsThreatAIYellow() {
    let failure_conditions = [
      "av-rule-22",
      "av-rule-23",
      "primus-checks",
      "restrictness-check",
      "forbidden-symbol",
      "spectre",
      "defective-symbol",
    ];
    let baps = await getBAP(this.asset_id, this.binary);
    if (!baps) return null;
    for (let bap of baps) {
      if (failure_conditions.includes(bap.NAME) && bap.STATUS === "FAIL") {
        this.clickable = "ThreatAI";
        return (
          <div className="cause">
            <h2>Failed Semi-Critical ThreatAI Test</h2>
            <div className="cause-field">{PASS_FAIL_NAMES[bap.NAME]}</div>
          </div>
        );
      }
    }
    return null;
  }

  async failsTaintAnalysisYellow() {
    // combine dangerous functions and fuzzy match into the same object
    let dangerous_functions = await getDangerousFunctions(
      this.asset_id,
      this.binary
    );

    for (let func in dangerous_functions) {
      for (let inst of dangerous_functions[func]) {
        if (
          (inst.severity >= 4 && parseFloat(inst.likelihood) >= 15) ||
          (inst.severity >= 3 && parseFloat(inst.likelihood) >= 20)
        ) {
          this.clickable = "Dangerous Functions";
          return (
            <div className="cause">
              <h2>Contains a Semi-Critically Dangerous Function</h2>
              <div className="cause-field">
                Position {inst.offset}:{" "}
                {dangerous_fn_likelhood_text(inst.likelihood)}
              </div>
              <h3>
                We have matched the signature of a potentially dangerous
                function.
              </h3>
            </div>
          );
        }
      }
    }

    return null;
  }

  yellowCWSS() {
    if (this.cwss > 1.21) {
      this.clickable = "CWEs";
      return (
        <div className="cause">
          <h2>High Weakness Score</h2>
          <div className="cause-field">{this.cwss}</div>
          <h3>
            This binary has a high weakness score. This means it contains many
            CWEs who's compounded vulnerability considerations exceed the
            threshold for a yellow score.
          </h3>
        </div>
      );
    }
    return null;
  }

  // base/error case
  // this gets called if the reasoner cannot determine a reason
  // the score was given, or if "reason()" was called from
  // a "GREEN" score binary
  unsure() {
    return (
      <div className="cause">
        <h2>Unsure</h2>
        <h3>
          We are unsure why this binary was scored the way it was. It is likely
          that this binary was scored is a previous update.
        </h3>
      </div>
    );
  }

  // determine what reason to display
  async reason() {
    if (this.score === "RED") {
      return (
        (await this.hasMalware()) ||
        (await this.containsSymbexResults()) ||
        (await this.containsWeakPointers()) ||
        (await this.containsCVEs()) ||
        (await this.hasSecretKey()) ||
        (await this.failsLineage()) ||
        (await this.failsThreatAIRed()) ||
        (await this.failsTaintAnalysisRed()) ||
        this.redCWSS() ||
        this.unsure()
      );
    } else if (this.score === "YELLOW") {
      return (
        (await this.hasNonSecretKey()) ||
        (await this.failsThreatAIYellow()) ||
        (await this.failsTaintAnalysisYellow()) ||
        this.yellowCWSS() ||
        this.unsure()
      );
    }
    return this.unsure();
  }

  isClickable() {
    return this.clickable;
  }
}
