/*
    This component enables the user to concatenate multiple report together into
    a single aggregate report. That is, the user can create a list of
    binary, asset, and facility reports that all get exported together as a single
    PDF.
*/

import { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import "./report_concatenator.css";
import { useReactToPrint } from "react-to-print";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { genConcatReportId, fetchConcatReportData } from "./helpers";

// components
import ConcatenatableReport from "./concatenatable_report";
import FacilityReport from "../report_builder_page/facility/facility_report";
import AssetReport from "../report_builder_page/asset/asset_report";
import BinaryReport from "../report_builder_page/binary/binary_report";

export default function ReportConcatenator({
  selectedReports,
  removeReport,
  reorderReport,
  generating,
  setGenerating,
}) {
  let [progress, setProgress] = useState(0);
  let [renderReports, setRenderReports] = useState([]);

  const useDraggableInPortal = () => {
    const self = useRef({}).current;

    useEffect(() => {
      const div = document.createElement("div");
      div.style.position = "absolute";
      div.style.pointerEvents = "none";
      div.style.top = "0";
      div.style.width = "100%";
      div.style.height = "100%";
      self.elt = div;
      document.body.appendChild(div);
      return () => {
        document.body.removeChild(div);
      };
    }, [self]);

    return (render) =>
      (provided, ...args) => {
        const element = render(provided, ...args);
        if (provided.draggableProps.style.position === "fixed") {
          return createPortal(element, self.elt);
        }
        return element;
      };
  };

  const renderDraggable = useDraggableInPortal();

  const printRef = useRef();
  /**
   * Print the fully concatenated report once it has been loaded into the DOM.
   */
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
    @media print {
      body {
        overflow: visible !important;

        --bg-color: #ffffff;
        --sec-bg-color: #fbfbfb;
        --pri-color: #0f1419;
        --pri-color-light: #7a8791;
        --bor-color: #cfd9de;
      }
    }
  `,

    print: async (printIframe) => {
      // Do whatever you want here, including asynchronous work
      printIframe.contentWindow.print();

      // finish loading
      setGenerating(false);
      setRenderReports([]);
    },
  });

  /**
   * Fetch the data needed to generate the report and add it to the DOM.
   * Update thje progress bar along the way.
   * This function should be cancellable, and should be wary of exceeding
   * available memory.
   */
  const genAggregateReport = async () => {
    // start loading
    setGenerating(true);
    setRenderReports([]);
    setProgress(0);

    let reports = [...selectedReports];
    let renderReport = async () => {
      if (reports.length <= 0) {
        setTimeout(handlePrint, 1000);
        return;
      }
      let report = reports.shift();
      let results = await fetchConcatReportData(report);
      switch (report.type) {
        case "FACILITY":
          setRenderReports((renderReports) => [
            ...renderReports,
            <FacilityReport
              items={results.items}
              facility_id={results.facility_id}
              facility={results.facility}
              assets={results.assets}
              done={() => {
                renderReport();
                setProgress((progress) => progress + 1);
              }}
            />,
          ]);
          break;
        case "ASSET":
          setRenderReports((renderReports) => [
            ...renderReports,
            <AssetReport
              items={results.items}
              asset_id={results.asset_id}
              asset={results.asset}
              binaries={results.binaries}
              audit={results.audit}
              done={() => {
                renderReport();
                setProgress((progress) => progress + 1);
              }}
            />,
          ]);
          break;
        case "BINARY":
          setRenderReports((renderReports) => [
            ...renderReports,
            <BinaryReport
              items={results.items}
              asset_id={results.asset_id}
              binary={results.binary}
              metadata={results.metadata}
              manalyze={results.manalyze}
              binaryEncryption={results.binaryEncryption}
              setItems={results.setItems}
              cves={results.cves}
              cwes={results.cwes}
              threatAI={results.threatAI}
              done={() => {
                renderReport();
                setProgress((progress) => progress + 1);
              }}
            />,
          ]);
          break;
        default:
          break;
      }
    };
    renderReport();
  };

  if (selectedReports.length === 0) return null;

  // The PDF generation progress bar should appear over the entire screen,
  // preventing the user from doing anything else while the PDF report is
  // generating.
  if (generating)
    return (
      <div className="report-concatenator">
        <div className="concatenated-report-creation-progress">
          <h1>
            PDF Report Generation In Progress.
            <br />
            This may take a few minutes.
          </h1>
          <progress value={"" + progress / selectedReports.length} max={"1"} />
          <h3>
            {progress} of {selectedReports.length} Reports Generated.
            <br />
            Please do not exit the page!
          </h3>
        </div>
        {/* The concatenated report should be in the DOM, but off screen so 
        the user cannot see it until the print menu is opened. */}
        <div className="offscreen-concatenated-report">
          <div className="fake-report-document" ref={printRef}>
            {renderReports}
          </div>
        </div>
      </div>
    );
  return (
    <div className="report-concatenator">
      <DragDropContext
        onDragEnd={(result) => {
          if (!result.destination) {
            return;
          }
          reorderReport(result.source.index, result.destination.index);
        }}
      >
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                overflowY: "scroll",
                marginBottom: 20,
                paddingRight: 10,
              }}
            >
              {selectedReports.map((report, index) => {
                let id = genConcatReportId(report);
                return (
                  <Draggable
                    className="report-builder-component"
                    key={id}
                    draggableId={id}
                    index={index}
                  >
                    {renderDraggable((provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                        }}
                      >
                        <ConcatenatableReport
                          report={report}
                          remove={() => removeReport(report)}
                        />
                        {provided.placeholder}
                      </div>
                    ))}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <button
        onClick={genAggregateReport}
        className="generate-concatenatable-report-button"
      >
        Generate Aggregate Report
      </button>
    </div>
  );
}
