import "./index.css";
import { DeltaReportsPage, DeltaReportsPageHeader } from "..";
import { useEffect, useState } from "react";
import { genDeltaReport } from "../../../api";
import AssetSelector from "./asset_selector";
import BinarySummaryInfoHelper from "../../binary_analysis_page/binary_summary_components/binary_summary_info_helper";
import { AlertCircle } from "react-feather";

// Before the Binary Delta Feature, assets were not tagged
// with the BinLens version they were assessed on
// Therefore, we tag such assets with the last version
// before the Binary Delta Feature was added: v2.1.1.
const VERSION_BEFORE_DELTA_FEATURE = "v2.1.1";

export default function GenerateDeltaReportPage() {
  return (
    <DeltaReportsPage>
      <DeltaReportForm />
    </DeltaReportsPage>
  );
}

function DeltaReportForm() {
  // TODO this should useReducer()
  let [asset1, setAsset1] = useState(null);
  let [asset2, setAsset2] = useState(null);
  let [title, setTitle] = useState(null);
  let [description, setDescription] = useState(null);
  let [versionA, setVersionA] = useState(null);
  let [versionB, setVersionB] = useState(null);
  let [header, setHeader] = useState(null);
  let [footer, setFooter] = useState(null);
  let [urlLink1, setURLLink1] = useState(null);
  let [urlLink2, setURLLink2] = useState(null);
  let [urlLink3, setURLLink3] = useState(null);
  let [versionMismatch, setVersionMismatch] = useState(null);

  const calcVersionMismatch = () => {
    if (!asset1 || !asset2) return null;
    let v1 = VERSION_BEFORE_DELTA_FEATURE;
    if ("binlens_version" in asset1 && asset1.binlens_version !== "")
      v1 = asset1.binlens_version;
    let v2 = VERSION_BEFORE_DELTA_FEATURE;
    if ("binlens_version" in asset2 && asset2.binlens_version !== "")
      v2 = asset2.binlens_version;
    if (v1 !== v2)
      return `These assets were assessed on different versions of BinLens. The first asset was assessed on ${v1}. The second asset was assessed on ${v2}.  Including these assets together in a Binary Delta Report may introduce potentially misleading results.`;
    return null;
  };
  useEffect(() => {
    setVersionMismatch(calcVersionMismatch());
  }, [asset1, asset2]);

  const submit = async () => {
    if (!asset1) {
      alert("Select the first asset for the Binary Delta Report.");
      return;
    }
    if (!asset2) {
      alert("Select the second asset for the Binary Delta Report.");
      return;
    }
    let url_links = [];
    if (urlLink1) url_links.push(urlLink1);
    if (urlLink2) url_links.push(urlLink2);
    if (urlLink3) url_links.push(urlLink3);
    let delta_report_id = await genDeltaReport(
      asset1.ref_id,
      asset2.ref_id,
      title,
      description,
      versionA,
      versionB,
      header,
      footer,
      url_links
    );
    window.location.assign(`#/delta/${delta_report_id}`);
  };

  return (
    <>
      <DeltaReportsPageHeader
        title="Generate New Binary Delta Report"
        canGoBack={true}
      />

      <div className="delta-report-form">
        <div className="delta-report-form-items">
          <BinarySummaryInfoHelper
            text="The Binary Delta Feature allows you to compare binary 
            assessment results for different versions of the same binary 
            executable or firmware image (e.g., between updates, patches, 
            upgrades, mergers, debloats, remediation, etc.).
            Select two assets you have already analyzed. The change in number
            of weaknesses and vulnerabilities from the first asset to
            the second asset will be displayed on a report.
            "
          />
          <h2>Asset 1</h2>
          <p>
            The first asset. Represents the "before" state in the Binary Delta
            Report (e.g., before a patch, before a debloat, etc.).
          </p>
          <AssetSelector setAsset={setAsset1} id="asset-selector-1" />
          <hr />
          <h2>Asset 2</h2>
          <p>
            The second asset. Represents the "after" state in the Binary Delta
            Report (e.g., after a patch, after a debloat, etc.).
          </p>
          <AssetSelector setAsset={setAsset2} id="asset-selector-1" />
          {versionMismatch && (
            <p className="delta-incomplete-warning">
              <AlertCircle className="icon" /> {versionMismatch}
            </p>
          )}
          <hr />
          <h2>Report Title</h2>
          <p>
            You may optionally add a title to the Binary Delta Report. If you
            choose not to add a title, the report will be titled "Binary Delta
            Report".
          </p>
          <input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="small-input"
            placeholder="Delta Report Title (Optional)"
          />
          <hr />
          <h2>Report Description</h2>
          <p>
            You may optionally add a description to the Binary Delta Report.
            This description could include information about why the report was
            generated, specific weaknesses/vulnerabilities to watch out for,
            etc.
          </p>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Report Description (Optional)"
          ></textarea>
          <hr />
          <h2>Versions</h2>
          <p>
            You may optionally include the versions of the two assets being
            analyzed (if applicable). For example, if you are comparing version
            v0.0.1 of a firmware to version v0.0.2 of a firmware, you would
            enter "v0.0.1" in the first text field, and "v0.0.2" in the second
            text field.
          </p>
          <input
            value={versionA}
            onChange={(e) => setVersionA(e.target.value)}
            className="small-input"
            placeholder="Asset Version 1 (Optional)"
          />
          <input
            value={versionB}
            onChange={(e) => setVersionB(e.target.value)}
            className="small-input"
            placeholder="Asset Version 2 (Optional)"
          />
          <hr />
          <h2>Header</h2>
          <p>
            You may optionally include text that will appear in the top right
            corner of the header section in the report. This header will appear
            on all pages of the report.
          </p>
          <input
            value={header}
            onChange={(e) => setHeader(e.target.value)}
            className="small-input"
            placeholder="Delta Report Header (Optional)"
          />
          <hr />
          <h2>Footer</h2>
          <p>
            You may optionally include text that will appear in the bottom left
            corner of the footer section in the report. This footer will appear
            on all pages of the report.
          </p>
          <input
            value={footer}
            onChange={(e) => setFooter(e.target.value)}
            className="small-input"
            placeholder="Delta Report Footer (Optional)"
          />
          <hr />
          <h2>URL Links</h2>
          <p>
            You may optionally add URL links that will rendered as hyperlinks on
            the report. You may use this to link to related GitHub issues, Jira
            tickets, etc. from the report PDF.
          </p>
          <input
            value={urlLink1}
            onChange={(e) => setURLLink1(e.target.value)}
            className="small-input"
            placeholder="URL Link 1 (Optional)"
          />
          <input
            value={urlLink2}
            onChange={(e) => setURLLink2(e.target.value)}
            className="small-input"
            placeholder="URL Link 2 (Optional)"
          />
          <input
            value={urlLink3}
            onChange={(e) => setURLLink3(e.target.value)}
            className="small-input"
            placeholder="URL Link 3 (Optional)"
          />
          <hr />
          <button onClick={submit}>Generate Report</button>
        </div>
      </div>
    </>
  );
}
