import { useState } from "react";

// components
import CVEs from "../CVEs";

// icons
import { Search, CheckCircle } from "react-feather";

export default function CVEsTab({ binary, data }) {
  let [search, setSearch] = useState("");

  if (!data.cves || data.cves.length === 0)
    return (
      <div style={{ marginTop: -10 }}>
        <h4 style={{ fontWeight: 500 }}>
          No CVEs were detected in this binary.
        </h4>
        <CheckCircle className="big-icon" />
      </div>
    );

  return (
    <>
      <h4
        style={{
          position: "absolute",
          width: "100%",
          zIndex: 20,
          padding: 10,
          left: 0,
          top: 27,
          paddingLeft: 20,
          borderTop: "1px solid var(--bor-color)",
          borderBottom: "1px solid var(--bor-color)",
          background: "var(--bg-color)",
        }}
      >
        <div className="search-row">
          <Search className="user-icon" />
          <input
            onChange={(e) => setSearch(e.target.value)}
            placeholder={`Search ${data.cves.length} CVEs...`}
          ></input>
        </div>
      </h4>
      <div
        style={{
          marginTop: 14,
          transform: "translateX(-10px)",
          width: "calc(100% + 20px)",
        }}
      >
        <CVEs cves={data.cves} cveSearch={search} />
      </div>
    </>
  );
}
