import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import "./CVEs.css";
import { CVE } from "../../constants";
import { Info } from "react-feather";
import { Tooltip } from "react-tippy";
import { BinLensTableColumn, BinLensTable } from "./table";

const CVE_COLUMNS = [
  new BinLensTableColumn(
    "cve_number",
    "Name",
    (a, b) => {
      let a_cve_spl = a.cve_number.split("-");
      let a_year = parseInt(a_cve_spl[1]);
      let a_idx = parseInt(a_cve_spl[2]);
      let b_cve_spl = b.cve_number.split("-");
      let b_year = parseInt(b_cve_spl[1]);
      let b_idx = parseInt(b_cve_spl[2]);
      if (a_year === b_year) return b_idx - a_idx;
      return b_year - a_year;
    },
    "20%"
  ),
  new BinLensTableColumn(
    "severity",
    "Severity",
    (a, b) => {
      const SEV_MAP = (v) => {
        // 'EXPLOITED' severities are replaced with a JSX element
        if (React.isValidElement(v)) {
          return 0;
        }
        switch (v) {
          case "CRITICAL":
            return 1;
          case "HIGH":
            return 2;
          case "MEDIUM":
            return 3;
          case "LOW":
          default:
            return 4;
        }
      };
      return SEV_MAP(a.severity) - SEV_MAP(b.severity);
    },
    "20%"
  ),
  new BinLensTableColumn(
    "product",
    "Product",
    (a, b) => {
      if (a.product < b.product) return -1;
      if (a.product > b.product) return 1;
      return 0;
    },
    "20%"
  ),
  new BinLensTableColumn("description", "Description", null, "40%"),
];
const EXPLOITED = "EXPLOITED";
const KEV = "kev";

export function createDescription(cve) {
  let description = "";
  if (cve.severity === EXPLOITED && KEV in cve) {
    let exploited = cve[KEV];
    description += `${exploited.vulnerabilityName}\n\n`;
    description +=
      `This CVE was added to CISA's Known ` +
      `Exploited Vulnerabilities Catalog on ${exploited.dateAdded}.\n`;
    description += `${exploited.shortDescription}\n`;
    description += `Required Action: ${exploited.requiredAction}\n\n`;
  }
  description += cve.description;
  return description;
}

/**
 * Displays a table of CVEs when viewing a binary report.
 */
export default function CVEs({ cves, cveSearch = "" }) {
  // filter by CVE number
  let filtered_cves = cves.filter((cve) =>
    cve.cve_number.toLowerCase().includes(cveSearch.toLowerCase())
  );

  // update CVE descriptions & severity
  for (let cve of filtered_cves) {
    cve.description = createDescription(cve);
    if (cve.severity === EXPLOITED) {
      cve.severity = (
        <div className="cve-severity-with-info">
          {cve.severity}
          {cve.severity === EXPLOITED && (
            <Tooltip
              title="A CVE is labeled EXPLOITED if is has been 
                    exploited in the past and is a part of CISA's 
                    Known Exploited Vulnerabilities Catalog. 
                    This does not mean the CVE has necessarily been 
                    exploited in your environment."
              position="bottom"
              trigger="mouseenter"
              arrow
            >
              <Info className="icon" />
            </Tooltip>
          )}
        </div>
      );
    }
  }

  return (
    <BinLensTable
      data={filtered_cves}
      columns={CVE_COLUMNS}
      defaultSortFuncIdx={1}
    />
  );
}

CVEs.propTypes = {
  /**
   * List of CVEs to display.
   */
  cves: PropTypes.arrayOf(PropTypes.instanceOf(CVE)).isRequired,

  /**
   * Search text to filter CVEs.
   */
  cveSearch: PropTypes.string.isRequired,
};
