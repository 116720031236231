import "./binary_summary_components.css";
import { Info } from "react-feather";

export default function BinarySummaryInfoHelper({ text, link }) {
  return (
    <div className="binary-summary-info-helper">
      <Info className="icon" />
      <h3>
        {text}{" "}
        {link && (
          <a href={link} target="_blank">
            Read more.
          </a>
        )}
      </h3>
    </div>
  );
}
