import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import { Modal } from "react-bootstrap";
import "./create_user_modal.css";

// icons
import { X, UserPlus, User, Check } from "react-feather";
import { addRoles, createUser, getAllUsers } from "../../api";

// components
import UserManagementTable from "./user_management_table";

/**
 * Modal that appears when a user being created.
 * To create a user, the following information is required:
 * 1. Username.
 * 2. Email.
 * User ID and password are randomly generated by the Back-End.
 * To enable a user to set their password, click the "Copy Invite Link"
 * button and send the link to them.
 */
export default class CreateUserModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      password: "",
      isSuperadmin: false,
      step: "start",
      loadingUsers: true,
      selectedUsers: [],
    };

    this.createUserClick = this.createUserClick.bind(this);
    this.addSelectedUsers = this.addSelectedUsers.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.modal !== prevProps.modal) {
      this.setState({
        username: "",
        email: "",
        password: "",
        isSuperadmin: false,
        step: "start",
        loadingUsers: true,
        selectedUsers: [],
      });
    }
  }

  async createUserClick() {
    let res = await createUser(
      this.state.username,
      (Math.random() + 1).toString(36).substring(7),
      this.state.email,
      this.props.selectedOrgId
    );
    if (!res.ok) {
      let text = await res.text();
      alert(text);
      return;
    }

    // refresh the set of users on the page
    await UserManagementTable._this.refresh();
    this.props.backFunction();
  }

  async addSelectedUsers() {
    if (this.state.selectedUsers.length === 0) {
      alert("Please select the users you wish to add to the organization.");
      return;
    }
    this.setState({ loadingUsers: true });
    for (let u of this.state.selectedUsers) {
      await addRoles(["operator"], u, this.props.selectedOrgId);
    }
    await UserManagementTable._this.refresh();
    this.props.backFunction();
  }

  /**
   * Render the component.
   */
  render() {
    switch (this.state.step) {
      case "new":
        return (
          <Modal
            show={this.props.modal}
            animation={false}
            className="modal-bg"
            enforceFocus={false}
          >
            <Modal.Body
              className="modal2 create-user-modal-top"
              style={{
                minHeight: 260,
                maxHeight: 260,
              }}
            >
              <div className="login-page-old create-user-modal">
                <div
                  className="sec-button x-user"
                  onClick={this.props.backFunction}
                >
                  <X className="user" />
                </div>

                <h2 className="man-user-title perm-title set-side-title">
                  USERNAME
                </h2>
                <input
                  placeholder="Username..."
                  value={this.state.username}
                  onChange={(e) => this.setState({ username: e.target.value })}
                ></input>

                <h2 className="man-user-title perm-title set-side-title">
                  EMAIL
                </h2>
                <input
                  placeholder="Email..."
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                ></input>

                <button onClick={this.createUserClick} className="upload">
                  Create User
                </button>
              </div>
            </Modal.Body>
          </Modal>
        );
      case "old":
        return (
          <Modal
            show={this.props.modal}
            animation={false}
            className="modal-bg"
            enforceFocus={false}
          >
            <Modal.Body
              className="modal2"
              style={{ maxWidth: "700px !important" }}
            >
              <div>
                <div
                  className="sec-button x-user"
                  onClick={this.props.backFunction}
                >
                  <X className="user" />
                </div>

                {!this.state.loadingUsers && (
                  <div>
                    <div
                      style={{
                        marginTop: 30,
                        overflowY: "scroll",
                        height: 200,
                        minWidth: 400,
                        marginBottom: 70,
                      }}
                    >
                      {this.state.users.map((u) => {
                        if (
                          Object.keys(u.roles).includes(
                            this.props.selectedOrgId
                          )
                        )
                          return null;
                        return (
                          <div
                            key={u.id}
                            className="existing-user"
                            onClick={() => {
                              let selectedUsers = [...this.state.selectedUsers];
                              if (selectedUsers.includes(u.id)) {
                                selectedUsers.splice(
                                  selectedUsers.indexOf(u.id),
                                  1
                                );
                              } else {
                                selectedUsers.push(u.id);
                              }
                              this.setState({ selectedUsers });
                            }}
                          >
                            <h2
                              style={{
                                color: this.state.selectedUsers.includes(u.id)
                                  ? "var(--green-color)"
                                  : "var(--pri-light)",
                              }}
                            >
                              {u.username}
                            </h2>
                            <h2
                              style={{
                                color: this.state.selectedUsers.includes(u.id)
                                  ? "var(--green-color)"
                                  : "var(--pri-color-light)",
                              }}
                            >
                              {u.email}
                            </h2>
                            <div
                              style={{
                                borderRadius: "100%",
                                minWidth: 15,
                                minHeight: 15,
                                borderWidth: 2,
                                marginRight: 20,
                                borderStyle: "solid",
                                marginLeft: "auto",
                                borderColor: this.state.selectedUsers.includes(
                                  u.id
                                )
                                  ? "var(--green-color)"
                                  : "var(--pri-color-light)",
                                position: "relative",
                              }}
                            >
                              {this.state.selectedUsers.includes(u.id) && (
                                <Check
                                  style={{
                                    stroke: "var(--green-color)",
                                    position: "absolute",
                                    height: 15,
                                    width: 15,
                                    strokeWidth: 3,
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <button
                      onClick={this.addSelectedUsers}
                      className="upload"
                      style={{
                        position: "absolute",
                        bottom: 20,
                        right: 20,
                      }}
                    >
                      Add Selected Users to Organization
                    </button>
                  </div>
                )}
                {this.state.loadingUsers && (
                  <div>
                    <div className="lds-dual-ring"></div>
                  </div>
                )}
              </div>
            </Modal.Body>
          </Modal>
        );
      case "start":
      default:
        return (
          <Modal
            show={this.props.modal}
            animation={false}
            className="modal-bg"
            enforceFocus={false}
          >
            <Modal.Body className="modal2 ">
              <div className="start_user">
                <div
                  className="sec-button x-user"
                  onClick={this.props.backFunction}
                >
                  <X className="user" />
                </div>

                <div style={{ display: "flex" }}>
                  <div
                    className="start_user_option"
                    onClick={() => {
                      this.setState({ step: "old", loadingUsers: true });
                      getAllUsers().then((users) => {
                        this.setState({ users, loadingUsers: false });
                      });
                    }}
                  >
                    <User className="icon" />
                    <h2 style={{ marginRight: 10, marginTop: 10 }}>
                      Add Existing User
                    </h2>
                  </div>
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                      margin: 10,

                      height: 160,
                    }}
                  >
                    <hr />
                    <h3>or</h3>
                    <hr />
                  </div>
                  <div
                    className="start_user_option"
                    onClick={() => this.setState({ step: "new" })}
                  >
                    <UserPlus className="icon" />
                    <h2 style={{ marginRight: 10, marginTop: 10 }}>
                      Create New User
                    </h2>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        );
    }
  }
}

CreateUserModal.propTypes = {
  /**
   * Is the modal appearing or not.
   */
  modal: PropTypes.bool.isRequired,

  /**
   * Callback that turns the modal off.
   */
  backFunction: PropTypes.func.isRequired,

  /**
   * The currently selected organization.
   * This is the organization the newly created user will be
   * initially added to.
   */
  selectedOrgId: PropTypes.string.isRequired,
};
