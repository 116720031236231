import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";
import { calcColor } from "../../../helpers";

export default function FacilitySummaryMap({ facility }) {
  return (
    <div
      style={{
        marginTop: 40,
        marginBottom: 40,
      }}
    >
      <ComposableMap
        projection="geoMercator"
        style={{
          borderRadius: 10,
          pointerEvents: "none",
          height: 450,
          width: "100%",
          overflow: "hidden",
          background: "var(--sec-bg-color)",
        }}
      >
        <ZoomableGroup zoom={3} center={facility.latLng}>
          <Geographies geography={"./map_data.json"}>
            {({ geographies, projection }) =>
              geographies.map((geo) => {
                return (
                  <Geography
                    style={{
                      default: { outline: "none" },
                      hover: { outline: "none" },
                      pressed: { outline: "none" },
                    }}
                    key={geo.rsmKey}
                    geography={geo}
                    fill="var(--bg-color)"
                    stroke="var(--bor-color)"
                  />
                );
              })
            }
          </Geographies>
          <Marker coordinates={facility.latLng}>
            <circle
              style={{ cursor: "pointer" }}
              r={2.3}
              fill={calcColor(facility.vulnerability_score)}
              filter={calcColor(facility.vulnerability_score)}
            />
          </Marker>
        </ZoomableGroup>
      </ComposableMap>
    </div>
  );
}
