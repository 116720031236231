import { FileText } from "react-feather";
import { PAGES } from "./asset_summary";

export default function AssetSummaryHeader({ asset, page, setPage }) {
  return (
    <div className="toggle-header" style={{ zIndex: 1 }}>
      {Object.values(PAGES).map((p) => (
        <div
          className={
            page === p
              ? "toggle-header-item sel-toggle-header-item"
              : "toggle-header-item"
          }
          onClick={() => {
            setPage(p);
          }}
        >
          <h3>{p}</h3>
          {page === p && <div className="blocker"></div>}
        </div>
      ))}
      <div className="side-buttons" style={{ height: 36 }}>
        {asset.state !== "in progress" && (
          <button
            style={{ width: 160 }}
            className="upload"
            onClick={() => {
              window.location.assign(
                `#/report/builder/asset?id=${asset.ref_id}&fromBinaryAnalysis`
              );
            }}
          >
            <h3>Generate Report</h3>
            <FileText className="edit-icon" />
          </button>
        )}
      </div>
    </div>
  );
}
