/*
    This file wraps the components that appear on the facility report.

    This component is used both when creating a facility report, and when
    creating a concatenated report.
    
    When this component is being displayed on a concatenated report,
    it automatically displays all of its results.
*/
import { useRef, useEffect } from "react";

import { createPortal } from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// components
import ReportBuilderComponent from "./report_builder_component";
import FacilitySummaryTitle from "../../../binary_analysis_page/facility_summary_components/facility_summary_title";
import FacilitySummaryMap from "../../../binary_analysis_page/facility_summary_components/facility_summary_map";
import FacilitySummaryDescription from "../../../binary_analysis_page/facility_summary_components/facility_summary_description";

export default function FacilityReport({
  items,
  setItems,
  facility_id,
  facility,
  assets,
  done,
}) {
  // all components on facility reports are stateless, therefore,
  // facility reports are done immediately upon first render
  useEffect(() => {
    if (done) done();
  }, []);

  const useDraggableInPortal = () => {
    const self = useRef({}).current;

    useEffect(() => {
      const div = document.createElement("div");
      div.style.position = "absolute";
      div.style.pointerEvents = "none";
      div.style.top = "0";
      div.style.width = "100%";
      div.style.height = "100%";
      self.elt = div;
      document.body.appendChild(div);
      return () => {
        document.body.removeChild(div);
      };
    }, [self]);

    return (render) =>
      (provided, ...args) => {
        const element = render(provided, ...args);
        if (provided.draggableProps.style.position === "fixed") {
          return createPortal(element, self.elt);
        }
        return element;
      };
  };
  const renderDraggable = useDraggableInPortal();

  return (
    <div>
      {/* Required components. */}
      <FacilitySummaryTitle facility={facility} />
      <FacilitySummaryMap facility={facility} />
      <FacilitySummaryDescription facility={facility} />
      <div style={{ height: 40 }}></div>
      {/* Dynamic list of items. */}
      <DragDropContext
        onDragEnd={(result) => {
          if (!result.destination) {
            return;
          }
          setItems((items) => {
            const [removed] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, removed);
            return items;
          });
        }}
      >
        <Droppable droppableId="droppable" style={{ overflow: "scroll" }}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{}}
            >
              {items.map((item, index) => {
                return (
                  <Draggable
                    className="report-builder-component"
                    draggableId={item.id}
                    index={index}
                    key={item.id}
                  >
                    {renderDraggable((provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                        }}
                      >
                        <ReportBuilderComponent
                          facility_id={facility_id}
                          setItems={setItems}
                          item={item}
                        />
                        {provided.placeholder}
                      </div>
                    ))}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="row">
        <img
          className="bottom-report-logo"
          src="/binlens_logo.png"
          alt="logo"
        />
        {assets.length} Assets
      </div>
    </div>
  );
}
