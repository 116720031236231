/*
    This component appears when a file is being deleted
    from the dropzone.
    It displays a simple loading spinner.
*/

export default function DeletingFile() {
  return (
    <div className="new-analysis-centerpiece">
      <div className="lds-dual-ring"></div>
      <h4>Processing</h4>
    </div>
  );
}
