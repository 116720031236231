import PropTypes from "prop-types";
import { AlertCircle } from "react-feather";
import "./alert.css";
import { parseTime } from "../../helpers";
import { Alert } from "../../constants";

/**
 * A single flash alert as it is displayed in the Headbar
 * Alerts component.
 * Displays the title, message, and creation datetime.
 */
export default function AlertComponent({ alert }) {
  let readby = false;
  let userid = window.localStorage.getItem("userid");
  if (alert.readby.includes(userid)) readby = true;

  return (
    <div className="alert-line-item">
      <AlertCircle className="icon" />
      <div>
        <p>{alert.title}</p>
        <div className="mapping-field">{alert.text}</div>
        <h3>{parseTime(alert.datetime)}</h3>
      </div>
    </div>
  );
}

Alert.propTypes = {
  /**
   * The alert data to display.
   */
  alert: PropTypes.instanceOf(Alert).isRequired,
}