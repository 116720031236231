import React from "react";
import "../../index.css";
import "./server_updates.css";
import {
  getUserProfile,
  applyLicense,
  changePassword,
  getDeploymentType,
} from "../../api";
import "react-tippy/dist/tippy.css";
import { TextField } from "@mui/material";

// icons
import { Search, Server, Users } from "react-feather";

// components
import Sidebar from "../sidebar/sidebar";
import Headbar from "../headbar/headbar";
import Botbar from "../botbar/botbar";
import CreateUserModal from "./create_user_modal";
import UserManagementTable from "./user_management_table";
import Notifications from "../login_page/notifications";
import BigDrop from "../sidebar/big_drop";
import CreateOrganizationModal from "./create_organization_modal";
import RightHandSidebar from "../sidebar/right_hand_sidebar";
import ELKLoggerInput from "./elk_logger_input";
import DBSetting, {
  ASSESSORS,
  CVE_ASSESSOR_FILE,
  MALWARE_ASSESSOR_FILE,
  CPE_ASSESSOR_FILE,
} from "./assessor_db_settings/db_setting";

/**
 * Settings page of the application.
 * The settings page can be broken into multiple sections.
 *
 * User Management: Displayed as a table of users which allows for
 * editing, deletion, and creation of users. There are tables
 * for each organization. These tables only show up if the current user
 * is the admin of said organization.
 *
 * Deployment: Deployment settings include license application
 * and SUPERADMIN account manipulation.
 */
export default class SettingsPage extends React.Component {
  constructor(props) {
    super(props);

    document.title = "BinLens - Settings";

    let darkMode = window.localStorage.getItem("darkMode") === "true";
    this.state = {
      page: "USER_MANAGEMENT",
      darkMode,
      createUserModal: false,
      userSearch: "",
      isSuperadmin: false,
      isAdmin: false,
      createOrganizationModal: false,
      selectedOrgId: null,

      // for license file application
      file: null,
      licenseLoading: false,
      licenseResponse: "",

      // password changing
      password: "",
      paswordResponse: null,

      // deployment type determines if certain settings are available
      deploymentType: null,
    };

    this.init = this.init.bind(this);

    this.hiddenFileInput = React.createRef();
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    let profile = await getUserProfile(window.localStorage.getItem("userid"));
    if (!profile) return;
    let isSuperadmin = false;
    let isAdmin = false;
    let adminOrgs = [];
    for (let org_id in profile.roles) {
      let org = profile.roles[org_id];
      for (let role of org) {
        if (role === "superadmin") {
          isSuperadmin = true;
          break;
        } else if (role === "admin") {
          isAdmin = true;
          adminOrgs.push(org_id);
        }
      }
    }
    this.setState({ isSuperadmin, isAdmin });

    let deploymentType = await getDeploymentType();
    this.setState({ deploymentType });
  }

  /**
   * Render the page.
   */
  render() {
    return (
      <BigDrop>
        <main>
          {/* Modals */}
          <CreateUserModal
            modal={this.state.createUserModal}
            backFunction={() => this.setState({ createUserModal: false })}
            selectedOrgId={this.state.selectedOrgId}
          />

          <CreateOrganizationModal
            modal={this.state.createOrganizationModal}
            backFunction={() =>
              this.setState({ createOrganizationModal: false })
            }
          />

          {/* Header */}
          <Headbar />
          <Botbar />
          <Notifications />

          {/* Get Started */}
          <div className="main-row">
            <Sidebar page="settings" />

            {/* Past Uploads */}

            <div className="settings-list">
              <div className="left-row">
                <h2>Settings</h2>
              </div>

              <div
                onClick={() => this.setState({ page: "USER_MANAGEMENT" })}
                className={
                  this.state.page === "USER_MANAGEMENT"
                    ? "managed-user sel-set"
                    : "managed-user"
                }
              >
                <Users className="user r-s" />
                <div>
                  <h2>Users</h2>
                </div>
                {this.state.page === "USER_MANAGEMENT" && (
                  <div className="setting-selected"></div>
                )}
              </div>

              {this.state.isSuperadmin && (
                <div
                  onClick={() => this.setState({ page: "DEPLOYMENT" })}
                  className={
                    this.state.page === "DEPLOYMENT"
                      ? "managed-user sel-set"
                      : "managed-user"
                  }
                >
                  <Server className="user r-s" />
                  <div>
                    <h2>Deployment</h2>
                  </div>
                  {this.state.page === "DEPLOYMENT" && (
                    <div className="setting-selected"></div>
                  )}
                </div>
              )}
            </div>

            {this.state.page === "USER_MANAGEMENT" && (
              <div className="flash-alerts manage-users setting-man-u">
                <div className="left-row" style={{ marginTop: 30 }}>
                  {(this.state.isSuperadmin || this.state.isAdmin) && (
                    <div className="search-row">
                      <Search
                        className="user-icon"
                        style={{ minWidth: 20, minHeight: 20, marginRight: 10 }}
                      />
                      <input
                        value={this.state.userSearch}
                        onChange={(e) =>
                          this.setState({ userSearch: e.target.value })
                        }
                        placeholder={`Search Users...`}
                      ></input>
                    </div>
                  )}
                  {this.state.isSuperadmin && (
                    <button
                      onClick={() => {
                        this.setState({ createOrganizationModal: true });
                      }}
                      className="upload"
                      style={{
                        marginRight: 10,
                        marginLeft: 10,
                        whiteSpace: "nowrap",
                      }}
                    >
                      Create Organization
                    </button>
                  )}
                </div>

                <UserManagementTable
                  search={this.state.userSearch}
                  createUserModal={(org_id) => {
                    this.setState({
                      createUserModal: true,
                      selectedOrgId: org_id,
                    });
                  }}
                  setIsSuperadmin={(isSuperadmin) =>
                    this.setState({ isSuperadmin })
                  }
                  setIsAdmin={(isAdmin) => this.setState({ isAdmin })}
                />

                <h2>Change Your Password:</h2>
                <div className="change-password-row">
                  <TextField
                    value={this.state.password}
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                    label="New Password"
                    type="password"
                    autoComplete="off"
                    inputProps={{
                      autocomplete: "new-password",
                      form: {
                        autocomplete: "off",
                      },
                    }}
                  />
                  {this.state.password !== "" && (
                    <button
                      onClick={async () => {
                        this.setState({ passwordResponse: null });
                        let passwordResponse = await changePassword(
                          window.localStorage.getItem("userid"),
                          this.state.password
                        );
                        this.setState({ passwordResponse });
                      }}
                    >
                      Submit
                    </button>
                  )}
                  {this.state.passwordResponse && (
                    <p>{this.state.passwordResponse}</p>
                  )}
                </div>
              </div>
            )}

            {this.state.page === "DEPLOYMENT" && (
              <div className="flash-alerts manage-users setting-man-u deployment-settings">
                {/* Add license. */}
                {this.state.deploymentType !== "CLOUD" && (
                  <>
                    <h2 style={{ marginTop: 40 }}>Apply License</h2>
                    <div className="server-update-form">
                      <div>
                        <button
                          onClick={() => this.hiddenFileInput.current.click()}
                        >
                          Choose File
                        </button>

                        <input
                          type="file"
                          ref={this.hiddenFileInput}
                          onChange={(e) => {
                            this.setState({ file: e.target.files[0] });
                          }}
                          style={{ display: "none" }}
                        />
                        <p>
                          {this.state.file === null
                            ? "No file selected."
                            : this.state.file.name}
                        </p>
                      </div>

                      {!this.state.licenseLoading && this.state.file && (
                        <button
                          onClick={async () => {
                            this.setState({ licenseLoading: true });
                            let licenseResponse = await applyLicense(
                              this.state.file
                            );
                            this.setState({
                              licenseLoading: false,
                              licenseResponse,
                            });
                          }}
                          style={{ marginLeft: 10 }}
                        >
                          Submit
                        </button>
                      )}
                      {this.state.licenseLoading && (
                        <div className="lds-dual-ring"></div>
                      )}
                      {!this.state.licenseLoading && (
                        <p>{this.state.licenseResponse}</p>
                      )}
                    </div>
                    <hr />
                  </>
                )}
                {this.state.deploymentType === "LOCAL" && (
                  <>
                    <DBSetting
                      title={"Update CVE Database"}
                      description="We use a Common Vulnerability Enumeration (CVE)
                        signature database to perform 
                        CVE detection. This database can be updated by uploading 
                        a database file below. Please contact ObjectSecurity to 
                        receive the most recent database file."
                      assessor={ASSESSORS.CVES}
                      assessor_file={CVE_ASSESSOR_FILE.DEFAULT}
                    />

                    <DBSetting
                      title={"Update KEV Catalog"}
                      description="CISA's Known Exploited Vulnerabilities (KEV) Catalog
                        is a living list of know CVEs that have been exploited in
                        the past. This list can be updated by uploading a KEV
                        JSON file below. Please contact ObjectSecurity 
                        to receive the most recent file."
                      assessor={ASSESSORS.CVES}
                      assessor_file={CVE_ASSESSOR_FILE.KEV}
                    />

                    <DBSetting
                      title={"Update CPEs Dictionary"}
                      description="We use a dictionary file of Common 
                        Platform Enumerations (CPEs) to determine if certain
                        assets contain vulnerabilities. This list can be 
                        updated by uploading a CPE XML file below. Please contact 
                        ObjectSecurity to receive the most recent file."
                      assessor={ASSESSORS.CPES}
                      assessor_file={CPE_ASSESSOR_FILE.DEFAULT}
                    />

                    <DBSetting
                      title={"Update Malware Database"}
                      description="We use a malware signature database to perform 
                        malware detection. This database can be updated by uploading a 
                        database file below. Please contact ObjectSecurity to 
                        receive the most recent database file."
                      assessor={ASSESSORS.MALWARE}
                      assessor_file={MALWARE_ASSESSOR_FILE.DEFAULT}
                    />
                  </>
                )}

                <ELKLoggerInput />
              </div>
            )}

            <RightHandSidebar />
          </div>
        </main>
      </BigDrop>
    );
  }
}
