/*
    This file defines a component that enables the user to enter their
    S3 credentials such that they may later be used to ingress files
    into the asset analysis dropzone.

    This component appears on the right-side of the file drop modal.
*/
import { TextField } from "@mui/material";

export default function S3Creds({
  cancel,
  next,
  accessKeyId,
  setAccessKeyId,
  secretKeyId,
  setSecretKeyId,
  bucket,
  setBucket,
}) {
  return (
    <div className="s3-creds">
      <TextField
        value={accessKeyId}
        onChange={(e) => setAccessKeyId(e.target.value)}
        label="AWS Access Key Id"
        fullWidth
        type="password"
      />
      <TextField
        value={secretKeyId}
        onChange={(e) => setSecretKeyId(e.target.value)}
        label="AWS Secret Access Key"
        fullWidth
        type="password"
      />
      <TextField
        value={bucket}
        onChange={(e) => setBucket(e.target.value)}
        label="S3 Bucket Name"
        fullWidth
      />
      <div className="row">
        <button onClick={cancel}>Cancel</button>
        <button onClick={next}>Next</button>
      </div>
    </div>
  );
}
