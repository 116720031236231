import PropTypes from "prop-types";
import { Binary } from "../../../constants";
import { calcColor, calcIcon, calcText } from "../../../helpers";

/**
 * Title section that appears on the Binary Summary Panel,
 * and on the Custom Report page.
 * Displays binary name and vulnerability score indicator.
 */
export default function BinarySummaryTitle({ binary }) {
  return (
    <div
      className="row-2"
      style={{
        justifyContent: "flex-start",
        marginBottom: 30,
        padding: 20,
        borderRadius: 10,
        border: "2px solid var(--sec-bg-color)",
        width: "calc(100% - 40px)",
        overflow: "hidden",
      }}
    >
      {binary.aggregate && (
        <div
          className="score-g"
          style={{
            borderColor: calcColor(binary.aggregate),
            background: calcColor(binary.aggregate),
          }}
        >
          {calcIcon(binary.aggregate)}
        </div>
      )}
      {!binary.aggregate && (
        <div
          className="score-g"
          style={{
            borderColor: "var(--sec-bg-color)",
            background: "var(--sec-bg-color)",
          }}
        >
          <div className="in-progress-ring-big"></div>
        </div>
      )}

      <div>
        <h2
          className="prev-file-name"
          style={{ fontSize: 24, marginBottom: 10 }}
        >
          {binary.name}
        </h2>
        {binary.aggregate && (
          <h3 style={{ marginTop: 5, fontSize: 16, lineHeight: 1.4 }}>
            {calcText(binary.aggregate, "binary")}
          </h3>
        )}
        {!binary.aggregate && (
          <h3 style={{ marginTop: 5, fontSize: 16, lineHeight: 1.4 }}>
            This binary is still under assessment. Only partial results are
            shown.
          </h3>
        )}
      </div>
    </div>
  );
}

BinarySummaryTitle.propTypes = {
  /**
   * The binary to display the title for.
   */
  binary: PropTypes.instanceOf(Binary).isRequired,
};
