import { useState } from "react";
import PropTypes from "prop-types";
import "./create_alert.css";
import { createAlert } from "../../api";

// icons
import { X } from "react-feather";

/**
 * Popup that enables the SUPERADMIN to generate a new alert
 * in their currently location.
 */
export default function CreateAlert({ close, refresh }) {
  let [alert, setAlert] = useState("");

  const submitAlert = async () => {
    await createAlert(alert);
    close();
    refresh();
  };

  return (
    <div className="single-string-modal-bg">
      <div className="single-string-modal create-alert">
        <X className="icon" onClick={close} />

        <div className="add-single-string">
          <h1>Create Alert</h1>
          <div className="single-string-name-input">
            <textarea
              value={alert}
              onChange={(e) => setAlert(e.target.value)}
              placeholder="The alert you type here will be sent to every user in this organization..."
            />
            <button
              className={alert !== "" ? "button-alive" : ""}
              onClick={submitAlert}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

CreateAlert.propTypes = {
  /**
   * Callback function to close the popup.
   */
  close: PropTypes.func.isRequired,

  /**
   * Callback function to refresh the set of loaded alerts.
   */
  refresh: PropTypes.func.isRequired,
};
