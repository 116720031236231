import React from "react";
import "../../index.css";

// icons
import {
  AlertCircle,
  AlertOctagon,
  AlertTriangle,
  CheckCircle,
  Clipboard,
  Lock,
  Terminal,
  Trash,
  UploadCloud,
} from "react-feather";

/**
 * Notifications component that exists of almost every page of the
 * application.
 * Notifications appear in the bottom right corner of the screen and
 * disappear after a short duration. The max number of notifications that
 * can be displayed at any given time is 3.
 */
export default class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
    };
    this.addClip = this.addClip.bind(this);
    this.popNotif = this.popNotif.bind(this);

    Notifications._this = this;
  }

  popNotif() {
    if (this !== Notifications._this) return;
    let notifs = [...this.state.notifications];
    notifs.pop();
    this.setState({
      notifications: notifs,
    });
  }

  static _this = null;

  /**
   * Add notfication saying the user has copied to clipboard
   */
  addClip() {
    let notifications = [...this.state.notifications];
    notifications.push("clip");
    this.setState({ notifications });
    setTimeout(() => {
      this.popNotif();
    }, 3000);
  }

  /**
   * Add notfication saying the user has updated a password.
   */
  addPassword() {
    let notifications = [...this.state.notifications];
    notifications.push("password");
    this.setState({ notifications });
    setTimeout(() => {
      this.popNotif();
    }, 3000);
  }

  /**
   * Add notfication saying the user has imported an analysis.
   */
  addImport() {
    let notifications = [...this.state.notifications];
    notifications.push("import");
    this.setState({ notifications });
    setTimeout(() => {
      this.popNotif();
    }, 3000);
  }

  /**
   * Add notfication saying an analysis has completed.
   */
  addAnalysis() {
    let notifications = [...this.state.notifications];
    notifications.push("analysis");
    this.setState({ notifications });
    setTimeout(() => {
      this.popNotif();
    }, 3000);
  }

  /**
   * Add notfication saying the user's session has expired.
   */
  addExpiration() {
    let notifications = [...this.state.notifications];
    notifications.push("expiration");
    this.setState({ notifications });
    setTimeout(() => {
      this.popNotif();
    }, 3000);
  }

  /**
   * Add notfication saying a feature is currently non-functional.
   */
  addNonfunctional() {
    let notifications = [...this.state.notifications];
    notifications.push("nonfunctional");
    this.setState({ notifications });
    setTimeout(() => {
      this.popNotif();
    }, 3000);
  }

  /**
   * Add notfication saying a feature is currently non-functional.
   */
  addNameTaken() {
    let notifications = [...this.state.notifications];
    notifications.push("nametaken");
    this.setState({ notifications });
    setTimeout(() => {
      this.popNotif();
    }, 3000);
  }

  /**
   * Add notfication saying a page is conceptual.
   */
  addConceptual() {
    let notifications = [...this.state.notifications];
    notifications.push("concept");
    this.setState({ notifications });
    setTimeout(() => {
      this.popNotif();
    }, 10000);
  }

  /**
   * Add notfication saying a feature is currently non-functional.
   */
  addDeleteFacility() {
    let notifications = [...this.state.notifications];
    notifications.push("deletefacility");
    this.setState({ notifications });
    setTimeout(() => {
      this.popNotif();
    }, 3000);
  }

  /**
   * Add notfication saying the user has imported the wrong file type.
   */
  addWrongImport() {
    let notifications = [...this.state.notifications];
    notifications.push("wrong_import");
    this.setState({ notifications });
    setTimeout(() => {
      this.popNotif();
    }, 3000);
  }

  /**
   * Add notfication saying the user has reach the max number of analyzable binaries.
   */
  addMaxBinaries() {
    let notifications = [...this.state.notifications];
    notifications.push("max_binaries");
    this.setState({ notifications });
    setTimeout(() => {
      this.popNotif();
    }, 3000);
  }

  /**
   * Add notfication saying the user should unzip their uploaded zip file.
   */
  addUnzip() {
    let notifications = [...this.state.notifications];
    notifications.push("unzip");
    this.setState({ notifications });
    setTimeout(() => {
      this.popNotif();
    }, 3000);
  }

  /**
   * Add notfication saying the user should request a Beta extension soon.
   */
  addRemindExtend(days_from) {
    let notifications = [...this.state.notifications];
    // TODO all of these should be objects (not strings)
    notifications.push({
      type: "remind_extend",
      arg: days_from,
    });
    this.setState({ notifications });
    setTimeout(() => {
      this.popNotif();
    }, 10000);
  }

  addLockout() {
    let notifications = [...this.state.notifications];

    notifications.push("lockout");
    this.setState({ notifications });
    setTimeout(() => {
      this.popNotif();
    }, 5000);
  }

  addWrong() {
    let notifications = [...this.state.notifications];
    notifications.push("wrong");
    this.setState({ notifications });
    setTimeout(() => {
      this.popNotif();
    }, 5000);
  }

  addNoLine() {
    let notifications = [...this.state.notifications];
    notifications.push("no-line");
    this.setState({ notifications });
    setTimeout(() => {
      this.popNotif();
    }, 5000);
  }

  addNoAddress() {
    let notifications = [...this.state.notifications];
    notifications.push("no-address");
    this.setState({ notifications });
    setTimeout(() => {
      this.popNotif();
    }, 5000);
  }

  /**
   * Render the component.
   */
  render() {
    return (
      <div className="notifs">
        {this.state.notifications.slice(0, 3).map((n, i) => {
          // TODO all notifications should eventually be objects
          if (typeof n === "object") {
            return (
              <div key={i} className="to-clipboard">
                <AlertCircle className="user" />
                <h3>
                  Your usage period expires in {n.arg} days <br />
                  Please contact ObjectSecurity to extend your usage.
                </h3>
              </div>
            );
          }

          switch (n) {
            case "max_binaries":
              return (
                <div key={i} className="to-clipboard">
                  <AlertOctagon className="user" />
                  <h3>
                    You have reached the maximum number of analyzable binaries!
                    <br />
                    Please contact ObjectSecurity to extend your usage.
                  </h3>
                </div>
              );
            case "wrong_import":
              return (
                <div key={i} className="to-clipboard">
                  <AlertOctagon className="user" />
                  <h3>
                    Wrong type of imported! <br /> Please import an analysis
                    JSON file.
                  </h3>
                </div>
              );
            case "nametaken":
              return (
                <div key={i} className="to-clipboard">
                  <AlertOctagon className="user" />
                  <h3>This name is already taken by another organization!</h3>
                </div>
              );
            case "deletefacility":
              return (
                <div key={i} className="to-clipboard">
                  <Trash className="user" />
                  <h3>The facility was deleted.</h3>
                </div>
              );
            case "nonfunctional":
              return (
                <div key={i} className="to-clipboard">
                  <AlertCircle className="user" />
                  <h3>
                    This feature is not implemented in the current version!
                  </h3>
                </div>
              );
            case "expiration":
              return (
                <div key={i} className="to-clipboard">
                  <AlertTriangle className="user" />
                  <h3>Your session has expired! Please re-login.</h3>
                </div>
              );
            case "concept":
              return (
                <div key={i} className="to-clipboard">
                  <AlertCircle className="user" />
                  <h3>
                    This page is a conceptualization and is <br /> not fully
                    implemented in the current version!
                  </h3>
                </div>
              );
            case "import":
              return (
                <div key={i} className="to-clipboard">
                  <UploadCloud className="user" />
                  <h3>Imported analysis.</h3>
                </div>
              );
            case "analysis":
              return (
                <div key={i} className="to-clipboard">
                  <CheckCircle className="user" />
                  <h3>Completed analysis.</h3>
                </div>
              );
            case "password":
              return (
                <div key={i} className="to-clipboard">
                  <Lock className="user" />
                  <h3>Updated password.</h3>
                </div>
              );
            case "unzip":
              return (
                <div key={i} className="to-clipboard">
                  <UploadCloud className="user" />
                  <h3>Please unzip your file before uploading!</h3>
                </div>
              );
            case "lockout":
              return (
                <div key={i} className="to-clipboard">
                  <AlertOctagon className="user" />
                  <h3>
                    You have reached you usage expiration date.
                    <br />
                    Please contact ObjectSecurity to extend your usage.
                  </h3>
                </div>
              );
            case "clip":
              return (
                <div key={i} className="to-clipboard">
                  <Clipboard className="user" />
                  <h3>Copied to clipboard.</h3>
                </div>
              );
            case "no-address":
              return (
                <div key={i} className="to-clipboard">
                  <Terminal className="user" />
                  <h3>This address could not be found by the disassembler.</h3>
                </div>
              );
            case "no-line":
              return (
                <div key={i} className="to-clipboard">
                  <Terminal className="user" />
                  <h3>This line could not be found by the decompiler.</h3>
                </div>
              );
            case "wrong":
            default:
              return (
                <div key={i} className="to-clipboard">
                  <AlertOctagon className="user" />
                  <h3>
                    Something went wrong! Please try again.
                    <br />
                    If the problem persists, submit a support ticket.
                  </h3>
                </div>
              );
          }
        })}
      </div>
    );
  }
}
