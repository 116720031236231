/*
    This file contains a list of helper functions used in the AnalysisQueue
    component.
*/

/**
 * Given a list of assessments, calculates the total percentage towards
 * completion.
 */
export function calcPercentCompleted(assets) {
  if (assets.length === 0) {
    return 1;
  }
  let done = 0;
  let total = 0;
  for (let asset of assets) {
    if (asset.state === "RUNNING")
      done += asset.progress;
    total += 1;
  }
  return done / total;
}

export function addAssetName(progress) {
  for (let assessment of progress.assessments) {
    let filt_a = progress.assets.filter(
      (a) => a.asset_id === assessment.id.asset_id
    );
    if (filt_a.length == 0) continue;
    assessment.asset_name = filt_a[0].name;
  }
}
