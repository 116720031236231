import { useState, useRef } from "react";
import { previewCPEs } from "../../../api";

export default function VendorProductVersionInputs({ form, setForm }) {
  return (
    <div className="vendor-product-version-inputs">
      {form.assetName !== "" && (
        <VPVInput field="vendor" form={form} setForm={setForm} />
      )}
      {form.vendor !== "" && (
        <VPVInput field="product" form={form} setForm={setForm} />
      )}
      {form.product !== "" && (
        <VPVInput field="version" form={form} setForm={setForm} />
      )}
    </div>
  );
}

function VPVInput({ field, form, setForm }) {
  let [preview, setPreview] = useState([]);
  let timeout = useRef(null);

  const refresh = async () => {
    setPreview([]);
    let preview = await previewCPEs(
      field,
      form.vendor,
      form.product,
      form.version
    );
    preview = preview.map((row) => row[field]);
    setPreview(preview);
  };

  const update = (value) => {
    let update = {};
    update[field] = value;
    setForm(update);
    updateTimeout();
  };

  const updateTimeout = () => {
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(refresh, 200);
  };

  return (
    <div className="vendor-product-version-input">
      <h3 className="new-analysis-question">
        {capitalizeFirstLetter(field)} <i>(Optional)</i>
      </h3>
      <input
        onFocus={() => {
          updateTimeout();
        }}
        onBlur={() => {
          clearTimeout(timeout.current);
          setPreview([]);
        }}
        className="new-analysis-input"
        value={form[field]}
        onChange={(e) => {
          update(e.target.value);
        }}
        type="text"
        placeholder={`${capitalizeFirstLetter(field)}...`}
      />
      {preview.length > 0 && (
        <div className="vpv-options">
          {preview.map((opt) => (
            <div
              className="vpv-option"
              onMouseDown={() => {
                update(opt);
              }}
            >
              {opt}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
