import { useRef } from "react";
import "../report_builder_preview.css";
import { Tooltip } from "react-tippy";
import { useReactToPrint } from "react-to-print";

// icons
import { X, FileText, Printer } from "react-feather";

// components
import FacilityReport from "./facility_report";

/**
 * A preview of the custom report PDF.
 * This displays the set of all user-added report items.
 * It can be exported to PDF format via a button click.
 * Custom report items can be dragged and dropped on the report,
 * changing their order.
 */
export default function ReportBuilderPreview({
  items,
  setItems,
  facility_id,
  facility,
  assets,
}) {
  const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
  let fromBinaryAnalysis = urlParams.get("fromBinaryAnalysis") !== null;
  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
    @media print {
      body {
        overflow: visible !important;

        --bg-color: #ffffff;
        --sec-bg-color: #fbfbfb;
        --pri-color: #0f1419;
        --pri-color-light: #7a8791;
        --bor-color: #cfd9de;
      }
    }
  `,

    print: async (printIframe) => {
      // Do whatever you want here, including asynchronous work
      printIframe.contentWindow.print();
    },
  });

  if (!facility) return null;
  return (
    <div className="report-builder-preview">
      <div
        className="report-builder-preview-internal"
        id="fake-report-document-scroller"
      >
        <div className="fake-report-document" ref={printRef} id="the-report">
          <FacilityReport
            items={items}
            setItems={setItems}
            facility_id={facility_id}
            facility={facility}
            assets={assets}
          />
        </div>
      </div>

      <Tooltip
        title={
          fromBinaryAnalysis
            ? "Back to Binary Analysis Page"
            : "Back to Reports Page"
        }
        position="left"
        trigger="mouseenter"
        arrow
        size="small"
        className="report-builder-button report-builder-button-close"
      >
        <button
          onClick={() => {
            if (fromBinaryAnalysis) window.location.assign("#/binary");
            else window.location.assign("#/report");
          }}
        >
          <X className="icon" />
        </button>
      </Tooltip>

      {fromBinaryAnalysis && (
        <Tooltip
          title="Back to Reports Page"
          position="left"
          trigger="mouseenter"
          arrow
          size="small"
          className="report-builder-button report-builder-button-docs"
        >
          <button
            onClick={() => {
              window.location.assign("#/report");
            }}
          >
            <FileText className="icon" />
          </button>
        </Tooltip>
      )}

      <Tooltip
        title="Print PDF"
        position="left"
        trigger="mouseenter"
        arrow
        size="small"
        className="report-builder-button report-builder-button-download"
        style={{
          top: fromBinaryAnalysis ? 100 : 60,
        }}
      >
        <button className="pdf-download-button" onClick={handlePrint}>
          <Printer className="icon" />
        </button>
      </Tooltip>
    </div>
  );
}
