import React from "react";
import PropTypes from "prop-types";
import "../../index.css";

// icons
import {
  AlertCircle,
  CheckCircle,
  ChevronDown,
  ChevronLeft,
  HelpCircle,
} from "react-feather";

export const PASS_FAIL_NAMES = {
  "restrictness-check": "Restricted Function Arguments",
  "av-rule-17": "Errno Indicator Check",
  "av-rule-174": "NULL Pointer Dereference Check",
  "av-rule-189": "GOTO Statement Check",
  "av-rule-19": "Setlocale and Localeconv Functions Check",
  "av-rule-20": "Setjmp and Longjmp Functions Check",
  "av-rule-21": "Signal.h Library Check",
  "av-rule-22": "Stdio.h Library Check",
  "av-rule-23": "Atof, Atoi, Atol Functions Check",
  "av-rule-24": "Abort, Exit, Getenv, System Functions Check",
  "av-rule-25": "Time.h Library Check",
  "av-rule-3": "Cyclomatic Complexity Number Check",
  "jpl-rule-11": "Reducible Control Flow Graph Check",
  "jpl-rule-14": "Unused Values Check",
  "jpl-rule-4": "Recursive Functions Check",
  "must-check-value": "Unchecked Return Value Check",
  "primus-checks":
    "Buffer Overflow, Information Flow, and Hardcoded Values Check",
  "untrusted-argument": "Untrusted Argument Check",
  "use-after-free": "Access Free Memory Check",
  "warn-unused": "Unused Function Results Check",
  "double-free": "Double Free Check",
  "heap-overflow": "Heap Overflow Check",
  "forbidden-symbol": "Forbidden Symbol Check",
  spectre: "Spectre Check",
  "defective-symbol": "Defective Symbol Check",
  "Lineage Analysis": "Lineage Analysis",
};

// TODO this is duplicated in the BAP CWEParser in rbng-containers
const ASSOCIATED_CWES = {
  "restrictness-check": ["785"],
  "av-rule-17": ["391", "628", "758"],
  "av-rule-174": ["476", "690"],
  "av-rule-189": ["116", "676"],
  "av-rule-19": ["20", "807"],
  "av-rule-20": ["691", "843"],
  "av-rule-21": ["364", "479", "662"],
  "av-rule-22": ["134", "242", "676"],
  "av-rule-23": ["190", "681", "691"],
  "av-rule-24": ["78", "382", "497", "676"],
  "av-rule-25": ["330", "676", "682"],
  "av-rule-3": ["691", "707", "710"],
  "jpl-rule-11": ["1120"],
  "jpl-rule-14": ["252", "476"],
  "jpl-rule-4": ["674"],
  "must-check-value": ["252"],
  "primus-checks": [],
  "untrusted-argument": ["20", "501"],
  "use-after-free": ["416"],
  "warn-unused": ["252"],
  "double-free": ["415", "664", "710"],
  "heap-overflow": ["122"],
  "forbidden-symbol": ["676", "749"],
  spectre: ["200", "571"],
  "defective-symbol": ["561", "691", "710"],
  "Lineage Analysis": [],
};

/**
 * A single Pass/Fail assessment in the list of Pass/Fail assessments
 * on the binary report page.
 */
export default class PassFail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.status === "FAIL",
    };
    this.ref = React.createRef();
  }

  /**
   * Render the component.
   */
  render() {
    return (
      <div
        style={{
          display: "flex",
          position: "relative",
          paddingTop: 20,
          paddingBottom: 40,
        }}
      >
        <div style={{ width: 20 }}></div>
        <hr
          style={{
            position: "absolute",
            left: 0,
            zIndex: 0,
            margin: 0,
            top: 25,
            width: "calc(100% - 20px)",
          }}
        />

        {!this.state.open && (
          <div
            style={{
              width: "calc(100% - 130px)",
              borderRadius: 10,
              background:
                this.props.name === "Lineage Analysis" &&
                this.props.status === "FAIL"
                  ? "var(--contrast-color)"
                  : "var(--sec-bg-color)",
              padding: 15,
              zIndex: 1,
              display: "flex",
              alignItems: "center",
              marginTop: -16,
              cursor: "pointer",
              marginLeft: 40,
            }}
            onClick={() => this.setState({ open: !this.state.open })}
          >
            {this.props.status === "PASS" && (
              <CheckCircle
                style={{
                  width: 16,
                  height: 16,
                  marginRight: 15,
                  stroke: "var(--green-color)",
                }}
              />
            )}
            {this.props.status === "FAIL" && (
              <AlertCircle
                style={{
                  width: 16,
                  height: 16,
                  marginRight: 15,
                  stroke:
                    this.props.name === "Lineage Analysis" &&
                    this.props.status === "FAIL"
                      ? "white"
                      : "var(--contrast-color)",
                }}
              />
            )}
            {this.props.status === "UNKNOWN" && (
              <HelpCircle
                style={{
                  width: 16,
                  height: 16,
                  marginRight: 15,
                  stroke: "var(--pri-color-light)",
                }}
              />
            )}
            <h2 style={{ fontSize: 14, width: 300, lineHeight: "1.4" }}>
              {PASS_FAIL_NAMES.hasOwnProperty(this.props.name)
                ? PASS_FAIL_NAMES[this.props.name]
                : this.props.name}
            </h2>
            <h3
              style={{
                fontSize: 14,
                lineHeight: "1.4",
                color:
                  this.props.name === "Lineage Analysis" &&
                  this.props.status === "FAIL"
                    ? "white"
                    : "var(--pri-color-light)",
              }}
            >
              {this.props.status}
            </h3>
            <ChevronLeft
              style={{
                marginLeft: "auto",
                stroke:
                  this.props.name === "Lineage Analysis" &&
                  this.props.status === "FAIL"
                    ? "white"
                    : "var(--pri-color-light)",
                width: 16,
                height: 16,
              }}
            />
          </div>
        )}

        {this.state.open && (
          <div
            style={{
              width: "calc(100% - 130px)",
              borderRadius: 10,
              background:
                this.props.name === "Lineage Analysis" &&
                this.props.status === "FAIL"
                  ? "var(--contrast-color)"
                  : "var(--sec-bg-color)",
              padding: 15,
              zIndex: 1,
              cursor: "pointer",
              marginTop: -16,
              marginLeft: 40,
            }}
            onClick={() => this.setState({ open: !this.state.open })}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {this.props.status === "PASS" && (
                <CheckCircle
                  style={{
                    width: 16,
                    height: 16,
                    marginRight: 15,
                    stroke: "var(--green-color)",
                  }}
                />
              )}
              {this.props.status === "FAIL" && (
                <AlertCircle
                  style={{
                    width: 16,
                    height: 16,
                    marginRight: 15,
                    stroke:
                      this.props.name === "Lineage Analysis" &&
                      this.props.status === "FAIL"
                        ? "white"
                        : "var(--contrast-color)",
                  }}
                />
              )}
              {this.props.status === "UNKNOWN" && (
                <HelpCircle
                  style={{
                    width: 16,
                    height: 16,
                    marginRight: 15,
                    stroke: "var(--pri-color-light)",
                  }}
                />
              )}
              <h2 style={{ fontSize: 14, width: 300, lineHeight: "1.4" }}>
                {PASS_FAIL_NAMES.hasOwnProperty(this.props.name)
                  ? PASS_FAIL_NAMES[this.props.name]
                  : this.props.name}
              </h2>
              <h3
                style={{
                  fontSize: 14,
                  lineHeight: "1.4",
                  color:
                    this.props.name === "Lineage Analysis" &&
                    this.props.status === "FAIL"
                      ? "white"
                      : "var(--pri-color-light)",
                }}
              >
                {this.props.status}
              </h3>
              <ChevronDown
                style={{
                  marginLeft: "auto",
                  stroke:
                    this.props.name === "Lineage Analysis" &&
                    this.props.status === "FAIL"
                      ? "white"
                      : "var(--pri-color-light)",
                  width: 16,
                  height: 16,
                }}
              />
            </div>
            <p
              style={{
                marginTop: 15,
                fontSize: 14,
                textAlign: "left",
                lineHeight: "1.4",
              }}
            >
              {this.props.desc}
            </p>
            {this.props.status === "FAIL" && (
              <div className="threatai-associated-cwes">
                {ASSOCIATED_CWES[this.props.name].map((cwe) => (
                  <h3 onClick={() => this.props.swapTab("CWEs", `CWE-${cwe}`)}>
                    CWE-{cwe}
                  </h3>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

PassFail.propTypes = {
  /**
   * The number of the Pass/Fail assessment in the list.
   */
  num: PropTypes.number.isRequired,

  /**
   * The name of the Pass/Fail assessment.
   */
  name: PropTypes.string.isRequired,

  /**
   * The status of the Pass/Fail assessment.
   */
  status: PropTypes.string.isRequired,

  /**
   * The description of the Pass/Fail assessment.
   */
  desc: PropTypes.string.isRequired,
};
