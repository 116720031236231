import { useState, useEffect } from "react";
import { getAssetAuditLog, removeAssetTag } from "../../../api";
import AuditHistory from "./audit_history";
import AssetSummaryTitle from "./asset_summary_title";
import { X } from "react-feather";
import AssetDescription from "./asset_description";
import BinaryOverview from "./binary_overview";

export default function AssetBaseTab({
  asset,
  refreshAsset,
  updateDescription,
}) {
  let [auditLoading, setAuditLoading] = useState(true);
  let [audit, setAudit] = useState([]);

  const refreshAuditLog = async () => {
    let audit = await getAssetAuditLog(asset.ref_id);
    setAudit(audit);
    setAuditLoading(false);
  };
  useEffect(() => {
    refreshAuditLog();
  }, []);

  // render differently if the asset hasn't completed assessing
  if (asset.state === "in progress")
    return (
      <>
        <div
          className="filter-internal"
          style={{
            marginTop: -5,
            height: "calc(100% + 40px)",
            paddingBottom: 20,
          }}
        >
          <div
            className="row-2"
            style={{
              justifyContent: "flex-start",
              marginBottom: 30,
              padding: 20,
              borderRadius: 10,
              border: "2px solid var(--sec-bg-color)",
              width: "calc(100% - 40px)",
              overflow: "hidden",
            }}
          >
            <div
              className="score-g"
              style={{
                borderColor: "var(--sec-bg-color)",
                background: "var(--sec-bg-color)",
              }}
            >
              <div className="in-progress-ring-big"></div>
            </div>

            <div>
              <h2
                className="prev-file-name"
                style={{ fontSize: 24, marginBottom: 10 }}
              >
                {asset.name}
              </h2>
              <h3 style={{ marginTop: 5, fontSize: 16, lineHeight: 1.4 }}>
                This asset is still under assessment.
              </h3>
            </div>
          </div>

          <hr style={{ width: "100%", zIndex: "20 !important" }} />

          <div
            className="asset-media-child"
            style={{
              minWidth: "50%",
              maxWidth: "50%",
              marginLeft: 30,
              zIndex: 1,
            }}
          >
            <div
              className="left-row"
              style={{
                marginTop: 30,
                padding: 0,
              }}
            >
              <h2 style={{ fontSize: 16 }}>History</h2>
            </div>

            {auditLoading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="lds-dual-ring"></div>
              </div>
            )}

            {!auditLoading && <AuditHistory audit={audit} />}
          </div>
        </div>
      </>
    );

  let nRed = 0;
  let nYellow = 0;
  let nGreen = 0;
  for (let b of asset.binaries.assessable) {
    if (b.cwss.aggregate == "RED") nRed++;
    else if (b.cwss.aggregate == "YELLOW") nYellow++;
    else nGreen++;
  }

  return (
    <>
      <div
        className="filter-internal"
        style={{
          marginTop: -5,
          height: "calc(100% + 40px)",
          paddingBottom: 20,
        }}
      >
        <AssetSummaryTitle asset={asset} />

        {asset.hasOwnProperty("tags") && asset.tags.length > 0 && (
          <div className="asset-summary-tags">
            <h3>Tags:</h3>
            <div className="asset-summary-tags-inner">
              {asset.tags.map((tag) => {
                return (
                  <div className="asset-tag">
                    <div className="tag-bull"></div>
                    {tag}
                    <X
                      onClick={async () => {
                        await removeAssetTag(asset.ref_id, tag);
                        refreshAsset(asset.ref_id);
                      }}
                      className="icon"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <BinaryOverview nRed={nRed} nYellow={nYellow} nGreen={nGreen} />
        <AssetDescription asset={asset} updateDescription={updateDescription} />
        <div
          className="asset-media-child"
          style={{
            minWidth: "50%",
            maxWidth: "50%",
            marginLeft: 30,
            zIndex: 1,
          }}
        >
          <div
            className="left-row"
            style={{
              marginTop: 30,
              padding: 0,
            }}
          >
            <h2 style={{ fontSize: 16 }}>History</h2>
          </div>

          {auditLoading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="lds-dual-ring"></div>
            </div>
          )}

          {!auditLoading && <AuditHistory audit={audit} />}
        </div>
      </div>
    </>
  );
}
