import { useState } from "react";
import BAPs from "../BAPs";
import BinarySummaryInfoHelper from "./binary_summary_info_helper";
import { Search, CheckCircle } from "react-feather";
import { PASS_FAIL_NAMES } from "../pass_fail";

export default function ThreatAITab({ binary, data, swapTab}) {
  let [search, setSearch] = useState("");
  if (!data.bap || data.bap.length === 0)
    return (
      <div style={{ marginTop: -10 }}>
        <h4 style={{ fontWeight: 500 }}>
          No ThreatAI assessment results were detected in this binary.
        </h4>
        <CheckCircle className="big-icon" />
      </div>
    );
  return (
    <>
      <h4
        style={{
          position: "absolute",
          width: "100%",
          zIndex: 20,
          padding: 10,
          left: 0,
          top: 27,
          paddingLeft: 20,
          borderTop: "1px solid var(--bor-color)",
          borderBottom: "1px solid var(--bor-color)",
          background: "var(--bg-color)",
        }}
      >
        <div className="search-row">
          <Search className="user-icon" />
          <input
            onChange={(e) => setSearch(e.target.value)}
            placeholder={`Search ${
              Object.keys(PASS_FAIL_NAMES).length
            } ThreatAI Assessments...`}
          ></input>
        </div>
      </h4>
      <div
        style={{
          marginTop: 14,
          transform: "translateX(-10px)",
          width: "calc(100% + 20px)",
        }}
      >
        <hr
          style={{
            margin: 0,
            width: "calc(100% + 40px)",
            transform: "translateX(-20px)",
            height: 1,
            background: "var(--bor-color)",
            marginBottom: 20,
            marginTop: 10,
          }}
        />

        <BinarySummaryInfoHelper
          text="ThreatAI assessments test binary files and produce
                Pass/Fail results through data modeling-to-ML training, auto 
                path conditions, and auto, rapid deep code scans. 
                Advanced binary analysis sciences are applied to 
                achieve the Pass/Fail results, such as 
                applied lineage analysis, taint and FLIRT analysis,
                attack vector analysis, and memoization across analyses."
        />

        <BAPs
          passfails={[
            ...data.bap,
            {
              MSG: data.lineage
                ? "Suspicious code and/or evasive behavior not detected. This binary exhibits clearly benign behaviors."
                : "Suspicious code and/or evasive behavior detected. This binary does not exhibit clearly benign behaviors - it exhibits characteristics that may be malicious.",
              NAME: "Lineage Analysis",
              STATUS: data.lineage ? "PASS" : "FAIL",
            },
          ]}
          passfailSearch={search}
          lineage={data.lineage}
          swapTab={swapTab}
        />
      </div>
    </>
  );
}
