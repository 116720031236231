import BinarySummaryTitle from "./binary_summary_title";
import ScoreCause from "../score_cause/score_cause";
import BinarySummaryChart from "./binary_summary_chart";
import BinarySummaryMetadata from "./binary_summary_metadata";

function ClamAV({ clamav, still_assessing }) {
  if (clamav === null || !clamav) return null;
  if (!("message" in clamav) || !("status" in clamav)) {
    return null;
  }
  if (clamav.status === "OK") {
    return (
      <div className="score-cause">
        <div className="cause">
          <h2>No Malware Detected</h2>
          <h3>This binary doesn't contain any malware.</h3>
        </div>
      </div>
    );
  }
  if (still_assessing) {
    return (
      <div className="score-cause">
        <div className="cause">
          <h2>Malware Detected</h2>
          <div className="cause-field">{clamav.message}</div>
          <h3>
            This binary contains malware and should be quarantined immediately.
          </h3>
        </div>
      </div>
    );
  }
  return null;
}

/**
 * The Base tab displays the title of the binary, the score cause,
 * the vulnerability radar chart, and extracted metadata.
 */
export default function BaseTab({ binary, data, setPage }) {
  return (
    <>
      {data.metadata !== null && <BinarySummaryTitle binary={binary} />}

      {binary.aggregate && data.metadata !== null && (
        <ScoreCause
          asset_id={binary.id}
          binary={binary.name}
          score={binary.aggregate}
          cwss={binary.cwss}
          clickFunction={(page) => setPage(page)}
        />
      )}

      <ClamAV clamav={data.clamav} still_assessing={!binary.aggregate} />

      {binary.aggregate && data.metadata !== null && (
        <BinarySummaryChart
          binary={{
            ...binary,
            cvss: data.cvss,
            weakness: data.weakness,
            exploitability: data.exploitability,
            impact: data.impact,
          }}
        />
      )}

      {data.metadata !== null && (
        <BinarySummaryMetadata
          metadata={data.metadata}
          manalyze={data.manalyze}
          binaryEncryption={data.binaryEncryption}
        />
      )}
    </>
  );
}
