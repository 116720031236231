import React from "react";
import PropTypes from "prop-types";
import { Facility } from "../../constants";
import "../../index.css";
import { COLORS_STRING } from "../../colors";
import { Chart as ChartJS } from "chart.js";
import { deleteFacility, getDefaultFacility } from "../../api";
import { calcColor, calcIcon, calcText } from "../../helpers";

// components
import EditFacilityModal from "./edit_facility_modal";
import FacilitySummaryTitle from "./facility_summary_components/facility_summary_title";
import FacilitySummaryMap from "./facility_summary_components/facility_summary_map";
import FacilitySummaryDescription from "./facility_summary_components/facility_summary_description";

// icons
import { Edit, FileText, Trash } from "react-feather";

/**
 * Summarizes a facility on the right-side panel of the Binary Analysis
 * Page. Displays facility name, score, location, and description.
 */
export default class FacilitySummary extends React.Component {
  constructor(props) {
    super(props);
    ChartJS.defaults.color = COLORS_STRING.PRI;
    this.state = {
      editFacilityModal: false,
    };
    this.deleteFacility = this.deleteFacility.bind(this);
  }

  async deleteFacility() {
    let defaultFacility = await getDefaultFacility();
    if (this.props.facility.id === defaultFacility) {
      alert("You cannot delete the default facility of an organization!");
      return;
    }
    if (
      window.confirm(
        "This will delete the facility and every asset in it. Are you sure you wish to continue?"
      )
    ) {
      deleteFacility(this.props.facility.id);
      this.props.backFunction();
    }
  }

  /**
   * Render the component.
   */
  render() {
    if (!this.props.facility)
      return (
        <div className="report-prev">
          <div className="filter">
            <div className="toggle-header"></div>
          </div>
        </div>
      );
    return (
      <div className="report-prev">
        <EditFacilityModal
          facility={this.props.facility}
          modal={this.state.editFacilityModal}
          backFunction={() => this.setState({ editFacilityModal: false })}
        />

        <div className="filter">
          <div className="toggle-header">
            <div className="side-buttons" style={{ height: 36 }}>
              <button
                style={{ width: 130 }}
                className="upload"
                onClick={() => this.setState({ editFacilityModal: true })}
              >
                <h3>Edit Facility</h3>
                <Edit className="edit-icon" />
              </button>
              <button
                onClick={this.deleteFacility}
                style={{ width: 150 }}
                className="upload"
              >
                <h3>Delete Facility</h3>
                <Trash className="edit-icon" />
              </button>
              <button
                style={{ width: 160 }}
                className="upload"
                onClick={() => {
                  window.location.assign(
                    `#/report/builder/facility?id=${this.props.facility.id}&fromBinaryAnalysis`
                  );
                }}
              >
                <h3>Generate Report</h3>
                <FileText className="edit-icon" />
              </button>
            </div>
          </div>

          <div
            className="filter-internal"
            style={{
              marginTop: -5,
              height: "calc(100% + 40px)",
              paddingBottom: 20,
            }}
          >
            <FacilitySummaryTitle facility={this.props.facility} />
            <FacilitySummaryMap facility={this.props.facility} />
            <hr style={{ width: "100%" }} />
            <FacilitySummaryDescription facility={this.props.facility} />
          </div>
        </div>
      </div>
    );
  }
}

FacilitySummary.propTypes = {
  /**
   * The facility to display.
   */
  facility: PropTypes.instanceOf(Facility).isRequired,

  /**
   * Callback to deselect the facility.
   */
  backFunction: PropTypes.func.isRequired,
};
