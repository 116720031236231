/*
  Generic help functions used throughout the application.
*/

import { Activity, AlertCircle, CheckCircle } from "react-feather";
import { COLORS_STRING } from "./colors";

// The current version of the application.
export const VERSION = "GA v3.0.0";

/**
 * Check if a deployment is or isn't electron-based.
 */
export function isElectron() {
  return !window.location.hostname || window.location.hostname === "localhost";
}

export function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

export function todaysDate() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = mm + "/" + dd + "/" + yyyy;
  return today;
}

export function currentTime() {
  let date = new Date();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

// ------------

export function calcColor(score) {
  return score === "RED"
    ? "var(--contrast-color)"
    : score === "YELLOW"
    ? "var(--yellow-color)"
    : "var(--green-color)";
}

export function calcBg(score) {
  return score === "RED"
    ? "rgba(235, 64, 52, 0.15)"
    : score === "YELLOW"
    ? "rgba(252, 186, 3, 0.15)"
    : "rgba(50, 168, 82, 0.15)";
}

export function calcHeader(score) {
  return score === "RED"
    ? "CRITICAL"
    : score === "YELLOW"
    ? "VULNERABLE"
    : "SAFE";
}

export function calcText(score, type) {
  return score === "RED"
    ? `This ${type} contains one or more critical vulnerabilties`
    : score === "YELLOW"
    ? `This ${type} contains some non-critical vulnerabilities.`
    : `This ${type} contains no outstanding vulnerabilities.`;
}

export function calcIcon(score) {
  return score === "RED" ? (
    <AlertCircle
      style={{
        stroke: "white",
        width: 34,
        height: 34,
        strokeWidth: 2.4,
      }}
    />
  ) : score === "YELLOW" ? (
    <Activity
      style={{
        stroke: "white",
        width: 34,
        height: 34,
        strokeWidth: 2.4,
      }}
    />
  ) : (
    <CheckCircle
      style={{
        stroke: "white",
        width: 34,
        height: 34,
        strokeWidth: 2.4,
      }}
    />
  );
}

export function calcColorString(score) {
  return score === "RED"
    ? COLORS_STRING.RED
    : score === "YELLOW"
    ? COLORS_STRING.YELLOW
    : COLORS_STRING.GREEN;
}

export function calcLight(score) {
  return score === "RED"
    ? "light-r"
    : score === "YELLOW"
    ? "light-y"
    : "light-g";
}

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function isHex(inputString) {
  try {
    return inputString.startsWith("0x");
  } catch {
    return false;
  }
}

export function genSevBgColor(sev) {
  switch (sev) {
    case "5":
      return "var(--contrast-color)";
    case "4":
      return "var(--contrast-color)";
    case "3":
      return "var(--yellow-color)";
    case "2":
      return "var(--yellow-color)";
    case "1":
      return "var(--yellow-color)";
    case "0":
    default:
      return "var(--green-color)";
  }
}

export const DANGEROUS_FN_MAP = {
  strcpy: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using snprintf, strcpy_s, or strlcpy (warning: strncpy easily misused)",
    category: "buffer",
  },
  strcpyA: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using snprintf, strcpy_s, or strlcpy (warning: strncpy easily misused)",
    category: "buffer",
  },
  strcpyW: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using snprintf, strcpy_s, or strlcpy (warning: strncpy easily misused)",
    category: "buffer",
  },
  StrCpy: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using snprintf, strcpy_s, or strlcpy (warning: strncpy easily misused)",
    category: "buffer",
  },
  StrCpyA: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using snprintf, strcpy_s, or strlcpy (warning: strncpy easily misused)",
    category: "buffer",
  },
  lstrcpyA: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using snprintf, strcpy_s, or strlcpy (warning: strncpy easily misused)",
    category: "buffer",
  },
  lstrcpyW: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using snprintf, strcpy_s, or strlcpy (warning: strncpy easily misused)",
    category: "buffer",
  },
  _tccpy: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using snprintf, strcpy_s, or strlcpy (warning: strncpy easily misused)",
    category: "buffer",
  },
  _mbccpy: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using snprintf, strcpy_s, or strlcpy (warning: strncpy easily misused)",
    category: "buffer",
  },
  _ftcscpy: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using snprintf, strcpy_s, or strlcpy (warning: strncpy easily misused)",
    category: "buffer",
  },
  _mbsncpy: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using snprintf, strcpy_s, or strlcpy (warning: strncpy easily misused)",
    category: "buffer",
  },
  StrCpyN: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using snprintf, strcpy_s, or strlcpy (warning: strncpy easily misused)",
    category: "buffer",
  },
  StrCpyNA: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using snprintf, strcpy_s, or strlcpy (warning: strncpy easily misused)",
    category: "buffer",
  },
  StrCpyNW: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using snprintf, strcpy_s, or strlcpy (warning: strncpy easily misused)",
    category: "buffer",
  },
  StrNCpy: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using snprintf, strcpy_s, or strlcpy (warning: strncpy easily misused)",
    category: "buffer",
  },
  strcpynA: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using snprintf, strcpy_s, or strlcpy (warning: strncpy easily misused)",
    category: "buffer",
  },
  StrNCpyA: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using snprintf, strcpy_s, or strlcpy (warning: strncpy easily misused)",
    category: "buffer",
  },
  StrNCpyW: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using snprintf, strcpy_s, or strlcpy (warning: strncpy easily misused)",
    category: "buffer",
  },
  lstrcpynA: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using snprintf, strcpy_s, or strlcpy (warning: strncpy easily misused)",
    category: "buffer",
  },
  lstrcpynW: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using snprintf, strcpy_s, or strlcpy (warning: strncpy easily misused)",
    category: "buffer",
  },
  lstrcpy: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using a function version that stops copying at the end of the buffer",
    category: "buffer",
  },
  wcscpy: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using a function version that stops copying at the end of the buffer",
    category: "buffer",
  },
  _tcscpy: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using a function version that stops copying at the end of the buffer",
    category: "buffer",
  },
  _mbscpy: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using a function version that stops copying at the end of the buffer",
    category: "buffer",
  },
  memcpy: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination (CWE-120)",
    mitigation: "Make sure destination can always hold the source data",
    category: "buffer",
  },
  CopyMemory: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination (CWE-120)",
    mitigation: "Make sure destination can always hold the source data",
    category: "buffer",
  },
  bcopy: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when copying to destination (CWE-120)",
    mitigation: "Make sure destination can always hold the source data",
    category: "buffer",
  },
  strcat: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation:
      "Consider using strcat_s, strncat, strlcat, or snprintf (warning: strncat is easily misused)",
    category: "buffer",
  },
  lstrcat: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  wcscat: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  _tcscat: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  _mbscat: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  StrCat: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  StrCatA: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  StrcatW: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  lstrcatA: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  lstrcatW: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  strCatBuff: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  StrCatBuffA: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  StrCatBuffW: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  StrCatChainW: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  _tccat: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  _mbccat: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  _ftcscat: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  StrCatN: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  StrCatNA: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  StrCatNW: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  StrNCat: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  StrNCatA: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  StrNCatW: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  lstrncat: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  lstrcatnA: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  lstrcatnW: {
    cwe: "CWE-120",
    description:
      "Does not check for buffer overflows when concatenating to destination [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  strncpy: {
    cwe: "CWE-120",
    description:
      "Easily used incorrectly; doesn't always \\0-terminate or check for invalid pointers [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  lstrcpyn: {
    cwe: "CWE-120",
    description:
      "Easily used incorrectly; doesn't always \\0-terminate or check for invalid pointers [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  wcsncpy: {
    cwe: "CWE-120",
    description:
      "Easily used incorrectly; doesn't always \\0-terminate or check for invalid pointers [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  _tcsncpy: {
    cwe: "CWE-120",
    description:
      "Easily used incorrectly; doesn't always \\0-terminate or check for invalid pointers [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  _mbsnbcpy: {
    cwe: "CWE-120",
    description:
      "Easily used incorrectly; doesn't always \\0-terminate or check for invalid pointers [MS-banned] (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  strncat: {
    cwe: "CWE-120",
    description:
      "Easily used incorrectly (e.g., incorrectly computing the correct maximum size to add) [MS-banned] (CWE-120)",
    mitigation:
      "Consider strcat_s, strlcat, snprintf, or automatically resizing strings",
    category: "buffer",
  },
  lstrcatn: {
    cwe: "CWE-120",
    description:
      "Easily used incorrectly (e.g., incorrectly computing the correct maximum size to add) [MS-banned] (CWE-120)",
    mitigation: "Consider strcat_s, strlcat, or automatically resizing strings",
    category: "buffer",
  },
  wcsncat: {
    cwe: "CWE-120",
    description:
      "Easily used incorrectly (e.g., incorrectly computing the correct maximum size to add) [MS-banned] (CWE-120)",
    mitigation: "Consider strcat_s, strlcat, or automatically resizing strings",
    category: "buffer",
  },
  _tcsncat: {
    cwe: "CWE-120",
    description:
      "Easily used incorrectly (e.g., incorrectly computing the correct maximum size to add) [MS-banned] (CWE-120)",
    mitigation: "Consider strcat_s, strlcat, or automatically resizing strings",
    category: "buffer",
  },
  _mbsnbcat: {
    cwe: "CWE-120",
    description:
      "Easily used incorrectly (e.g., incorrectly computing the correct maximum size to add) [MS-banned] (CWE-120)",
    mitigation: "Consider strcat_s, strlcat, or automatically resizing strings",
    category: "buffer",
  },
  strccpy: {
    cwe: "CWE-120",
    description:
      "Subject to buffer overflow if buffer is not as big as claimed (CWE-120)",
    mitigation: "Ensure that destination buffer is sufficiently large",
    category: "buffer",
  },
  strcadd: {
    cwe: "CWE-120",
    description:
      "Subject to buffer overflow if buffer is not as big as claimed (CWE-120)",
    mitigation: "Ensure that destination buffer is sufficiently large",
    category: "buffer",
  },
  char: {
    cwe: "CWE-119",
    description:
      "Statically-sized arrays can be improperly restricted, leading to potential overflows or other issues (CWE-119!/CWE-120)",
    mitigation:
      "Perform bounds checking, use functions that limit length, or ensure that the size is larger than the maximum possible length",
    category: "buffer",
  },
  TCHAR: {
    cwe: "CWE-119",
    description:
      "Statically-sized arrays can be improperly restricted, leading to potential overflows or other issues (CWE-119!/CWE-120)",
    mitigation:
      "Perform bounds checking, use functions that limit length, or ensure that the size is larger than the maximum possible length",
    category: "buffer",
  },
  wchar_t: {
    cwe: "CWE-119",
    description:
      "Statically-sized arrays can be improperly restricted, leading to potential overflows or other issues (CWE-119!/CWE-120)",
    mitigation:
      "Perform bounds checking, use functions that limit length, or ensure that the size is larger than the maximum possible length",
    category: "buffer",
  },
  gets: {
    cwe: "CWE-120",
    description: "Does not check for buffer overflows (CWE-120, CWE-20)",
    mitigation: "Use fgets() instead",
    category: "buffer",
  },
  _getts: {
    cwe: "CWE-120",
    description: "Does not check for buffer overflows (CWE-120, CWE-20)",
    mitigation: "Use fgets() instead",
    category: "buffer",
  },
  sprintf: {
    cwe: "CWE-120",
    description: "Does not check for buffer overflows (CWE-120)",
    mitigation: "Use sprintf_s, snprintf, or vsnprintf",
    category: "buffer",
  },
  vsprintf: {
    cwe: "CWE-120",
    description: "Does not check for buffer overflows (CWE-120)",
    mitigation: "Use sprintf_s, snprintf, or vsnprintf",
    category: "buffer",
  },
  swprintf: {
    cwe: "CWE-120",
    description: "Does not check for buffer overflows (CWE-120)",
    mitigation: "Use sprintf_s, snprintf, or vsnprintf",
    category: "buffer",
  },
  vswprintf: {
    cwe: "CWE-120",
    description: "Does not check for buffer overflows (CWE-120)",
    mitigation: "Use sprintf_s, snprintf, or vsnprintf",
    category: "buffer",
  },
  _stprintf: {
    cwe: "CWE-120",
    description: "Does not check for buffer overflows (CWE-120)",
    mitigation: "Use sprintf_s, snprintf, or vsnprintf",
    category: "buffer",
  },
  _vstprintf: {
    cwe: "CWE-120",
    description: "Does not check for buffer overflows (CWE-120)",
    mitigation: "Use sprintf_s, snprintf, or vsnprintf",
    category: "buffer",
  },
  printf: {
    cwe: "CWE-134",
    description:
      "If format strings can be influenced by an attacker, they can be exploited (CWE-134)",
    mitigation: "Use a constant for the format specification",
    category: "format",
  },
  vprintf: {
    cwe: "CWE-134",
    description:
      "If format strings can be influenced by an attacker, they can be exploited (CWE-134)",
    mitigation: "Use a constant for the format specification",
    category: "format",
  },
  vwprintf: {
    cwe: "CWE-134",
    description:
      "If format strings can be influenced by an attacker, they can be exploited (CWE-134)",
    mitigation: "Use a constant for the format specification",
    category: "format",
  },
  vfwprintf: {
    cwe: "CWE-134",
    description:
      "If format strings can be influenced by an attacker, they can be exploited (CWE-134)",
    mitigation: "Use a constant for the format specification",
    category: "format",
  },
  _vtprintf: {
    cwe: "CWE-134",
    description:
      "If format strings can be influenced by an attacker, they can be exploited (CWE-134)",
    mitigation: "Use a constant for the format specification",
    category: "format",
  },
  wprintf: {
    cwe: "CWE-134",
    description:
      "If format strings can be influenced by an attacker, they can be exploited (CWE-134)",
    mitigation: "Use a constant for the format specification",
    category: "format",
  },
  fprintf: {
    cwe: "CWE-134",
    description:
      "If format strings can be influenced by an attacker, they can be exploited (CWE-134)",
    mitigation: "Use a constant for the format specification",
    category: "format",
  },
  vfprintf: {
    cwe: "CWE-134",
    description:
      "If format strings can be influenced by an attacker, they can be exploited (CWE-134)",
    mitigation: "Use a constant for the format specification",
    category: "format",
  },
  _ftprintf: {
    cwe: "CWE-134",
    description:
      "If format strings can be influenced by an attacker, they can be exploited (CWE-134)",
    mitigation: "Use a constant for the format specification",
    category: "format",
  },
  _vftprintf: {
    cwe: "CWE-134",
    description:
      "If format strings can be influenced by an attacker, they can be exploited (CWE-134)",
    mitigation: "Use a constant for the format specification",
    category: "format",
  },
  fwprintf: {
    cwe: "CWE-134",
    description:
      "If format strings can be influenced by an attacker, they can be exploited (CWE-134)",
    mitigation: "Use a constant for the format specification",
    category: "format",
  },
  fvwprintf: {
    cwe: "CWE-134",
    description:
      "If format strings can be influenced by an attacker, they can be exploited (CWE-134)",
    mitigation: "Use a constant for the format specification",
    category: "format",
  },
  syslog: {
    cwe: "CWE-134",
    description:
      "If syslog's format strings can be influenced by an attacker, they can be exploited (CWE-134)",
    mitigation: "Use a constant format string for syslog",
    category: "format",
  },
  snprintf: {
    cwe: "CWE-134",
    description:
      "If format strings can be influenced by an attacker, they can be exploited, and note that sprintf variations do not always \\0-terminate (CWE-134)",
    mitigation: "Use a constant for the format specification",
    category: "format",
  },
  vsnprintf: {
    cwe: "CWE-134",
    description:
      "If format strings can be influenced by an attacker, they can be exploited, and note that sprintf variations do not always \\0-terminate (CWE-134)",
    mitigation: "Use a constant for the format specification",
    category: "format",
  },
  _snprintf: {
    cwe: "CWE-134",
    description:
      "If format strings can be influenced by an attacker, they can be exploited, and note that sprintf variations do not always \\0-terminate (CWE-134)",
    mitigation: "Use a constant for the format specification",
    category: "format",
  },
  _sntprintf: {
    cwe: "CWE-134",
    description:
      "If format strings can be influenced by an attacker, they can be exploited, and note that sprintf variations do not always \\0-terminate (CWE-134)",
    mitigation: "Use a constant for the format specification",
    category: "format",
  },
  _vsntprintf: {
    cwe: "CWE-134",
    description:
      "If format strings can be influenced by an attacker, they can be exploited, and note that sprintf variations do not always \\0-terminate (CWE-134)",
    mitigation: "Use a constant for the format specification",
    category: "format",
  },
  scanf: {
    cwe: "CWE-120",
    description:
      "The scanf() family's %s operation, without a limit specification, permits buffer overflows (CWE-120, CWE-20)",
    mitigation: "Specify a limit to %s, or use a different input function",
    category: "buffer",
  },
  vscanf: {
    cwe: "CWE-120",
    description:
      "The scanf() family's %s operation, without a limit specification, permits buffer overflows (CWE-120, CWE-20)",
    mitigation: "Specify a limit to %s, or use a different input function",
    category: "buffer",
  },
  wscanf: {
    cwe: "CWE-120",
    description:
      "The scanf() family's %s operation, without a limit specification, permits buffer overflows (CWE-120, CWE-20)",
    mitigation: "Specify a limit to %s, or use a different input function",
    category: "buffer",
  },
  _tscanf: {
    cwe: "CWE-120",
    description:
      "The scanf() family's %s operation, without a limit specification, permits buffer overflows (CWE-120, CWE-20)",
    mitigation: "Specify a limit to %s, or use a different input function",
    category: "buffer",
  },
  vwscanf: {
    cwe: "CWE-120",
    description:
      "The scanf() family's %s operation, without a limit specification, permits buffer overflows (CWE-120, CWE-20)",
    mitigation: "Specify a limit to %s, or use a different input function",
    category: "buffer",
  },
  fscanf: {
    cwe: "CWE-120",
    description:
      "The scanf() family's %s operation, without a limit specification, permits buffer overflows (CWE-120, CWE-20)",
    mitigation: "Specify a limit to %s, or use a different input function",
    category: "buffer",
  },
  sscanf: {
    cwe: "CWE-120",
    description:
      "The scanf() family's %s operation, without a limit specification, permits buffer overflows (CWE-120, CWE-20)",
    mitigation: "Specify a limit to %s, or use a different input function",
    category: "buffer",
  },
  vsscanf: {
    cwe: "CWE-120",
    description:
      "The scanf() family's %s operation, without a limit specification, permits buffer overflows (CWE-120, CWE-20)",
    mitigation: "Specify a limit to %s, or use a different input function",
    category: "buffer",
  },
  vfscanf: {
    cwe: "CWE-120",
    description:
      "The scanf() family's %s operation, without a limit specification, permits buffer overflows (CWE-120, CWE-20)",
    mitigation: "Specify a limit to %s, or use a different input function",
    category: "buffer",
  },
  _ftscanf: {
    cwe: "CWE-120",
    description:
      "The scanf() family's %s operation, without a limit specification, permits buffer overflows (CWE-120, CWE-20)",
    mitigation: "Specify a limit to %s, or use a different input function",
    category: "buffer",
  },
  fwscanf: {
    cwe: "CWE-120",
    description:
      "The scanf() family's %s operation, without a limit specification, permits buffer overflows (CWE-120, CWE-20)",
    mitigation: "Specify a limit to %s, or use a different input function",
    category: "buffer",
  },
  vfwscanf: {
    cwe: "CWE-120",
    description:
      "The scanf() family's %s operation, without a limit specification, permits buffer overflows (CWE-120, CWE-20)",
    mitigation: "Specify a limit to %s, or use a different input function",
    category: "buffer",
  },
  vswscanf: {
    cwe: "CWE-120",
    description:
      "The scanf() family's %s operation, without a limit specification, permits buffer overflows (CWE-120, CWE-20)",
    mitigation: "Specify a limit to %s, or use a different input function",
    category: "buffer",
  },
  strlen: {
    cwe: "CWE-126",
    description:
      "Does not handle strings that are not \\0-terminated; if given one it may perform an over-read (it could cause a crash if unprotected) (CWE-126)",
    mitigation: "",
    category: "buffer",
  },
  wcslen: {
    cwe: "CWE-126",
    description:
      "Does not handle strings that are not \\0-terminated; if given one it may perform an over-read (it could cause a crash if unprotected) (CWE-126)",
    mitigation: "",
    category: "buffer",
  },
  _tcslen: {
    cwe: "CWE-126",
    description:
      "Does not handle strings that are not \\0-terminated; if given one it may perform an over-read (it could cause a crash if unprotected) (CWE-126)",
    mitigation: "",
    category: "buffer",
  },
  _mbslen: {
    cwe: "CWE-126",
    description:
      "Does not handle strings that are not \\0-terminated; if given one it may perform an over-read (it could cause a crash if unprotected) (CWE-126)",
    mitigation: "",
    category: "buffer",
  },
  MultiByteToWideChar: {
    cwe: "CWE-120",
    description: "Requires maximum length in CHARACTERS, not bytes (CWE-120)",
    mitigation: "",
    category: "buffer",
  },
  streadd: {
    cwe: "CWE-120",
    description:
      "This function does not protect against buffer overflows (CWE-120)",
    mitigation:
      "Ensure the destination has 4 times the size of the source, to leave room for expansion",
    category: "buffer",
  },
  strecpy: {
    cwe: "CWE-120",
    description:
      "This function does not protect against buffer overflows (CWE-120)",
    mitigation:
      "Ensure the destination has 4 times the size of the source, to leave room for expansion",
    category: "buffer",
  },
  strtrns: {
    cwe: "CWE-120",
    description:
      "This function does not protect against buffer overflows (CWE-120)",
    mitigation: "Ensure that destination is at least as long as the source",
    category: "buffer",
  },
  realpath: {
    cwe: "CWE-120",
    description:
      "This function does not protect against buffer overflows, and some implementations can overflow internally (CWE-120/CWE-785!)",
    mitigation:
      "Ensure that the destination buffer is at least of size MAXPATHLEN, andto protect against implementation problems, the input argument should also be checked to ensure it is no larger than MAXPATHLEN",
    category: "buffer",
  },
  getopt: {
    cwe: "CWE-120",
    description:
      "Some older implementations do not protect against internal buffer overflows (CWE-120, CWE-20)",
    mitigation:
      "Check implementation on installation, or limit the size of all string inputs",
    category: "buffer",
  },
  getopt_long: {
    cwe: "CWE-120",
    description:
      "Some older implementations do not protect against internal buffer overflows (CWE-120, CWE-20)",
    mitigation:
      "Check implementation on installation, or limit the size of all string inputs",
    category: "buffer",
  },
  getwd: {
    cwe: "CWE-120",
    description:
      "This does not protect against buffer overflows by itself, so use with caution (CWE-120, CWE-20)",
    mitigation: "Use getcwd instead",
    category: "buffer",
  },
  getchar: {
    cwe: "CWE-120",
    description:
      "Check buffer boundaries if used in a loop including recursive loops (CWE-120, CWE-20)",
    mitigation: "",
    category: "buffer",
  },
  fgetc: {
    cwe: "CWE-120",
    description:
      "Check buffer boundaries if used in a loop including recursive loops (CWE-120, CWE-20)",
    mitigation: "",
    category: "buffer",
  },
  getc: {
    cwe: "CWE-120",
    description:
      "Check buffer boundaries if used in a loop including recursive loops (CWE-120, CWE-20)",
    mitigation: "",
    category: "buffer",
  },
  read: {
    cwe: "CWE-120",
    description:
      "Check buffer boundaries if used in a loop including recursive loops (CWE-120, CWE-20)",
    mitigation: "",
    category: "buffer",
  },
  _gettc: {
    cwe: "CWE-120",
    description:
      "Check buffer boundaries if used in a loop including recursive loops (CWE-120, CWE-20)",
    mitigation: "",
    category: "buffer",
  },
  access: {
    cwe: "CWE-362",
    description:
      "This usually indicates a security flaw. If an attacker can change anything along the path between the call to access() and the file's actual use (e.g., by moving files), the attacker can exploit the race condition (CWE-362/CWE-367!)",
    mitigation:
      "Set up the correct permissions (e.g., using setuid()) and try to open the file directly",
    category: "race",
  },
  chown: {
    cwe: "CWE-362",
    description:
      "This accepts filename arguments; if an attacker can move those files, a race condition results. CWE-362)",
    mitigation: "Use fchown( ) instead",
    category: "race",
  },
  chgrp: {
    cwe: "CWE-362",
    description:
      "This accepts filename arguments; if an attacker can move those files, a race condition results. CWE-362)",
    mitigation: "Use fchgrp( ) instead",
    category: "race",
  },
  chmod: {
    cwe: "CWE-362",
    description:
      "This accepts filename arguments; if an attacker can move those files, a race condition results. CWE-362)",
    mitigation: "Use fchmod( ) instead",
    category: "race",
  },
  vfork: {
    cwe: "CWE-362",
    description:
      "On some old systems, vfork() permits race conditions, and it's very difficult to use correctly (CWE-362)",
    mitigation: "Use fork() instead",
    category: "race",
  },
  readlink: {
    cwe: "CWE-362",
    description:
      "This accepts filename arguments; if an attacker can move those files or change the link content, a race condition results.  Also, it does not terminate with ASCII NUL. CWE-362, CWE-20)",
    mitigation: "Reconsider approach",
    category: "race",
  },
  tmpfile: {
    cwe: "CWE-377",
    description:
      "Function tmpfile() has a security flaw on some systems (e.g., older System V systems) (CWE-377)",
    mitigation: "",
    category: "tmpfile",
  },
  tmpnam: {
    cwe: "CWE-377",
    description: "Temporary file race condition (CWE-377)",
    mitigation: "",
    category: "tmpfile",
  },
  tempnam: {
    cwe: "CWE-377",
    description: "Temporary file race condition (CWE-377)",
    mitigation: "",
    category: "tmpfile",
  },
  mktemp: {
    cwe: "CWE-377",
    description: "Temporary file race condition (CWE-377)",
    mitigation: "",
    category: "tmpfile",
  },
  mkstemp: {
    cwe: "CWE-377",
    description:
      "Potential for temporary file vulnerability in some circumstances. Some older Unix-like systems create temp files with permission to write by all by default, so be sure to set the umask to override this. Also, some older Unix systems might fail to use O_EXCL when opening the file, so make sure that O_EXCL is used by the library (CWE-377)",
    mitigation: "",
    category: "tmpfile",
  },
  fopen: {
    cwe: "CWE-362",
    description:
      "Check when opening files - can an attacker redirect it (via symlinks), force the opening of special file type (e.g., device files), move things around to create a race condition, control its ancestors, or change its contents? (CWE-362)",
    mitigation: "",
    category: "misc",
  },
  open: {
    cwe: "CWE-362",
    description:
      "Check when opening files - can an attacker redirect it (via symlinks), force the opening of special file type (e.g., device files), move things around to create a race condition, control its ancestors, or change its contents? (CWE-362)",
    mitigation: "",
    category: "misc",
  },
  umask: {
    cwe: "CWE-732",
    description:
      "Ensure that umask is given most restrictive possible setting (e.g., 066 or 077) (CWE-732)",
    mitigation: "",
    category: "access",
  },
  GetTempFileName: {
    cwe: "CWE-377",
    description:
      "Temporary file race condition in certain cases (e. if run as SYSTEM in many versions of Windows) (CWE-377)",
    mitigation: "",
    category: "tmpfile",
  },
  execl: {
    cwe: "CWE-78",
    description:
      "This causes a new program to execute and is difficult to use safely (CWE-78)",
    mitigation:
      "try using a library call that implements the same functionality if available",
    category: "shell",
  },
  execlp: {
    cwe: "CWE-78",
    description:
      "This causes a new program to execute and is difficult to use safely (CWE-78)",
    mitigation:
      "try using a library call that implements the same functionality if available",
    category: "shell",
  },
  execle: {
    cwe: "CWE-78",
    description:
      "This causes a new program to execute and is difficult to use safely (CWE-78)",
    mitigation:
      "try using a library call that implements the same functionality if available",
    category: "shell",
  },
  execv: {
    cwe: "CWE-78",
    description:
      "This causes a new program to execute and is difficult to use safely (CWE-78)",
    mitigation:
      "try using a library call that implements the same functionality if available",
    category: "shell",
  },
  execvp: {
    cwe: "CWE-78",
    description:
      "This causes a new program to execute and is difficult to use safely (CWE-78)",
    mitigation:
      "try using a library call that implements the same functionality if available",
    category: "shell",
  },
  popen: {
    cwe: "CWE-78",
    description:
      "This causes a new program to execute and is difficult to use safely (CWE-78)",
    mitigation:
      "try using a library call that implements the same functionality if available",
    category: "shell",
  },
  WinExec: {
    cwe: "CWE-78",
    description:
      "This causes a new program to execute and is difficult to use safely (CWE-78)",
    mitigation:
      "try using a library call that implements the same functionality if available",
    category: "shell",
  },
  ShellExecute: {
    cwe: "CWE-78",
    description:
      "This causes a new program to execute and is difficult to use safely (CWE-78)",
    mitigation:
      "try using a library call that implements the same functionality if available",
    category: "shell",
  },
  system: {
    cwe: "CWE-78",
    description:
      "This causes a new program to execute and is difficult to use safely (CWE-78)",
    mitigation:
      "try using a library call that implements the same functionality if available",
    category: "shell",
  },
  CreateProcessAsUser: {
    cwe: "CWE-78",
    description:
      "This causes a new process to execute and is difficult to use safely (CWE-78)",
    mitigation: "Especially watch out for embedded spaces",
    category: "shell",
  },
  CreateProcessWithLogon: {
    cwe: "CWE-78",
    description:
      "This causes a new process to execute and is difficult to use safely (CWE-78)",
    mitigation: "Especially watch out for embedded spaces",
    category: "shell",
  },
  CreateProcess: {
    cwe: "CWE-78",
    description:
      "This causes a new process to execute and is difficult to use safely (CWE-78)",
    mitigation:
      "Specify the application path in the first argument, NOT as part of the second, or embedded spaces could allow an attacker to force a different program to run",
    category: "shell",
  },
  atoi: {
    cwe: "CWE-190",
    description:
      "Unless checked, the resulting number can exceed the expected range (CWE-190)",
    mitigation:
      "If source untrusted, check both minimum and maximum, even if the input had no minus sign (large numbers can roll over into negative number; consider saving to an unsigned value if that is intended)",
    category: "integer",
  },
  atol: {
    cwe: "CWE-190",
    description:
      "Unless checked, the resulting number can exceed the expected range (CWE-190)",
    mitigation:
      "If source untrusted, check both minimum and maximum, even if the input had no minus sign (large numbers can roll over into negative number; consider saving to an unsigned value if that is intended)",
    category: "integer",
  },
  _wtoi: {
    cwe: "CWE-190",
    description:
      "Unless checked, the resulting number can exceed the expected range (CWE-190)",
    mitigation:
      "If source untrusted, check both minimum and maximum, even if the input had no minus sign (large numbers can roll over into negative number; consider saving to an unsigned value if that is intended)",
    category: "integer",
  },
  _wtoi64: {
    cwe: "CWE-190",
    description:
      "Unless checked, the resulting number can exceed the expected range (CWE-190)",
    mitigation:
      "If source untrusted, check both minimum and maximum, even if the input had no minus sign (large numbers can roll over into negative number; consider saving to an unsigned value if that is intended)",
    category: "integer",
  },
  drand48: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  erand48: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  jrand48: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  lcong48: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  lrand48: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  mrand48: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  nrand48: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  random: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  seed48: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  setstate: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  srand: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  strfry: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  srandom: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  g_rand_boolean: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  g_rand_int: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  g_rand_int_range: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  g_rand_double: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  g_rand_double_range: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  g_random_boolean: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  g_random_int: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  g_random_int_range: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  g_random_double: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  g_random_double_range: {
    cwe: "CWE-327",
    description:
      "This function is not sufficiently random for security-related functions such as key and nonce creation (CWE-327)",
    mitigation: "Use a more secure technique for acquiring random values",
    category: "random",
  },
  crypt: {
    cwe: "CWE-327",
    description:
      "The crypt functions use a poor one-way hashing algorithm; since they only accept passwords of 8 characters or fewer and only a two-byte salt, they are excessively vulnerable to dictionary attacks given today's faster computing equipment (CWE-327)",
    mitigation:
      "Use a different algorithm, such as SHA-256, with a larger, non-repeating salt",
    category: "crypto",
  },
  crypt_r: {
    cwe: "CWE-327",
    description:
      "The crypt functions use a poor one-way hashing algorithm; since they only accept passwords of 8 characters or fewer and only a two-byte salt, they are excessively vulnerable to dictionary attacks given today's faster computing equipment (CWE-327)",
    mitigation:
      "Use a different algorithm, such as SHA-256, with a larger, non-repeating salt",
    category: "crypto",
  },
  EVP_des_ecb: {
    cwe: "CWE-327",
    description:
      "DES only supports a 56-bit keysize, which is too small given today's computers (CWE-327)",
    mitigation:
      "Use a different patent-free encryption algorithm with a larger keysize, such as 3DES or AES",
    category: "crypto",
  },
  EVP_des_cbc: {
    cwe: "CWE-327",
    description:
      "DES only supports a 56-bit keysize, which is too small given today's computers (CWE-327)",
    mitigation:
      "Use a different patent-free encryption algorithm with a larger keysize, such as 3DES or AES",
    category: "crypto",
  },
  EVP_des_cfb: {
    cwe: "CWE-327",
    description:
      "DES only supports a 56-bit keysize, which is too small given today's computers (CWE-327)",
    mitigation:
      "Use a different patent-free encryption algorithm with a larger keysize, such as 3DES or AES",
    category: "crypto",
  },
  EVP_des_ofb: {
    cwe: "CWE-327",
    description:
      "DES only supports a 56-bit keysize, which is too small given today's computers (CWE-327)",
    mitigation:
      "Use a different patent-free encryption algorithm with a larger keysize, such as 3DES or AES",
    category: "crypto",
  },
  EVP_desx_cbc: {
    cwe: "CWE-327",
    description:
      "DES only supports a 56-bit keysize, which is too small given today's computers (CWE-327)",
    mitigation:
      "Use a different patent-free encryption algorithm with a larger keysize, such as 3DES or AES",
    category: "crypto",
  },
  EVP_rc4_40: {
    cwe: "CWE-327",
    description:
      "These keysizes are too small given today's computers (CWE-327)",
    mitigation:
      "Use a different patent-free encryption algorithm with a larger keysize, such as 3DES or AES",
    category: "crypto",
  },
  EVP_rc2_40_cbc: {
    cwe: "CWE-327",
    description:
      "These keysizes are too small given today's computers (CWE-327)",
    mitigation:
      "Use a different patent-free encryption algorithm with a larger keysize, such as 3DES or AES",
    category: "crypto",
  },
  EVP_rc2_64_cbc: {
    cwe: "CWE-327",
    description:
      "These keysizes are too small given today's computers (CWE-327)",
    mitigation:
      "Use a different patent-free encryption algorithm with a larger keysize, such as 3DES or AES",
    category: "crypto",
  },
  chroot: {
    cwe: "CWE-250",
    description:
      "chroot can be very helpful, but is hard to use correctly (CWE-250, CWE-22)",
    mitigation:
      'Make sure the program immediately chdir("/"), closes file descriptors, and drops root privileges, and that all necessary files (and no more!) are in the new root',
    category: "misc",
  },
  getenv: {
    cwe: "CWE-807",
    description:
      "Environment variables are untrustable input if they can be set by an attacker. They can have any content and length, and the same variable can be set more than once (CWE-807, CWE-20)",
    mitigation: "Check environment variables carefully before using them",
    category: "buffer",
  },
  curl_getenv: {
    cwe: "CWE-807",
    description:
      "Environment variables are untrustable input if they can be set by an attacker. They can have any content and length, and the same variable can be set more than once (CWE-807, CWE-20)",
    mitigation: "Check environment variables carefully before using them",
    category: "buffer",
  },
  g_get_home_dir: {
    cwe: "CWE-807",
    description:
      "This function is synonymous with 'getenv(\"HOME\")';it returns untrustable input if the environment can beset by an attacker. It can have any content and length, and the same variable can be set more than once (CWE-807, CWE-20)",
    mitigation: "Check environment variables carefully before using them",
    category: "buffer",
  },
  g_get_tmp_dir: {
    cwe: "CWE-807",
    description:
      "This function is synonymous with 'getenv(\"TMP\")';it returns untrustable input if the environment can beset by an attacker. It can have any content and length, and the same variable can be set more than once (CWE-807, CWE-20)",
    mitigation: "Check environment variables carefully before using them",
    category: "buffer",
  },
  RpcImpersonateClient: {
    cwe: "CWE-250",
    description:
      "If this call fails, the program could fail to drop heightened privileges (CWE-250)",
    mitigation:
      "Make sure the return value is checked, and do not continue if a failure is reported",
    category: "access",
  },
  ImpersonateLoggedOnUser: {
    cwe: "CWE-250",
    description:
      "If this call fails, the program could fail to drop heightened privileges (CWE-250)",
    mitigation:
      "Make sure the return value is checked, and do not continue if a failure is reported",
    category: "access",
  },
  CoImpersonateClient: {
    cwe: "CWE-250",
    description:
      "If this call fails, the program could fail to drop heightened privileges (CWE-250)",
    mitigation:
      "Make sure the return value is checked, and do not continue if a failure is reported",
    category: "access",
  },
  ImpersonateNamedPipeClient: {
    cwe: "CWE-250",
    description:
      "If this call fails, the program could fail to drop heightened privileges (CWE-250)",
    mitigation:
      "Make sure the return value is checked, and do not continue if a failure is reported",
    category: "access",
  },
  ImpersonateDdeClientWindow: {
    cwe: "CWE-250",
    description:
      "If this call fails, the program could fail to drop heightened privileges (CWE-250)",
    mitigation:
      "Make sure the return value is checked, and do not continue if a failure is reported",
    category: "access",
  },
  ImpersonateSecurityContext: {
    cwe: "CWE-250",
    description:
      "If this call fails, the program could fail to drop heightened privileges (CWE-250)",
    mitigation:
      "Make sure the return value is checked, and do not continue if a failure is reported",
    category: "access",
  },
  SetThreadToken: {
    cwe: "CWE-250",
    description:
      "If this call fails, the program could fail to drop heightened privileges (CWE-250)",
    mitigation:
      "Make sure the return value is checked, and do not continue if a failure is reported",
    category: "access",
  },
  InitializeCriticalSection: {
    cwe: "Exceptions can be thrown in low-memory situations",
    description: "Exceptions can be thrown in low-memory situations",
    mitigation: "Use InitializeCriticalSectionAndSpinCount instead",
    category: "misc",
  },
  LoadLibrary: {
    cwe: "CWE-829",
    description:
      "Ensure that the full path to the library is specified, or current directory may be used (CWE-829, CWE-20)",
    mitigation:
      "Use LoadLibraryEx with one of the search flags, or call SetSearchPathMode to use a safe search path, or pass a full path to the library",
    category: "misc",
  },
  LoadLibraryEx: {
    cwe: "CWE-829",
    description:
      "Ensure that the full path to the library is specified, or current directory may be used (CWE-829, CWE-20)",
    mitigation:
      "Use a flag like LOAD_LIBRARY_SEARCH_SYSTEM32 or LOAD_LIBRARY_SEARCH_APPLICATION_DIR to search only desired folders",
    category: "misc",
  },
  SetSecurityDescriptorDacl: {
    cwe: "CWE-732",
    description:
      "Never create NULL ACLs; an attacker can set it to Everyone (Deny All Access), which would even forbid administrator access (CWE-732)",
    mitigation: "",
    category: "misc",
  },
  AddAccessAllowedAce: {
    cwe: "CWE-732",
    description:
      "This doesn't set the inheritance bits in the access control entry (ACE) header (CWE-732)",
    mitigation:
      "Make sure that you set inheritance by hand if you wish it to inherit",
    category: "misc",
  },
  getlogin: {
    cwe: "CWE-807",
    description:
      "It's often easy to fool getlogin. Sometimes it does not work at all, because some program messed up the utmp file. Often, it gives only the first 8 characters of the login name. The user currently logged in on the controlling tty of our program need not be the user who started it.  Avoid getlogin() for security-related purposes (CWE-807)",
    mitigation:
      "Use getpwuid(geteuid()) and extract the desired information instead",
    category: "misc",
  },
  cuserid: {
    cwe: "CWE-120",
    description:
      "Exactly what cuserid() does is poorly defined (e.g., some systems use the effective uid, like Linux, while others like System V use the real uid). Thus, you can't trust what it does. It's certainly not portable (The cuserid function was included in the 1988 version of POSIX, but removed from the 1990 version).  Also, if passed a non-null parameter, there's a risk of a buffer overflow if the passed-in buffer is not at least L_cuserid characters long (CWE-120)",
    mitigation:
      "Use getpwuid(geteuid()) and extract the desired information instead",
    category: "misc",
  },
  getpw: {
    cwe: "CWE-676",
    description:
      "This function is dangerous; it may overflow the provided buffer. It extracts data from a 'protected' area, but most systems have many commands to let users modify the protected area, and it's not always clear what their limits are. Best to avoid using this function altogether (CWE-676, CWE-120)",
    mitigation: "Use getpwuid() instead",
    category: "buffer",
  },
  getpass: {
    cwe: "CWE-676",
    description:
      "This function is obsolete and not portable. It was in SUSv2 but removed by POSIX.2.  What it does exactly varies considerably between systems, particularly in where its prompt is displayed and where it gets its data (e.g., /dev/tty, stdin, stderr, etc.). In addition, some implementations overflow buffers. CWE-676, CWE-120, CWE-20)",
    mitigation:
      "Make the specific calls to do exactly what you want. If you continue to use it, or write your own, be sure to zero the password as soon as possible to avoid leaving the cleartext password visible in the process' address space",
    category: "misc",
  },
  gsignal: {
    cwe: "CWE-676",
    description:
      "These functions are considered obsolete on most systems, and very non-portable (Linux-based systems handle them radically different, basically if gsignal/ssignal were the same as raise/signal respectively, while System V considers them a separate set and obsolete) (CWE-676)",
    mitigation: "Switch to raise/signal, or some other signalling approach",
    category: "obsolete",
  },
  ssignal: {
    cwe: "CWE-676",
    description:
      "These functions are considered obsolete on most systems, and very non-portable (Linux-based systems handle them radically different, basically if gsignal/ssignal were the same as raise/signal respectively, while System V considers them a separate set and obsolete) (CWE-676)",
    mitigation: "Switch to raise/signal, or some other signalling approach",
    category: "obsolete",
  },
  memalign: {
    cwe: "CWE-676",
    description:
      "On some systems (though not Linux-based systems) an attempt to free() results from memalign() may fail. This may, on a few systems, be exploitable. Also note that memalign() may not check that the boundary parameter is correct (CWE-676)",
    mitigation:
      "Use posix_memalign instead (defined in POSIX's 1003.1d).  Don't switch to valloc(); it is marked as obsolete in BSD 4.3, as legacy in SUSv2, and is no longer defined in SUSv3. In some cases, malloc()'s alignment may be sufficient",
    category: "free",
  },
  ulimit: {
    cwe: "CWE-676",
    description:
      "This C routine is considered obsolete (as opposed to the shell command by the same name, which is NOT obsolete) (CWE-676)",
    mitigation: "Use getrlimit(2), setrlimit(2), and sysconf(3) instead",
    category: "obsolete",
  },
  usleep: {
    cwe: "CWE-676",
    description:
      "This C routine is considered obsolete (as opposed to the shell command by the same name). The interaction of this function with SIGALRM and other timer functions such as sleep(), alarm(), setitimer(), and nanosleep() is unspecified (CWE-676)",
    mitigation: "Use nanosleep(2) or setitimer(2) instead",
    category: "obsolete",
  },
  recv: {
    cwe: "CWE-20",
    description: "Function accepts input from outside program (CWE-20)",
    mitigation:
      "Make sure input data is filtered, especially if an attacker could manipulate it",
    category: "input",
  },
  recvfrom: {
    cwe: "CWE-20",
    description: "Function accepts input from outside program (CWE-20)",
    mitigation:
      "Make sure input data is filtered, especially if an attacker could manipulate it",
    category: "input",
  },
  recvmsg: {
    cwe: "CWE-20",
    description: "Function accepts input from outside program (CWE-20)",
    mitigation:
      "Make sure input data is filtered, especially if an attacker could manipulate it",
    category: "input",
  },
  fread: {
    cwe: "CWE-20",
    description: "Function accepts input from outside program (CWE-20)",
    mitigation:
      "Make sure input data is filtered, especially if an attacker could manipulate it",
    category: "input",
  },
  readv: {
    cwe: "CWE-20",
    description: "Function accepts input from outside program (CWE-20)",
    mitigation:
      "Make sure input data is filtered, especially if an attacker could manipulate it",
    category: "input",
  },
  equal: {
    cwe: "CWE-126",
    description:
      "Function does not check the second iterator for over-read conditions (CWE-126)",
    mitigation:
      "This function is often discouraged by most C++ coding standards in favor of its safer alternatives provided since C++14. Consider using a form of this function that checks the second iterator before potentially overflowing it",
    category: "buffer",
  },
  mismatch: {
    cwe: "CWE-126",
    description:
      "Function does not check the second iterator for over-read conditions (CWE-126)",
    mitigation:
      "This function is often discouraged by most C++ coding standards in favor of its safer alternatives provided since C++14. Consider using a form of this function that checks the second iterator before potentially overflowing it",
    category: "buffer",
  },
  is_permutation: {
    cwe: "CWE-126",
    description:
      "Function does not check the second iterator for over-read conditions (CWE-126)",
    mitigation:
      "This function is often discouraged by most C++ coding standards in favor of its safer alternatives provided since C++14. Consider using a form of this function that checks the second iterator before potentially overflowing it",
    category: "buffer",
  },
};

export function dangerous_fn_likelhood_text(likelihood) {
  let like_num = parseFloat(likelihood) / 100;
  if (like_num > 0.6) return "unguarded weakness that may be exploitable.";
  else if (like_num > 0.3) return "moderately guarded weakness.";
  else if (like_num > 0) return "low likelihood of exploitation.";
  else return "no likelihood of exploitation.";
}

/**
 * This function computes the delta between the
 * provided timestamp and the current time, then test
 * the delta for predefined ranges.
 */
export function parseTime(ts) {
  var d = new Date();
  var nowTs = Math.floor(d.getTime() / 1000);
  var seconds = nowTs - ts / 1000;
  if (seconds > 2 * 24 * 3600) {
    return "A few days ago";
  }
  if (seconds > 24 * 3600) {
    return "Yesterday";
  }
  if (seconds > 3600) {
    return "A few hours ago";
  }
  if (seconds > 1800) {
    return "Half an hour ago";
  }
  if (seconds > 60) {
    return Math.floor(seconds / 60) + " minutes ago";
  } else return "Now";
}

/**
 * Parse a unix timestamp into a human-readable form.
 */
export function unixToReadable(unix_time) {
  var a = new Date(unix_time * 1000);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time =
    date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
  return time;
}
