import React from "react";
import "../../index.css";
import { Modal } from "react-bootstrap";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";

// components
import LogoutModal from "./logout_modal";
import AnalysisProgressBar from "./analysis_progress_bar";
import OrganizationsToggle from "./organizations_toggle";

// icons
import { LogOut } from "react-feather";
import Alerts from "./alerts";

/**
 * Header that appears on every page of the application and
 * displays the project logo, the set of toggleable organizations,
 * the set of ongoing analyses, and the logout button.
 */
export default class Headbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logoutModal: false,
    };
  }

  /**
   * Render component.
   */
  render() {
    return (
      <header>
        {/* Modals */}

        <Modal
          enforceFocus={false}
          show={this.state.logoutModal}
          animation={false}
          className="modal-bg"
        >
          <Modal.Body className="modal2">
            <LogoutModal
              backFunction={() => this.setState({ logoutModal: false })}
            />
          </Modal.Body>
        </Modal>

        {/* Actual Content */}
        <div
          style={{
            minWidth: 50,
            maxWidth: 50,
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 0,
            paddingBottom: 10,
            paddingLeft: 20,
            paddingRight: 20,
            marginRight: 5,
            overflow: "hidden",
          }}
        >
          <img
            onClick={() => window.location.assign("#/dashboard")}
            src="./binlens_logo.png"
            alt="OS"
            style={{
              cursor: "pointer",
              minWidth: 80,
              maxWidth: 80,
              marginTop: 10,
              marginLeft: 13,
            }}
          />
        </div>

        <OrganizationsToggle />

        <AnalysisProgressBar />

        <Alerts />

        <Tooltip
          // options
          title="Logout"
          position="bottom"
          trigger="mouseenter"
          arrow
          size="small"
          style={{ marginRight: 20, marginLeft: 10, minWidth: "fit-content" }}
        >
          <div
            style={{ marginRight: 0 }}
            className="sec-button headbar-alerts-button"
            onClick={() => this.setState({ logoutModal: true })}
          >
            <LogOut className="user" />
          </div>
        </Tooltip>
      </header>
    );
  }
}
