import PropTypes from "prop-types";
import "./right_hand_sidebar.css";
import { Tooltip } from "react-tippy";
import HelpTooltip from "../binary_analysis_page/help_tooltip";
import { BookOpen, FileText } from "react-feather";
import { getAllLogs } from "../../api";

/**
 * The right-hand-sidebar that appears on all main-application
 * pages. Has buttons for the following:
 * - Running the tutorial.
 * - Navigating to the Knowledgebase.
 * - Downloading logs.
 */
export default function RightHandSidebar({ tutorialClick }) {
  return (
    <div className="right-bar" style={{ height: "100%" }}>
      <HelpTooltip clickFunction={tutorialClick} />
      <Tooltip
        // options
        html={<p>Support Portal</p>}
        position="left-start"
        trigger="mouseenter"
        arrow
        hideOnClick={false}
        size="small"
        style={{ height: 22, marginTop: 20 }}
      >
        <BookOpen
          onClick={(e) => {
            window.open("https://objectsecurity.com/otai/support/", "_blank");
          }}
          className="user"
        />
      </Tooltip>

      <Tooltip
        // options
        html={<p>Download Server Logs</p>}
        position="left-start"
        trigger="mouseenter"
        arrow
        hideOnClick={false}
        size="small"
        style={{ height: 22, marginTop: 20 }}
      >
        <FileText className="user" onClick={getAllLogs} />
      </Tooltip>
    </div>
  );
}

RightHandSidebar.propTypes = {
  /**
   * Callback function to run the tutorial.
   */
  tutorialClick: PropTypes.func.isRequired,
};
