import React from "react";
import "../../index.css";
import { sameReport } from "./report_concatenator/helpers";

// components
import Headbar from "../headbar/headbar";
import Sidebar from "../sidebar/sidebar";
import GeneratedReports from "./generated_reports";
import Botbar from "../botbar/botbar";
import Notifications from "../login_page/notifications";
import BigDrop from "../sidebar/big_drop";

// icons
import RightHandSidebar from "../sidebar/right_hand_sidebar";
import ReportConcatenator from "./report_concatenator/report_concatenator";

/**
 * The Reports page of the application.
 * The Reports page has two different states. The current state
 * depends of the search params.
 *
 * No search params: The Reports page shows a view of all
 * generated/annotated reports.
 * Search params: The Reports page shows a view of the
 * full report for the binary/device specified in the search params.
 *
 * A full reports shows general information such as file name and
 * metadata, as well as vulnerability information like CVEs,
 * CWEs, and RTFs.
 *
 * A full report can be downloaded as a PDF, printed, emailed,
 * annotated, and linked to.
 */
export default class ReportsPage extends React.Component {
  constructor(props) {
    super(props);

    document.title = "BinLens - Reports";

    this.state = {
      /*    
        The reports are used by the ReportConcatenator component to generate 
        a aggregate PDF consisting of multiple other reports.

        [
            {
                type: 'FACILITY",
                name,
                facility_id,
            },
            {
                type: "ASSET",
                name,
                asset_id,
            },
            {
                type: "BINARY",
                name,
                asset_id,
                bin_id,
            }
        ]
      */
      selectedReports: [],

      // is the concatenated report PDF currently generating or not
      generating: false,
    };

    // report selection logic
    this.addReport = this.addReport.bind(this);
    this.removeReport = this.removeReport.bind(this);
    this.reorderReport = this.reorderReport.bind(this);
  }

  // --- REPORT SELECTION LOGIC ---

  addReport(report) {
    // ensure that this report doesn't already exist
    for (let r of this.state.selectedReports) {
      if (sameReport(report, r)) return;
    }

    // add the new report
    this.setState(prevState => ({
      selectedReports: [...prevState.selectedReports, report],
    }));
  }

  removeReport(report) {
    // find the index of the report
    let idx = -1;
    for (let i = 0; i < this.state.selectedReports.length; i++) {
      let r = this.state.selectedReports[i];
      if (sameReport(report, r)) {
        idx = i;
        break;
      }
    }
    if (idx == -1) return;

    // remove the report
    let selectedReports = this.state.selectedReports;
    selectedReports.splice(idx, 1);
    this.setState({ selectedReports: [...selectedReports] });
  }

  reorderReport(fromIdx, toIdx) {
    let selectedReports = this.state.selectedReports;
    const [removed] = selectedReports.splice(fromIdx, 1);
    selectedReports.splice(toIdx, 0, removed);
    this.setState({ selectedReports: [...selectedReports] });
  }

  // ------

  /**
   * Render the page.
   */
  render() {
    return (
      <BigDrop>
        <main>
          <Notifications />
          <Headbar />
          <Botbar />
          <div className="main-row">
            <Sidebar page="reports" />
            <GeneratedReports
              refreshURL={this.refreshURL}
              addReport={this.addReport}
              removeReport={this.removeReport}
              selectedReports={this.state.selectedReports}
              generating={this.state.generating}
            />
            <ReportConcatenator
              selectedReports={this.state.selectedReports}
              removeReport={this.removeReport}
              reorderReport={this.reorderReport}
              generating={this.state.generating}
              setGenerating={(generating) => this.setState({ generating })}
            />
            <RightHandSidebar />
          </div>
        </main>
      </BigDrop>
    );
  }
}
