import React, { useState } from "react";
import AssetFetcher from "../../../asset_fetcher";
import { AlertCircle, Search } from "react-feather";

export default function AssetSelector({ setAsset, id }) {
  let [assetName, setAssetName] = useState(null);
  let [selectingAsset, setSelectingAsset] = useState(false);
  let [isIncomplete, setIsIncomplete] = useState(false);

  if (selectingAsset)
    return (
      <AssetSelectorPopup
        selectAsset={(asset) => {
          setAsset(asset);
          setAssetName(asset.name);
          setIsIncomplete(isAssetIncomplete(asset));
          setSelectingAsset(false);
        }}
        id={id}
      />
    );

  return (
    <>
      <div className="asset-selector">
        <button onClick={() => setSelectingAsset(true)}>Select Asset</button>
        <h3>{assetName ? assetName : "No asset selected."}</h3>
      </div>
      {isIncomplete && (
        <p className="delta-incomplete-warning">
          <AlertCircle className="icon" /> This asset has incomplete results!
          This means that the asset has not finished analyzing, or the user who
          analyzed this asset stopped some assessments before they completed.
          Including this asset in a Binary Delta Report may introduce
          potentially misleading results.
        </p>
      )}
    </>
  );
}

function isAssetIncomplete(asset) {
  return (
    asset.state !== "completed" || ("incomplete" in asset && asset.incomplete)
  );
}

// ---

class AssetSelectorPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assets: [],
      loading: true,
      searchText: "",
    };
    this.init = this.init.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    this.assetFetcher = null;
    this.setState({ assets: [], loading: true });
    this.assetFetcher = new AssetFetcher(
      document.getElementById(this.props.id),
      (assets, self) => {
        // only set state if this is our asset fetcher!
        if (this.assetFetcher === self)
          this.setState({ assets, loading: false });
      },
      null,
      this.state.searchText,
      "date"
    );
    this.assetFetcher.loadAssets();
  }

  render() {
    return (
      <div className="asset-selector-popup">
        <div className="searchbar">
          <div className="internal-searchbar">
            <Search className="icon" />
            <input
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                this.setState({ searchText: e.target.value });
                if (this.searchTimeout) clearTimeout(this.searchTimeout);
                this.searchTimeout = setTimeout(this.init, 500);
              }}
              value={this.state.searchText}
              placeholder="Search Assets..."
            ></input>
          </div>
        </div>
        {this.state.loading && (
          <div className="lds-dual-ring-wrapper">
            <div className="lds-dual-ring"></div>
          </div>
        )}
        <div
          className="select-deep"
          id={this.props.id}
          onScroll={() => {
            if (this.assetFetcher) this.assetFetcher.loadAssets();
          }}
        >
          {this.state.assets.map((a, i) => {
            if (a.state === "unassessable") return null;
            return (
              <div
                onClick={() => {
                  this.props.selectAsset(a);
                }}
                key={i}
                className="delta-clickable-asset"
              >
                {a.name}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
