import { useState } from "react";
import { ChevronUp, ChevronDown } from "react-feather";

export class BinLensTableColumn {
  constructor(name, displayName, sortFunc, width) {
    this.name = name;
    this.displayName = displayName;
    this.sortFunc = sortFunc;
    this.width = width;
  }
}

export function BinLensTable({
  data,
  columns,
  defaultSortFuncIdx,
  onRowClick,
  shouldGreyOut,
}) {
  let [sortFuncIdx, setSortFuncIdx] = useState(defaultSortFuncIdx);
  let [reverse, setReverse] = useState(false);

  data.sort(columns[sortFuncIdx].sortFunc);
  if (reverse) {
    data.reverse();
  }

  return (
    <div>
      <div className="bap-table">
        <table>
          <tbody>
            <tr className="t-headdd">
              {columns.map((col, i) => (
                <th
                  className="t-1"
                  style={{ width: col.width }}
                  onClick={() => {
                    if (!col.sortFunc) {
                      return;
                    }
                    if (sortFuncIdx === i) {
                      setReverse((reverse) => !reverse);
                    } else {
                      setReverse(false);
                    }
                    setSortFuncIdx(i);
                  }}
                >
                  <div className="cve-table-header">
                    {col.displayName}
                    {col.sortFunc && (
                      <ColumnSortArrows
                        isTop={sortFuncIdx === i && !reverse}
                        isBottom={sortFuncIdx === i && reverse}
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
            {data.map((d, i) => {
              return (
                <BinLensTableRow
                  key={i}
                  data={d}
                  columns={columns}
                  onClick={onRowClick}
                  shouldGreyOut={shouldGreyOut}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function BinLensTableRow({ data, columns, onClick, shouldGreyOut }) {
  let grey_out = false;
  if (shouldGreyOut) grey_out = shouldGreyOut(data);

  let classNames = ["vulnerability-row-cve"];
  if (grey_out) {
    classNames.push("vulnerability-row-cve-grey");
  } else if (onClick) {
    classNames.push("vulnerability-row-cve-hoverable");
  }

  return (
    <tr
      className={classNames.join(" ")}
      onClick={() => {
        if (grey_out) return;
        if (onClick) onClick(data);
      }}
    >
      {columns.map((col) => (
        <td className="t-1">{data[col.name]}</td>
      ))}
    </tr>
  );
}

function ColumnSortArrows({ isTop, isBottom }) {
  return (
    <div className="column-sort-arrows">
      <ChevronUp className={isTop ? "icon sort-arrow-sel" : "icon"} />
      <ChevronDown className={isBottom ? "icon sort-arrow-sel" : "icon"} />
    </div>
  );
}
