import { useState } from "react";
import LoginHeadbar from "./login_headbar/login_headbar";
import { TextField } from "@mui/material";
import Copyright from "./copyright/copyright";
import { sendPasswordResetEmail } from "../../api";

export default function ResetPaswordPage() {
  let [email, setEmail] = useState("");
  let [loading, setLoading] = useState(false);
  let [response, setResponse] = useState(null);

  return (
    <div className="login-page">
      <LoginHeadbar />
      <h1>Reset Password</h1>
      <div className="login-center">
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label="Email"
          fullWidth
          className="username-input"
        />
        <p>
          Enter the Email associated with your account, and click the "Send
          Email" button to recieve a password reset link.
        </p>
        <div className="row">
          {loading && <div className="lds-dual-ring"></div>}
          {!loading && <p>{response}</p>}
          <button
            onClick={async () => {
              setLoading(true);
              let response = await sendPasswordResetEmail(email);
              setResponse(response);
              setLoading(false);
            }}
          >
            Send Email
          </button>
        </div>
      </div>
      <div className="login-bot-row">
        <a
          onClick={() => {
            window.location.assign("#/login");
          }}
        >
          Back to Sign In
        </a>
      </div>
      <Copyright />
    </div>
  );
}
