/*
    This component controls the right side of the file drop modal.
    It contains logic to manage the current subpage of the right side
    (upload button, current files, etc.)

    This component appears on the right-side of the file drop modal.
*/
import { useState, useEffect } from "react";
import {
  uploadBinary,
  deleteBinary,
  ingressS3Files,
  deleteAllBinariesInDropzone,
} from "../../../api";

// components
import UploadOption from "./upload_option";
import Uploaded from "./uploaded";
import UploadingFile from "./uploading_file";
import DeletingFile from "./deleting_file";
import S3Creds from "./s3_creds";
import ChooseFiles from "./choose_files";

// icons
import { File, Info, X } from "react-feather";

// the current subpage of the component
const PAGE = {
  UPLOAD: "UPLOAD",
  UPLOADING_FILE: "UPLOADING_FILE",
  DELETING_FILE: "DELETING_FILE",
  UPLOADED: "UPLOADED",
  S3CREDS: "S3CREDS",
  CHOOSE_FILES: "CHOOSE_FILES",
};

export default function FileDropRight({
  form, // asset form on the left side of the modal
  backFunction, // close the modal
  refresh, // refresh the parent components list of dropFiles
  acceptedFiles, // file that the user has drag-dropped over the page
  dropUploads, // the list of files in the dropzone
}) {
  let [page, setPage] = useState(PAGE.UPLOAD);

  // information used to display file upload progress
  let [currentlyLoading, setCurrentlyLoading] = useState(["", 0, 1]);

  // S3 credentials used when ingressing data
  let [accessKeyId, setAccessKeyId] = useState("");
  let [secretKeyId, setSecretKeyId] = useState("");
  let [bucket, setBucket] = useState("");

  let [uploadRequest, setUploadRequest] = useState(null);

  /**
   * File drop listener.
   * Takes the first dropped file and stores its information
   * in localStorage.
   */
  const dropFiles = async (acceptedFilesOld) => {
    if (page === PAGE.UPLOADING_FILE) {
      alert(
        "Please wait for your first upload to finish before uploading additional files."
      );
      return;
    }
    let acceptedFiles = [...acceptedFilesOld];

    setPage(PAGE.UPLOADING_FILE);
    setCurrentlyLoading(["", 0, 1]);

    let completeFunc = () => {
      setUploadRequest(null);
      if (acceptedFiles.length <= 0) {
        refresh();
        return;
      }
      let file = acceptedFiles.pop();
      let request = uploadBinary(
        file,
        (e) => {
          setCurrentlyLoading([file.name, e.loaded, e.total]);
        },
        completeFunc
      );
      setUploadRequest(request);
    };
    completeFunc();
  };

  /**
   * Whenever the user drag-drops files over the page, catch them and upload
   * them to the dropzone.
   */
  useEffect(() => {
    if (acceptedFiles) dropFiles(acceptedFiles);
  }, [acceptedFiles]);

  /**
   * If there exist uploaded files every, this component should display them.
   */
  useEffect(() => {
    if (dropUploads && dropUploads.length > 0) {
      setPage(PAGE.UPLOADED);
      return;
    }
    setPage(PAGE.UPLOAD);
  }, [dropUploads]);

  /**
   * Close the modal. This function runs when the user clicks the '<X />'
   * icon.
   *
   * If the user is currently uploading file/s, this function should first
   * display an alert stating that their uploads will be cancelled.
   */
  const closeModal = () => {
    if (page === PAGE.UPLOADING_FILE) {
      if (
        window.confirm(
          "This will stop all incomplete uploads! Your files that have already completed uploading will remain available in the dropzone, but uploads which have not yet finished will not be available. Are you sure you want to continue? "
        )
      ) {
        backFunction();
        if (uploadRequest) uploadRequest.abort();
      }
      return;
    }
    backFunction();
  };

  if (form.analyzing === "") {
    return (
      <div className="new-analysis-file-holder" style={{ height: 605 }}>
        <div
          className="new-analysis-base-row"
          style={{ padding: 20, width: "calc(100% - 40px)" }}
        >
          <File className="new-analysis-icon" />
          <h2>Binary Files</h2>
          <X className="new-analysis-close" onClick={closeModal} />
        </div>
      </div>
    );
  }

  return (
    <div
      className="new-analysis-file-holder"
      style={{ height: 605, transform: "none" }}
    >
      <div
        className="new-analysis-base-row"
        style={{ padding: 20, width: "calc(100% - 40px)" }}
      >
        <File className="new-analysis-icon" />
        <h2>Binary Files</h2>
        <X className="new-analysis-close" onClick={closeModal} />
      </div>

      {page === PAGE.UPLOAD && (
        <UploadOption
          dropFiles={dropFiles}
          ingressFromS3={() => setPage(PAGE.S3CREDS)}
        />
      )}

      {page === PAGE.UPLOADED && (
        <Uploaded
          dropFiles={dropFiles}
          dropUploads={dropUploads}
          deleteBinary={async (filename) => {
            setPage(PAGE.DELETING_FILE);
            await deleteBinary(filename);
            await refresh();
            setPage(PAGE.UPLOADED);
          }}
          deleteAllBinaries={async () => {
            setPage(PAGE.DELETING_FILE);
            await deleteAllBinariesInDropzone();
            await refresh();
            setPage(PAGE.UPLOADED);
          }}
        />
      )}

      {page === PAGE.UPLOADING_FILE && (
        <UploadingFile currentlyLoading={currentlyLoading} />
      )}

      {page === PAGE.DELETING_FILE && <DeletingFile />}

      {page === PAGE.S3CREDS && (
        <S3Creds
          cancel={() => setPage(PAGE.UPLOAD)}
          next={() => setPage(PAGE.CHOOSE_FILES)}
          accessKeyId={accessKeyId}
          setAccessKeyId={setAccessKeyId}
          secretKeyId={secretKeyId}
          setSecretKeyId={setSecretKeyId}
          bucket={bucket}
          setBucket={setBucket}
        />
      )}

      {page === PAGE.CHOOSE_FILES && (
        <ChooseFiles
          back={() => setPage(PAGE.S3CREDS)}
          ingress={async (files) => {
            try {
              await ingressS3Files(accessKeyId, secretKeyId, bucket, files);
            } catch (error) {
              console.error(error);
            }
            refresh();
            setPage(PAGE.UPLOADED);
          }}
          accessKeyId={accessKeyId}
          secretKeyId={secretKeyId}
          bucket={bucket}
        />
      )}

      <div
        onClick={() => {
          alert(
            "Supported File Systems:\nExt4, JFFS2, YAFFS, UBIFS, SquashFS, NTFS, FAT, EXFAT, UFS 1, UFS 2, EXT2FS, EXT3FS, HFS, YAFFS2, EnCase, AFF, EPROM\n\nSupported Binary File Types:\nElf, WASM, PE/PE+, SPX, MBN/SBL bootloader, DEX, OMF, COFF, MZ, EXE, QNX, Fatmach-O, Mach-O, DYldcache\n\nSupported Packed Binary Files:\nZIP, TAR, GZIP, BZIP2, XAR, BIN, MSI, MS CAB, ARJ, L7H, LZS, PMA, LZ0, UPN, XZ, APK, ISO 9660, BIOS/UEF\n\nCurrently Not Supported:\nJIT Languages (e.g. Java Class Files), Image Files (e.g., JPG, PNG, GIF, ART), Non-Executable Binary Files (e.g., TextEase (TE) CT Database Files, SELinux Type Enforcement Files), Bell Labs Plan 9 EXE (untested)"
          );
        }}
        className="new-analysis-base-row new-analysis-info-bottom"
        style={{ marginTop: "auto", justifyContent: "center" }}
      >
        <Info
          className="icon"
          style={{
            stroke: "var(--pri-color-light)",
            marginRight: 5,
            width: 16,
            height: 16,
          }}
        />
        <h3>What can be analyzed?</h3>
      </div>
    </div>
  );
}
