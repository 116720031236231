import PropTypes from "prop-types";
import "./mappings.css";

// components
import { Binary } from "../../../constants";

/**
 * An individual instance of an ISA mapping.
 * Contains the originating CWE, the mapping line-items,
 * and deep dive discovery locations (if applicable).
 */
export function ISA_Mapping({ asset_id, binary, cwe, requirements }) {
  return (
    <div className="mapping">
      <div style={{ width: "100%" }}>
        <h3>Discovered Vulnerability</h3>
        <h2>
          {cwe.cwe} {cwe.info ? "• " + cwe.info.name : ""}
        </h2>

        <h3>Unmet Requirements</h3>
        <div className="mapping-field">
          {requirements.map((req) => {
            return (
              <p>
                {req.items.map((item) => {
                  return <div>{item}</div>;
                })}
                <div>{req.desc}</div>
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
}

ISA_Mapping.propTypes = {
  /**
   * The ID of the asset being mapped.
   */
  asset_id: PropTypes.string.isRequired,

  /**
   * The binary being mapped.
   */
  binary: PropTypes.instanceOf(Binary).isRequired,

  /**
   * The CWE being mapped.
   */
  cwe: PropTypes.object.isRequired,

  /**
   * The ISA line items mapped to.
   */
  requirements: PropTypes.arrayOf(PropTypes.string).isRequired,
};
