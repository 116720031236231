import { getBasePath } from ".";

/**
 * Get all the CWEs for a given binary.
 */
export async function getCWEs(dutId, name) {
  let body = new FormData();
  body.append("assessment", "cwes");
  body.append("asset", dutId);
  body.append("bin", name);
  let res = await fetch(getBasePath() + "/result/get", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  if (!res.ok) return [];
  let parsed = await res.json();
  return parsed;
}

export async function getUnifiedCWEs(asset_id, bin_id) {
  try {
    let body = new FormData();
    body.append("assessment", "unified_cwes");
    body.append("asset", asset_id);
    body.append("bin", bin_id);
    let res = await fetch(getBasePath() + "/result/get", {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + window.localStorage.getItem("auth"),
      }),
      body,
    });
    let parsed = await res.json();
    return parsed;
  } catch {
    return null;
  }
}

/**
 * Get the number of lines of a disassembled/decompiled file.
 */
export async function getDeepDiveSize(dutId, name, assessment) {
  let body = new FormData();
  body.append("assessment", assessment);
  body.append("asset", dutId);
  body.append("bin", name);
  let res = await fetch(getBasePath() + "/result/deepdivesize", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  if (!res.ok) return { size: 0 };
  let parsed = await res.json();
  return parsed.size;
}

/**
 *  Get the a subset of the lines of a disassembled/decompiled file.
 */
export async function getDeepDiveLines(
  dutId,
  name,
  assessment,
  offset,
  limit,
  return_offset = false
) {
  if (isNaN(offset)) return [];
  let body = new FormData();
  body.append("assessment", assessment);
  body.append("asset", dutId);
  body.append("bin", name);
  body.append("offset", offset);
  body.append("limit", limit);
  let res = await fetch(getBasePath() + "/result/deepdivelines", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  if (return_offset) {
    if (!res.ok) return -1;
    let parsed = await res.json();
    return parsed.offset;
  } else {
    if (!res.ok) return [];
    let parsed = await res.json();
    return parsed.lines;
  }
}

/**
 *  Get the containing function name based on line number in
 *  a disassembled/decompiled file.
 */
export async function getDeepDiveFunc(asset, bin, assessment, line_num) {
  let body = new FormData();
  body.append("assessment", assessment);
  body.append("asset", asset);
  body.append("bin", bin);
  body.append("line_num", line_num);
  let res = await fetch(getBasePath() + "/result/deepdivefunc", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  if (!res.ok) {
    throw new Error("Failed to find deep dive function name.");
  }
  let parsed = await res.json();
  return parsed.func_name;
}

/**
 *  Get theline number based on containing function name in
 *  a disassembled/decompiled file.
 */
export async function getDeepDiveBridge(asset, bin, assessment, func_name) {
  let body = new FormData();
  body.append("assessment", assessment);
  body.append("asset", asset);
  body.append("bin", bin);
  body.append("func_name", func_name);
  let res = await fetch(getBasePath() + "/result/deepdivebridge", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  if (!res.ok) {
    throw new Error("Failed to find deep dive line number.");
  }
  let parsed = await res.json();
  return parsed.line_num;
}
/**
 * Get all the CVEs for a given binary.
 */
export async function getCVEs(dutId, name) {
  let body = new FormData();
  body.append("assessment", "cves");
  body.append("asset", dutId);
  body.append("bin", name);
  let res = await fetch(getBasePath() + "/result/get", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  if (!res.ok) return null;
  let parsed = await res.json();
  return parsed;
}

/**
 * Get all BAP results for a given binary.
 */
export async function getBAP(dutId, name) {
  let body = new FormData();
  body.append("assessment", "restrictness");
  body.append("asset", dutId);
  body.append("bin", name);
  let res = await fetch(getBasePath() + "/result/get", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  if (!res.ok) return [];
  let parsed = await res.json();
  return parsed;
}

/**
 * Get file metadata for a given binary.
 */
export async function getMetadata(dutId, name) {
  let body = new FormData();
  body.append("assessment", "metadata");
  body.append("asset", dutId);
  body.append("bin", name);
  let res = await fetch(getBasePath() + "/result/get", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  if (!res.ok) {
    let parsed = null;
    return parsed;
  }
  let parsed = await res.json();
  return parsed;
}

/**
 * Get entropy for a given binary.
 */
export async function getEntropy(dutId, name) {
  let body = new FormData();
  body.append("assessment", "entropy_etc");
  body.append("asset", dutId);
  body.append("bin", name);
  let res = await fetch(getBasePath() + "/result/get", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  if (!res.ok) {
    let parsed = null;
    return parsed;
  }
  let parsed = await res.json();
  return parsed;
}

/**
 * Get binary encryption results.
 */
export async function getBinaryEncryption(dutId, name) {
  let body = new FormData();
  body.append("assessment", "encryption_binary");
  body.append("asset", dutId);
  body.append("bin", name);
  let res = await fetch(getBasePath() + "/result/get", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  if (!res.ok) {
    let parsed = null;
    return parsed;
  }
  let parsed = await res.json();
  return parsed;
}

/**
 * Get runtime encryption results.
 */
export async function getRuntimeEncryption(dutId, name) {
  let body = new FormData();
  body.append("assessment", "encryption_runtime");
  body.append("asset", dutId);
  body.append("bin", name);
  let res = await fetch(getBasePath() + "/result/get", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  if (!res.ok) {
    let parsed = null;
    return parsed;
  }
  let parsed = await res.json();
  return parsed;
}

/**
 * Get lineage analysis results for a given binary.
 * Returns "false" if the binary fails the assessment, and
 * "true" if the binary passes the assessment.
 */
export async function getLineage(dutId, name) {
  let body = new FormData();
  body.append("assessment", "lineage");
  body.append("asset", dutId);
  body.append("bin", name);
  let res = await fetch(getBasePath() + "/result/get", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  if (!res.ok) {
    return true;
  }
  let parsed = await res.json();
  if (parsed.results === "no assessment results for this binary found") {
    return true;
  }
  return parsed.results !== "Liveness: false\n";
}

/**
 * Get Manalyze results for a given binary.
 */
export async function getManalyze(dutId, name) {
  let body = new FormData();
  body.append("assessment", "pe");
  body.append("asset", dutId);
  body.append("bin", name);
  let res = await fetch(getBasePath() + "/result/get", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  if (!res.ok) {
    return null;
  }
  let parsed = await res.json();
  if (parsed === "no assessment results for this binary found") {
    return null;
  }
  return parsed;
}

/**
 * Get ClamAV results for a given binary.
 */
export async function getClamAV(dutId, name) {
  let body = new FormData();
  body.append("assessment", "malware");
  body.append("asset", dutId);
  body.append("bin", name);
  let res = await fetch(getBasePath() + "/result/get", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  if (!res.ok) {
    return { status: "OK" };
  }
  let parsed = await res.json();
  if (parsed === "no assessment results for this binary found") {
    return null;
  }
  return parsed;
}

/**
 * Cryptographic key results for a given binary.
 */
export async function getCrypto(dutId, name) {
  let body = new FormData();
  body.append("assessment", "crypto");
  body.append("asset", dutId);
  body.append("bin", name);
  let res = await fetch(getBasePath() + "/result/get", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  if (!res.ok) {
    return null;
  }
  let parsed = await res.json();
  if (
    parsed === "no assessment results for this binary found" ||
    Object.values(parsed).length === 0
  ) {
    return null;
  }
  return parsed;
}

/**
 * Get ClamAV results for a given binary.
 */
export async function getDangerousFunctions(dutId, name) {
  let body = new FormData();
  body.append("assessment", "dangerous_fns");
  body.append("asset", dutId);
  body.append("bin", name);
  let res = await fetch(getBasePath() + "/result/get", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  if (!res.ok) {
    return {};
  }
  let parsed = await res.json();
  if (
    parsed === "no assessment results for this binary found" ||
    Object.values(parsed).length === 0
  ) {
    return {};
  }
  return parsed;
}

export async function getWeakPointers(asset_id, binary) {
  let body = new FormData();
  body.append("assessment", "weak_pointers");
  body.append("asset", asset_id);
  body.append("bin", binary);
  let res = await fetch(getBasePath() + "/result/get", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });

  if (!res.ok) {
    return {};
  }
  let parsed = await res.json();
  if (
    parsed === "no assessment results for this binary found" ||
    Object.values(parsed).length === 0
  ) {
    return null;
  }
  return parsed;
}

/**
 * Get OS Vulnerability Score results for a given binary.
 */
export async function getVulscore(dutId, name) {
  let body = new FormData();
  body.append("assessment", "vulscore");
  body.append("asset", dutId);
  body.append("bin", name);
  let res = await fetch(getBasePath() + "/result/get", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  if (!res.ok) {
    return {};
  }
  let parsed = await res.json();
  if (parsed === "no assessment results for this binary found") {
    return {};
  }
  return parsed;
}

/**
 * Get CWE information for a given list of CWE IDs.
 */
export async function getCWEInfo(cwes) {
  let body = new FormData();
  body.append("cwes", JSON.stringify(cwes));
  let res = await fetch(getBasePath() + "/result/cwe_info", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  if (!res.ok) {
    let parsed = null;
    return parsed;
  }
  let parsed = await res.json();

  // trim the fat a bit... we only care about a couple fields
  let ret = {};
  for (let id in parsed) {
    let allinfo = parsed[id];
    let info = {};

    // CWE description
    info.name = allinfo._Name;
    info.description = allinfo.Description + " ";
    switch (typeof allinfo.Extended_Description) {
      case "string":
        info.description += allinfo.Extended_Description;
        break;
      case "object":
        for (let text of allinfo.Extended_Description.p) {
          info.description += text.__text + " ";
        }
        break;
      case "null":
      case "undefined":
      default:
        break;
    }

    // CWE mitigations
    info.mitigations = [];
    if (
      allinfo.Potential_Mitigations !== undefined &&
      allinfo.Potential_Mitigations !== null
    ) {
      for (let miti of allinfo.Potential_Mitigations.Mitigation) {
        info.mitigations.push(miti.Description);
      }
    }

    // CWE affected resource
    info.resource = "Misc";
    if (allinfo.hasOwnProperty("Affected_Resources"))
      info.resource = allinfo.Affected_Resources.Affected_Resource[0];

    // CWE examples
    info.examples = [];
    if (allinfo.hasOwnProperty("Observed_Examples")) {
      for (let c of allinfo.Observed_Examples.Observed_Example) {
        if (c.hasOwnProperty("Link") && c.hasOwnProperty("Description"))
          info.examples.push({
            desc: c.Description,
            link: c.Link,
          });
      }
    }

    // CWE consequences
    info.consequences = [];
    if (allinfo.hasOwnProperty("Common_Consequences")) {
      for (let c of allinfo.Common_Consequences.Consequence) {
        if (c.hasOwnProperty("Note")) info.consequences.push(c.Note[0]);
      }
    }

    ret[id] = info;
  }
  return ret;
}

export async function getDisassemble(dutId, name) {
  let body = new FormData();
  body.append("assessment", "disassembly");
  body.append("asset", dutId);
  body.append("bin", name);
  let res = await fetch(getBasePath() + "/result/get", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  if (!res.ok) return "";
  let parsed = await res.json();
  return parsed.results;
}

export async function getDecompile(dutId, name) {
  let body = new FormData();
  body.append("assessment", "decompiled");
  body.append("asset", dutId);
  body.append("bin", name);
  let res = await fetch(getBasePath() + "/result/get", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  if (!res.ok) {
    return "";
  }
  let parsed = await res.json();
  return parsed.results;
}
