import React from "react";
import "../../index.css";
import AssetFetcher from "../../asset_fetcher";
import {
  genReportFromBinary,
  genReportFromAsset,
  genReportFromFacility,
} from "./report_concatenator/helpers";
import { getBinaries, getAllFacilities } from "../../api";
import { calcColor } from "../../helpers";
import { getAssetIcon } from "../../asset_types";

// components
import GeneratedReport from "./generated_report";

// icons
import { Search } from "react-feather";

/**
 * Shows a view of all generated/annotated reports.
 * Display when the URL on the reports page does not contain any
 * search params.
 * Shows a grid view of the most recently annotated reports,
 * aswell as a list view of all reports.
 */
export default class GeneratedReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingAssets: true,
      loadingBinaries: true,
      assets: [],
      selectedAsset: null,
      selectedFacility: null,
      binaries: [],
      searchText: "",
      popup: false,
    };
    this.init = this.init.bind(this);
  }

  componentDidMount() {
    GeneratedReports._this = this;
    this.init();
  }

  componentWillUnmount() {
    GeneratedReports._this = null;
  }

  static _this = null;

  async init() {
    this.assetFetcher = null;
    // reset
    this.setState({
      loadingAssets: true,
      loadingBinaries: true,
      assets: [],
      selectedAsset: null,
      selectedFacility: null,
      binaries: [],
      popup: false,
    });

    // get list of assets from the BE
    this.assetFetcher = new AssetFetcher(
      document.getElementById("assets-in-line"),
      (assets, self) => {
        if (this.assetFetcher === self) {
          this.setState({ assets, loadingAssets: false });
          if (assets.length > 0 && !this.state.selectedAsset) {
            this.setState({ selectedAsset: assets[0] });
            getBinaries(assets[0].ref_id).then((binaries) => {
              this.setState({
                binaries: binaries.assessable,
                loadingBinaries: false,
              });
            });
            getAllFacilities().then((facilities) => {
              let selectedFacility = facilities.filter(
                (f) => f.id === assets[0].facility_id
              )[0];
              this.setState({
                selectedFacility,
              });
            });
          } else {
            this.setState({ loadingBinaries: false });
          }
        }
      },
      null,
      this.state.searchText,
      "date"
    );
    this.assetFetcher.loadAssets();
  }

  /**
   * Render the component.
   */
  render() {
    // are all the binary/asset reports selected for the concatenated report
    let allSelected =
      this.state.selectedAsset &&
      this.state.binaries.every((binary) => {
        if (binary.cwss.aggregate)
          return this.props.selectedReports.some(
            (report) =>
              report.type === "BINARY" &&
              report.bin_id === binary.name &&
              report.asset_id === this.state.selectedAsset.ref_id
          );
        return true;
      }) &&
      this.state.selectedAsset &&
      this.state.selectedAsset.state &&
      ((this.state.selectedAsset.state === "completed" &&
        this.props.selectedReports.some(
          (report) =>
            report.type === "ASSET" &&
            report.asset_id === this.state.selectedAsset.ref_id
        )) ||
        this.state.selectedAsset.state !== "completed");

    return (
      <div
        className="generated-reports"
        style={{ height: "calc(100vh - 100px)", alignItems: "flex-start" }}
      >
        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
          <div className="searchbar" style={{ maxWidth: 600 }}>
            <div className="internal-searchbar" style={{ height: 25 }}>
              <Search className="search" />
              <input
                onChange={(e) => {
                  this.setState({ searchText: e.target.value });
                  if (this.searchTimeout) clearTimeout(this.searchTimeout);
                  this.searchTimeout = setTimeout(this.init, 500);
                }}
                value={this.state.searchText}
                placeholder="Search Assets..."
              ></input>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              width: "calc(100% + 20px)",
              maxHeight: "calc(100vh - 170px)",
            }}
          >
            <div
              id="assets-in-line"
              className="assets-in-line"
              onScroll={() => {
                if (this.assetFetcher) this.assetFetcher.loadAssets();
              }}
              style={{
                minWidth: 272,
                maxWidth: 272,
                minHeight: "calc(100vh - 170px)",
                marginRight: 30,
                alignItems: "center",
                borderRight: "1px solid var(--bor-color)",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              {!this.state.loadingAssets && this.state.assets.length === 0 && (
                <h4
                  style={{
                    width: "100%",
                    lineHeight: "1.4",
                  }}
                >
                  No assets found.
                </h4>
              )}

              {this.state.loadingAssets && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: 20,
                  }}
                >
                  <div className="lds-dual-ring"></div>
                </div>
              )}
              {[...this.state.assets].map((asset) => {
                if (asset.state === "unassessable") return null;
                if (
                  this.state.selectedAsset &&
                  asset.ref_id === this.state.selectedAsset.ref_id
                )
                  return (
                    <div
                      key={asset.ref_id}
                      style={{
                        position: "relative",
                        width: 242,

                        padding: 15,
                        borderTop: "1px solid var(--bor-color)",
                        borderLeft: "1px solid var(--bor-color)",

                        borderBottom: "1px solid var(--bor-color)",
                        borderTopLeftRadius: 5,
                        borderBottomLeftRadius: 5,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          height: "100%",
                          width: 20,
                          right: -10,
                          zIndex: 10,
                          top: 0,
                          background: "var(--bg-color)",
                        }}
                      ></div>

                      {asset.state !== "in progress" &&
                        getAssetIcon(asset.type, "", {
                          width: 16,
                          height: 16,
                          marginRight: 10,
                          stroke: calcColor(asset.cwss_score),
                        })}

                      {asset.state === "in progress" && (
                        <div
                          className="in-progress-ring"
                          style={{
                            margin: 0,
                            marginRight: 10,
                            width: 16,
                            height: 16,
                          }}
                        ></div>
                      )}
                      <h3
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "90%",
                        }}
                      >
                        {asset.name}
                      </h3>
                    </div>
                  );

                return (
                  <div
                    key={asset.ref_id}
                    style={{
                      overflow: "hidden",
                      width: 242,
                      padding: 15,
                      borderTop: "1px solid transparent",
                      borderLeft: "1px solid transparent",

                      borderBottom: "1px solid transparent",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.setState({
                        selectedAsset: asset,
                        loadingBinaries: true,
                        binaries: [],
                      });
                      getBinaries(asset.ref_id).then((binaries) =>
                        this.setState({
                          binaries: binaries.assessable,
                          loadingBinaries: false,
                        })
                      );
                      getAllFacilities().then((facilities) => {
                        let selectedFacility = facilities.filter(
                          (f) => f.id === asset.facility_id
                        )[0];
                        this.setState({
                          selectedFacility,
                        });
                      });
                    }}
                  >
                    {asset.state !== "in progress" &&
                      getAssetIcon(asset.type, "", {
                        width: 16,
                        height: 16,
                        marginRight: 10,
                        stroke: calcColor(asset.cwss_score),
                      })}

                    {asset.state === "in progress" && (
                      <div
                        className="in-progress-ring"
                        style={{
                          margin: 0,
                          marginRight: 10,
                          width: 16,
                          height: 16,
                        }}
                      ></div>
                    )}

                    <h3
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "90%",
                      }}
                    >
                      {asset.name}
                    </h3>
                  </div>
                );
              })}
            </div>

            {!this.state.loadingAssets && this.state.loadingBinaries && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: 20,
                }}
              >
                <div className="lds-dual-ring"></div>
              </div>
            )}

            {!this.state.loadingBinaries &&
              this.state.assets.length !== 0 &&
              this.state.binaries.length === 0 && (
                <div style={{ marginTop: 0 }}>
                  <h4 className="upload-header">
                    This asset has no assessable binaries.
                  </h4>
                </div>
              )}

            {!this.state.loadingBinaries && this.state.binaries.length !== 0 && (
              <div
                style={{
                  marginTop: 0,
                  overflowY: "scroll",
                  width: "100%",
                }}
              >
                <h4 className="upload-header" style={{ marginBottom: 20 }}>
                  Associated Reports
                </h4>
                <button
                  onClick={() => {
                    if (allSelected) {
                      this.props.removeReport(
                        genReportFromAsset(this.state.selectedAsset)
                      );
                      for (let binary of this.state.binaries) {
                        this.props.removeReport(
                          genReportFromBinary(
                            binary,
                            this.state.selectedAsset.ref_id
                          )
                        );
                      }
                      return;
                    }

                    if (
                      this.state.selectedAsset &&
                      this.state.selectedAsset.state &&
                      this.state.selectedAsset.state === "completed"
                    ) {
                      // add all selected reports that are on this page
                      this.props.addReport(
                        genReportFromAsset(this.state.selectedAsset)
                      );
                    }
                    for (let binary of this.state.binaries) {
                      if (!binary.cwss.aggregate) continue;
                      this.props.addReport(
                        genReportFromBinary(
                          binary,
                          this.state.selectedAsset.ref_id
                        )
                      );
                    }
                  }}
                  className={
                    allSelected
                      ? "report-builder-toggle-all report-builder-toggle-all-on"
                      : "report-builder-toggle-all"
                  }
                  style={{ marginLeft: 2 }}
                >
                  Toggle All Asset & Binary Reports
                </button>
                <div
                  className="recent-reports-grid"
                  style={{
                    width:
                      this.props.selectedReports.length > 0
                        ? "calc(100% - 360px)"
                        : "100%",
                  }}
                >
                  {this.state.binaries.map((binary, i) => {
                    if (!binary.cwss.aggregate) return null;
                    return (
                      <GeneratedReport
                        key={i}
                        name={binary.name}
                        type={"BINARY"}
                        score={binary.cwss.aggregate}
                        goToReport={() => {
                          window.location.assign(
                            `#/report/builder/binary?asset_id=${this.state.selectedAsset.ref_id}&binary=${binary.name}`
                          );
                        }}
                        addConcatReport={() => {
                          if (this.props.generating) {
                            alert(
                              "Please wait for the current PDF to finish generating before adding more reports!"
                            );
                            return;
                          }

                          this.props.addReport(
                            genReportFromBinary(
                              binary,
                              this.state.selectedAsset.ref_id
                            )
                          );
                        }}
                        included={
                          this.state.selectedAsset &&
                          this.props.selectedReports.some(
                            (report) =>
                              report.type === "BINARY" &&
                              report.bin_id === binary.name &&
                              report.asset_id ===
                                this.state.selectedAsset.ref_id
                          )
                        }
                      />
                    );
                  })}

                  {this.state.selectedAsset &&
                    this.state.selectedAsset.state &&
                    this.state.selectedAsset.state === "completed" && (
                      <GeneratedReport
                        name={this.state.selectedAsset.name}
                        type={"ASSET"}
                        score={this.state.selectedAsset.cwss_score}
                        goToReport={() => {
                          window.location.assign(
                            `#/report/builder/asset?id=${this.state.selectedAsset.ref_id}`
                          );
                        }}
                        addConcatReport={() => {
                          if (this.props.generating) {
                            alert(
                              "Please wait for the current PDF to finish generating before adding more reports!"
                            );
                            return;
                          }

                          this.props.addReport(
                            genReportFromAsset(this.state.selectedAsset)
                          );
                        }}
                        included={
                          this.state.selectedAsset &&
                          this.props.selectedReports.some(
                            (report) =>
                              report.type === "ASSET" &&
                              report.asset_id ===
                                this.state.selectedAsset.ref_id
                          )
                        }
                      />
                    )}
                  {this.state.selectedFacility && (
                    <GeneratedReport
                      name={this.state.selectedFacility.name}
                      type={"FACILITY"}
                      score={this.state.selectedFacility.vulnerability_score}
                      goToReport={() => {
                        window.location.assign(
                          `#/report/builder/facility?id=${this.state.selectedAsset.facility_id}`
                        );
                      }}
                      addConcatReport={() => {
                        if (this.props.generating) {
                          alert(
                            "Please wait for the current PDF to finish generating before adding more reports!"
                          );
                          return;
                        }

                        this.props.addReport(
                          genReportFromFacility(this.state.selectedFacility)
                        );
                      }}
                      included={
                        this.state.selectedAsset &&
                        this.props.selectedReports.some(
                          (report) =>
                            report.type === "FACILITY" &&
                            report.facility_id ===
                              this.state.selectedAsset.facility_id
                        )
                      }
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

GeneratedReports.propTypes = {};
