import { getBasePath } from ".";

export async function genDeltaReport(
  asset_id_a,
  asset_id_b,
  title,
  description,
  version_a,
  version_b,
  header,
  footer,
  url_links
) {
  let res = await fetch(
    getBasePath() + `/delta/report/${asset_id_a}/${asset_id_b}`,
    {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + window.localStorage.getItem("auth"),
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        title,
        description,
        version_a,
        version_b,
        header,
        footer,
        url_links,
      }),
    }
  );
  let parsed = await res.text();
  return parsed;
}

export async function getDeltaReportStatus(delta_report_id) {
  let res = await fetch(
    getBasePath() + `/delta/report/${delta_report_id}/status`,
    {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + window.localStorage.getItem("auth"),
      }),
    }
  );
  let parsed = await res.text();
  return parsed;
}

export async function getAllDeltaReports() {
  let res = await fetch(getBasePath() + `/delta/report`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  let parsed = await res.json();
  return parsed;
}

export async function deleteDeltaReport(delta_report_id) {
  let res = await fetch(
    getBasePath() + `/delta/report/${delta_report_id}/delete`,
    {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + window.localStorage.getItem("auth"),
      }),
    }
  );
  let parsed = await res.text();
  return parsed;
}

export async function downloadDeltaReport(delta_report_id) {
  let res = await fetch(getBasePath() + `/delta/report/${delta_report_id}`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  const blob = await res.blob();
  let file = window.URL.createObjectURL(blob);
  return file;
}
