import { AnalysisResult, AnalysisStatus, Log } from "dangr-ui";
import { getBasePath } from ".";

/**
 * Gets the binary analysis results produced by dangr
 * for a given binary.
 */
export async function getSymbexResults(
  asset_id: string,
  binary: string
): Promise<AnalysisResult[]> {
  let body = new FormData();
  body.append("assessment", "symbex");
  body.append("asset", asset_id);
  body.append("bin", binary);
  let res = await fetch(getBasePath() + "/result/get", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  let jsonResponse = await res.json();
  return assignIDs(jsonResponse);
}

/**
 * Assign unique IDs for each analysis result/vulnerability
 * detected by the symbex/dangr analysis.
 * TODO This function should be revisited/removed once we
 * implement a better strategy for assigning unique identifiers
 * to AnalysisResults in dangr/dangr-backend.
 */
function assignIDs(results: Omit<AnalysisResult, "id">[]): AnalysisResult[] {
  let idTracker: { [key: string]: number } = {};
  return results.map((result) => {
    const { analysis_name } = result;
    if (!idTracker[analysis_name]) {
      idTracker[analysis_name] = 0;
    }
    idTracker[analysis_name]++;
    return {
      ...result,
      id: idTracker[analysis_name].toString(),
    };
  });
}

/**
 * Gets the status of a dangr binary analysis run
 * on a given binary, including start time, states_explored,
 * etc.
 */
export async function getSymbexStatus(
  asset_id: string,
  binary: string
): Promise<AnalysisStatus> {
  let res = await fetch(
    getBasePath() + `/symbex/status/${asset_id}/${binary}`,
    {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + window.localStorage.getItem("auth"),
      }),
    }
  );
  return await res.json();
}

/**
 * Gets the logs produced by dangr while analyzing
 * a given binary.
 */
export async function getSymbexLogs(
  asset_id: string,
  binary: string,
  offset: number,
  limit: number
): Promise<Log[]> {
  let params = new URLSearchParams();
  params.append("limit", limit.toString());
  params.append("offset", offset.toString());
  let res = await fetch(
    getBasePath() + `/symbex/logs/${asset_id}/${binary}?${params}`,
    {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + window.localStorage.getItem("auth"),
      }),
    }
  );
  return await res.json();
}

/**
 * Gets the total number of symbex logs produced during
 * an analysis of a binary file.
 */
export async function getSymbexNumLogs(
  asset_id: string,
  binary: string
): Promise<number> {
  let res = await fetch(
    getBasePath() + `/symbex/num_logs/${asset_id}/${binary}`,
    {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + window.localStorage.getItem("auth"),
      }),
    }
  );
  let num_logs = await res.json();
  return num_logs.num_logs;
}
