import { getBasePath } from ".";

/**
 * Get all the facilities in the user's parent organization.
 */
export async function getAllFacilities() {
  let res = await fetch(getBasePath() + `/facility/get`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  let facilities = await res.json();
  facilities = facilities.map((f) => {
    if (Array.isArray(f.latLng)) return f;
    let parts = f.latLng.split(",");
    f.latLng = [parseFloat(parts[0]), parseFloat(parts[1])];
    return f;
  });
  return facilities;
}

/**
 * Delete a facility.
 */
export async function deleteFacility(facility_id) {
  let res = await fetch(getBasePath() + `/facility/delete/${facility_id}`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  let parsed = await res.text();
  return parsed;
}

/**
 * Create a new facility.
 */
export async function createFacility(name, description, latLng) {
  let body = new FormData();
  body.append("name", name);
  body.append("description", description);
  body.append("latLng", latLng);
  let res = await fetch(getBasePath() + "/facility/create", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  let parsed = await res.text();
  return parsed;
}

/***
 * Update a facility.
 */
export async function updateFacility(facility_id, name, description, latLng) {
  let body = new FormData();
  body.append("id", facility_id);
  body.append("name", name);
  body.append("description", description);
  body.append("latLng", latLng);
  let res = await fetch(getBasePath() + "/facility/update", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  let parsed = await res.text();
  return parsed;
}

/**
 * Get the default facility in an org.
 */
export async function getDefaultFacility() {
  let res = await fetch(getBasePath() + `/facility/default`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  let parsed = await res.text();
  return parsed;
}
