import DangerousFunction from "./dangerous_function";
import BinarySummaryInfoHelper from "./binary_summary_info_helper";
import { CheckCircle } from "react-feather";
import { DANGEROUS_FN_MAP } from "../../../helpers";

export default function DangerousFunctionsTab({ binary, data, swapTab }) {
  if (
    !data.dangerous_functions ||
    Object.keys(data.dangerous_functions).length === 0
  )
    return (
      <div style={{ marginTop: -10 }}>
        <h4 style={{ fontWeight: 500 }}>
          No dangerous functions were detected in this binary.
        </h4>
        <CheckCircle className="big-icon" />
      </div>
    );

  return (
    <>
      <BinarySummaryInfoHelper
        text="Many functions in C have the possibility of introducing security 
          issues into their corresponding compiled binary
          executable. Some of these security issues include buffer
          overflow, command injection, and race conditions. The
          Dangerous Functions assessment reports
          such examples, linking each discovered function to 
          a CWE."
      />
      {Object.entries(data.dangerous_functions).map(([fn, samples]) => {
        if (samples.length === 0) return null;
        if (!DANGEROUS_FN_MAP.hasOwnProperty(fn)) return null;
        return (
          <DangerousFunction
            fn={fn}
            samples={samples}
            onClick={() => {
              swapTab(DANGEROUS_FN_MAP[fn].cwe);
            }}
          />
        );
      })}
    </>
  );
}
