import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import PassFailComponent, { PASS_FAIL_NAMES } from "./pass_fail";
import { PassFail } from "../../constants";

/**
 *  Displays a table of Pass/Fail assessments when viewing a binary report.
 */
export default class BAPs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: 0,
    };
  }

  render() {
    // filter out non-search term relate rows first
    let baps = [];
    for (let bap of this.props.passfails) {
      if (
        PASS_FAIL_NAMES[bap.NAME]
          .toLowerCase()
          .includes(this.props.passfailSearch.toLowerCase())
      )
        baps.push(bap);
    }

    let totPage = baps.length / 5;
    let paginations = [];
    for (let i = 0; i < totPage; i++) paginations.push(i + 1);

    // filter failed pass / fails tests at the top,
    // and passing tests at the bottom
    let failing = baps.filter((a) => {
      return a.STATUS === "FAIL";
    });
    failing.sort((a, b) => {
      if (a.NAME === "Lineage Analysis") return -1;
      else return 1;
    });
    let passing = baps.filter((a) => {
      return a.STATUS === "PASS";
    });

    // the set of ThreatAI assessments that we don't have results for
    let unknown = [];
    let bap_names = baps.map((b) => b.NAME);
    for (let pfn of Object.keys(PASS_FAIL_NAMES)) {
      if (!bap_names.includes(pfn)) {
        unknown.push(pfn);
      }
    }

    return (
      <div
        style={{
          paddingTop: 30,
        }}
      >
        {failing.map((b) => {
          return (
            <PassFailComponent
              key={b.NAME}
              name={b.NAME}
              status={b.STATUS}
              desc={b.MSG}
              swapTab={this.props.swapTab}
            />
          );
        })}
        {failing.length > 0 && <div style={{ marginBottom: 30 }}></div>}

        {passing.map((b) => {
          return (
            <PassFailComponent
              key={b.NAME}
              name={b.NAME}
              status={b.STATUS}
              desc={b.MSG}
              swapTab={this.props.swapTab}
            />
          );
        })}

        {unknown.map((u) => {
          return (
            <PassFailComponent
              key={u}
              name={u}
              status={"UNKNOWN"}
              desc={"No results were produced for this ThreatAI assessment."}
              swapTab={this.props.swapTab}
            />
          );
        })}
      </div>
    );
  }
}

BAPs.propTypes = {
  /**
   * List of Pass/Fail assessments to display.
   */
  passfails: PropTypes.arrayOf(PropTypes.instanceOf(PassFail)).isRequired,

  /**
   * Search text to filter Pass/Fail assessments.
   */
  passfailSearch: PropTypes.string.isRequired,
};
