import { useState } from "react";
import {
  map_to_nist_800_53,
  map_to_nist_800_82,
} from "./mapping_datastructures";
import BinarySummaryInfoHelper from "../binary_summary_components/binary_summary_info_helper";
import { CheckCircle, Search } from "react-feather";
import { NIST_Mapping } from "./nist_mapping";

const HELP_TEXT_800_53 =
  "NIST 800-53 defines security and privacy controls for information systems and organizations.";
const HELP_TEXT_800_82 =
  "NIST 800-82 defines a guide to operational technology (OT) security.";

/**
 * This component displays mappings to NIST compliance.
 * It is diplayed as a tab in the Binary Summary Panel.
 * These mappings may be to either NIST 800-53, or NIST 800-82
 * frameworks, depending on the `is800_82` prop.
 */
export default function NIST({ cwes, is800_82 }) {
  let [search, setSearch] = useState("");

  let mappings = null;
  if (is800_82) mappings = map_to_nist_800_82(cwes);
  else mappings = map_to_nist_800_53(cwes);

  if (mappings.length === 0)
    return (
      <div style={{ marginTop: -10 }}>
        <h4 style={{ fontWeight: 500 }}>
          No NIST 800-{is800_82 ? "82" : "53"} mappings detected in this binary.
        </h4>
        <CheckCircle className="big-icon" />
      </div>
    );
  return (
    <div style={{ paddingTop: 50 }}>
      <h4 className="mappings-search">
        <div className="search-row">
          <Search className="user-icon" />
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={`Search ${mappings.length} mappings...`}
          ></input>
        </div>
      </h4>
      <BinarySummaryInfoHelper
        text={is800_82 ? HELP_TEXT_800_82 : HELP_TEXT_800_53}
      />
      {mappings.map((mapping) => {
        if (!mapping.line_item.toLowerCase().includes(search.toLowerCase()))
          return null;
        return <NIST_Mapping mapping={mapping} />;
      })}
    </div>
  );
}
