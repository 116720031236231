import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import {
  addAssetTag,
  deleteAsset,
  getBinaries,
  markAssetViewed,
  renameAsset,
  updateAssetType,
} from "../../api";
import { Asset } from "../../constants";

// components
import BinaryLineItem from "./binary_line_item";
import BinaryAnalysisPage from "./binary_analysis_page";
import Sidebar from "../sidebar/sidebar";

// icons
import { ChevronRight, MoreHorizontal, ChevronDown } from "react-feather";

import { calcColor } from "../../helpers";
import { getAssetIcon } from "../../asset_types";
import SingleStringModal from "./asset_line_item_popups/single_string_modal";
import AssetTypeFilter from "./asset_line_item_popups/asset_type_filter";

/**
 * A single asset that appears in the scrollable assets
 * list on the left-side of the Binary Analysis Page.
 * This component and be clicked on to display the binaries
 * associated with it.
 * Each AssetLineItem has a asset-type (displayed as an icon),
 * and an asset score (displayed as a color.)
 */
export default class AssetLineItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      redOpen: true,
      yellowOpen: true,
      greenOpen: true,
      inProgOpen: true,
      unassessableOpen: true,
      popup: false,
      renaming: false,
      viewed: this.props.viewed,
      loading: true,
      color: this.props.isUnassessable
        ? "var(--pri-color-light)"
        : calcColor(this.props.asset.cwss_score),
      binaries: null,
      updating_asset_type: false,
      updating_asset_tags: false,
    };

    this.renderListView = this.renderListView.bind(this);
    this.popupOff = this.popupOff.bind(this);
    this.hiddenFileInput = React.createRef();
  }

  popupOff() {
    this.setState({ popup: false });
  }

  /**
   * Mount click listener.
   */
  componentDidMount() {
    window.addEventListener("mousedown", this.popupOff, false);
  }

  /**
   * Unmount click listener.
   */
  componentWillUnmount() {
    window.removeEventListener("mousedown", this.popupOff, false);
  }

  /**
   * Open the bucket if the collection length has changed.
   * (This implies that the collection has been updated)
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selected !== this.props.selected && this.props.selected) {
      this.setState({
        open: true,
        color: this.props.isUnassessable
          ? "var(--pri-color-light)"
          : calcColor(this.props.asset.cwss_score),
      });
      getBinaries(this.props.asset.ref_id).then((binaries) => {
        this.setState({ binaries, loading: false });
      });
    } else if (
      this.props.selectedUpload.id === this.props.asset.ref_id &&
      this.props.selectedUpload.name !== prevProps.selectedUpload.name &&
      !this.state.open
    ) {
      this.setState({
        open: true,
        color: this.props.isUnassessable
          ? "var(--pri-color-light)"
          : calcColor(this.props.asset.cwss_score),
      });
      getBinaries(this.props.asset.ref_id).then((binaries) => {
        this.setState({ binaries, loading: false });
      });
    } else if (this.props.percentage !== prevProps.percentage) {
      if (this.state.open) {
        getBinaries(this.props.asset.ref_id).then((binaries) => {
          this.setState({
            binaries,
            loading: false,
            color: this.props.isUnassessable
              ? "var(--pri-color-light)"
              : calcColor(this.props.asset.cwss_score),
          });
        });
      }
    }
  }

  /**
   * Render the component.
   */
  render() {
    // this.props.isUnassessable
    return (
      <div
        className="collection"
        onClick={() => {
          if (
            this.props.asset.state !== "in progress" &&
            !this.props.isUnassessable &&
            !this.state.viewed
          ) {
            this.setState({ viewed: true });
            markAssetViewed(this.props.asset.ref_id);
            Sidebar.numNew = Sidebar.numNew <= 0 ? 0 : Sidebar.numNew - 1;
            if (Sidebar._this) Sidebar._this.forceUpdate();
          }
        }}
      >
        {/* MODALS */}

        <SingleStringModal
          show={this.state.renaming}
          close={() => this.setState({ renaming: false })}
          title="Rename Asset"
          field_name="New Name..."
          button_text="Submit"
          completeFunc={async (new_name) => {
            this.setState({ renaming: false });
            await renameAsset(this.props.asset.ref_id, new_name);
            this.props.refreshAsset(this.props.asset.ref_id);
          }}
        />

        <AssetTypeFilter
          updater
          open={this.state.updating_asset_type}
          close={() => this.setState({ updating_asset_type: false })}
          setAssetType={async (new_type) => {
            await updateAssetType(this.props.asset.ref_id, new_type);
            this.props.refreshAsset(this.props.asset.ref_id);
          }}
          selectedType={this.props.asset.type}
        />

        <SingleStringModal
          show={this.state.updating_asset_tags}
          close={() => this.setState({ updating_asset_tags: false })}
          title="Add Tag"
          field_name="New Tag..."
          button_text="Add"
          completeFunc={async (new_tag) => {
            this.setState({ updating_asset_tags: false });
            await addAssetTag(this.props.asset.ref_id, new_tag);
            this.props.refreshAsset(this.props.asset.ref_id);
          }}
        />

        {/* ----- */}

        <div
          style={{
            width: "100%",
            position: "relative",
          }}
        >
          <div>
            <div
              onClick={() => {
                this.setState({ open: true });

                if (this.props.isUnassessable) {
                  if (this.state.loading) {
                    getBinaries(this.props.asset.ref_id).then((binaries) => {
                      this.setState({ binaries, loading: false });
                    });
                  }
                  return;
                }

                if (this.state.loading) {
                  getBinaries(this.props.asset.ref_id).then((binaries) => {
                    this.setState({ binaries, loading: false });
                    this.props.clickFunction({
                      ...this.props.asset,
                      binaries,
                    });
                  });
                } else
                  this.props.clickFunction({
                    ...this.props.asset,
                    binaries: this.state.binaries,
                  });
              }}
              className="left-row file-cat-head"
              style={{
                paddingLeft: 20,
                background:
                  this.state.popup || this.props.selected
                    ? "var(--sec-bg-color)"
                    : "var(--bg-color)",
              }}
            >
              {!this.state.open && <ChevronRight className="user" />}
              {this.state.open && (
                <ChevronDown
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ open: !this.state.open });
                  }}
                  className="user"
                />
              )}

              {this.props.asset.state !== "in progress" &&
                getAssetIcon(this.props.asset.type, "user", {
                  marginLeft: 5,
                  stroke: this.state.color,
                  height: 14,
                  width: 14,
                })}

              {this.props.asset.state === "in progress" && (
                <div className="in-progress-ring"></div>
              )}

              <h3
                className="upload-header"
                style={{
                  color: "var(--pri-color)",

                  fontWeight: this.props.isUnassessable
                    ? 500
                    : this.state.viewed
                    ? 700
                    : 800,
                }}
              >
                {this.props.asset.name}
              </h3>

              <div
                className="asset-tag"
                style={{
                  opacity:
                    this.props.asset.hasOwnProperty("tags") &&
                    this.props.asset.tags.length > 0
                      ? 1
                      : 0,
                }}
              >
                <div className="tag-bull"></div>
                {this.props.asset.hasOwnProperty("tags") &&
                  this.props.asset.tags.length}
              </div>

              {this.props.asset.state !== "in progress" &&
                (this.props.isUnassessable || this.state.viewed) && (
                  <MoreHorizontal
                    onClick={() => {
                      this.setState({ popup: true });
                    }}
                    className="user more-hor"
                  />
                )}
              {this.props.asset.state !== "in progress" &&
                !this.props.isUnassessable &&
                !this.state.viewed && (
                  <div
                    className="light-r"
                    style={{
                      background: "var(--sec-color)",
                      marginRight: 4,
                      marginBottom: -4,
                    }}
                  ></div>
                )}
              {this.props.asset.state === "in progress" && (
                <h3 className="curr-analyze" style={{ marginTop: 5 }}>
                  {this.props.asset.percentage}%
                </h3>
              )}
            </div>

            {this.state.open && this.renderListView()}
            <input
              type="file"
              ref={this.hiddenFileInput}
              onInput={(e) => {
                this.props.importAnalysis(
                  e.target.files,
                  this.props.asset.ref_id
                );
              }}
              style={{ display: "none" }}
            />

            {this.state.open && <div className="dotted-left"></div>}

            {this.state.popup && (
              <div className="collection-options" style={{ marginTop: 10 }}>
                <h3
                  onMouseDown={() => {
                    this.setState({ renaming: true });
                  }}
                >
                  Rename
                </h3>

                <h3
                  onMouseDown={() =>
                    this.setState({ updating_asset_type: true })
                  }
                >
                  Change Asset Type
                </h3>

                <h3
                  onMouseDown={() =>
                    this.setState({ updating_asset_tags: true })
                  }
                >
                  Add Tag
                </h3>

                <h3
                  onMouseDown={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this asset?"
                      )
                    ) {
                      deleteAsset(this.props.asset.ref_id).then(() => {
                        BinaryAnalysisPage._this.init_fromOrgSwap();
                      });
                    }
                  }}
                  style={{ color: "var(--contrast-color)" }}
                >
                  Delete
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  /**
   * Render the list of past analyses in the collection.
   */
  renderListView() {
    if (this.state.loading)
      return (
        <div style={{ width: "100%", display: "flex" }}>
          <div className="lds-dual-ring lds-dual-ring-center"></div>
        </div>
      );

    // sort by vulnerability level
    let red = [];
    let yellow = [];
    let green = [];
    let in_progress = [];
    if (!this.state.binaries.assessable) return null;
    for (let b of this.state.binaries.assessable) {
      if (b.percentage < 100) in_progress.push(b);
      else if (b.cwss.aggregate === "RED") red.push(b);
      else if (b.cwss.aggregate === "YELLOW") yellow.push(b);
      else green.push(b);
    }
    return (
      <div>
        {red.length > 0 && (
          <div>
            <div
              onClick={() => this.setState({ redOpen: !this.state.redOpen })}
              className="left-row file-cat-head"
            >
              {!this.state.redOpen && <ChevronRight className="user" />}
              {this.state.redOpen && <ChevronDown className="user" />}
              <h3 className="upload-header">Red</h3>
            </div>

            {this.state.redOpen && (
              <div className="list-of-files">
                {red.map((u) => {
                  return (
                    <BinaryLineItem
                      key={`${this.props.asset.ref_id}_${u.name}`}
                      selectUpload={this.props.selectUpload}
                      asset_id={this.props.asset.ref_id}
                      loading={this.props.loading}
                      selectedUpload={
                        this.props.panel === "ANALYSIS"
                          ? this.props.selectedUpload
                          : { id: -1 }
                      }
                      color="light-r"
                      binary={u}
                    />
                  );
                })}
              </div>
            )}
            <hr
              style={{
                margin: 0,
                alignSelf: "center",
                width: "calc(100% + 10px)",
                marginTop: 10,
                marginBottom: 10,
              }}
            />
          </div>
        )}

        {yellow.length > 0 && (
          <div>
            <div
              onClick={() =>
                this.setState({ yellowOpen: !this.state.yellowOpen })
              }
              className="left-row file-cat-head"
            >
              {!this.state.yellowOpen && <ChevronRight className="user" />}
              {this.state.yellowOpen && <ChevronDown className="user" />}
              <h3 className="upload-header">Yellow</h3>
            </div>
            {this.state.yellowOpen && (
              <div className="list-of-files">
                {yellow.map((u) => {
                  return (
                    <BinaryLineItem
                      key={`${this.props.asset.ref_id}_${u.name}`}
                      selectUpload={this.props.selectUpload}
                      asset_id={this.props.asset.ref_id}
                      binary={u}
                      selectedUpload={
                        this.props.panel === "ANALYSIS"
                          ? this.props.selectedUpload
                          : { id: -1 }
                      }
                      color="light-y"
                      refreshBinaries={() => {
                        this.setState({ binaries: [], loading: true });
                        getBinaries(this.props.asset.ref_id).then(
                          (binaries) => {
                            this.setState({ binaries, loading: false });
                          }
                        );
                      }}
                    />
                  );
                })}
              </div>
            )}
            <hr
              style={{
                margin: 0,
                alignSelf: "center",
                width: "calc(100% + 10px)",
                marginTop: 10,
                marginBottom: 10,
              }}
            />
          </div>
        )}

        {green.length > 0 && (
          <div>
            <div
              onClick={() =>
                this.setState({ greenOpen: !this.state.greenOpen })
              }
              className="left-row file-cat-head"
            >
              {!this.state.greenOpen && <ChevronRight className="user" />}
              {this.state.greenOpen && <ChevronDown className="user" />}
              <h3 className="upload-header">Green</h3>
            </div>
            {this.state.greenOpen && (
              <div className="list-of-files">
                {green.map((u) => {
                  return (
                    <BinaryLineItem
                      key={`${this.props.asset.ref_id}_${u.name}`}
                      selectUpload={this.props.selectUpload}
                      asset_id={this.props.asset.ref_id}
                      binary={u}
                      selectedUpload={
                        this.props.panel === "ANALYSIS"
                          ? this.props.selectedUpload
                          : { id: -1 }
                      }
                      color="light-g"
                      refreshBinaries={() => {
                        this.setState({ binaries: [], loading: true });
                        getBinaries(this.props.asset.ref_id).then(
                          (binaries) => {
                            this.setState({ binaries, loading: false });
                          }
                        );
                      }}
                    />
                  );
                })}
              </div>
            )}
            <hr
              style={{
                margin: 0,
                alignSelf: "center",
                width: "calc(100% + 10px)",
                marginTop: 10,
                marginBottom: 10,
              }}
            />
          </div>
        )}

        {in_progress.length > 0 && (
          <div>
            <div
              onClick={() =>
                this.setState({ inProgOpen: !this.state.inProgOpen })
              }
              className="left-row file-cat-head"
            >
              {!this.state.inProgOpen && <ChevronRight className="user" />}
              {this.state.inProgOpen && <ChevronDown className="user" />}
              <h3 className="upload-header">In Progress</h3>
            </div>
            {this.state.inProgOpen && (
              <div className="list-of-files">
                {in_progress
                  .sort((a, b) => b.percentage - a.percentage)
                  .map((u) => {
                    return (
                      <BinaryLineItem
                        key={`${this.props.asset.ref_id}_${u.name}`}
                        selectUpload={this.props.selectUpload}
                        asset_id={this.props.asset.ref_id}
                        binary={u}
                        selectedUpload={
                          this.props.panel === "ANALYSIS"
                            ? this.props.selectedUpload
                            : { id: -1 }
                        }
                        color="light-g"
                        refreshBinaries={() => {
                          this.setState({ binaries: [], loading: true });
                          getBinaries(this.props.asset.ref_id).then(
                            (binaries) => {
                              this.setState({ binaries, loading: false });
                            }
                          );
                        }}
                      />
                    );
                  })}
              </div>
            )}
            <hr
              style={{
                margin: 0,
                alignSelf: "center",
                width: "calc(100% + 10px)",
                marginTop: 10,
                marginBottom: 10,
              }}
            />
          </div>
        )}

        {this.state.binaries.unassessable &&
          this.state.binaries.unassessable.length > 0 && (
            <div>
              <div
                onClick={() =>
                  this.setState({
                    unassessableOpen: !this.state.unassessableOpen,
                  })
                }
                className="left-row file-cat-head"
              >
                {!this.state.unassessableOpen && (
                  <ChevronRight className="user" />
                )}
                {this.state.unassessableOpen && (
                  <ChevronDown className="user" />
                )}
                <h3 className="upload-header">Unassessable</h3>
              </div>

              {this.state.unassessableOpen && (
                <div className="list-of-files">
                  {this.state.binaries.unassessable.map((u) => {
                    return (
                      <BinaryLineItem
                        key={`${this.props.asset.ref_id}_${u.name}`}
                        selectUpload={this.props.selectUpload}
                        asset_id={this.props.asset.ref_id}
                        loading={this.props.loading}
                        unassessable
                        selectedUpload={
                          this.props.panel === "ANALYSIS"
                            ? this.props.selectedUpload
                            : { id: -1 }
                        }
                        color="light-gr"
                        binary={u}
                      />
                    );
                  })}
                </div>
              )}
              <hr
                style={{
                  margin: 0,
                  alignSelf: "center",
                  width: "calc(100% + 10px)",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              />
            </div>
          )}
      </div>
    );
  }
}

AssetLineItem.propTypes = {
  /**
   * Whether the asset has been view. If the asset hasn't been viewed,
   * a blue dot appears next to it.
   */
  viewed: PropTypes.bool.isRequired,

  /**
   * The type of panel the Binary Analysis Page is currently displaying.
   */
  panel: PropTypes.string.isRequired,

  /**
   * If the asset is selected, it's corresponding line-item is highlighted.
   */
  selected: PropTypes.bool.isRequired,

  /**
   * The asset to render as a list item.
   */
  asset: PropTypes.instanceOf(Asset).isRequired,

  /**
   * Is the asset list loading.
   */
  loading: PropTypes.bool.isRequired,

  /**
   * The asset being viewed.
   */
  selectedUpload: PropTypes.instanceOf(Asset).isRequired,

  /**
   * Callback for when the user clicks on a binary in the asset.
   */
  selectUpload: PropTypes.func.isRequired,

  /**
   * Callback for when the user clicks on this asset.
   */
  clickFunction: PropTypes.func.isRequired,
};
