import React, { useEffect } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getUserProfile, superadminExists } from "./api";
import BinaryAnalysisPage from "./components/binary_analysis_page/binary_analysis_page";
import ReportsPage from "./components/reports_page/reports_page";
import ReportBuilderPage_Binary from "./components/reports_page/report_builder_page/binary/report_builder_page";
import ReportBuilderPage_Asset from "./components/reports_page/report_builder_page/asset/report_builder_page";
import ReportBuilderPage_Facility from "./components/reports_page/report_builder_page/facility/report_builder_page";
import Login0Page from "./components/login_page/login0_page";
import SettingsPage from "./components/settings_page/settings_page";
import DeepDivePage from "./components/deep_dive_page/deep_dive_page";
import Login1Page from "./components/login_page/login1_page";
import OpenAPIPage from "./components/openapi_page/openapi_page";
import EulaPage from "./components/login_page/eula_page";
import Dashboard from "./components/dashboard/dashboard";
import SetPasswordPage from "./components/login_page/set_password_page";
import SetupGoogleAuth from "./components/login_page/set_up_google_auth";
import CreateSuperadminPage from "./components/login_page/create_superadmin_page/create_superadmin_page";
import ResetPaswordPage from "./components/login_page/reset_password_page";
import AllDeltaReportsPage from "./components/delta_report_page/all_reports";
import GenerateDeltaReportPage from "./components/delta_report_page/generate_report";
import ViewDeltaReportPage from "./components/delta_report_page/view_report";

// set up MUI colors
const theme = createTheme({
  components: {
    // CTRL + SPACE to find the component you would like to override.
    // For most of them you will need to adjust just the root...
    MuiTextField: {
      styleOverrides: {
        root: {
          "& input": {
            color: "var(--pri-color)",
          },
          "& textarea": {
            color: "var(--pri-color)",
          },
          "& label": {
            color: "var(--pri-color-light)",
          },
          "& label.Mui-focused": {
            color: "var(--sec-color)",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "var(--bor-color)",
            },
            "&:hover fieldset": {
              borderColor: "var(--sec-color)",
            },
            "&.Mui-focused fieldset": {
              borderColor: "var(--sec-color)",
            },
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#4287f5",
    },
  },
});

export default function App() {
  const config_superadmin = async () => {
    // check to see if the superadmin has been configured...
    // navigate to /create_superadmin if not
    const superadmin_exists = await superadminExists();
    if (!superadmin_exists) {
      window.location.assign("#/create_superadmin");
      return;
    }

    // check for session expiration, logout if required
    if (
      window.localStorage.getItem("auth") &&
      window.localStorage.getItem("auth") !== ""
    ) {
      await getUserProfile(window.localStorage.getItem("userid"));
    }
  };
  useEffect(() => {
    config_superadmin();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route
            replace={true}
            path="/"
            element={
              window.localStorage.getItem("auth") &&
              window.localStorage.getItem("auth") !== "" ? (
                <Navigate to="/dashboard" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          {/* Login Pages */}
          <Route path="/login" element={<Login0Page />} />
          <Route path="/create_superadmin" element={<CreateSuperadminPage />} />
          <Route path="/eula" element={<EulaPage />} />
          <Route path="/login1" element={<Login1Page />} />
          <Route path="/setupgoogleauth" element={<SetupGoogleAuth />} />
          <Route path="/setpassword" element={<SetPasswordPage />} />
          <Route path="/reset_password" element={<ResetPaswordPage />} />

          {/* Main Application Pages*/}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/binary" element={<BinaryAnalysisPage />} />
          <Route path="/deepdive" element={<DeepDivePage />} />

          <Route path="/report" element={<ReportsPage />} />
          <Route
            path="/report/builder/binary"
            element={<ReportBuilderPage_Binary />}
          />
          <Route
            path="/report/builder/asset"
            element={<ReportBuilderPage_Asset />}
          />
          <Route
            path="/report/builder/facility"
            element={<ReportBuilderPage_Facility />}
          />

          <Route path="/delta" element={<AllDeltaReportsPage />} />
          <Route path="/delta/generate" element={<GenerateDeltaReportPage />} />
          <Route
            path="/delta/:delta_report_id"
            element={<ViewDeltaReportPage />}
          />

          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/openapi" element={<OpenAPIPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}
