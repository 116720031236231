import React from "react";
import "../../index.css";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import Joyride from "react-joyride";
import { getBinaries, getDeepDiveBridge } from "../../api";

// icons
import { X, Plus } from "react-feather";

// components
import Headbar from "../headbar/headbar";
import Sidebar from "../sidebar/sidebar";
import Botbar from "../botbar/botbar";
import Notifications from "../login_page/notifications";
import BigDrop from "../sidebar/big_drop";
import DeepDiveAssets from "./deep_dive_assets";
import DeepDiveLines from "./deep_dive_lines";
import RightHandSidebar from "../sidebar/right_hand_sidebar";

// react-joyride user-onboarding steps
const steps = [
  {
    target: "#disassembler-viewer",
    content: "We are now on the Deep Dive Page.",
    disableBeacon: true,
    placement: "center",
  },
  {
    target: "#disassembler-viewer",
    content: "Disassembled binary code will appear here.",
    disableBeacon: true,
    placement: "right",
  },
  {
    target: "#disassembler-plus",
    content: "Click here to add a binary to the disassembler for viewing.",
    disableBeacon: true,
    placement: "right",
  },
  {
    target: "#decompiler-viewer",
    content: "Decompiled binary code will appear here.",
    disableBeacon: true,
    placement: "left",
  },
  {
    target: "#decompiler-plus",
    content: "Click here to add a binary to the decompiler for viewing.",
    disableBeacon: true,
    placement: "left",
  },
];

/**
 * Page of the application allowing the user to tab between disassembled
 * and decompiled view of the binaries they've uploaded.
 */
export default class DeepDivePage extends React.Component {
  constructor(props) {
    super(props);

    document.title = "BinLens - Deep Dive";

    let params = window.location.href.split("?")[1];

    this.state = {
      onboard: params !== undefined && params === "onboard",

      // old
      assets: [],
      disFiles: [],
      decFiles: [],
      addingDis: false,
      addingDec: false,

      // new
      dis_asset: null,
      dis_binary: null,
      dis_is_assessing: false,
      dec_asset: null,
      dec_binary: null,
      dec_is_assessing: false,
      dis_jmp: "",
      dec_jmp: "",

      // location to jump to
      loc: null,
    };
    this.init = this.init.bind(this);
    this.addingOff = this.addingOff.bind(this);

    this.dis_jumper = React.createRef();
    this.dec_jumper = React.createRef();
  }

  static _this = null;

  addingOff() {
    this.setState({ addingDis: false, addingDec: false });
  }

  /**
   * Unmount click listener
   */
  componentWillUnmount() {
    window.removeEventListener("click", this.addingOff, false);
    DeepDivePage._this = null;
  }

  /**
   * Mount drag panel handle resize listener.
   */
  componentDidMount() {
    window.addEventListener("click", this.addingOff, false);

    let handle = document.getElementById("drag-w");
    var isResizing = false;
    handle.addEventListener("mousedown", (e) => {
      isResizing = true;
      document.body.style.userSelect = "none";
    });

    document.addEventListener("mousemove", (e) => {
      if (!isResizing) return;

      document.getElementsByClassName("filter")[0].style.width = `${
        window.innerWidth - e.clientX - 41
      }px`;
    });

    document.addEventListener("mouseup", (e) => {
      isResizing = false;
      document.body.style.userSelect = "auto";
    });

    DeepDivePage._this = this;
    this.init();
  }

  /**
   * Load disassemble and decompile data if the URL specifies so.
   * The URL will specify so if the user is linked to this page from
   * the Binary Analysis Page.
   */
  async init() {
    this.setState({
      disFiles: [],
      decFiles: [],
      addingDis: false,
      addingDec: false,
      dis_asset: null,
      dis_binary: null,
      dis_is_assessing: false,
      dec_asset: null,
      dec_binary: null,
      dec_is_assessing: false,
      dis_jmp: "",
      dec_jmp: "",
    });

    // parse GET arguments to determin if we should be viewing a binary to begin with
    const urlParams = new URLSearchParams(window.location.href.split("?")[1]);

    if (
      window.location.href.split("?")[1] &&
      window.location.href.split("?")[1] !== "onboard"
    ) {
      let id = urlParams.get("id");
      let name = urlParams.get("name");

      let binaries = await getBinaries(id);
      let dis_is_assessing = false;
      let dec_is_assessing = false;
      if (binaries.assessable) {
        let bin = binaries.assessable.filter((b) => b.name === name)[0];

        dis_is_assessing = bin && (!bin.cwss || !bin.cwss.aggregate);
        dec_is_assessing = dis_is_assessing;
      }

      // TODO this should work for the disassembler too
      let loc = urlParams.get("line");
      this.setState({
        disFiles: [{ id, name }],
        decFiles: [{ id, name }],
        dis_asset: id,
        dis_binary: name,
        dec_asset: id,
        dec_binary: name,
        loc,
        dec_is_assessing,
        dis_is_assessing,
      });
    }
  }

  /**
   * Render the page.
   */
  render() {
    return (
      <BigDrop>
        {this.state.onboard && (
          <button
            style={{
              position: "fixed",
              top: 40,
              right: 30,
              zIndex: 2001,
              background: "var(--contrast-color)",
              color: "white",
              borderRadius: 40,
            }}
            onClick={() => this.setState({ onboard: false })}
          >
            Cancel Tutorial
          </button>
        )}
        <Joyride
          locale={{
            back: "Back",
            close: "Close",
            last: "Next",
            next: "Next",
            open: "Open the dialog",
            skip: "Skip",
          }}
          disableScrolling
          continuous
          steps={steps}
          run={this.state.onboard}
          callback={(data) => {
            if (data.action === "reset") {
              window.location.assign(`#/openapi?onboard`);
            }
            if (data.action === "close" && data.type === "step:after") {
              // This explicitly stops the tour (otherwise it displays a "beacon" to resume the tour)
              //this.setState({ onboard: false });
            }
          }}
          styles={{
            options: {
              arrowColor: "var(--sec-bg-color)",
              backgroundColor: "var(--sec-bg-color)",
              borderColor: "var(--bor-color)",
              primaryColor: "var(--sec-color)",
              textColor: "var(--pri-color)",
            },
          }}
        />
        <main className="deep-dive">
          <Notifications />

          <Headbar />
          <Botbar />

          <div className="main-row">
            <Sidebar page="deepdive" />

            <div className="content" id="disassembler-viewer">
              <div className="left-row dis-dec-head">
                <h3>Disassembler</h3>

                <input
                  ref={this.dis_jumper}
                  className="deep-jumper"
                  placeholder="Jump to hex address..."
                ></input>
                <button
                  style={{
                    color: "white",
                    background: "var(--sec-color)",
                    padding: 0,
                    height: 35,
                    width: 70,
                    marginRight: 10,
                  }}
                  onClick={() => {
                    let dis_jmp = this.dis_jumper.current.value;
                    if (dis_jmp.slice(0, 2) !== "0x") dis_jmp = "0x" + dis_jmp;
                    this.setState({ dis_jmp }, () =>
                      this.setState({ dis_jmp: "" })
                    );
                  }}
                >
                  Go
                </button>
              </div>

              <div
                className="toggle-header"
                style={{ height: 31, alignItems: "center" }}
              >
                {this.state.disFiles.map((u, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        this.dis_jumper.current.value = "";
                        this.setState({
                          dis_asset: u.id,
                          dis_binary: u.name,
                          dis_is_assessing: !u.cwss || !u.cwss.aggregate,
                        });
                      }}
                      className={
                        this.state.dis_asset === u.id &&
                        this.state.dis_binary === u.name
                          ? "toggle-header-item toggle-header-item-dd sel-toggle-header-item"
                          : "toggle-header-item toggle-header-item-dd"
                      }
                    >
                      <h3>{u.name}</h3>
                      <X
                        onClick={(e) => {
                          e.stopPropagation();
                          let disFiles = [...this.state.disFiles];
                          for (let i = 0; i < disFiles.length; i++) {
                            if (
                              u.name === disFiles[i].name &&
                              u.id === disFiles[i].id
                            ) {
                              disFiles.splice(i, 1);
                              break;
                            }
                          }
                          this.setState({
                            dis_asset:
                              this.state.dis_asset === u.id &&
                              this.state.dis_binary === u.name
                                ? null
                                : this.state.dis_asset,
                            dis_binary:
                              this.state.dis_asset === u.id &&
                              this.state.dis_binary === u.name
                                ? null
                                : this.state.dis_binary,
                            disFiles,
                          });
                        }}
                        className="user"
                      />

                      {this.state.dis_asset === u.id &&
                        this.state.dis_binary === u.name && (
                          <div className="blocker"></div>
                        )}
                    </div>
                  );
                })}
                <Tooltip
                  // options
                  title="Add Binary"
                  position="top"
                  trigger="mouseenter"
                  arrow
                  size="small"
                  style={{ marginLeft: 10, marginTop: 5 }}
                >
                  <Plus
                    id="disassembler-plus"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({ addingDis: true });
                    }}
                    style={{ width: 20, height: 20 }}
                    className="user"
                  />
                </Tooltip>

                <DeepDiveAssets
                  id="deep-assets-1"
                  open={this.state.addingDis}
                  shownFiles={this.state.disFiles}
                  selectBinary={(u) => {
                    let disFiles = [...this.state.disFiles];
                    disFiles.push(u);
                    this.setState({
                      dis_asset: u.id,
                      dis_binary: u.name,
                      dis_is_assessing: !u.cwss || !u.cwss.aggregate,
                      disFiles,
                    });
                  }}
                />
              </div>

              <DeepDiveLines
                asset={this.state.dis_asset}
                binary={this.state.dis_binary}
                assessment={"disassemble"}
                is_assessing={this.state.dis_is_assessing}
                jump_to={this.state.dis_jmp}
                jump_to_other={async (func_name) => {
                  // ensure that the other side of the deep dive viewer is
                  // the same binary
                  let dis = `${this.state.dis_asset}_${this.state.dis_binary}`;
                  let dec = `${this.state.dec_asset}_${this.state.dec_binary}`;
                  if (dis !== dec) {
                    alert(
                      "You must be viewing the same binary file on both sides of the Deep Dive Page to jump to a function."
                    );
                    return;
                  }

                  // get the function's line number and jump to it
                  let line_num = await getDeepDiveBridge(
                    this.state.dis_asset,
                    this.state.dis_binary,
                    "decompile",
                    func_name
                  );
                  line_num--;
                  this.setState({ dec_jmp: line_num }, () =>
                    this.setState({ dec_jmp: "" })
                  );
                }}
              />
            </div>

            <div className="report-prev" id="decompiler-viewer">
              <div className="filter">
                <div className="left-row dis-dec-head">
                  <h3>Decompiler</h3>
                  <input
                    className="deep-jumper"
                    placeholder="Jump to line number..."
                    ref={this.dec_jumper}
                  ></input>
                  <button
                    style={{
                      color: "white",
                      background: "var(--sec-color)",
                      padding: 0,
                      height: 35,
                      width: 70,
                      marginRight: 10,
                    }}
                    onClick={() => {
                      this.setState(
                        { dec_jmp: this.dec_jumper.current.value },
                        () => this.setState({ dec_jmp: "" })
                      );
                    }}
                  >
                    Go
                  </button>
                </div>
                <div
                  className="toggle-header"
                  style={{ height: 31, alignItems: "center" }}
                >
                  {this.state.decFiles.map((u, i) => {
                    return (
                      <div
                        key={i}
                        onClick={() => {
                          this.dec_jumper.current.value = "";
                          this.setState({
                            dec_asset: u.id,
                            dec_binary: u.name,
                            dec_is_assessing: !u.cwss || !u.cwss.aggregate,
                          });
                        }}
                        className={
                          this.state.dec_asset === u.id &&
                          this.state.dec_binary === u.name
                            ? "toggle-header-item toggle-header-item-dd sel-toggle-header-item"
                            : "toggle-header-item toggle-header-item-dd"
                        }
                      >
                        <h3>{u.name}</h3>
                        <X
                          onClick={(e) => {
                            e.stopPropagation();
                            let decFiles = [...this.state.decFiles];
                            for (let i = 0; i < decFiles.length; i++) {
                              if (
                                u.name === decFiles[i].name &&
                                u.id === decFiles[i].id
                              ) {
                                decFiles.splice(i, 1);
                                break;
                              }
                            }
                            this.setState({
                              dec_asset:
                                this.state.dec_asset === u.id &&
                                this.state.dec_binary === u.name
                                  ? null
                                  : this.state.dec_asset,
                              dec_binary:
                                this.state.dec_asset === u.id &&
                                this.state.dec_binary === u.name
                                  ? null
                                  : this.state.dec_binary,
                              decFiles,
                            });
                          }}
                          className="user"
                        />

                        {this.state.dec_asset === u.id &&
                          this.state.dec_binary === u.name && (
                            <div className="blocker"></div>
                          )}
                      </div>
                    );
                  })}
                  <Tooltip
                    // options
                    title="Add Binary"
                    position="top"
                    trigger="mouseenter"
                    arrow
                    size="small"
                    style={{ marginLeft: 10, marginTop: 5 }}
                  >
                    <Plus
                      id="decompiler-plus"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ addingDec: true });
                      }}
                      className="user"
                      style={{ width: 20, height: 20 }}
                    />
                  </Tooltip>

                  <DeepDiveAssets
                    id="deep-assets-2"
                    open={this.state.addingDec}
                    shownFiles={this.state.decFiles}
                    selectBinary={(u) => {
                      let decFiles = [...this.state.decFiles];
                      decFiles.push(u);
                      this.setState({
                        dec_asset: u.id,
                        dec_binary: u.name,
                        dec_is_assessing: !u.cwss || !u.cwss.aggregate,
                        decFiles,
                      });
                    }}
                  />
                </div>

                <DeepDiveLines
                  asset={this.state.dec_asset}
                  binary={this.state.dec_binary}
                  assessment={"decompile"}
                  loc={this.state.loc}
                  is_assessing={this.state.dec_is_assessing}
                  jump_to={this.state.dec_jmp}
                  jump_to_other={async (func_name) => {
                    // ensure that the other side of the deep dive viewer is
                    // the same binary
                    let dis = `${this.state.dis_asset}_${this.state.dis_binary}`;
                    let dec = `${this.state.dec_asset}_${this.state.dec_binary}`;
                    if (dis !== dec) {
                      alert(
                        "You must be viewing the same binary file on both sides of the Deep Dive Page to jump to a function."
                      );
                      return;
                    }

                    // go to line numbr of specified function
                    let line_num = await getDeepDiveBridge(
                      this.state.dis_asset,
                      this.state.dis_binary,
                      "disassemble",
                      func_name
                    );
                    line_num--;
                    this.setState({ dis_jmp: line_num }, () =>
                      this.setState({ dis_jmp: "" })
                    );
                  }}
                />

                <div id="drag-w"></div>
              </div>
            </div>
            <RightHandSidebar />
          </div>
        </main>
      </BigDrop>
    );
  }
}
