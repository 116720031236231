import React from "react";
import PropTypes from "prop-types";
import { Search } from "react-feather";
import "../../index.css";
import "./facility_dropdown.css";
import { getAllFacilities } from "../../api";

// components
import Sidebar from "../sidebar/sidebar";
import FacilityRow from "./facility_row";

/**
 * Dropdown that appears when a user is swapping between facilities of the
 * Binary Analysis Page. Depending on the selected facility, different sets
 * of assets appear.
 */
export default class FacilityDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      facilities: [],
      search: "",
    };

    this.init = this.init.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    // get all facilities in the organization
    let facilities = await getAllFacilities();
    this.setState({ facilities, loading: false });
  }

  render() {
    return (
      <div
        className="facility-dropdown"
        style={
          this.props.fromfileDrop
            ? {
                top: 43,
              }
            : { top: 43 }
        }
      >
        <div className="row">
          <div className="facility-searhbar">
            <Search className="icon" />
            <input
              placeholder="Search Facilities..."
              onMouseDown={(e) => e.stopPropagation()}
              onChange={(e) => this.setState({ search: e.target.value })}
              value={this.state.search}
            />
          </div>
          <button
            className="new-facility-button-dropdown"
            onMouseDown={() => {
              if (Sidebar._this)
                Sidebar._this.setState({ createFacilityModal: true });
            }}
          >
            + New
          </button>
        </div>
        {this.state.loading && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginBottom: 10,
            }}
          >
            <div className="lds-dual-ring"></div>
          </div>
        )}
        {!this.state.loading &&
          this.state.facilities.map((f, i) => {
            if (!f.name.toLowerCase().includes(this.state.search.toLowerCase()))
              return null;
            return (
              <FacilityRow
                key={i}
                facility={f}
                new={!this.props.fromfileDrop && f.new}
                selectFacility={this.props.selectFacility}
              />
            );
          })}
      </div>
    );
  }
}

FacilityDropdown.propTypes = {
  /**
   * Callback that selects a facility to view.
   */
  selectFacility: PropTypes.func.isRequired,

  /**
   * The dropdown is from the file-drop modal.
   */
  fromfileDrop: PropTypes.bool,
};
