import { useState } from "react";
import "./binary_analysis_searchbar.css";
import { Tooltip } from "react-tippy";
import { getAssetIcon } from "../../asset_types";

// components
import AssetTypeFilter from "./asset_line_item_popups/asset_type_filter";

// icons
import {
  Search,
  RefreshCw,
  AlertTriangle,
  Type,
  Box,
  Tag,
  GitPullRequest,
  X,
} from "react-feather";
import { FETCHABLE } from "../../asset_fetcher";

/**
 * Enables the user to search for by various categories
 * (assets, tags, binaries, cwes)
 */
export default function BinaryAnalysisSearchbar({
  // The type and way to search/fetch.
  // I.e. searching for asset by name or tag, or searching
  // for binaries by name or CWE.
  fetchable,
  setFetchable,

  // Text  to search for.
  searchText,
  setSearchText,

  // Type of asset to filter for.
  // Only applicable when searching for assets by name or tags.
  assetType,
  setAssetType,

  // Sorting either by date or vulnerability.
  // Only applicable when searching for assets by name or tags.
  sortBy,
  setSortBy,

  // refresh the loaded-in list
  refresh,
}) {
  let [settingAssetType, setSettingAssetType] = useState("");
  let [fetchablePopup, setFetchablePopup] = useState(false);

  let searchTimeout = null;

  return (
    <div className="searchbar fetchable-searchbar">
      <AssetTypeFilter
        open={settingAssetType}
        close={() => setSettingAssetType(false)}
        setAssetType={(assetType) => setAssetType(assetType)}
        selectedType={assetType === "" ? "All Assets" : assetType}
      />

      {fetchablePopup && (
        <div className="fetachable-popup">
          <h2>What are you searching for?</h2>

          <div className="fetchable-options">
            <div
              className={
                fetchable === FETCHABLE.ASSET_IN_FACILITY_BY_NAME
                  ? "new-analysis-option-selected"
                  : "new-analysis-option"
              }
              onMouseDown={() => {
                setFetchable(FETCHABLE.ASSET_IN_FACILITY_BY_NAME);
                setFetchablePopup(false);
              }}
            >
              <Box className="icon" />
              Assets
            </div>
            <div
              className={
                fetchable === FETCHABLE.ASSET_IN_FACILITY_BY_TAG
                  ? "new-analysis-option-selected"
                  : "new-analysis-option"
              }
              onMouseDown={() => {
                setFetchable(FETCHABLE.ASSET_IN_FACILITY_BY_TAG);
                setFetchablePopup(false);
              }}
            >
              <Tag className="icon" />
              Tags
            </div>
            <div
              className={
                fetchable === FETCHABLE.BINARY_BY_NAME
                  ? "new-analysis-option-selected"
                  : "new-analysis-option"
              }
              onMouseDown={() => {
                setFetchable(FETCHABLE.BINARY_BY_NAME);
                setFetchablePopup(false);
              }}
            >
              <GitPullRequest className="icon" />
              Binaries
            </div>
            <div
              className={
                fetchable === FETCHABLE.BINARY_BY_CWE
                  ? "new-analysis-option-selected"
                  : "new-analysis-option"
              }
              onMouseDown={() => {
                setFetchable(FETCHABLE.BINARY_BY_CWE);
                setFetchablePopup(false);
              }}
            >
              <AlertTriangle className="icon" />
              CWEs
            </div>
          </div>
        </div>
      )}

      <div className="internal-searchbar">
        <Search className="search" />

        {fetchable && (
          <div className="selected-fetchable">
            {fetchable}
            <X className="icon" onClick={() => setFetchable(null)} />
          </div>
        )}

        <input
          onChange={(e) => {
            setSearchText(e.target.value);
            if (e.target.value === "") {
              setFetchable(null);
            } else if (!fetchable) {
              setFetchable(FETCHABLE.ASSET_IN_FACILITY_BY_NAME);
              setFetchablePopup(false);
            }
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(refresh, 500);
          }}
          value={searchText}
          placeholder="Search"
          onFocus={() => {
            if (fetchable) return;
            setFetchablePopup(true);
          }}
          onBlur={() => setFetchablePopup(false)}
        ></input>

        <Tooltip
          // options
          title="Refresh"
          position="top"
          trigger="mouseenter"
          arrow
          size="small"
          style={{
            marginLeft: 0,
            marginRight: 5,
          }}
        >
          <RefreshCw
            onClick={refresh}
            style={{ marginLeft: 0 }}
            className={"view-toggle"}
          />
        </Tooltip>

        <Tooltip
          // options
          title={`Asset Type: ${assetType}`}
          position="top"
          trigger="mouseenter"
          arrow
          size="small"
          style={{
            marginLeft: 5,
          }}
        >
          {assetType === "" && (
            <Type
              onClick={() => setSettingAssetType(true)}
              style={{ marginLeft: 0, padding: 5 }}
              className={"view-toggle"}
            />
          )}
          {assetType !== "" &&
            getAssetIcon(
              assetType,
              "view-toggle",
              {
                marginLeft: 0,
                background: "var(--bg-color)",
                outline: "1px solid var(--bor-color)",
                stroke: "white",
                borderRadius: 5,
                padding: 5,
                boxShadow:
                  "0 1px 2px 0 rgb(60 64 67 / 30%), 0s 1px 3px 1px rgb(60 64 67 / 15%)",
              },
              () => setSettingAssetType(true)
            )}
        </Tooltip>

        <Tooltip
          // options
          title="Sort by Vulnerability"
          position="top"
          trigger="mouseenter"
          arrow
          size="small"
          style={{
            marginLeft: 5,
            marginRight: 5,
          }}
        >
          {sortBy === "date" && (
            <AlertTriangle
              onClick={() => setSortBy("score")}
              style={{ marginLeft: 0, padding: 5 }}
              className={"view-toggle"}
            />
          )}
          {sortBy === "score" && (
            <AlertTriangle
              onClick={() => setSortBy("date")}
              style={{
                marginLeft: 0,
                background: "var(--bg-color)",
                outline: "1px solid var(--bor-color)",
                stroke: "white",
                borderRadius: 5,
                padding: 5,
                boxShadow:
                  "0 1px 2px 0 rgb(60 64 67 / 30%), 0s 1px 3px 1px rgb(60 64 67 / 15%)",
              }}
              className={"view-toggle"}
            />
          )}
        </Tooltip>
      </div>
    </div>
  );
}
