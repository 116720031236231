import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import { Modal } from "react-bootstrap";

// icons
import { X } from "react-feather";
import { updateOrganization } from "../../api";

// components
import UserManagementTable from "./user_management_table";
import OrganizationsToggle from "../headbar/organizations_toggle";

/**
 * Modal that appears when an organization is being updated.
 * To update an organization, the following information is required:
 * 1. Name.
 * 2. Description.
 */
export default class UpdateOrganizationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      description: "",
    };

    this.updateOrganizationClick = this.updateOrganizationClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.modal !== prevProps.modal) {
      this.setState({
        name: "",
        description: "",
      });
    }
  }

  async updateOrganizationClick() {
    await updateOrganization(
      this.state.name,
      this.state.description,
      this.props.org_id
    );
    this.props.backFunction();
    UserManagementTable._this.refresh();
    OrganizationsToggle._this.init();
  }

  /**
   * Render the component.
   */
  render() {
    return (
      <Modal
        enforceFocus={false}
        show={this.props.modal}
        animation={false}
        className="modal-bg"
      >
        <Modal.Body className="modal2 create-user-modal-top">
          <div className="login-page-old create-user-modal">
            <div
              className="sec-button x-user"
              onClick={this.props.backFunction}
            >
              <X className="user" />
            </div>

            <h2 className="man-user-title perm-title set-side-title">
              ORGANIZATION NAME
            </h2>
            <input
              placeholder="Organization Name..."
              value={this.state.name}
              onChange={(e) => this.setState({ name: e.target.value })}
            ></input>

            <h2 className="man-user-title perm-title set-side-title">
              DESCRIPTION
            </h2>
            <textarea
              className="create-org-textarea"
              placeholder="Description..."
              value={this.state.description}
              onChange={(e) => this.setState({ description: e.target.value })}
            ></textarea>

            <button onClick={this.updateOrganizationClick} className="upload">
              Update Organization
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

UpdateOrganizationModal.propTypes = {
  /**
   * Is the modal appearing or not.
   */
  modal: PropTypes.bool.isRequired,

  /**
   * Callback that turns the modal off.
   */
  backFunction: PropTypes.func.isRequired,

  /**
   * The ID of the organization being updated.
   */
  org_id: PropTypes.string.isRequired,
};
