import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import "./sidebar.css";
import { Link } from "react-router-dom";
import { getNumNew, getUserProfile, startAnalysis } from "../../api";

// components
import FileDropModal from "./file_drop_modal/file_drop_modal";
import AnalysisProgressBar from "../headbar/analysis_progress_bar";
import CreateFacilityModal from "./create_facility_modal";

// icons
import {
  Settings,
  Grid,
  GitPullRequest,
  DownloadCloud,
  FileText,
  Terminal,
  Plus,
  Triangle,
} from "react-feather";

/**
 * Sidebar that appears on every page of the application and
 * displays a link to each page of the application.
 */
export default class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // analyzation modals
      modal: false,
      createFacilityModal: false,

      hovering: false,

      clearingDropzone: false,

      // full page dropzone
      acceptedFiles: null,

      numNew: 0,
    };
    Sidebar._this = this;
    this.startBinAnalysis = this.startBinAnalysis.bind(this);
    this.init = this.init.bind(this);
  }

  static _this = null;
  static numNew = 0;

  componentDidMount() {
    this.init();
  }

  /**
   * Starts the binary analysis process.
   * Gets called from the questionairre component.
   * Sends the questionairre to the backend as a JSON object.
   */
  async startBinAnalysis(facility_id) {
    // start analysis and refresh drozone ID by logging in
    let res = await startAnalysis(facility_id);

    // close the file upload modal now that the assessment is processing
    this.setState({
      modal: false,
      hovering: false,
      acceptedFiles: null,
    });

    // determine if the analysis start was a success or not
    if (res !== "OK") {
      alert(res);
      return;
    }

    // update the dropzone asset_id
    await getUserProfile(window.localStorage.getItem("userid"));

    if (AnalysisProgressBar._this) AnalysisProgressBar._this.refresh();
  }

  async init() {
    let res = await getNumNew();
    Sidebar.numNew = res.numNew;
    this.forceUpdate();
  }

  /**
   * Render the component.
   */
  render() {
    let ret = [<div key="back" className="sidebar-back"></div>];

    ret.push(
      <div
        key="front"
        className={this.state.hovering ? "sidebar sidebar-hover" : "sidebar"}
        onMouseEnter={() =>
          this.setState({
            hovering:
              !this.state.modal && !this.state.createFacilityModal && true,
          })
        }
        onMouseLeave={() => this.setState({ hovering: false })}
      >
        <FileDropModal
          modal={this.state.modal}
          backFunction={() =>
            this.setState({
              modal: false,
              hovering: false,
              acceptedFiles: null,
            })
          }
          nextFunction={(facility_id) => {
            this.startBinAnalysis(facility_id);
          }}
          acceptedFiles={this.state.acceptedFiles}
        />

        <CreateFacilityModal
          modal={this.state.createFacilityModal}
          backFunction={() => {
            this.setState({ createFacilityModal: false });
          }}
        />

        <button
          id="new-analysis-button"
          onClick={() => {
            this.setState({
              modal: true,
              hovering: false,
              clearingDropzone: false,
            });
          }}
          style={{
            maxWidth: this.state.clearingDropzone ? 60 : "none",
          }}
        >
          {!this.state.clearingDropzone && <Plus className="icon" />}
          {this.state.clearingDropzone && <div className="lds-dual-ring"></div>}
          {!this.state.clearingDropzone && <h3>New Analysis</h3>}
        </button>

        <div id="all-sidebar-links">
          <Link
            to="/dashboard"
            className={
              this.props.page === "dashboard"
                ? "side-link selected-link"
                : "side-link"
            }
          >
            <Grid className="link-icon" />
            <h5>Dashboard</h5>
            {this.props.page === "dashboard" && (
              <div className="setting-selected"></div>
            )}
          </Link>

          <Link
            id="binary-analysis-page-link"
            to="/binary"
            className={
              this.props.page === "binary"
                ? "side-link selected-link"
                : "side-link"
            }
            style={{ position: "relative" }}
          >
            <GitPullRequest className="link-icon" />
            <h5>Binary Analysis</h5>
            {this.props.page === "binary" && (
              <div className="setting-selected"></div>
            )}
            {Sidebar.numNew > 0 && (
              <h6 className="num-complete">{Sidebar.numNew}</h6>
            )}
          </Link>

          <Link
            to="/deepdive"
            className={
              this.props.page === "deepdive"
                ? "side-link selected-link"
                : "side-link"
            }
          >
            <Terminal className="link-icon" />
            <h5>Deep Dive</h5>
            {this.props.page === "deepdive" && (
              <div className="setting-selected"></div>
            )}
          </Link>

          <Link
            to="/report"
            className={
              this.props.page === "reports"
                ? "side-link selected-link"
                : "side-link"
            }
          >
            <FileText className="link-icon" />
            <h5>Reports</h5>
            {this.props.page === "reports" && (
              <div className="setting-selected"></div>
            )}
          </Link>

          <Link
            to="/delta"
            className={
              this.props.page === "delta"
                ? "side-link selected-link"
                : "side-link"
            }
          >
            <Triangle className="link-icon" />
            <h5>Delta</h5>
            {this.props.page === "delta" && (
              <div className="setting-selected"></div>
            )}
          </Link>

          <Link
            to="/openapi"
            className={
              this.props.page === "openapi"
                ? "side-link selected-link"
                : "side-link"
            }
          >
            <DownloadCloud className="link-icon" />
            <h5>OpenAPI</h5>
            {this.props.page === "openapi" && (
              <div className="setting-selected"></div>
            )}
          </Link>

          <hr />

          <Link
            to="/settings"
            className={
              this.props.page === "settings"
                ? "side-link selected-link"
                : "side-link"
            }
          >
            <Settings className="link-icon" />
            <h5>Settings</h5>
            {this.props.page === "settings" && (
              <div className="setting-selected"></div>
            )}
          </Link>
        </div>
      </div>
    );
    return ret;
  }
}

Sidebar.propTypes = {
  /**
   * The parent page the sidebar is located on.
   * Highlights the link associated with the parent page.
   */
  page: PropTypes.oneOf([
    "dashboard",
    "binary",
    "deepdive",
    "reports",
    "openapi",
    "settings",
    "delta"
  ]).isRequired,
};
