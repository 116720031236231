import React from "react";
import PropTypes from "prop-types";
import "./audit_history.css";
import { Audit } from "../../../constants";

/**
 * A list of audit entries sorted by datetime. The most recent
 * entries are filtered towards the top. This component is displayed
 * from the Dashboard component aswell as the AssetSummary component.
 *
 * Currently, the following actions get logged:
 * 1. Analysis begins on an asset.
 * 2. Analysis begins on a binary.
 * 3. Analysis ends on a binary.
 * 4. Analysis ends on an asset.
 */
export default class AuditHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.parseText = this.parseText.bind(this);
  }

  parseText(a) {
    if (!a.data.hasOwnProperty("username")) a.data.username = "A user";
    if (a.ent_type === "eula") {
      return `${a.data.username} accepted the EULA.`;
    }
    switch (a.type) {
      case "delete":
        return `${a.data.username} deleted ${a.ent_type} '${a.data.name}'.`;
      case "update":
        return `${a.data.username} completed analysis for ${a.ent_type} '${a.data.name}'.`;
      case "create":
      default:
        return `${a.data.username} started analysis for ${a.ent_type} '${a.data.name}'.`;
    }
  }

  render() {
    let audit = [...this.props.audit].reverse();
    if (this.props.max) {
      audit = audit.splice(0, this.props.max);
    }
    return (
      <div className="audit-org-history">
        {audit.map((a, i) => {
          return (
            <div className="audit-entry" key={i}>
              <div className="audit-bubble"></div>
              <h3>
                {new Date(a.timestamp).toDateString() +
                  " " +
                  new Date(a.timestamp).toLocaleTimeString()}
              </h3>
              <p>{this.parseText(a)}</p>
            </div>
          );
        })}
        {audit.length > 0 && this.props.max && (
          <h3
            className="view-all-audit"
            onClick={() => {
              let text = "";

              for (let a of [...this.props.audit].reverse()) {
                text += a.timestamp;
                text += "\t" + this.parseText(a);
                text += "\n";
              }

              var element = document.createElement("a");
              element.setAttribute(
                "href",
                "data:text/plain;charset=utf-8," + encodeURIComponent(text)
              );
              element.setAttribute("download", "audit.txt");

              element.style.display = "none";
              document.body.appendChild(element);

              element.click();

              document.body.removeChild(element);
            }}
          >
            Download All &#8594;
          </h3>
        )}
      </div>
    );
  }
}

AuditHistory.propTypes = {
  /**
   * The list of audit entries to display.
   */
  audit: PropTypes.arrayOf(PropTypes.instanceOf(Audit)).isRequired,

  /**
   * The max number of audit entries to display at once.
   * If this value is not present, it is assumed the AuditHistory is
   * being displayed from the AssetSummary panel. Otherwise, the
   * component displays a "Download All" button which download the entire
   * audit log as a text file.
   */
  max: PropTypes.number,
};
