import {
  dangerous_fn_likelhood_text,
  DANGEROUS_FN_MAP,
  genSevBgColor,
} from "../../../helpers";

/**
 * Displays a single smaple of a dangerous function on the Binary Summary
 * Page, or an the Custom Binary Report builder.
 */
export default function DangerousFunction({ fn, samples, onClick }) {
  return (
    <div
      onClick={onClick}
      className="score-cause score-cause-clickable"
      style={{
        borderRadius: 10,
        padding: 20,
        marginBottom: 30,
      }}
    >
      <div
        style={{
          display: "flex",
          paddingLeft: 0,
          alignItems: "flex-start !important",
        }}
      >
        <div
          className="score-g2"
          style={{
            borderColor: genSevBgColor("" + samples[0].severity),
            background: genSevBgColor("" + samples[0].severity),
          }}
        >
          <h1
            style={{
              color: "white",
              fontFamily: "monospace",
              fontStyle: "normal",
            }}
          >
            {samples[0].severity}
          </h1>
        </div>

        <div style={{ width: "100%" }}>
          <h2 style={{ marginTop: 20 }}>
            Found a Potentially Dangerous Function:
          </h2>

          <div>
            <div
              style={{
                background: "var(--sec-bg-color)",
                borderRadius: 10,
                padding: 20,
                marginTop: 20,
                marginBottom: 20,

                wordBreak: "break-all",
                fontFamily: "monospace",

                width: "calc(100% - 70px)",
              }}
            >
              {fn}
            </div>
            <ul>
              {samples.map((p, i) => {
                if (i === 2) {
                  return (
                    <p
                      style={{
                        color: "var(--pri-color-light)",
                      }}
                    >
                      + {samples.length - 2} more positions
                    </p>
                  );
                } else if (i > 2) return null;
                return (
                  <li
                    style={{
                      color: "var(--pri-color-light)",
                      marginBottom: 10,
                    }}
                  >
                    Position {p.offset}:{" "}
                    {dangerous_fn_likelhood_text(p.likelihood)}
                  </li>
                );
              })}
            </ul>
          </div>

          <h2
            style={{
              marginTop: 20,
            }}
          >
            Associated CWE:
          </h2>
          <div
            style={{
              background: "var(--sec-bg-color)",
              borderRadius: 10,
              padding: 20,
              marginTop: 20,
              marginBottom: 20,

              wordBreak: "break-all",
              fontFamily: "monospace",

              width: "calc(100% - 70px)",
            }}
          >
            {DANGEROUS_FN_MAP[fn].cwe}
          </div>
        </div>
      </div>
    </div>
  );
}
