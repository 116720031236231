import React from "react";
import PropTypes from "prop-types";
import { ChevronDown, ChevronRight } from "react-feather";
import { getBinaries, getMetadata } from "../../api";
import { calcColor, calcLight } from "../../helpers";
import "../../index.css";
import { Asset, Binary } from "../../constants";
import { getAssetIcon } from "../../asset_types";

/**
 * A single asset as it appears in the DeepDiveAssets component
 * on the Deep Dive Page. When click, this components displays
 * a set of binaries to choose from. Choosing a binary displays in
 * either the disassembler or the decompiler.
 */
export default class DeepDiveAsset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      open: false,
      binaries: [],
    };

    this.load = this.load.bind(this);
    this.selectBinary = this.selectBinary.bind(this);
  }

  async load() {
    this.setState({ open: true });
    if (this.state.loading) {
      let binaries = await getBinaries(this.props.asset.ref_id);
      this.setState({ binaries: binaries.assessable, loading: false });
    }
  }

  async selectBinary(binary) {
    let shownFiles = this.props.shownFiles.map((f) => {
      return `${f.id}_${f.name}`;
    });
    if (shownFiles.includes(`${this.props.asset.ref_id}_${binary.name}`))
      return;

    let metadata = await getMetadata(this.props.asset.ref_id, binary.name);
    this.props.selectBinary({
      ...binary,
      id: this.props.asset.ref_id,
      metadata,
    });
  }

  render() {
    let shownFiles = this.props.shownFiles.map((f) => {
      return `${f.id}_${f.name}`;
    });
    return (
      <div>
        <div
          className="left-row"
          onClick={(e) => {
            e.stopPropagation();
            this.load();
          }}
        >
          {this.state.open && (
            <ChevronDown style={{ width: 16, height: 16, marginRight: 5 }} />
          )}
          {!this.state.open && (
            <ChevronRight style={{ width: 16, height: 16, marginRight: 5 }} />
          )}

          {this.props.asset.state !== "in progress" &&
            getAssetIcon(this.props.asset.type, "", {
              width: 16,
              height: 16,
              stroke: calcColor(this.props.asset.cwss_score),
            })}

          {this.props.asset.state === "in progress" && (
            <div
              className="in-progress-ring"
              style={{
                margin: 0,

                width: 16,
                height: 16,
              }}
            ></div>
          )}

          <h3
            style={{
              maxWidth: "75%",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {this.props.asset.name}
          </h3>
        </div>
        {this.state.open && this.state.loading && (
          <div
            style={{ width: 280, display: "flex", justifyContent: "center" }}
          >
            <div className="lds-dual-ring"></div>
          </div>
        )}
        {this.state.open &&
          !this.state.loading &&
          this.state.binaries.length === 0 && (
            <div className="left-row" style={{ paddingLeft: 39 }}>
              <div
                className="light-r"
                style={{ background: "var(--bor-color)" }}
              ></div>
              <h3
                style={{
                  maxWidth: "85%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  color: "var(--pri-color-light)",
                }}
              >
                No binaries in this asset.
              </h3>
            </div>
          )}
        {this.state.binaries.map((b, i) => {
          let shown = shownFiles.includes(
            `${this.props.asset.ref_id}_${b.name}`
          );
          return (
            <div
              key={i}
              className="left-row"
              style={{ paddingLeft: 39 }}
              onClick={() => this.selectBinary(b)}
            >
              {b.cwss.aggregate && (
                <div
                  className={calcLight(b.cwss.aggregate)}
                  style={
                    shown
                      ? {
                          background: "var(--bor-color)",
                        }
                      : {}
                  }
                ></div>
              )}

              {!b.cwss.aggregate && (
                <div
                  className="in-progress-ring"
                  style={{
                    margin: 0,

                    width: 16,
                    height: 16,
                  }}
                ></div>
              )}

              <h3
                style={{
                  maxWidth: "85%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  color: shown ? "var(--pri-color-light)" : "var(--pri-color)",
                  textDecoration: shown ? "line-through" : "",
                }}
              >
                {b.name}
              </h3>
            </div>
          );
        })}
      </div>
    );
  }
}

DeepDiveAsset.propTypes = {
  /**
   * The asset to display
   */
  asset: PropTypes.instanceOf(Asset).isRequired,

  /**
   * The set of binaries currently displaying as tabs in the
   * decompiler/disassembler.
   */
  shownFiles: PropTypes.arrayOf(PropTypes.instanceOf(Binary)).isRequired,

  /**
   * Callback that selects a binary to view.
   */
  selectBinary: PropTypes.func.isRequired,
};
