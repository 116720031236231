import { ArrowRight, Navigation } from "react-feather";
import CWESearcher from "./cwe_searcher";

/**
 * Display the parent/child context of a selected CWE.
 * Underline a CWE when hovered in the nav menu.
 * When a CWE is clicked, call onClickCWE, navigating to it.
 */
export default function CWENavigator({ cwePath, setCWEPath }) {
  return (
    <div className="cwe-navigator">
      <CWESearcher setCWEPath={setCWEPath} />
      <h2
        className={cwePath.length === 0 ? "current-cwe" : ""}
        onClick={() => {
          setCWEPath([]);
        }}
      >
        All CWEs
      </h2>
      {cwePath.map((cwe, i) => {
        let last = i === cwePath.length - 1;
        return (
          <>
            <ArrowRight className="arrow-right" />
            <h2
              className={last ? "current-cwe" : ""}
              onClick={() => {
                setCWEPath(cwePath.slice(0, i + 1));
              }}
            >
              CWE-{cwe}
            </h2>
          </>
        );
      })}
    </div>
  );
}
