import React from "react";
import "../../index.css";
import "./progress_bar_again.css";
import { getAssetProgress } from "../../api";
import { addAssetName, calcPercentCompleted } from "./analysis_queue/helpers";

// components
import BinaryAnalysisPage from "../binary_analysis_page/binary_analysis_page";
import AnalysisQueue from "./analysis_queue/analysis_queue";

/**
 * Collection of analyzation progress popups that display at the top right
 * of the headbar.
 */
export default class AnalysisProgressBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      progress: {},
      hovering: false,
      pinned: false,
      stalled: false,
    };

    this.timeout = -1;
    this.init = this.init.bind(this);
    this.refresh = this.refresh.bind(this);
    this.stallRefresh = this.stallRefresh.bind(this);

    AnalysisProgressBar._this = this;
  }

  static _this = null;

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  async init() {
    clearTimeout(this.timeout);
    this.setState({ progress: {} });
    let progress = await getAssetProgress();
    addAssetName(progress);
    this.setState({ progress });
    this.timeout = setTimeout(this.refresh, 5000);
  }

  async refresh() {
    clearTimeout(this.timeout);

    // check backend for updates every 5 seconds
    // also runs when the user clicks the start analysis button
    let progress = await getAssetProgress();
    this.setState({ stalled: false });
    addAssetName(progress);
    this.setState({ progress });

    // statically refresh any binaries in the binary analysis page /w their
    // new percentage

    if (BinaryAnalysisPage._this) {
      BinaryAnalysisPage._this.updateProgress();
    }

    this.timeout = setTimeout(this.refresh, 5000);
  }

  /**
   * This function is called whenever an asset or binary is reordered.
   * It prevents the user from altering the assessment priority order on the
   * right side of the assessment management dropdown for some time.
   */
  async stallRefresh() {
    clearTimeout(this.timeout);
    this.setState({ stalled: true });
    this.timeout = setTimeout(this.refresh, 3000);
  }

  /**
   * Render the component.
   */
  render() {
    if (!this.state.progress || Object.values(this.state.progress).length === 0)
      return null;

    let done = calcPercentCompleted(this.state.progress.assets);

    return (
      <>
        {"num_extracting" in this.state.progress &&
          this.state.progress.num_extracting > 0 && (
            <div
              style={{ display: "flex", alignItems: "center", marginBottom: 6 }}
            >
              <div
                className="lds-dual-ring"
                style={{ transform: "scale(0.8)" }}
              ></div>
              <h3 style={{ marginLeft: 5, marginTop: 2, marginRight: 10 }}>
                {this.state.progress.num_extracting} Extracting
              </h3>
            </div>
          )}

        {Object.values(this.state.progress.assessments).length !== 0 && (
          <>
            <div
              className="head-prog"
              onMouseEnter={() => this.setState({ hovering: true })}
              onMouseLeave={() => this.setState({ hovering: false })}
              style={{
                outline:
                  this.state.pinned || this.state.hovering
                    ? "2px solid var(--sec-color)"
                    : "",
              }}
            >
              <div className="head-prog-top">
                <div
                  style={{
                    display: "flex",
                    zIndex: 100,

                    marginRight: 10,
                  }}
                >
                  <h3 style={{ marginRight: "auto" }}>
                    <b>
                      {this.state.progress.assets.length >= 100
                        ? "100+"
                        : this.state.progress.assets.length}
                    </b>{" "}
                    Asset{this.state.progress.assets.length > 1 ? "s" : ""}
                  </h3>
                </div>

                <progress
                  value={"" + done}
                  max={"1"}
                  style={{
                    width: 200,
                    zIndex: 100,
                  }}
                ></progress>

                {(this.state.pinned || this.state.hovering) && (
                  <div className="analysis-queue-fold"></div>
                )}
                {(this.state.pinned || this.state.hovering) && (
                  <AnalysisQueue
                    analyses={this.state.progress}
                    refresh={this.refresh}
                    stalled={this.state.stalled}
                    stallRefresh={this.stallRefresh}
                    pinned={this.state.pinned}
                    setPinned={(pinned) => {
                      this.setState({ pinned });
                    }}
                  />
                )}
              </div>
            </div>
            {(this.state.pinned || this.state.hovering) && (
              <div className="modal-bg analysis-queue-modal-bg"></div>
            )}
          </>
        )}
      </>
    );
  }
}
