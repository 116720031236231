import { useState } from "react";
import PropTypes from "prop-types";
import "../report_builder_sidebar.css";

// icons
import { ChevronLeft, ChevronRight } from "react-feather";
import { DANGEROUS_FN_MAP, isHex } from "../../../../helpers";
import { createDescription } from "../../../binary_analysis_page/CVEs";

const PAGE = {
  START: "START",
  CVE: "CVE",
  CWE: "CWE",
  THREATAI: "THREATAI",
  DANGEROUS_FUNCTIONS: "DANGEROUS_FUNCTIONS",
  CRYPTO: "CRYPTO",
  CRYPTO_SIGNATURES: "CRYPTO_SIGNATURES",
  STRONG_ENCRYPTION: "STRONG_ENCRYPTION",
  WEAK_ENCRYPTION: "WEAK_ENCRYPTION",
  WEAK_POINTERS: "WEAK_POINTERS",
};

/**
 * Sidebar of the Report Builder Preview that enables users
 * to toggle the items on the report.
 * Has 4 tabs, each with different roles:
 * 1. START: Lets the user access the other tabs.
 * 2. CVE: Enables the user to add discovered CVEs to the report.
 * 3. CWE: Enables the user to add discovered CWEs to the report.
 * 4. THREATAI: Enables the user to add ThreatAI results to the report.
 */
export default function ReportBuilderSidebar({
  loading,
  cves,
  cwes,
  threatAI,
  dangerousFns,
  crypto,
  binaryEncryption,
  runtimeEncryption,
  weakPointers,
  setItems,
  items,
}) {
  let [page, setPage] = useState(PAGE.START);

  function addItem(item) {
    if (items.filter((i) => i.id === item.id).length > 0) return;
    setItems((items) => [...items, item]);
    let scroller = document.getElementById("fake-report-document-scroller");
    scroller.scrollTop = scroller.scrollHeight;
  }

  return (
    <div className="report-builder-sidebar">
      {page === PAGE.START && (
        <>
          <h2>Add vulnerability to report:</h2>

          {cves.length > 0 && (
            <div
              onClick={() => setPage(PAGE.CVE)}
              className="report-section-opt"
            >
              CVE <i>{cves.length}</i>
              <ChevronRight className="icon" />
            </div>
          )}
          {cwes.length > 0 && (
            <div
              onClick={() => setPage(PAGE.CWE)}
              className="report-section-opt"
            >
              CWE <i>{cwes.length}</i>
              <ChevronRight className="icon" />
            </div>
          )}
          {threatAI.length > 0 && (
            <div
              onClick={() => setPage(PAGE.THREATAI)}
              className="report-section-opt"
            >
              ThreatAI <i>{threatAI.length}</i>
              <ChevronRight className="icon" />
            </div>
          )}
          {Object.keys(dangerousFns).length > 0 && (
            <div
              onClick={() => setPage(PAGE.DANGEROUS_FUNCTIONS)}
              className="report-section-opt"
            >
              Dangerous Functions <i>{Object.keys(dangerousFns).length}</i>
              <ChevronRight className="icon" />
            </div>
          )}

          {crypto && Object.values(crypto).length > 0 && (
            <div
              onClick={() => setPage(PAGE.CRYPTO)}
              className="report-section-opt"
            >
              Cryptographic Keys <i>{Object.values(crypto).length}</i>
              <ChevronRight className="icon" />
            </div>
          )}

          {runtimeEncryption &&
            runtimeEncryption.runtime.signatures.length > 0 && (
              <div
                onClick={() => setPage(PAGE.CRYPTO_SIGNATURES)}
                className="report-section-opt"
              >
                Cryptographic Signatures{" "}
                <i>{runtimeEncryption.runtime.signatures.length}</i>
                <ChevronRight className="icon" />
              </div>
            )}

          {binaryEncryption &&
            binaryEncryption.binary.entropy.contains_strong_encryption && (
              <div
                onClick={() => setPage(PAGE.STRONG_ENCRYPTION)}
                className="report-section-opt"
              >
                Strong Encryption{" "}
                <i>{binaryEncryption.binary.entropy.strong_chunks.length}</i>
                <ChevronRight className="icon" />
              </div>
            )}

          {binaryEncryption &&
            binaryEncryption.binary.entropy.contains_weak_encryption && (
              <div
                onClick={() => setPage(PAGE.WEAK_ENCRYPTION)}
                className="report-section-opt"
              >
                Weak Encryption{" "}
                <i>{binaryEncryption.binary.entropy.weak_chunks.length}</i>
                <ChevronRight className="icon" />
              </div>
            )}

          {Object.keys(weakPointers).length > 0 && (
            <div
              onClick={() => setPage(PAGE.WEAK_POINTERS)}
              className="report-section-opt"
            >
              Weak Pointers <i>{Object.keys(weakPointers).length}</i>
              <ChevronRight className="icon" />
            </div>
          )}

          {loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <div className="lds-dual-ring"></div>
            </div>
          )}
        </>
      )}
      {page !== PAGE.START && (
        <div
          onClick={() => setPage(PAGE.START)}
          className="report-section-opt report-section-opt-back"
        >
          <ChevronLeft className="icon" /> Back
        </div>
      )}
      {page === PAGE.CVE && (
        <div className="report-builder-sidebar-scroller">
          {cves.map((cve) => (
            <div
              className={
                items.filter((item) => item.id === cve.cve_number).length > 0
                  ? "report-builder-sidebar-item report-builder-sidebar-item-unsel"
                  : "report-builder-sidebar-item"
              }
              onClick={() => {
                addItem({
                  type: "CVE",
                  title: cve.cve_number,
                  desc: createDescription(cve),
                  id: cve.cve_number,
                  severity: cve.severity,
                });
              }}
            >
              <h2>{cve.cve_number}</h2>
              <h3>{cve.description}</h3>
              <div className="cause-field">Severity: {cve.severity}</div>
            </div>
          ))}
        </div>
      )}
      {page === PAGE.CWE && (
        <div className="report-builder-sidebar-scroller">
          {cwes.map((cwe) => (
            <div
              className={
                items.filter((item) => item.id === cwe.cwe).length > 0
                  ? "report-builder-sidebar-item report-builder-sidebar-item-unsel"
                  : "report-builder-sidebar-item"
              }
              onClick={() => {
                addItem({
                  type: "CWE",
                  title: cwe.cwe + " • " + cwe.info.name,
                  desc: cwe.msg,
                  id: cwe.cwe,
                  arr: cwe.arr,
                  mappings: cwe.mappings,
                });
              }}
            >
              <h2>{cwe.cwe}</h2>
              <h3>{cwe.msg}</h3>
              <div className="cause-field">
                {cwe.arr.filter((c) => !isHex(c.loc) && parseInt(c.loc)).length}{" "}
                locations
              </div>
              {cwe.hasOwnProperty("mappings") && cwe.mappings.length > 0 && (
                <div className="cause-field">
                  {cwe.mappings.length} mappings
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {page === PAGE.THREATAI && (
        <div className="report-builder-sidebar-scroller">
          {threatAI.map((tai) => (
            <div
              className={
                items.filter((item) => item.id === tai.NAME).length > 0
                  ? "report-builder-sidebar-item report-builder-sidebar-item-unsel"
                  : "report-builder-sidebar-item"
              }
              onClick={() => {
                addItem({
                  type: "THREATAI",
                  title: tai.NAME,
                  desc: tai.MSG,
                  id: tai.NAME,
                  status: tai.STATUS,
                  mappings: tai.mappings,
                });
              }}
            >
              <h2>{tai.NAME}</h2>
              <h3>{tai.MSG}</h3>
              <div className="cause-field">{tai.STATUS}</div>
              {tai.hasOwnProperty("mappings") && tai.mappings.length > 0 && (
                <div className="cause-field">
                  {tai.mappings.length} mappings
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {page === PAGE.DANGEROUS_FUNCTIONS && (
        <div className="report-builder-sidebar-scroller">
          {Object.entries(dangerousFns).map(([fn, samples]) => {
            if (!DANGEROUS_FN_MAP.hasOwnProperty(fn)) return null;
            return (
              <div
                className={
                  items.filter((item) => item.id === fn).length > 0
                    ? "report-builder-sidebar-item report-builder-sidebar-item-unsel"
                    : "report-builder-sidebar-item"
                }
                onClick={() => {
                  addItem({
                    type: "DANGEROUS_FUNCTION",
                    title: fn,
                    samples,
                    id: fn,
                  });
                }}
              >
                <h2>{fn}</h2>
                <h3>Related to {DANGEROUS_FN_MAP[fn].cwe}</h3>
                <div className="cause-field">
                  Severity: {samples[0].severity}
                </div>
                {samples.length > 0 && (
                  <div className="cause-field">{samples.length} samples</div>
                )}
              </div>
            );
          })}
        </div>
      )}

      {page === PAGE.CRYPTO && (
        <div className="report-builder-sidebar-scroller">
          {Object.entries(crypto).map(([offset, key]) => {
            let title = `Cryptographic Key at ${offset}`;
            return (
              <div
                className={
                  items.filter((item) => item.id === title).length > 0
                    ? "report-builder-sidebar-item report-builder-sidebar-item-unsel"
                    : "report-builder-sidebar-item"
                }
                onClick={() => {
                  addItem({
                    type: "CRYPTO",
                    title,
                    value: key.value,
                    id: title,
                  });
                }}
              >
                <h2>At {offset}</h2>
                <div className="cause-field">{key.value}</div>
              </div>
            );
          })}
        </div>
      )}

      {page === PAGE.CRYPTO_SIGNATURES && (
        <div className="report-builder-sidebar-scroller">
          {runtimeEncryption.runtime.signatures.map((sig) => {
            let title = `[0x${sig.offset}] - Cryptographic Signature: ${sig.description}.`;
            return (
              <div
                className={
                  items.filter((item) => item.id === title).length > 0
                    ? "report-builder-sidebar-item report-builder-sidebar-item-unsel"
                    : "report-builder-sidebar-item"
                }
                onClick={() => {
                  addItem({
                    type: "CRYPTO_SIGNATURE",
                    title,
                    id: title,
                  });
                }}
              >
                <h2>At 0x{sig.offset}</h2>
                <div className="cause-field">{sig.description}</div>
              </div>
            );
          })}
        </div>
      )}

      {page === PAGE.STRONG_ENCRYPTION && (
        <div className="report-builder-sidebar-scroller">
          {binaryEncryption.binary.entropy.strong_chunks.map((chunk) => {
            let title = `[0x${chunk.offset}] - Strong Encryption; Entropy Level: ${chunk.value}.`;
            return (
              <div
                className={
                  items.filter((item) => item.id === title).length > 0
                    ? "report-builder-sidebar-item report-builder-sidebar-item-unsel"
                    : "report-builder-sidebar-item"
                }
                onClick={() => {
                  addItem({
                    type: "STRONG_ECRYPTION",
                    title,
                    id: title,
                  });
                }}
              >
                <h2>At 0x{chunk.offset}</h2>
                <div className="cause-field">Entropy {chunk.value}</div>
              </div>
            );
          })}
        </div>
      )}

      {page === PAGE.WEAK_ENCRYPTION && (
        <div className="report-builder-sidebar-scroller">
          {binaryEncryption.binary.entropy.weak_chunks.map((chunk) => {
            let title = `[0x${chunk.offset}] - Weak Encryption; Entropy Level: ${chunk.value}.`;
            return (
              <div
                className={
                  items.filter((item) => item.id === title).length > 0
                    ? "report-builder-sidebar-item report-builder-sidebar-item-unsel"
                    : "report-builder-sidebar-item"
                }
                onClick={() => {
                  addItem({
                    type: "WEAK_ECRYPTION",
                    title,
                    id: title,
                  });
                }}
              >
                <h2>At 0x{chunk.offset}</h2>
                <div className="cause-field">Entropy {chunk.value}</div>
              </div>
            );
          })}
        </div>
      )}

      {page === PAGE.WEAK_POINTERS && (
        <div className="report-builder-sidebar-scroller">
          {Object.keys(weakPointers).map((wp) => (
            <div
              className={
                items.filter((item) => item.id === wp).length > 0
                  ? "report-builder-sidebar-item report-builder-sidebar-item-unsel"
                  : "report-builder-sidebar-item"
              }
              onClick={() => {
                addItem({
                  type: "WEAK_POINTER",
                  desc: "Found a weak pointer at the associated address.",
                  title: "Weak Pointer at " + wp,
                  id: wp,
                  paths: weakPointers[wp].paths,
                  confidence: weakPointers[wp].confidence,
                });
              }}
            >
              <h2>{wp}</h2>
              <div className="cause-field">
                {weakPointers[wp].paths.length} Paths
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

ReportBuilderSidebar.propTypes = {
  /**
   * The list of items current on the custom report.
   */
  items: PropTypes.arrayOf(PropTypes.object).isRequired,

  /**
   * Callback function that sets the items on the report.
   */
  setItems: PropTypes.func.isRequired,

  /**
   * The set of all CVEs in the parent binary.
   * Used to construct the set of toggleable items.
   */
  cves: PropTypes.array.isRequired,

  /**
   * The set of all CWEs in the parent binary.
   * sed to construct the set of toggleable items.
   */
  cwes: PropTypes.array.isRequired,

  /**
   * The set of all ThreatAI tests in the parent binary.
   * sed to construct the set of toggleable items.
   */
  threatAI: PropTypes.array.isRequired,
};
