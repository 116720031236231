import { useEffect, useState } from "react";
import "./weak_pointer.css";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/hljs";

// icons
import { ChevronLeft, ChevronRight } from "react-feather";
import { getDeepDiveLines } from "../../../../api";

export default function WeakPointer({
  asset_id,
  binary,
  weakPointer,
  confidence,
  paths,
  fromCustomReport,
  done,
  swapTab,
}) {
  let [viewedPathIdx, setViewedPathIdx] = useState(0);
  let path = [];
  if (paths.length > 0) {
    path = paths[viewedPathIdx];
  } else {
    path = [{ addr: weakPointer }];
  }

  let [viewedAddressIdx, setViewedAddressIdx] = useState(
    Math.max(path.length - 1, 0)
  );
  let [showingAll, setShowingAll] = useState(false);

  // lines to display in the disassembly preview
  let [lines, setLines] = useState([]);
  let [loadingLines, setLoadingLines] = useState(true);

  // the offset from the top for the weak pointer address
  // location viewer
  let [locationTop, setLocationTop] = useState(100);

  const fetchDissas = async () => {
    // move the address view to the correct spot
    if (viewedAddressIdx < 4) {
      setLocationTop(16);
    } else if (viewedAddressIdx > path.length - 6) {
      setLocationTop("auto");
    } else {
      setLocationTop(viewedAddressIdx * 41.8 + 16);
    }

    setLoadingLines(true);

    // TODO this assumes address here is 4 bytes long, and
    // address stored in the disassembled.asm backend file
    // is 8 bytes long!!
    let address = path[viewedAddressIdx].addr;
    let split_address = address.split("x");
    address = `0x${split_address[1].padStart(8, "0")}`.toLowerCase();

    // fetch the disassembler preview lines
    // at the weakpointer address selected by the user
    let lines = await getDeepDiveLines(
      asset_id,
      binary,
      "disassemble",
      address,
      10
    );

    setLines(lines);
    setLoadingLines(false);
  };

  // fetch the disassembled viewer anytime the user switches the
  // view weak pointer address
  useEffect(() => {
    try {
      fetchDissas();
    } catch (_e) {}
    if (done) done();
  }, [viewedAddressIdx]);

  // restart the path from scratch whenever the use toggles between
  // the total set of paths
  useEffect(() => {
    if (paths.length <= 0) return;
    setShowingAll(false);
    let path = paths[viewedPathIdx];
    setViewedAddressIdx(Math.max(path.length - 1, 0));
  }, [viewedPathIdx]);

  // weak pointers without an assigned confidence score are flagged as unresolved
  if (!confidence || confidence === "Unresolved")
    confidence = "Unresolved • Under Research";
  else if (confidence !== "Certain") confidence = "Uncertain";

  /**
   * Calculate the background color of the weak pointers's confidence score.
   */
  const calcConfidenceColor = () => {
    if (confidence === "Certain") return "var(--green-color)";
    if (confidence === "Unresolved • Under Research")
      return "var(--sec-bg-color)";
    return "var(--contrast-color)";
  };

  return (
    <div
      className={
        fromCustomReport
          ? "weak-pointer weak-pointer-from-custom"
          : "weak-pointer"
      }
    >
      {!fromCustomReport && (
        <>
          <h3>Weak Pointer</h3>
          <h2>{weakPointer}</h2>
        </>
      )}

      <>
        <h3>Confidence</h3>
        <h2
          className="weak-pointer-confidence"
          style={{
            background: calcConfidenceColor(),
          }}
        >
          {confidence}
        </h2>
      </>

      <>
        <h3>Associated CWE</h3>
        <h2
          onClick={() => {
            // TODO currently Weak Pointers always map to CWE-416...
            // in the future this should swap to the CWE associated
            // with the context this specific weak pointer was detected
            // in
            if (swapTab) swapTab("CWEs", "CWE-416");
          }}
          className={swapTab ? "clickable-associated-cwe" : ""}
        >
          CWE-416
        </h2>
      </>

      <h3>
        {paths.length} Possible Path{paths.length === 1 ? "" : "s"}
      </h3>
      {paths.length === 0 && (
        <h3>
          No paths were found that reach this Weak Pointer. This may indicate
          that the Weak Pointer was found in a area of dead code in the program.
          Further investigation may still be warranted: the address associated
          with the Weak Pointer can be searched for on the Deep Dive Page.
        </h3>
      )}
      <div className="row">
        <div className="weak-pointer-path">
          {path.map((address, j) => {
            if (!showingAll && j === 4)
              return (
                <div
                  className="path-address path-address-no-hover"
                  key="path-address-show-all"
                >
                  <h3
                    className="path-address-show-all"
                    onClick={() => setShowingAll(true)}
                  >
                    ...show {path.length - 5} more
                  </h3>
                </div>
              );
            else if (!showingAll && j > 4 && j !== path.length - 1) return null;
            return (
              <div
                className={
                  j === viewedAddressIdx
                    ? j === path.length - 1
                      ? "path-address path-address-sel path-address-final"
                      : "path-address path-address-sel"
                    : j === path.length - 1
                    ? "path-address path-address-final"
                    : "path-address"
                }
                onClick={() => {
                  setViewedAddressIdx(j);
                }}
                key={`path-address-${j}`}
              >
                <div
                  className={
                    j === path.length - 1
                      ? "path-address-bubble path-address-bubble-final"
                      : "path-address-bubble"
                  }
                ></div>
                <p
                  className={
                    j === path.length - 1 ? "path-address-text-final" : ""
                  }
                >
                  {address.addr}
                </p>
              </div>
            );
          })}
        </div>

        <div
          className="discovery-location"
          style={{
            marginTop: locationTop,
          }}
        >
          {loadingLines && (
            <div className="lds-dual-ring lds-dual-ring-center"></div>
          )}
          {!loadingLines && (
            <SyntaxHighlighter
              wrapLines={true}
              wrapLongLines={true}
              startingLineNumber={0}
              language={"x86asm"}
              style={dark}
            >
              {lines
                .map((line) => {
                  return line;
                })
                .join("")}
            </SyntaxHighlighter>
          )}
        </div>
      </div>

      {paths.length > 0 && (
        <div className="row">
          <h3 className="page-count">
            Path {viewedPathIdx + 1} of {paths.length}
          </h3>
          <ChevronLeft
            onClick={() => {
              if (viewedPathIdx === 0) return;
              setViewedPathIdx((viewedPathIdx) => viewedPathIdx - 1);
            }}
            className="icon"
          />
          <ChevronRight
            onClick={() => {
              if (viewedPathIdx === paths.length - 1) return;
              setViewedPathIdx((viewedPathIdx) => viewedPathIdx + 1);
            }}
            className="icon"
          />
        </div>
      )}
    </div>
  );
}
