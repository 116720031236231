import { useState, useEffect } from "react";
import "./generated_report.css";

// import icons
import { Box, Home, GitPullRequest } from "react-feather";

export default function GeneratedReport({
  name,
  type,
  score,
  addConcatReport,
  goToReport,
  included,
}) {
  let [popup, setPopup] = useState(false);
  useEffect(() => setPopup(false), [included]);

  const renderIcon = () => {
    switch (type) {
      case "FACILITY":
        return <Home className="icon" />;
      case "ASSET":
        return <Box className="icon" />;
      case "BINARY":
      default:
        return <GitPullRequest className="icon" />;
    }
  };

  const renderDesc = () => {
    switch (type) {
      case "FACILITY":
        return "Facility Report";
      case "ASSET":
        return "Asset Report";
      case "BINARY":
      default:
        return "Binary Report";
    }
  };

  return (
    <div
      className="generated-report"
      onMouseEnter={() => setPopup(true)}
      onMouseLeave={() => setPopup(false)}
      style={
        included
          ? {
              opacity: "0.2",
              pointerEvents: "none",
            }
          : {}
      }
    >
      <div className="fake-report">
        <div className="row-2">
          <div
            className={
              score === "RED"
                ? "light2-r"
                : score === "YELLOW"
                ? "light2-y"
                : "light2-g"
            }
          ></div>

          <div className="report-title">
            <h2>{name}</h2>
          </div>
        </div>
        <hr />
        <hr />
        <hr />
        <hr />
        <hr />
        <hr />
        <hr />
        <hr />
        <hr />
        <hr />
        {renderIcon()}
      </div>

      <div className="gen-rep-desc" style={{ height: 36 }}>
        <h3>{name}</h3>
        <h4 style={{ fontSize: 13, paddingBottom: 10 }}>{renderDesc()}</h4>
      </div>

      {!included && popup && (
        <div className="generated-report-popup">
          <div onClick={goToReport}>View in Detail</div>
          <div onClick={addConcatReport}>Add to Aggregate Report</div>
        </div>
      )}
    </div>
  );
}
