/*
    A single report as it appears in the Report Contenator UI component.
    Concatenatable reports can be reordered in any way the user desires.
*/

// icons
import { Box, Home, GitPullRequest, X } from "react-feather";

export default function ConcatenatableReport({ report, remove }) {
  const renderIcon = () => {
    switch (report.type) {
      case "FACILITY":
        return <Home className="icon" />;
      case "ASSET":
        return <Box className="icon" />;
      case "BINARY":
      default:
        return <GitPullRequest className="icon" />;
    }
  };

  const renderReportType = () => {
    let type = report.type.toLowerCase();
    // capitalize the first letter of the word
    return type.charAt(0).toUpperCase() + type.slice(1);
  };

  return (
    <div className="concatenatable-report">
      {renderIcon()}
      <div>
        <h2>{report.name}</h2>
        <h3>{renderReportType()} Report</h3>
      </div>
      <X onClick={remove} className="remove-concatenatable-report" />
    </div>
  );
}
