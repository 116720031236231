import React from "react";
import PropTypes from "prop-types";
import { Binary } from "../../constants";
import "../../index.css";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Title,
  Legend,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  LineElement,
  PointElement,
  BarElement,
  Filler,
} from "chart.js";
import { COLORS_STRING } from "../../colors.js";

// components
import BinarySummaryHeader from "./binary_summary_header";
import BinarySummaryTab from "./binary_summary_components/binary_summary_tab";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  BarElement,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler
);

ChartJS.defaults.color = COLORS_STRING.PRI;

/**
 * Full analysis report that appears when a user selects a binary.
 * The component is broken into sub-tabs:
 */
export default class BinarySummary extends React.Component {
  constructor(props) {
    super(props);

    this.available_pages = [
      "Base",
      "SymbEx",
      "CWEs",
      "Weak Pointers",
      "ThreatAI",
      "Dangerous Functions",
      "Crypto",
      "CVEs",
    ];
    if (this.props.is_feature_enabled["OWASP 10:2021"])
      this.available_pages.push("OWASP 10:2021");
    if (this.props.is_feature_enabled["ISA/IEC 62443-3-3"])
      this.available_pages.push("ISA/IEC 62443-3-3");
    if (this.props.is_feature_enabled["ISA/IEC 62443-4-2"])
      this.available_pages.push("ISA/IEC 62443-4-2");
    if (this.props.is_feature_enabled["NIST 800-53"])
      this.available_pages.push("NIST 800-53");
    if (this.props.is_feature_enabled["NIST 800-82"])
      this.available_pages.push("NIST 800-82");

    this.state = {
      page: "Base",
      preSearch: "",
    };
    ChartJS.defaults.color = COLORS_STRING.PRI;
    this.chartInstance = null;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.binary.id !== prevProps.binary.id ||
      this.props.binary.name !== prevProps.binary.name
    ) {
      this.setState({
        page: "Base",
      });
    }
  }

  /**
   * Render the component.
   */
  render() {
    return (
      <div className="report-prev">
        <div className="filter">
          <BinarySummaryHeader
            pages={this.available_pages}
            page={this.state.page}
            setPage={(page) => this.setState({ page, preSearch: "" })}
            binary={this.props.binary}
          />

          <BinarySummaryTab
            setPage={(page) => this.setState({ page, preSearch: "" })}
            binary={this.props.binary}
            tab={this.state.page}
            swapTab={(page, preSearch) => {
              this.setState({ page, preSearch });
            }}
            preSearch={this.state.preSearch}
          />
        </div>
      </div>
    );
  }
}

BinarySummary.propTypes = {
  /**
   * The binary to display.
   */
  binary: PropTypes.instanceOf(Binary).isRequired,
};
