/*
    This component displays the list of currently uploaded files in the 
    user's dropzone. It also enables them to delete files from the list.

    This component appears on the right side of the file drop modal.
*/
import { useRef } from "react";

// icons
import { Trash } from "react-feather";

export default function Uploaded({
  dropFiles,
  dropUploads,
  deleteBinary,
  deleteAllBinaries,
}) {
  let hiddenFileInput = useRef();

  return (
    <div className="has-drops">
      <div className="new-analysis-centerpiece" style={{ marginTop: 20 }}>
        <button onClick={() => hiddenFileInput.current.click()}>
          Upload More Files
        </button>
        <input
          type="file"
          multiple
          ref={hiddenFileInput}
          onChange={(e) => {
            dropFiles(e.target.files);
          }}
          style={{ display: "none" }}
        />
      </div>
      <hr />

      <div className="row">
        <button
          className="delete-all-dropzone-items"
          onClick={deleteAllBinaries}
        >
          Delete All
        </button>
      </div>

      {dropUploads.map((filename) => {
        return (
          <div key={filename} className="drop-upload" style={{ width: "95%" }}>
            <h2
              style={{
                minWidth: 240,
                maxWidth: 240,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {filename}
            </h2>
            <Trash
              className="cancel-up"
              onClick={() => deleteBinary(filename)}
            />
          </div>
        );
      })}
    </div>
  );
}
