import React from "react";
import PropTypes from "prop-types";
import { Facility } from "../../constants";
import "./facility_dropdown.css";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";
import { calcColor } from "../../helpers";

/**
 * A row representing a single facility.
 */
export default class FacilityRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div
        className="facility-row"
        onMouseDown={(e) => {
          e.stopPropagation();
          this.props.selectFacility(this.props.facility);
        }}
      >
        <div className="facility-row-map">
          <div
            style={{
              position: "absolute",
              top: 1,
              left: 1,
              width: "calc(100% - 2px)",
              height: "calc(100% - 2px)",
              overflow: "hidden",
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
            }}
          >
            <ComposableMap projection="geoMercator">
              <ZoomableGroup zoom={5} center={this.props.facility.latLng}>
                <Geographies geography={"./map_data.json"}>
                  {({ geographies, projection }) =>
                    geographies.map((geo) => {
                      return (
                        <Geography
                          style={{
                            default: { outline: "none" },
                            hover: { outline: "none" },
                            pressed: { outline: "none" },
                          }}
                          key={geo.rsmKey}
                          geography={geo}
                          fill="var(--bg-color)"
                          stroke="var(--bor-color)"
                        />
                      );
                    })
                  }
                </Geographies>
                <Marker coordinates={this.props.facility.latLng}>
                  <circle
                    style={{ cursor: "pointer" }}
                    r={10}
                    fill={calcColor(this.props.facility.vulnerability_score)}
                    filter={calcColor(this.props.facility.vulnerability_score)}
                  />
                </Marker>
              </ZoomableGroup>
            </ComposableMap>
          </div>
        </div>
        <h2>{this.props.facility.name}</h2>
        <h3 style={{ marginRight: 10 }}>{this.props.facility.description}</h3>
        {this.props.new && (
          <div
            className="light-r"
            style={{
              background: "var(--sec-color)",

              marginBottom: 0,
              minWidth: 11,
              minHeight: 11,
              maxWidth: 11,
              maxHeight: 11,
              marginLeft: "auto",
            }}
          ></div>
        )}
        <div></div>
      </div>
    );
  }
}

FacilityRow.propTypes = {
  /**
   * The facility to display.
   */
  facility: PropTypes.instanceOf(Facility).isRequired,

  /**
   * If the facility has new assets, a blue dot will appear.
   */
  new: PropTypes.bool,

  /**
   * Callback to select the displayed facility.
   */
  selectFacility: PropTypes.func.isRequired,
};
