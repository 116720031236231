import "./mappings.css";

/**
 * An individual instance of an NIST 800-53 mapping.
 * Contains the originating CWE, the mapping line-items,
 * and deep dive discovery locations (if applicable).
 */
export function NIST_Mapping({ mapping }) {
  return (
    <div className="mapping">
      <div style={{ width: "100%" }}>
        <h3>Control Name</h3>
        <h2>{mapping.line_item}</h2>

        <h3>Inciting CWEs</h3>
        <div className="mapping-field">
          <p>
            {mapping.cwes.map((cwe, i) => {
              if (i === mapping.cwes.length - 1) return `CWE-${cwe}`;
              return `CWE-${cwe}, `;
            })}
          </p>
        </div>
        {mapping.actionables && mapping.actionables.length > 0 && (
          <>
            <h3>Actionable Items</h3>
            <div className="mapping-field">
              <ul>
                {mapping.actionables.map((action) => (
                  <li>{action}</li>
                ))}
              </ul>
            </div>
          </>
        )}

        {mapping.hasOwnProperty("notes") && (
          <>
            <h3>Notes</h3>
            <div className="mapping-field" style={{ wordBreak: "break-word"}}>
              <p>{mapping.notes}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
