import React from "react";
import PropTypes from "prop-types";
import "./single_string_modal.css";

// icons
import { X } from "react-feather";

/**
 * Popup that enables the user to update a single string
 * input field.
 * Is used in multiple places, including when renaming an
 * asset, and when adding an asset tag.
 */
export default class SingleStringModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      field: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show) {
      this.setState({ field: "" });
    }
  }

  render() {
    if (!this.props.show) return null;
    return (
      <div className="single-string-modal-bg">
        <div className="single-string-modal">
          <X className="icon" onClick={this.props.close} />

          <div className="add-single-string">
            <h1>{this.props.title}</h1>
            <div className="single-string-name-input">
              <input
                value={this.state.field}
                onChange={(e) => this.setState({ field: e.target.value })}
                placeholder={this.props.field_name}
              />
              <button
                className={this.state.field !== "" ? "button-alive" : ""}
                onClick={() => this.props.completeFunc(this.state.field)}
              >
                {this.props.button_text}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SingleStringModal.propTypes = {
  /**
   * Is the popup open or not.
   */
  show: PropTypes.bool,

  /**
   * Callback function to close the popup.
   */
  close: PropTypes.func.isRequired,

  /**
   * The title header of the popup.
   */
  title: PropTypes.string.isRequired,

  /**
   * The text that appears on the submit button on
   * the popup.
   */
  button_text: PropTypes.string.isRequired,

  /**
   * Callback function that is called when the user
   * clicks the submit button.
   */
  completeFunc: PropTypes.func.isRequired,

  /**
   * The name of the single string field being input.
   */
  field_name: PropTypes.string.isRequired,
};
