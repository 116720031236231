import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";

// icons
import { Info } from "react-feather";

/**
 * Help tooltip that exists on the right-sidebar of the application.
 * Navigates the user to the dashboard and starts the tutorial.
 */
export default class HelpTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Render the component.
   */
  render() {
    return (
      <Tooltip
        // options
        html={<p>Run Tutorial</p>}
        position="left-start"
        trigger="mouseenter"
        arrow
        hideOnClick={false}
        size="small"
        style={{ height: 22 }}
      >
        <Info
          onClick={(e) => {
            if (this.props.clickFunction) {
              this.props.clickFunction();
              return;
            }
            window.location.assign("#/dashboard?tutorial");
          }}
          className="user"
        />
      </Tooltip>
    );
  }
}

HelpTooltip.propTypes = {
  /**
   * Optional callback that runs when the tooltip is clicked.
   */
  clickFunction: PropTypes.func,
};
