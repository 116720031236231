import {
  fetchFacilityItems,
  FACILITY_REPORT_RESULT_TYPES,
} from "../report_builder_page/facility/helpers";
import {
  fetchAssetItems,
  ASSET_REPORT_RESULT_TYPES,
} from "../report_builder_page/asset/helpers";
import {
  fetchBinaryItems,
  BINARY_REPORT_RESULT_TYPES,
} from "../report_builder_page/binary/helpers";

/**
 * Generates a unique ID from a concatenatable report.
 */
export function genConcatReportId(report) {
  switch (report.type) {
    case "FACILITY":
      return `FACILITY_${report.facility_id}`;
    case "ASSET":
      return `ASSET_${report.asset_id}`;
    case "BINARY":
    default:
      return `BINARY_${report.asset_id}_${report.bin_id}`;
  }
}

/**
 * Determine if two report objects are equal.
 */
export function sameReport(report_a, report_b) {
  return genConcatReportId(report_a) === genConcatReportId(report_b);
}

/**
 * Generate a concatenatable report object from a facility.
 */
export function genReportFromFacility(facility) {
  return {
    type: "FACILITY",
    name: facility.name,
    facility_id: facility.id,
  };
}

/**
 * Generate a concatenatable report object from an asset.
 */
export function genReportFromAsset(asset) {
  return { type: "ASSET", name: asset.name, asset_id: asset.ref_id };
}

/**
 * Generate a concatenatable report object from a binary.
 */
export function genReportFromBinary(binary, asset_id) {
  return {
    type: "BINARY",
    name: binary.name,
    asset_id,
    bin_id: binary.name,
  };
}

// --- REPORT GENERATION DATA FETCHING ---

/**
 * Fetch the data to paint a facility report to the DOM.
 */
async function fetchConcatReportData_Facility(report) {
  let results = {
    items: [],
    facility: null,
    assets: [],
  };
  const fetchDataCallback = (type, data) => {
    switch (type) {
      case FACILITY_REPORT_RESULT_TYPES.FACILITY:
        results.facility = data;
        break;
      case FACILITY_REPORT_RESULT_TYPES.ASSETS:
        results.assets = data;
        break;
      default:
        break;
    }
  };
  await fetchFacilityItems(report.facility_id, fetchDataCallback);

  // append all items to the report
  results.assets.forEach((asset) =>
    results.items.push({
      title: asset.name,
      id: asset.ref_id,
      severity: asset.cwss_score,
    })
  );

  return results;
}

/**
 * Fetch the data to paint an asset report to the DOM.
 */
async function fetchConcatReportData_Asset(report) {
  let results = {
    items: [],
    asset_id: report.asset_id,
    asset: null,
    binaries: [],
    audit: [],
  };
  const fetchDataCallback = (type, data) => {
    switch (type) {
      case ASSET_REPORT_RESULT_TYPES.ASSET:
        results.asset = data;
        break;
      case ASSET_REPORT_RESULT_TYPES.BINARIES:
        results.binaries = data;
        break;
      case ASSET_REPORT_RESULT_TYPES.AUDIT:
        results.audit = data;
        break;
      default:
        break;
    }
  };
  await fetchAssetItems(report.asset_id, fetchDataCallback);

  // append all items to the report
  results.binaries.forEach((binary) =>
    results.items.push({
      title: binary.name,
      desc: "A binary.",
      id: binary.name,
      severity: binary.cwss.aggregate,
      cwss: binary.cwss.base,
    })
  );

  return results;
}

/**
 * Fetch the data to paint a binary report to the DOM.
 */
async function fetchConcatReportData_Binary(report) {
  let results = {
    items: [],
    asset_id: report.asset_id,
    binary: null,
    metadata: null,
    manalyze: null,
    binaryEncryption: null,
    cwes: [],
    cves: [],
    threatAI: [],
    dangerousFns: {},
    crypto: null,
    runtimeEncryption: null,
    weakPointers: [],
    weakPointerConfidence: [],
  };

  const fetchDataCallback = (type, data) => {
    switch (type) {
      case BINARY_REPORT_RESULT_TYPES.BINARY:
        results.binary = data;
        break;
      case BINARY_REPORT_RESULT_TYPES.METADATA:
        results.metadata = data;
        break;
      case BINARY_REPORT_RESULT_TYPES.MANALYZE:
        results.manalyze = data;
        break;
      case BINARY_REPORT_RESULT_TYPES.BINARY_ENCRYPTION:
        results.binaryEncryption = data;
        break;
      case BINARY_REPORT_RESULT_TYPES.CWES:
        results.cwes = data;
        break;
      case BINARY_REPORT_RESULT_TYPES.CVES:
        results.cves = data;
        break;
      case BINARY_REPORT_RESULT_TYPES.THREATAI:
        results.threatAI = data;
        break;
      case BINARY_REPORT_RESULT_TYPES.DANGEROUS_FUNCTIONS:
        results.dangerousFns = data;
        break;
      case BINARY_REPORT_RESULT_TYPES.CRYPTO:
        results.crypto = data;
        break;
      case BINARY_REPORT_RESULT_TYPES.RUNTIME_ENCRYPTION:
        results.runtimeEncryption = data;
        break;
      case BINARY_REPORT_RESULT_TYPES.WEAK_POINTERS:
        results.weakPointers = data;
        break;
      case BINARY_REPORT_RESULT_TYPES.WEAK_POINTER_CONFIDENCE:
        results.weakPointerConfidence = data;
        break;
      default:
        break;
    }
  };
  await fetchBinaryItems(report.asset_id, report.bin_id, fetchDataCallback);

  // append all items to the report
  results.cves.forEach((cve) =>
    results.items.push({
      type: "CVE",
      title: cve.cve_number,
      desc: cve.description,
      id: cve.cve_number,
      severity: cve.severity,
    })
  );

  results.cwes.forEach((cwe) =>
    results.items.push({
      type: "CWE",
      title: cwe.cwe + " • " + cwe.info.name,
      desc: cwe.msg,
      id: cwe.cwe,
      arr: cwe.arr,
      mappings: cwe.mappings,
    })
  );

  results.threatAI.forEach((tai) => {
    if (tai.STATUS === "FAIL")
      results.items.push({
        type: "THREATAI",
        title: tai.NAME,
        desc: tai.MSG,
        id: tai.NAME,
        status: tai.STATUS,
        mappings: tai.mappings,
      });
  });

  Object.entries(results.dangerousFns).forEach(([fn, samples]) =>
    results.items.push({
      type: "DANGEROUS_FUNCTION",
      title: fn,
      samples,
      id: fn,
    })
  );

  if (results.crypto)
    Object.entries(results.crypto).forEach(([offset, key]) =>
      results.items.push({
        type: "CRYPTO",
        title: `Cryptographic Key at ${offset}`,
        value: key.value,
        id: `Cryptographic Key at ${offset}`,
      })
    );

  if (results.runtimeEncryption)
    results.runtimeEncryption.runtime.signatures.forEach((sig) =>
      results.items.push({
        type: "HASH_SIGNATURE",
        title: `[0x${sig.offset}] - Hash Signature: ${sig.description}.`,
        id: `[0x${sig.offset}] - Hash Signature: ${sig.description}.`,
      })
    );

  if (
    results.binaryEncryption &&
    results.binaryEncryption.binary.entropy.contains_strong_encryption
  )
    results.binaryEncryption.binary.entropy.strong_chunks.forEach((chunk) =>
      results.items.push({
        type: "STRONG_ECRYPTION",
        title: `[0x${chunk.offset}] - Strong Encryption; Entropy Level: ${chunk.value}.`,
        id: `[0x${chunk.offset}] - Strong Encryption; Entropy Level: ${chunk.value}.`,
      })
    );

  if (
    results.binaryEncryption &&
    results.binaryEncryption.binary.entropy.contains_weak_encryption
  )
    results.binaryEncryption.binary.entropy.weak_chunks.forEach((chunk) =>
      results.items.push({
        type: "WEAK_ECRYPTION",
        title: `[0x${chunk.offset}] - Weak Encryption; Entropy Level: ${chunk.value}.`,
        id: `[0x${chunk.offset}] - Weak Encryption; Entropy Level: ${chunk.value}.`,
      })
    );

  Object.keys(results.weakPointers).forEach((wp) =>
    results.items.push({
      type: "WEAK_POINTER",
      desc: "Found a weak pointer at the associated address.",
      title: "Weak Pointer at " + wp,
      id: wp,
      paths: results.weakPointers[wp].paths,
      confidence: results.weakPointers[wp].confidence,
    })
  );

  return results;
}

/**
 * Fetch data needed to render a PDF report to the DOM.
 */
export async function fetchConcatReportData(report) {
  switch (report.type) {
    case "FACILITY":
      return await fetchConcatReportData_Facility(report);
    case "ASSET":
      return await fetchConcatReportData_Asset(report);
    case "BINARY":
      return await fetchConcatReportData_Binary(report);
    default:
      return null;
  }
}
