import { getBasePath } from ".";

/**
 * Get an asset's metadata by ref_id.
 */
export async function getAsset(asset_id) {
  let body = new FormData();
  body.append("asset", asset_id);
  let res = await fetch(getBasePath() + "/asset/get", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  let parsed = await res.json();
  return parsed;
}

/**
 * Adds a tag to an asset.
 */
export async function addAssetTag(asset_id, tag) {
  let body = new FormData();
  body.append("asset", asset_id);
  body.append("tag", tag);
  let res = await fetch(getBasePath() + "/asset/add_tag", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  let parsed = await res.text();
  return parsed;
}

/**
 * Removes a tag from an asset.
 */
export async function removeAssetTag(asset_id, tag) {
  let body = new FormData();
  body.append("asset", asset_id);
  body.append("tag", tag);
  let res = await fetch(getBasePath() + "/asset/remove_tag", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  let parsed = await res.text();
  return parsed;
}

/**
 * Get the list of Devices Under Test.
 * Helper function when getting all past uploads.
 */
export async function getAssets(
  sortingByVulnerability,
  offset = null,
  limit = null,
  search = null,
  asset_type = null
) {
  let params = new URLSearchParams();
  if (sortingByVulnerability) params.append("sortby", "score");
  else params.append("sortby", "date");
  if (limit !== null) params.append("limit", limit);
  if (offset !== null) params.append("offset", offset);
  if (search) params.append("search", search);
  if (asset_type) params.append("asset_type", asset_type);

  let res = await fetch(getBasePath() + "/asset/get_all?" + params, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  if (!res.ok) return false;
  let allAssets = await res.json();
  if (allAssets.length === 0) return false;
  return allAssets;
}

/**
 * Get the number of new assets in the organization/
 */
export async function getNumNew() {
  let res = await fetch(getBasePath() + `/asset/num_new`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  if (!res.ok) {
    return { numNew: 0 };
  }
  let parsed = await res.json();
  return parsed;
}

/**
 * Update the name of an asset to be a different name.
 */
export async function renameAsset(asset_id, new_name) {
  let body = new FormData();
  body.append("asset", asset_id);
  body.append("new_name", new_name);
  let res = await fetch(getBasePath() + "/asset/rename", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  let parsed = await res.text();
  return parsed;
}

/**
 * Update the the description of an asset.
 */
export async function updateAssetDescription(asset_id, new_description) {
  let body = new FormData();
  body.append("asset", asset_id);
  body.append("new_description", new_description);
  let res = await fetch(getBasePath() + "/asset/description", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  let parsed = await res.text();
  return parsed;
}

/**
 * Update the the type of an asset.
 */
export async function updateAssetType(asset_id, new_type) {
  let body = new FormData();
  body.append("asset", asset_id);
  body.append("new_type", new_type);
  let res = await fetch(getBasePath() + "/asset/type", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  let parsed = await res.text();
  return parsed;
}

/**
 * Get all the assets in a facility.
 */
export async function getAllAssetsInFacility(
  facility_id,
  sortingByVulnerability,
  offset = null,
  limit = null,
  search = null,
  asset_type = null
) {
  let params = new URLSearchParams();

  if (sortingByVulnerability) params.append("sortby", "score");
  else params.append("sortby", "date");
  if (limit !== null) params.append("limit", limit);
  if (offset !== null) params.append("offset", offset);
  if (search) params.append("search", search);
  if (asset_type) params.append("asset_type", asset_type);

  let res = await fetch(
    getBasePath() + `/asset/facility/${facility_id}/get_all?` + params,
    {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + window.localStorage.getItem("auth"),
      }),
    }
  );
  if (!res.ok) return false;
  let allAssets = await res.json();
  if (allAssets.length === 0) return false;
  return allAssets;
}

export async function getAllAssetsInFacility_byTag(
  facility_id,
  sortingByVulnerability,
  offset = null,
  limit = null,
  search = null,
  asset_type = null
) {
  let params = new URLSearchParams();

  if (sortingByVulnerability) params.append("sortby", "score");
  else params.append("sortby", "date");
  if (limit !== null) params.append("limit", limit);
  if (offset !== null) params.append("offset", offset);
  if (search) params.append("search", search);
  if (asset_type) params.append("asset_type", asset_type);

  let res = await fetch(
    getBasePath() + `/asset/facility/${facility_id}/by_tag?` + params,
    {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + window.localStorage.getItem("auth"),
      }),
    }
  );
  if (!res.ok) return false;
  let allAssets = await res.json();
  if (allAssets.length === 0) return false;
  return allAssets;
}

/**
 * Mark an asset as being viewed.
 */
export async function markAssetViewed(asset_id) {
  let body = new FormData();
  body.append("asset", asset_id);
  let res = await fetch(getBasePath() + "/asset/viewed", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  let parsed = await res.text();
  return parsed;
}

export async function updateAssetVendorProductVersion(
  asset_id,
  vendor,
  product,
  version
) {
  let body = new FormData();
  body.append("asset", asset_id);
  body.append("vendor", vendor);
  body.append("product", product);
  body.append("version", version);
  let res = await fetch(getBasePath() + "/asset/vendor_product_version", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  let parsed = await res.text();
  return parsed;
}

/**
 * Get the audit log history of a specific asset.
 */
export async function getAssetAuditLog(asset_id) {
  let res = await fetch(getBasePath() + `/asset/audit/${asset_id}`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  let parsed = await res.json();
  return parsed;
}

/**
 * Delete an asset in an organization.
 */
export async function deleteAsset(asset_id) {
  let body = new FormData();
  body.append("asset", asset_id);
  let res = await fetch(getBasePath() + "/asset/delete", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  let parsed = await res.text();
  return parsed;
}

export async function getAssetCPEs(asset_id) {
  let res = await fetch(getBasePath() + `/asset/${asset_id}/result/cpes`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  let parsed = await res.json();
  return parsed;
}
