import React from "react";
import "./organizations_toggle.css";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";

// icons
import { User, UserPlus } from "react-feather";
import { getUserProfile, swapOrganizations } from "../../api";
import BinaryAnalysisPage from "../binary_analysis_page/binary_analysis_page";
import AnalysisProgressBar from "./analysis_progress_bar";
import NewDashboard from "../dashboard/dashboard";
import DeepDivePage from "../deep_dive_page/deep_dive_page";
import GeneratedReports from "../reports_page/generated_reports";

/**
 * Displays a set of clickable organizations names based upon those
 * organizations which the user is a part of.
 * Upon clicking an organzation, the application is refreshed to be
 * in the context of the selected organization. That is, the assets,
 * facilities, analyses etc displayed in the application will be those
 * corresponding to the selected organization.
 * The currently selected organizations is highlighted.
 */
export default class OrganizationsToggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOrgId: null,
      orgs: [],
      roles: {},
      org_details: {},
    };
    this.init = this.init.bind(this);
    this.swapOrgs = this.swapOrgs.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    let profile = await getUserProfile(window.localStorage.getItem("userid"));
    if (!profile) return;
    let orgs = Object.keys(profile.roles);
    this.setState({
      orgs,
      org_details: profile.org_details,
      selectedOrgId: profile.org_id,
      roles: profile.roles,
    });
  }

  async swapOrgs(org_id) {
    if (this.state.selectedOrgId === org_id) return;
    await swapOrganizations(window.localStorage.getItem("userid"), org_id);
    this.setState({ selectedOrgId: null });
    this.init();

    // refresh pages with new org context if possible
    if (NewDashboard._this) NewDashboard._this.init();
    if (BinaryAnalysisPage._this) BinaryAnalysisPage._this.init_fromOrgSwap();
    if (AnalysisProgressBar._this) AnalysisProgressBar._this.init();
    if (DeepDivePage._this) DeepDivePage._this.refresh();
    if (GeneratedReports._this) GeneratedReports._this.init();
  }

  /**
   * Render the component.
   */
  render() {
    if (!this.state.selectedOrgId)
      return <div style={{ marginRight: "auto" }}></div>;
    return (
      <div
        className="joyride-org-step"
        style={{
          marginRight: "auto",
          maxWidth: "calc(100vw - 520px)",
          overflow: "hidden",
          marginLeft: 15,
        }}
      >
        {this.state.orgs.map((o, i) => {
          let selected = this.state.selectedOrgId === o;
          return (
            <Tooltip
              key={i}
              // options
              title={selected ? "Current Organization" : "Switch Organization"}
              position="bottom"
              trigger="mouseenter"
              arrow
              size="small"
              style={{
                overflow: "hidden",
                display: "flex",
              }}
            >
              <div
                style={{
                  background: selected
                    ? "var(--sec-bg-color) !important"
                    : "transparent",
                  borderRadius: selected ? 10 : 0,
                  padding: selected ? 5 : 0,
                }}
                className="row head-row-high an-org"
                onClick={() => this.swapOrgs(o)}
              >
                <h3
                  style={{
                    color: selected
                      ? "var(--sec-color)"
                      : "var(--pri-color-light)",
                    width: "calc(100% - 20px)",
                    overflow: "hidden",

                    textOverflow: "ellipsis",
                  }}
                >
                  {this.state.org_details[o].name}
                </h3>

                <div className="org-users">
                  {(this.state.roles[o].includes("admin") ||
                    this.state.roles[o].includes("superadmin")) && (
                    <UserPlus
                      className="user"
                      style={{
                        stroke: selected
                          ? "var(--sec-color)"
                          : "var(--pri-color-light)",
                      }}
                    />
                  )}
                  {!this.state.roles[o].includes("admin") &&
                    !this.state.roles[o].includes("superadmin") && (
                      <User
                        className="user"
                        style={{
                          stroke: selected
                            ? "var(--sec-color)"
                            : "var(--pri-color-light)",
                        }}
                      />
                    )}
                </div>
              </div>
            </Tooltip>
          );
        })}
      </div>
    );
  }
}
