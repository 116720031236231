import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import "./top_risk.css";
import { TopRisk as TopRiskClass } from "../../constants";
import { getAsset } from "../../api";

// icons
import { Info } from "react-feather";
import { getAssetIcon } from "../../asset_types";

/**
 * An individual top risk. A top risk is a specific vulnerability in an
 * organization that is considered of significant importance.
 * It is display on the organization's dashboard, and can be clicked on
 * to jump to the specific vulnerability on the Binary Analysis Page.
 */
export default class TopRisk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
    };
    this.init = this.init.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    // get the current asset name of the ID'd asset
    let asset_data = await getAsset(this.props.top_risk.asset);
    this.setState({ name: asset_data.name });
  }

  render() {
    if (!this.state.name) return null;
    return (
      <div
        className="top-risk"
        style={
          this.props.highAlert
            ? {
                boxShadow:
                  "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                border: "1px solid var(--contrast-color)",
                background: "var(--contrast-color)",
              }
            : {}
        }
        onClick={() => {
          window.location.assign(
            `#/binary?asset=${this.props.top_risk.asset}&facility_id=${this.props.top_risk.facility_id}&asset_name=${this.state.name}`
          );
        }}
      >
        <div className="top-risk-left">
          <div
            className="top-risk-icon-red"
            style={{
              borderColor: this.props.highAlert
                ? "white"
                : "var(--contrast-color)",
            }}
          >
            {getAssetIcon(this.props.top_risk.type, "top-risk-icon", {
              stroke: this.props.highAlert ? "white" : "var(--pri-color-light)",
            })}
          </div>
        </div>
        <div>
          <h2>{`Asset '${this.state.name}' contains vulnerability ${this.props.top_risk.name}.`}</h2>
          <p
            style={{
              textAlign: "left",
              color: this.props.highAlert ? "white" : "var(--pri-color-light)",
            }}
          >
            {this.props.top_risk.desc}
          </p>

          {this.props.highAlert && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <Info
                style={{
                  width: 16,
                  height: 16,
                  stroke: this.props.highAlert
                    ? "white"
                    : "var(--pri-color-light)",
                  marginRight: 5,
                }}
              />
              <p
                style={{
                  textAlign: "left",
                  lineHeight: 1,
                  color: this.props.highAlert
                    ? "white"
                    : "var(--pri-color-light)",
                }}
              >
                Learn More
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

TopRisk.propTypes = {
  /**
   * The top risk to display.
   */
  top_risk: PropTypes.instanceOf(TopRiskClass).isRequired,

  /**
   * If this value exists, the TopRisk is a high-alert, and is
   * colored a bright/obvious red.
   */
  highAlert: PropTypes.bool,
};
