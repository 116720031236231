import PropTypes from "prop-types";
import { bytesToSize } from "../../../helpers";

// icons
import {
  Cpu,
  DollarSign,
  FileText,
  GitCommit,
  Globe,
  HardDrive,
  Hash,
  Home,
  Type,
} from "react-feather";
import { Binary } from "../../../constants";

/**
 * Metadata section that appears on the Binary Summary Panel,
 * and on the Custom Report page.
 * Displays metadata including file size, hash, entropy, etc.
 */
export default function BinarySummaryMetadata({
  metadata,
  manalyze,
  binaryEncryption,
}) {
  if (!metadata) return null;
  return (
    <div
      style={{
        border: "2px solid var(--sec-bg-color)",
        borderRadius: 10,
        padding: 20,
        marginBottom: 30,
      }}
    >
      <div className="left-row" style={{ alignItems: "flex-start" }}>
        <div>
          <h2 style={{ marginTop: 20, marginBottom: 30 }}>
            Extracted Metadata:
          </h2>
          <div className="base-metadata" style={{ borderLeft: "none" }}>
            <div>
              <h3>{metadata === null ? "-" : bytesToSize(metadata.size)}</h3>
              <h4>size</h4>
              <HardDrive className="user" />
            </div>
            <div>
              <h3 style={{ wordBreak: "break-all" }}>
                {metadata === null ? "-" : metadata.hash}
              </h3>
              <h4>hash</h4>
              <Hash className="user" />
            </div>
            <div>
              <h3>{metadata === null ? "-" : metadata.type_str}</h3>
              <h4>type</h4>
              <Type className="user" />
            </div>

            <div>
              <h3>
                {manalyze
                  ? manalyze[Object.keys(manalyze)[0]].Summary.Architecture
                  : "-"}
              </h3>
              <h4>architecture</h4>
              <Cpu className="user" />
            </div>
            <div>
              <h3>
                {manalyze
                  ? manalyze[Object.keys(manalyze)[0]].Summary.CompanyName
                    ? manalyze[Object.keys(manalyze)[0]].Summary.CompanyName
                    : "-"
                  : "-"}
              </h3>
              <h4>publisher</h4>
              <Home className="user" />
            </div>

            <div>
              <h3>
                {manalyze
                  ? manalyze[Object.keys(manalyze)[0]].Summary[
                      "Detected languages"
                    ] > 0
                    ? manalyze[Object.keys(manalyze)[0]].Summary[
                        "Detected languages"
                      ][0]
                    : "-"
                  : "-"}
              </h3>
              <h4>language</h4>
              <Globe className="user" />
            </div>

            <div>
              <h3>
                {manalyze
                  ? manalyze[Object.keys(manalyze)[0]].Summary.FileDescription
                    ? manalyze[Object.keys(manalyze)[0]].Summary.FileDescription
                    : "-"
                  : "-"}
              </h3>
              <h4>description</h4>
              <FileText className="user" />
            </div>

            <div>
              <h3>
                {manalyze
                  ? manalyze[Object.keys(manalyze)[0]].Summary.ProductVersion
                    ? manalyze[Object.keys(manalyze)[0]].Summary.ProductVersion
                    : "-"
                  : "-"}
              </h3>
              <h4>version</h4>
              <GitCommit className="user" />
            </div>

            <div>
              <h3>
                {manalyze
                  ? manalyze[Object.keys(manalyze)[0]].Summary.LegalCopyright
                    ? manalyze[Object.keys(manalyze)[0]].Summary.LegalCopyright
                    : "-"
                  : "-"}
              </h3>
              <h4>copyright</h4>
              <h1 className="user">©</h1>
            </div>

            <div>
              <h3>
                {manalyze
                  ? manalyze[Object.keys(manalyze)[0]].Summary.ProductName
                    ? manalyze[Object.keys(manalyze)[0]].Summary.ProductName
                    : "-"
                  : "-"}
              </h3>
              <h4>product</h4>
              <DollarSign className="user" />
            </div>
          </div>

          {metadata.entropy && Object.keys(metadata.entropy).length > 0 && (
            <>
              <h2 style={{ marginTop: 20 }}>Entropy:</h2>
              <div
                style={{
                  background: "var(--sec-bg-color)",
                  borderRadius: 10,
                  padding: 20,
                  marginTop: 20,
                  marginBottom: 10,
                  wordBreak: "break-all",
                  fontFamily: "monospace",
                  width: "calc(100% - 70px)",
                }}
              >
                Base: {metadata.entropy.entropy}
                <br />
                Mean: {metadata.entropy.mean}
                <br />
                Chi Square: {metadata.entropy.chi_square}
                <br />
                Monte Carlo Pi: {metadata.entropy.monte_carlo_pi} <br />
                Serial Correlation: {metadata.entropy.serial_correlation} <br />
              </div>
            </>
          )}

          {binaryEncryption &&
            binaryEncryption.binary.entrypoint.result === "pass" && (
              <>
                <h2 style={{ marginTop: 40 }}>Program Entry Point:</h2>
                <div
                  style={{
                    background: "var(--sec-bg-color)",
                    borderRadius: 10,
                    padding: 20,
                    marginTop: 20,
                    marginBottom: 10,
                    wordBreak: "break-all",
                    fontFamily: "monospace",
                    width: "calc(100% - 70px)",
                  }}
                >
                  {binaryEncryption.binary.entrypoint.address}
                </div>
              </>
            )}
        </div>
      </div>
    </div>
  );
}

BinarySummaryMetadata.propTypes = {
  /**
   * The binary to display metadata for.
   */
  binary: PropTypes.instanceOf(Binary).isRequired,

  /**
   * Manalyze assessment results to display.
   */
  manalyze: PropTypes.object.isRequired,

  /**
   * Binary encryption results to display.
   */
  binaryEncryption: PropTypes.object.isRequired,
};
