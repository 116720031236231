/*
    This component appears when a file is being uploaded
    to the dropzone.
    It displays an upload progress circle.
*/
import { COLORS_STRING } from "../../../colors";
import { Doughnut } from "react-chartjs-2";

export default function UploadingFile({ currentlyLoading }) {
  return (
    <div className="new-analysis-centerpiece">
      <div style={{ marginBottom: 20, width: 100, height: 100 }}>
        <Doughnut
          className="CWSS-chart"
          data={{
            datasets: [
              {
                data: [
                  currentlyLoading[1],
                  currentlyLoading[2] - currentlyLoading[1],
                ],
                backgroundColor: [COLORS_STRING.BLUE, COLORS_STRING.BOR],
                borderColor: [COLORS_STRING.BOR],
                borderWidth: 1,
              },
            ],
          }}
          options={{
            cutout: 40,
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
          }}
        />
      </div>

      <h4
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxWidth: 300,
        }}
      >
        {currentlyLoading[0]} Uploading
      </h4>

      <h3
        style={{
          textAlign: "left",
          padding: 20,
          lineHeight: 1.4,
          marginTop: 20,
        }}
      >
        Once your binaries have completed uploading, remember to click the{" "}
        <b>Start Analysis</b> button!
      </h3>
    </div>
  );
}
