import { useState } from "react";
import PropTypes from "prop-types";
import "./crypto_tab.css";
import { Binary } from "../../../constants";

// icons
import { CheckCircle, ChevronLeft, ChevronRight } from "react-feather";
import BinarySummaryInfoHelper from "./binary_summary_info_helper";

// the maximum number of components to display at one time.
const MAX_TO_DISPLAY = 5;
const ENTRY_POINT_INFO_TXT =
  "The entry point of a program is the place \
  where a program begins execution, and where the program has access to \
  command line arguments (e.g., main() in C/C++). The lack of an \
  entry point in a binary executable indicates that it is either a \
  library file, or that it is packed and/or obfuscated in some way.";

// type of components to display in the CryptoContainer
const CRYPTO_ITEM_TYPE = {
  STRONG_ENCRYPTION: "STRONG_ENCRYPTION",
  WEAK_ENCRYPTION: "WEAK_ENCRYPTION",
  CRYPTO_SIGNATURE: "CRYPTO_SIGNATURE",
};

const StrongEncryption = ({ item }) => {
  return (
    <div className="strong-enc-row">
      [{item.offset}] - Entropy: {item.value}.
    </div>
  );
};

const WeakEncryption = ({ item }) => {
  return (
    <div className="strong-enc-row">
      [{item.offset}] - Entropy: {item.value}.
    </div>
  );
};

const HashSignature = ({ item }) => {
  return (
    <div className="strong-enc-row">
      <div>
        [{item.offset}] - Signature: {item.description}.
      </div>
      {item.deprecation_msg && (
        <div>
          <hr />
          <br />
          <b>Deprecation Information</b>
          <br />
          <br />
          {item.deprecation_msg}
        </div>
      )}
    </div>
  );
};

/**
 * We only want to render 5 samples at a time.
 */
const CryptoContainer = ({ items, title, type }) => {
  let [offset, setOffset] = useState(0);

  const MAX_OFFSET = Math.ceil(items.length / MAX_TO_DISPLAY);

  const renderItem = (item) => {
    switch (type) {
      case CRYPTO_ITEM_TYPE.CRYPTO_SIGNATURE:
        return <HashSignature item={item} />;
      case CRYPTO_ITEM_TYPE.WEAK_ENCRYPTION:
        return <WeakEncryption item={item} />;
      case CRYPTO_ITEM_TYPE.STRONG_ENCRYPTION:
      default:
        return <StrongEncryption item={item} />;
    }
  };

  return (
    <div className="crypto-container">
      <h2 style={{ marginTop: 20 }}>{title}</h2>
      {items
        .slice(
          offset * MAX_TO_DISPLAY,
          offset * MAX_TO_DISPLAY + MAX_TO_DISPLAY
        )
        .map((item) => renderItem(item))}

      <div className="row">
        <h3 className="page-count">
          {offset + 1} of {MAX_OFFSET}
        </h3>
        <ChevronLeft
          onClick={() => {
            if (offset === 0) return;
            setOffset((offset) => offset - 1);
          }}
          className="icon"
        />
        <ChevronRight
          onClick={() => {
            if (offset === MAX_OFFSET - 1) return;
            setOffset((offset) => offset + 1);
          }}
          className="icon"
        />
      </div>
    </div>
  );
};

/**
 * Tab of the Binary Summary Panel that displays cryptographic
 * information, including Program Entry Point, encrypted segments,
 * secret keys, etc...
 */
export default function CryptoTab({ binary, data }) {
  if (!data.crypto && !data.binaryEncryption && !data.runtimeEncryption)
    return (
      <div style={{ marginTop: -10 }}>
        <h4 style={{ fontWeight: 500 }}>
          No cryptographic information was detected in this binary.
        </h4>
        <CheckCircle className="big-icon" />
      </div>
    );

  return (
    <>
      {data.crypto && (
        <>
          <BinarySummaryInfoHelper
            text="A cryptographic key is a binary string that, when processed through a cryptographic algorithm, can encode or decode data.
                  Because cryptographic keys protect confidential information, it is
                  important that they are kept secret from unauthorized parties. The
                  discovery of an embedded cryptographic key within an analyzed
                  binary is indicative of a security concern (e.g, CWE-321: Use of
                  Hard-coded Cryptographic Key and/or CWE-798: Use of Hard-coded
                  Credentials)."
          />

          <div
            style={{
              border: "2px solid var(--sec-bg-color)",
              borderRadius: 10,
              padding: 20,
              marginBottom: 30,
            }}
          >
            <div
              className="left-row"
              style={{ paddingLeft: 20, alignItems: "flex-start" }}
            >
              <div style={{ width: "100%" }}>
                <h2 style={{ marginTop: 20 }}>Found Cryptographic Keys:</h2>
                {Object.keys(data.crypto).map((k) => {
                  return (
                    <div>
                      <div
                        style={{
                          background: "var(--sec-bg-color)",
                          borderRadius: 10,
                          padding: 20,
                          marginTop: 20,
                          marginBottom: 20,

                          wordBreak: "break-all",
                          fontFamily: "monospace",

                          width: "calc(100% - 70px)",
                        }}
                      >
                        {data.crypto[k].value}
                      </div>
                      <ul>
                        <li style={{ color: "var(--pri-color-light)" }}>
                          At position {k}.
                        </li>
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}

      {data.binaryEncryption &&
        data.binaryEncryption.binary.entrypoint.result === "pass" && (
          <>
            <BinarySummaryInfoHelper text={ENTRY_POINT_INFO_TXT} />
            <div
              style={{
                border: "2px solid var(--sec-bg-color)",
                borderRadius: 10,
                padding: 20,
                marginBottom: 30,
              }}
            >
              <div
                className="left-row"
                style={{ paddingLeft: 20, alignItems: "flex-start" }}
              >
                <div style={{ width: "100%" }}>
                  <h2 style={{ marginTop: 20 }}>Program Entry Point:</h2>
                  <div>
                    <div
                      style={{
                        background: "var(--sec-bg-color)",
                        borderRadius: 10,
                        padding: 20,
                        marginTop: 20,
                        marginBottom: 20,
                        wordBreak: "break-all",
                        fontFamily: "monospace",
                        width: "calc(100% - 70px)",
                      }}
                    >
                      {data.binaryEncryption.binary.entrypoint.address}
                    </div>
                  </div>
                  <h3 style={{ lineHeight: "1.4" }}>
                    This indicates that the program is probably not packed.
                  </h3>
                </div>
              </div>
            </div>
          </>
        )}

      {data.binaryEncryption &&
        data.binaryEncryption.binary.entrypoint.result === "fail" && (
          <>
            <BinarySummaryInfoHelper text={ENTRY_POINT_INFO_TXT} />
            <div
              style={{
                border: "2px solid var(--sec-bg-color)",
                borderRadius: 10,
                padding: 20,
                marginBottom: 30,
              }}
            >
              <div
                className="left-row"
                style={{ paddingLeft: 20, alignItems: "flex-start" }}
              >
                <div style={{ width: "100%" }}>
                  <h2 style={{ marginTop: 20 }}>Program Entry Point:</h2>
                  <div>
                    <div
                      style={{
                        background: "var(--sec-bg-color)",
                        borderRadius: 10,
                        padding: 20,
                        marginTop: 20,
                        marginBottom: 20,
                        wordBreak: "break-all",
                        fontFamily: "monospace",
                        width: "calc(100% - 70px)",
                      }}
                    >
                      None!
                    </div>
                  </div>
                  <h3 style={{ lineHeight: "1.4" }}>
                    This indicates that the program is probably packed. This may
                    be cause for concern: the program is attempting to obfuscate
                    itself.
                  </h3>
                </div>
              </div>
            </div>
          </>
        )}

      {data.binaryEncryption &&
        data.binaryEncryption.binary.entropy.contains_strong_encryption && (
          <>
            <BinarySummaryInfoHelper
              text="A high level of entropy (randomness) is typically associated
                    with packed, encrypted, or obfuscated data. Strong encryption is
                    encryption that has been performed with a key of sufficient
                    length, reducing the possibility that the encryption scheme
                    could be broken (cracked)."
            />
            <CryptoContainer
              items={data.binaryEncryption.binary.entropy.strong_chunks}
              title="Contains Strong Encryption at the Following Addresses:"
              type={CRYPTO_ITEM_TYPE.STRONG_ENCRYPTION}
            />
          </>
        )}

      {data.binaryEncryption &&
        data.binaryEncryption.binary.entropy.contains_weak_encryption && (
          <>
            <BinarySummaryInfoHelper
              text="A high level of entropy (randomness) is typically associated
                    with packed, encrypted, or obfuscated data. Weak encryption is
                    encryption that has been performed with a key of insufficient
                    length, opening up the possibility that the encryption scheme
                    could be broken (cracked)."
            />
            <CryptoContainer
              items={data.binaryEncryption.binary.entropy.weak_chunks}
              title="Contains Weak Encryption:"
              type={CRYPTO_ITEM_TYPE.WEAK_ENCRYPTION}
            />
          </>
        )}

      {data.runtimeEncryption &&
        data.runtimeEncryption.runtime.signatures.length > 0 && (
          <>
            <BinarySummaryInfoHelper
              text="A cryptographic algorithm maps an arbitrary binary string to a
                    different binary string of some fixed size. Cryptographic
                    algorithms have numerous use cases, including encrypting secret
                    messages and determining the validity of downloaded files. The
                    Cryptographic Signatures section lists cases in the analyzed
                    binary executable wherein a certain cryptographic algorithm is
                    invoked at runtime."
            />
            <CryptoContainer
              items={data.runtimeEncryption.runtime.signatures}
              title="Cryptographic Signatures:"
              type={CRYPTO_ITEM_TYPE.CRYPTO_SIGNATURE}
            />
          </>
        )}
    </>
  );
}

CryptoTab.propTypes = {
  /**
   * Asset Id of the parent asset.
   */
  asset_id: PropTypes.string.isRequired,

  /**
   * Binary who's cryptographic info is being displayed.
   */
  binary: PropTypes.instanceOf(Binary),
};
