import { TOP_LEVEL_CWE_ID } from ".";

/**
 * Displays the CWE number, name, and human-readable description.
 * TODO Optionally display the long description and other CWE info such
 * as mitigations, examples, and consequences.
 */
export default function CWETitle({ cwe }) {
  if (!cwe || cwe.id === TOP_LEVEL_CWE_ID) return null;
  return (
    <div className="mapping">
      <h3>Detected CWE</h3>
      <h2>
        CWE-{cwe.id}: {cwe.description}
      </h2>

      <h3>Description</h3>
      <div className="mapping-field"
        style={{ whiteSpace: "pre-line"}}
      >{cwe.long_description}</div>
    </div>
  );
}
