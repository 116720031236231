import { useState } from "react";
import { Search } from "react-feather";
import { determineCWEPath, matchCWEs } from "./helpers";

export default function CWESearcher({ setCWEPath }) {
  let [text, setText] = useState("");
  let [dropdown, setDropdown] = useState(false);

  let matched_cwes = matchCWEs(text);
  let dropdown_on = dropdown && matched_cwes.length > 0;
  return (
    <div
      className="cwe-searcher"
      style={{
        outline: dropdown_on ? "2px solid var(--sec-color)" : "none",
        borderBottomLeftRadius: dropdown_on ? 0 : 29,
        borderBottomRightRadius: dropdown_on ? 0 : 29,
      }}
    >
      <Search className="navigate" />
      <input
        onFocus={() => setDropdown(true)}
        onBlur={() => setDropdown(false)}
        placeholder="Find CWE..."
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      {dropdown_on && (
        <div className="matched-cwes">
          {matched_cwes.map((cwe_text) => (
            <div
              className="matched-cwe"
              onMouseDown={() => {
                setCWEPath(
                  determineCWEPath(cwe_text.split("-")[1].split(":")[0])
                );
                setText("");
              }}
            >
              {cwe_text}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
