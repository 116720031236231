import { getBasePath } from ".";

/**
 * List binaries currently in the dropzone.
 */
export async function listBinaries() {
  let res = await fetch(getBasePath() + "/dropzone/list", {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  if (!res.ok) {
    return "failed";
  }
  let data = await res.json();
  return data;
}

/**
 * Upload a binary for analysis.
 * Takes a function that is attached to the upload POST request.
 * This function should be used to display upload progress.
 */
export function uploadBinary(file, progressFunc, completeFunc) {
  let body = new FormData();
  body.append(file.name, file);
  let request = new XMLHttpRequest();
  request.upload.addEventListener("progress", progressFunc);
  request.onload = () => {
    completeFunc();
  };
  request.onerror = () => {
    completeFunc();
  };
  request.open("post", getBasePath() + "/dropzone/add");
  request.setRequestHeader(
    "Authorization",
    "Bearer " + window.localStorage.getItem("auth")
  );
  request.send(body);
  return request;
}

/**
 * Remove a binary file from the dropzone.
 */
export async function deleteBinary(filename) {
  let body = new FormData();
  body.append("file", filename);
  await fetch(getBasePath() + "/dropzone/remove", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
}

/**
 * Remove a binary file from the dropzone.
 */
export async function deleteAllBinariesInDropzone() {
  await fetch(getBasePath() + "/dropzone/remove_all", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
}

/**
 * Start analyzing the files in the dropzone.
 */
export async function startAnalysis(facility_id) {
  let body = new FormData();
  body.append("facility_id", facility_id);
  let res = await fetch(getBasePath() + "/dropzone/start", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });

  // res will be 403 if the user has exceeded the max number of assets
  // or exceeded the expiration timeout
  if (res.ok) {
    res = await res.json();

    // if the upload exceeds the assessment queue size available
    if (res.status === "failure") {
      return `Only ${res.max_allowed} assets are allowed to be in the queue at once. Currently, there are ${res.in_queue} assets in the queue. You are trying to analyze ${res.your_amount} more.`;
    }
    // otherwise we are ok
    return "OK";
  }
  let text = await res.text();
  return text;
}

export async function getS3Files(
  aws_access_key_id,
  aws_secret_access_key,
  bucket
) {
  let res = await fetch(getBasePath() + "/dropzone/s3/files/list", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      aws_access_key_id,
      aws_secret_access_key,
      bucket,
    }),
  });
  if (!res.ok) {
    throw "Files unavailable.";
  }
  let files = await res.json();
  return files;
}

export async function ingressS3Files(
  aws_access_key_id,
  aws_secret_access_key,
  bucket,
  files
) {
  let res = await fetch(getBasePath() + "/dropzone/s3/files/ingress", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      aws_access_key_id,
      aws_secret_access_key,
      bucket,
      files,
    }),
  });
  if (!res.ok) {
    throw "Ingress failed.";
  }
  res = await res.json();
  return res;
}

export async function previewCPEs(field, vendor, product, version) {
  try {
    let res = await fetch(getBasePath() + "/dropzone/preview/cpes", {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + window.localStorage.getItem("auth"),
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        field,
        vendor,
        product,
        version,
      }),
    });
    return await res.json();
  } catch {
    return [];
  }
}
