import React from "react";
import "../../index.css";
import { genTOTP, sendTOTP } from "../../api";
import QRCode from "qrcode";
import { TextField } from "@mui/material";

// components
import Copyright from "./copyright/copyright";
import LoginHeadbar from "./login_headbar/login_headbar";

/**
 * Page of the application that allows new users setup Google
 * Authenticator on their mobile device. Prompts that the user scan
 * a QRcode.
 * Enforces that the user enters their newly generated Google
 * Authenticator code before continuing to the <EULAPage />
 * component.
 */
export default class SetupGoogleAuth extends React.Component {
  constructor(props) {
    super(props);

    document.title = "BinLens - Login";

    this.state = {
      loading: false,
      pincode: "",
    };

    this.login = this.login.bind(this);
    this.init = this.init.bind(this);
    this.enterClick = this.enterClick.bind(this);
  }

  enterClick(e) {
    if (e.key === "Enter") {
      this.login();
    }
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    let res = await genTOTP();

    // get google auth data
    var canvas = document.getElementById("totp");
    QRCode.toCanvas(canvas, res.qr, function (error) {
      if (error) console.error(error);
    });
  }

  /**
   * Login the user.
   */
  async login() {
    if (this.state.pincode === "") {
      alert("Please enter your 6-digit verification code!");
      return;
    }

    this.setState({ loading: true });
    try {
      await sendTOTP(this.state.pincode);

      this.setState({ loading: false });
      window.location.assign("#/eula");
    } catch (e) {
      this.setState({ loading: false, fail: "Invalid pincode." });
    }
  }

  /**
   * Render the page.
   */
  render() {
    return (
      <div className="login-page">
        <LoginHeadbar />
        <h1>Enable Google Authenticator</h1>

        <div className="login-center">
          <canvas
            id="totp"
            style={{
              height: 164,

              position: "relative",
              marginBottom: 30,
            }}
          ></canvas>

          <TextField
            value={this.state.pincode}
            onKeyDown={this.enterClick}
            onChange={(e) => this.setState({ pincode: e.target.value })}
            label="6-Digit Pincode"
            fullWidth
          />

          <p>
            Please enter your Google Authenticator pincode.
            <br />
            <br />
            To setup Google Authenticator, please follow the steps below:
            <br />
            <br />
            1. Install Google Authenticator on your phone.
            <br />
            2. Open the Google Authenticator app.
            <br />
            3. Tap menu, then tap "Set up account", then tap "Scan a barcode".
            <br />
            4. Your phone will now be in "scanning" mode. When you are in this
            mode, scan the barcode above.
          </p>

          <div className="row">
            {this.state.loading && <div className="lds-dual-ring"></div>}
            {!this.state.loading && <p>{this.state.fail}</p>}

            <button onClick={this.login}>Sign in</button>
          </div>
        </div>

        <Copyright />
      </div>
    );
  }
}
