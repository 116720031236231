import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import "./tri_tip.css";

/**
 * Popup that appears when clicking on a facility on the dashboard
 * facilities map. Displays the following information:
 * 1. Facility name.
 * 2. Facility vulnerability level.
 * 3. Link to view the facility in detail.
 * The position of the TriTip is dynamically calculated, such that
 * the arrow which points to the facility marker is either in the top-left,
 * top-right, bottom-left, or bottom-right of the TriTip.
 */
export default class TriTip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getTransform = this.getTransform.bind(this);
  }

  getTransform() {
    switch (this.props.position) {
      case "BOTTOM-LEFT":
        return "translate(0, -100%)";
      case "BOTTOM-RIGHT":
        return "translate(calc(-100% + 22px), -100%)";
      case "TOP-RIGHT":
        return "translate(calc(-100% + 22px), 20px)";
      case "TOP-LEFT":
        return "translate(0, 20px)";
      default:
        return "";
    }
  }

  render() {
    return (
      <div
        className="cate-tooltip tri-tip"
        style={{
          transform: this.getTransform(),
          top: this.props.top,
          left: this.props.left,
          animationDelay: this.props.delay,
        }}
      >
        <div className="left-row">
          {this.props.light === "RED" && <div className="light-r"></div>}
          {this.props.light === "YELLOW" && <div className="light-y"></div>}
          {this.props.light === "GREEN" && <div className="light-g"></div>}

          {this.props.subtitle && <h3>{this.props.subtitle}</h3>}
        </div>
        <h2>{this.props.title}</h2>
        <h3
          className="fac-see-more"
          onClick={() => {
            window.location.assign(`#/binary?facility_id=${this.props.id}`);
          }}
        >
          View in detail.
        </h3>

        {this.props.position === "BOTTOM-LEFT" && (
          <div className="bot-arrow"></div>
        )}
        {this.props.position === "BOTTOM-LEFT" && (
          <div className="bot-arrow2"></div>
        )}

        {this.props.position === "BOTTOM-RIGHT" && (
          <div className="bot-right-arrow"></div>
        )}
        {this.props.position === "BOTTOM-RIGHT" && (
          <div className="bot-right-arrow2"></div>
        )}

        {this.props.position === "TOP-RIGHT" && (
          <div className="top-right-arrow"></div>
        )}
        {this.props.position === "TOP-RIGHT" && (
          <div className="top-right-arrow2"></div>
        )}

        {this.props.position === "TOP-LEFT" && (
          <div className="top-left-arrow"></div>
        )}
        {this.props.position === "TOP-LEFT" && (
          <div className="top-left-arrow2"></div>
        )}
      </div>
    );
  }
}

TriTip.propTypes = {
  /**
   * The title of the popup.
   * Usually the facility's name.
   */
  title: PropTypes.string.isRequired,

  /**
   * The subtitle of the popup.
   * Usually "SAFE", "CRITICAL", etc...
   */
  subtitle: PropTypes.string.isRequired,

  /**
   * The color of the vuln-level of the popup.
   */
  light: PropTypes.oneOf(["RED", "YELLOW", "GREEN"]).isRequired,

  /**
   * The position of the triangle pointer attached to a corner of the popup.
   */
  position: PropTypes.oneOf([
    "TOP-LEFT",
    "BOTTOM-LEFT",
    "TOP-RIGHT",
    "BOTTOM-RIGHT",
  ]).isRequired,

  /**
   * The delay before the startup animation plays.
   */
  delay: PropTypes.string.isRequired,

  /**
   * The vertical offset of the popup.
   */
  top: PropTypes.number.isRequired,

  /**
   * The horizontal offset of the popup.
   */
  left: PropTypes.number.isRequired,

  /**
   * The id of the facility being clicked.
   */
  id: PropTypes.string.isRequired,
};
