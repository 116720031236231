import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { isHex } from "../../helpers";

// icons
import { Terminal } from "react-feather";

// components
import { getCWEs, getDeepDiveLines, getDeepDiveSize } from "../../api";
import DeepDiveLine from "./deep_dive_line";
import { Asset, Binary } from "../../constants";
import Notifications from "../login_page/notifications";

const LINE_HEIGHT = 15; // the height in px of a single line
const NUM_LINE = 100; // the number of lines rendered on the page
// at any given time

/**
 * Displays a binary in either its decompiled or disassembled form.
 * Specific vulnerabilities are highlighted. That is, if there is
 * a vulnerability on line 5, line 5 is highlighted.
 */
export default class DeepDiveLines extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lines: [
        /*
            {
                vuln: null | {
                    id: "CWE-120",
                    desc: "CWE-120 is a..."
                },
                idx: int,
                content: str,
            }
        */
      ],
      height: 0, // controles the height of the code viewer ... this depends on how
      // many lines are in the file

      loading: true,
    };

    this.first_init = false;

    this.lock = "";
    this.size = 0; // the number of lines in the file we are currently viewing
    this.current_line = 0; // the current line our scroll is centered on
    this.offset = 0; // the offset currently loaded in
    this.oldOffset = -1;
    this.vulns = {};

    this.init = this.init.bind(this);
    this.refresh = this.refresh.bind(this);
    this.scrolling = this.scrolling.bind(this);

    setInterval(this.refresh, 500);
  }

  componentDidUpdate(prevProps) {
    // if this is a different file
    if (
      this.props.asset &&
      this.props.binary &&
      (this.props.asset !== prevProps.asset ||
        this.props.binary !== prevProps.binary)
    ) {
      this.lock = `${this.props.asset}_${this.props.binary}`;
      let lock = `${this.props.asset}_${this.props.binary}`;
      this.size = 0;
      this.current_line = 0;
      this.offset = 0;
      this.oldOffset = -1;
      this.vulns = {};

      this.init().then(() => {
        if (lock !== this.lock) return;
        if (!this.first_init && this.props.loc) {
          setTimeout(() => {
            let line_num = parseInt(this.props.loc);
            let scroll_target = document.getElementById(
              `scroller-${this.props.assessment}`
            );

            scroll_target.scroll(0, LINE_HEIGHT * line_num - 200);

            this.scrolling();
            this.first_init = true;

            this.refresh();
          }, 500);
        }
      });
    } else if (
      this.props.jump_to !== prevProps.jump_to &&
      this.props.jump_to !== ""
    ) {
      let scroll_target = document.getElementById(
        `scroller-${this.props.assessment}`
      );
      if (this.props.assessment === "decompile") {
        let line_num = parseInt(this.props.jump_to);
        if (!isNaN(line_num) && line_num < this.size)
          scroll_target.scroll(0, LINE_HEIGHT * line_num - 200);
        else {
          Notifications._this.addNoLine();
        }
      } else {
        getDeepDiveLines(
          this.props.asset,
          this.props.binary,
          "disassemble",
          this.props.jump_to,
          10,
          true
        ).then((offset) => {
          if (offset > 0) {
            scroll_target.scroll(0, LINE_HEIGHT * offset - 200);
          } else {
            Notifications._this.addNoAddress();
          }
        });
      }
    }
  }

  async init() {
    let lock = `${this.props.asset}_${this.props.binary}`;
    this.setState({ lines: [], height: 0, loading: true });
    let cwes = await getCWEs(this.props.asset, this.props.binary);
    this.vulns = {};
    if (this.props.assessment === "decompile") {
      for (let cwe of cwes) {
        for (let instance of cwe.arr) {
          if (parseInt(instance.loc)) {
            this.vulns[parseInt(instance.loc)] = {
              id: cwe.cwe,
              msg: instance.msg,
            };
          }
        }
      }
    } else if (this.props.assessment === "disassemble") {
      for (let cwe of cwes) {
        for (let instance of cwe.arr) {
          if (instance.loc.length === 8 && isHex(instance.loc)) {
            this.vulns["0x" + instance.loc] = {
              id: cwe.cwe,
              msg: instance.msg,
            };
          } else if (
            instance.loc.length - 2 === 8 &&
            instance.loc.substr(0, 2) === "0x" &&
            isHex(instance.loc.substr(2, instance.loc.length))
          ) {
            this.vulns[instance.loc] = {
              id: cwe.cwe,
              msg: instance.msg,
            };
          }
        }
      }
    }

    if (!this.props.asset || !this.props.binary) return;
    this.size = await getDeepDiveSize(
      this.props.asset,
      this.props.binary,
      this.props.assessment
    );
    let new_height = this.size * LINE_HEIGHT;

    if (lock === this.lock) this.setState({ height: new_height });

    // clamp our scroll height to a position within [0, this.state.size]...
    let scroll_target = document.getElementById(
      `scroller-${this.props.assessment}`
    );
    this.current_line = Math.floor(
      (scroll_target.scrollTop / new_height) * this.size
    );

    // then get the lines based of off that
    this.offset = 0;
    this.refresh();
  }

  async refresh() {
    if (this.oldOffset === this.offset) return;
    if (!this.props.asset || !this.props.binary) return;
    let lock = `${this.props.asset}_${this.props.binary}`;

    this.oldOffset = this.offset;

    let u_lines = await getDeepDiveLines(
      this.props.asset,
      this.props.binary,
      this.props.assessment,
      this.oldOffset,
      NUM_LINE
    );

    // get the set of associated vulnerabilities
    let lines = [];
    let i = this.oldOffset;
    for (let line of u_lines) {
      // replace references top r2ghidra
      if (this.props.assessment === "decompile") {
        line = line.replace("[r2ghidra]", "");
        line = line.replace("r2ghidra", "");
      }
      let vuln = null;
      if (this.vulns.hasOwnProperty(i + 2)) vuln = this.vulns[i + 2];
      try {
        let first_x = line.indexOf("x");
        let hex = line.substr(first_x - 1, 10);
        if (this.vulns.hasOwnProperty(hex)) vuln = this.vulns[hex];
      } catch (e) {
        continue;
      }

      lines.push({
        vuln,
        idx: i + 1,
        content: line,
      });
      i++;
    }
    if (lock === this.lock) this.setState({ lines, loading: false });
  }

  scrolling() {
    let scroll_target = document.getElementById(
      `scroller-${this.props.assessment}`
    );
    this.current_line = Math.floor(
      (scroll_target.scrollTop / this.state.height) * this.size
    );
    this.offset = this.current_line - NUM_LINE / 4;
    if (this.offset < 0) this.offset = 0;
  }

  render() {
    return (
      <div
        className="syntax"
        style={{
          overflow: "scroll",
        }}
        id={`scroller-${this.props.assessment}`}
        onScroll={this.scrolling}
      >
        {!this.props.asset && !this.props.binary && (
          <div
            style={{
              position: "relative",
              height: "calc(100vh - 220px)",
            }}
          >
            <h3 style={{ marginTop: 20, marginLeft: 20 }}>
              Select a binary to view in the{" "}
              {this.props.assessment === "decompile"
                ? "decompiler"
                : "disassembler"}{" "}
              by clicking on the <b>+</b> icon.
            </h3>
            <Terminal className="big-icon" />
          </div>
        )}

        {this.state.loading && (
          <div
            style={{ marginTop: 30 }}
            className="lds-dual-ring lds-dual-ring-center"
          ></div>
        )}

        {!this.state.loading &&
          this.props.binary &&
          this.state.lines.length > 0 && (
            <div
              style={{
                maxHeight: this.state.height,
                height: this.state.height,
                minHeight: this.state.height,
                overflow: "hidden",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top:
                    this.state.lines.length > 0
                      ? this.state.lines[0].idx * LINE_HEIGHT
                      : 0,
                }}
              >
                <SyntaxHighlighter
                  wrapLines={true}
                  wrapLongLines={true}
                  lineProps={{ style: { paddingBottom: 50 } }}
                  language={
                    this.props.assessment === "disassemble" ? "x86asm" : "c"
                  }
                  style={dark}
                >
                  {this.state.lines
                    .map((line) => {
                      return line.idx + "\t\t" + line.content;
                    })
                    .join("")}
                </SyntaxHighlighter>
              </div>
              {this.state.lines.map((line) => {
                return (
                  <DeepDiveLine
                    asset={this.props.asset}
                    bin={this.props.binary}
                    assessment={this.props.assessment}
                    line={line}
                    jump_to_other={this.props.jump_to_other}
                  />
                );
              })}
            </div>
          )}
        {!this.state.loading &&
          this.props.is_assessing &&
          this.props.binary &&
          this.state.lines.length <= 0 && (
            <div
              className="unpaintable"
              style={{
                position: "relative !important",
                display: "block",
                marginTop: 0,
                maxWidth: "100%",
                height: "calc(100vh - 220px)",
              }}
            >
              <div className="lds-dual-ring lds-dual-ring-center"></div>
              <h3>
                This binary is still under assessment. This tab will produce
                results later on. Other tabs may already have partial results.
              </h3>
            </div>
          )}
        {!this.state.loading &&
          !this.props.is_assessing &&
          this.props.binary &&
          this.state.lines.length <= 0 && (
            <div
              className="unpaintable"
              style={{
                position: "relative !important",
                display: "block",
                marginTop: 0,
                maxWidth: "100%",
                height: "calc(100vh - 220px)",
              }}
            >
              <h3>
                {this.props.assessment === "disassemble"
                  ? "Disassembled"
                  : "Decompiled"}{" "}
                results were unable to be produced for this binary.
                <br />
                <a
                  target="_blank"
                  href="https://objectsecurity.com/otai/contact-us/"
                >
                  Submit a support ticket.
                </a>
              </h3>
            </div>
          )}
      </div>
    );
  }
}

DeepDiveLines.propTypes = {
  /**
   * The parent asset of the binary being viewed.
   */
  asset: PropTypes.instanceOf(Asset).isRequired,

  /**
   * The binary being viewed.
   */
  binary: PropTypes.instanceOf(Binary).isRequired,

  /**
   * The type of assessment being displayed.
   */
  assessment: PropTypes.oneOf(["disassemble, decompile"]).isRequired,
};
