import "../report_builder_sidebar.css";

export default function ReportBuilderSidebar({
  loading,
  assets,
  setItems,
  items,
}) {

  function addItem(item) {
    if (items.filter((i) => i.id === item.id).length > 0) return;
    setItems((items) => [...items, item]);
    let scroller = document.getElementById("fake-report-document-scroller");
    scroller.scrollTop = scroller.scrollHeight;
  }

  return (
    <div className="report-builder-sidebar">
      <h2>Assets</h2>
      <div className="report-builder-sidebar-scroller">
        {assets &&
          assets.map((asset) => {
            if (!asset.state || asset.state !== "completed") return null;
            return (
              <div
                className={
                  items.filter((item) => item.id === asset.ref_id).length > 0
                    ? "report-builder-sidebar-item report-builder-sidebar-item-unsel"
                    : "report-builder-sidebar-item"
                }
                onClick={() => {
                  addItem({
                    title: asset.name,
                    id: asset.ref_id,
                    severity: asset.cwss_score,
                  });
                }}
              >
                <h2>{asset.name}</h2>

                <div className="cause-field">
                  Vulnerability Score: {asset.cwss_score}
                </div>
              </div>
            );
          })}

        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <div className="lds-dual-ring"></div>
          </div>
        )}
      </div>
    </div>
  );
}
