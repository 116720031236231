import { getBasePath } from ".";

/**
 * Get a list of binaries in a DUT.
 * Helper function when getting all past uploads.
 */
export async function getBinaries(dutId) {
  let body = new FormData();
  body.append("asset", dutId);
  let res = await fetch(getBasePath() + "/binary/get", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  let parsed = await res.json();
  return parsed;
}

export async function getBinariesByName(
  facility_id,
  sortingByVulnerability,
  offset = null,
  limit = null,
  search = null,
  asset_type = null
) {
  let params = new URLSearchParams();

  if (sortingByVulnerability) params.append("sortby", "score");
  else params.append("sortby", "date");
  if (limit !== null) params.append("limit", limit);
  if (offset !== null) params.append("offset", offset);
  if (search) params.append("search", search);
  if (asset_type) params.append("asset_type", asset_type);

  let res = await fetch(
    getBasePath() + `/binary/facility/${facility_id}/by_name?` + params,
    {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + window.localStorage.getItem("auth"),
      }),
    }
  );
  if (!res.ok) return false;
  let allBinaries = await res.json();
  if (allBinaries.length === 0) return false;
  return allBinaries;
}

export async function getBinariesByCWE(
  facility_id,
  sortingByVulnerability,
  offset = null,
  limit = null,
  search = null,
  asset_type = null
) {
  let params = new URLSearchParams();

  if (sortingByVulnerability) params.append("sortby", "score");
  else params.append("sortby", "date");
  if (limit !== null) params.append("limit", limit);
  if (offset !== null) params.append("offset", offset);
  if (search) params.append("search", search);
  if (asset_type) params.append("asset_type", asset_type);

  let res = await fetch(
    getBasePath() + `/binary/facility/${facility_id}/by_cwe?` + params,
    {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + window.localStorage.getItem("auth"),
      }),
    }
  );
  if (!res.ok) return false;
  let allBinaries = await res.json();
  if (allBinaries.length === 0) return false;
  return allBinaries;
}
