import { useState, useEffect } from "react";
import { updateAssetDescription } from "../../../api";
import { getAssetIcon, getAssetTypeReadable } from "../../../asset_types";
import TextareaAutosize from "react-textarea-autosize";

export default function AssetDescription({ asset, updateDescription }) {
  let [description, setDescription] = useState(asset.description);

  useEffect(() => {
    setDescription(asset.description ? asset.description : "");
  }, [asset]);

  return (
    <div className="asset-description-component">
      <div style={{ marginBottom: 30 }}>
        <h3
          style={{
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
            marginBottom: 10,
            position: "relative",
            fontSize: 16,
            color: "var(--pri-color)",
          }}
        >
          {asset.type}
        </h3>
        <p
          style={{
            textAlign: "left",
            fontSize: 15,
            lineHeight: 1.5,
            color: "var(--pri-color-light)",
          }}
        >
          This asset represents a {getAssetTypeReadable(asset.type)}.
        </p>
      </div>
      <hr style={{ width: "100%" }} />
      <h2
        style={{
          fontSize: 16,
          color: "var(--pri-color)",
          marginBottom: 10,
          marginTop: 30,
        }}
      >
        Description
      </h2>
      <TextareaAutosize
        style={{
          textAlign: "left",
          whiteSpace: "pre-wrap",
          width: "100%",
          fontFamily: `"Mulish", sans-serif`,
          fontSize: 15,
          outline: "none !important",
          background: "var(--bg-color)",
        }}
        value={description}
        onChange={(e) => {
          if (!updateDescription) return;
          setDescription(e.target.value);
        }}
        onBlur={() => {
          if (!updateDescription) return;
          updateAssetDescription(asset.ref_id, description);
          updateDescription(description);
        }}
        placeholder="Add a description to the asset..."
      />
    </div>
  );
}
