import "./index.css";
import { DeltaReportsPage } from "..";
import { useEffect, useState } from "react";
import { deleteDeltaReport, getAllDeltaReports } from "../../../api";
import { Hexagon, MoreVertical, Plus, Search } from "react-feather";

export default function AllDeltaReportsPage() {
  return (
    <DeltaReportsPage>
      <AllDeltaReports />
    </DeltaReportsPage>
  );
}

function AllDeltaReports() {
  let [reports, setReports] = useState([]);
  let [searchText, setSearchText] = useState("");

  const init = async () => {
    let reports = await getAllDeltaReports();
    setReports(reports);
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <div className="all-delta-reports">
      <div className="all-delta-reports-header">
        <div className="delta-report-searchbar">
          <Search className="icon" />
          <input
            value={searchText}
            placeholder="Search delta reports..."
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <button
          onClick={() => {
            window.location.assign("#/delta/generate");
          }}
        >
          <Plus className="icon" />
          New
        </button>
      </div>
      <div className="binary-delta-reports">
        {reports
          .filter((report) => {
            let s = searchText.toLowerCase();
            return (
              report.asset_name_a.toLowerCase().includes(s) ||
              report.asset_name_b.toLowerCase().includes(s)
            );
          })
          .map((report) => (
            <BinaryDeltaReport report={report} />
          ))}
        {reports.length === 0 && (
          <div className="no-delta-reports">
            <Hexagon className="icon" />
            <h3>
              No delta reports yet. <br />
              Click <i>+ New</i> to generate one.
            </h3>
          </div>
        )}
      </div>
    </div>
  );
}

function BinaryDeltaReport({ report }) {
  const color =
    report.status === "COMPLETE" ? "var(--green-color)" : "var(--yellow-color)";
  return (
    <div
      className="binary-delta-report"
      onClick={() => {
        window.location.assign(`#/delta/${report.id}`);
      }}
    >
      <BinaryDeltaReportOptions report={report} />
      <h2>
        Asset {report.asset_name_a} &rarr; Asset {report.asset_name_b}
      </h2>
      <h3>{report.date}</h3>
      <div
        style={{
          color,
          borderColor: color,
        }}
        className="delta-report-status"
      >
        {report.status}
      </div>
    </div>
  );
}

function BinaryDeltaReportOptions({ report }) {
  let [open, setOpen] = useState(false);

  const close = () => setOpen(false);
  useEffect(() => {
    window.addEventListener("click", close);
    return () => {
      window.removeEventListener("click", close);
    };
  }, []);

  return (
    <div className="binary-delta-report-options">
      <MoreVertical
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
        className="icon"
      />
      {open && (
        <div className="binary-delta-report-options-list">
          <div
            onClick={async (e) => {
              e.stopPropagation();
              await deleteDeltaReport(report.id);
              window.location.reload();
            }}
          >
            Delete
          </div>
        </div>
      )}
    </div>
  );
}
