/*
    This component diplays the total assessment progress for a give binary 
    that is currently under analysis.

    It displays an estimated time of completion and number of assessments 
    completed out of the total.

    Its order in relation to ther <AnalysisQueue_Binary /> components is 
    determined based on the set of associated assessments. This component 
    contains a delete button which deletes the binary and all
    associated assessments from the queue.
*/
import { deleteBinaryFile } from "../../../api";

// icons
import { CheckCircle, X } from "react-feather";

/**
 * Displays a doughnut progress indicator next to each binary.
 */
function BinaryProgressDoughnut({ progress }) {
  const stroke = 3;
  const radius = 22;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;

  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <svg
      class="binary-progress-doughnut"
      width={radius * 2}
      height={radius * 2}
    >
      <circle
        class="binary-progress-doughnut-circle-bg"
        stroke="var(--bor-color)"
        stroke-width={stroke + 2}
        fill="transparent"
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        stroke-dasharray={`${circumference} ${circumference}`}
      />
      <circle
        class="binary-progress-doughnut-circle"
        stroke="var(--sec-color)"
        stroke-width={stroke}
        fill="transparent"
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        stroke-dasharray={`${circumference} ${circumference}`}
        style={{
          strokeDashoffset,
        }}
      />
    </svg>
  );
}

export default function AnalysisQueue_Binary({
  binary,
  org_id,
  asset_id,
  refresh,
}) {
  let progress = binary.progress * 100;
  return (
    <div
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="analysis-queue-binary"
    >
      <div
        className="bin-prog-row"
        style={{ borderLeft: "2px solid var(--sec-bg-color)" }}
      >
        <div>
          <h2
            style={{
              maxWidth: 220,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {binary.bin_id}
          </h2>
          <h3>{Math.round(progress * 100) / 100}% Complete</h3>
        </div>

        {progress < 100 && (
          <div className="bin-prog-row-circ">
            <BinaryProgressDoughnut progress={progress} />
          </div>
        )}
        {progress >= 100 && (
          <div className="bin-prog-row-circ">
            <CheckCircle
              style={{
                height: 43,
                width: 43,

                stroke: "var(--green-color)",

                marginTop: 10,
                marginLeft: 3,
              }}
            />
          </div>
        )}
        <X
          className="delete-assessment-button"
          onClick={async () => {
            if (
              window.confirm(
                "Are you sure you want to stop assessing this binary? Binary assessment results that have been produced will still be available for viewing on the Binary Analysis Page."
              )
            ) {
              await deleteBinaryFile(org_id, asset_id, binary.bin_id);
              refresh();
            }
          }}
        />
      </div>
    </div>
  );
}
