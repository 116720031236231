import PropTypes from "prop-types";
import "./mappings.css";
import { genSevBgColor } from "../../../helpers";

// components
import DiscoveryLocation from "./discovery_location";
import { Binary } from "../../../constants";

/**
 * An individual instance of an OWASP mapping.
 * Contains the originating CWE, the mapping line-items,
 * and deep dive discovery locations (if applicable).
 */
export function OWASP_Mapping({
  asset_id,
  binary,
  cwe,
  cwe_desc,
  static_support,
  dynamic_support,
  severity,
}) {
  return (
    <div className="mapping">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginRight: 30,
        }}
      >
        <div
          style={{
            background: genSevBgColor(severity.split(" ")[0]),
          }}
          className="mapping-severity"
        >
          {severity.split(" ")[0]}
        </div>
        <h2
          style={{
            textAlign: "center",
            marginTop: 10,
            fontWeight: 500,
            fontSize: 12,
            width: "fit-content",
            wordBreak: "break-word",
          }}
        >
          {severity.split(" - ")[1]}
        </h2>
      </div>

      <div style={{ width: "100%" }}>
        <h3>Discovered Vulnerability</h3>
        <h2>
          {cwe.cwe} &bull; {cwe_desc}
        </h2>
        <h3>Static Support</h3>
        <div className="mapping-field">{static_support ? "True" : "False"}</div>
        <h3>Dynamic Support</h3>
        <div className="mapping-field">
          {dynamic_support ? "True" : "False"}
        </div>
        <DiscoveryLocation
          asset_id={asset_id}
          binary={binary}
          locations={cwe.arr}
        />
      </div>
    </div>
  );
}

OWASP_Mapping.propTypes = {
  /**
   * The ID of the asset being mapped.
   */
  asset_id: PropTypes.string.isRequired,

  /**
   * The binary being mapped.
   */
  binary: PropTypes.instanceOf(Binary).isRequired,

  /**
   * The CWE being mapped.
   */
  cwe: PropTypes.object.isRequired,

  /**
   * A long for description of the CWE being mapped.
   */
  cwe_desc: PropTypes.string.isRequired,

  /**
   * Is OWASP Static Support true?
   */
  static_support: PropTypes.bool.isRequired,

  /**
   * Is OWASP Dynamic Support true?
   */
  dynamic_support: PropTypes.bool.isRequired,

  /**
   * Severity (1-5) of the OWASP line-item.
   */
  severity: PropTypes.number.isRequired,
};
