import React from "react";
import "../../index.css";
import "./big_drop.css";

// components
import Dropzone from "react-dropzone";
import Sidebar from "./sidebar";

/**
 * Component that exists on most application pages that allows a user
 * to drop files for upload by dragging then over anywhere on the
 * screen.
 * Dropped files are added to the user's dropzone, and the New Analysis
 * Modal is displayed.
 */
export default class BigDrop extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dragging: false,
    };

    this.dropFiles = this.dropFiles.bind(this);
  }

  async dropFiles(acceptedFiles) {
    this.setState({ dragging: false });
    Sidebar._this.setState({
      modal: true,
      hovering: false,
      acceptedFiles,
    });
  }

  render() {
    return (
      <Dropzone
        onDragEnter={() => this.setState({ dragging: true })}
        onDragLeave={() => this.setState({ dragging: false })}
        onDrop={this.dropFiles}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <div className={"big-drop"} {...getRootProps()}>
              {this.state.dragging && (
                <div className="big-drop-hovered">
                  <h1>Drag and drop a binaries for analysis.</h1>
                </div>
              )}
              {this.props.children}
            </div>
          );
        }}
      </Dropzone>
    );
  }
}
