import PropTypes from "prop-types";
import { COLORS_STRING } from "../../../colors";
import { calcColorString } from "../../../helpers";
import { Radar } from "react-chartjs-2";
import { Binary } from "../../../constants";

/**
 * Radar chart that appears on the Binary Summary Panel,
 * and on the Custom Report page.
 * Displays vulnerability score information about the binary
 * including Weakness Score, Attack Score, Impact Score, etc.
 */
export default function BinarySummaryChart({ binary }) {
  if (binary.cwss <= 0)
    return (
      <div
        style={{
          border: "2px solid var(--sec-bg-color)",
          borderRadius: 10,
          padding: 20,
          marginBottom: 30,
        }}
      >
        <h3>No CWEs were discovered.</h3>
      </div>
    );
  return (
    <div
      style={{
        border: "2px solid var(--sec-bg-color)",
        borderRadius: 10,
        padding: 20,
        marginBottom: 30,
      }}
    >
      <div className="left-row" style={{ alignItems: "flex-start" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div className="chartt" style={{ alignSelf: "center" }}>
            <div
              className="CWSS-chart-holder CWSS-chart-holder-prev"
              style={{
                height: binary.cvss ? 400 : 360,
                width: 400,
              }}
            >
              <Radar
                id="radar-canvas"
                data={
                  binary.cvss
                    ? {
                        labels: [
                          [
                            `${Number(parseFloat(binary.cwss).toFixed(2))} / 4`,
                            `Weakness`,
                          ],
                          [
                            `${Number(
                              parseFloat(binary.attack).toFixed(2)
                            )} / 1`,
                            `Attack`,
                          ],
                          [
                            `${Number(
                              (binary.environment * 10).toFixed(2)
                            )} / 1 `,
                            `Environment`,
                          ],
                          [`${binary.cvss} / 10`, `Vulnerability`],
                          [`${binary.exploitability} / 10`, `Exploitability`],
                          [`${binary.impact} / 10`, `Impact`],
                        ],
                        datasets: [
                          {
                            borderColor: calcColorString(binary.aggregate),
                            pointBackgroundColor: calcColorString(
                              binary.aggregate
                            ),
                            pointRadius: 0,
                            fill: true,
                            backgroundColor: calcColorString(binary.aggregate),

                            data: [
                              binary.cwss / 4,
                              binary.attack / 1,
                              binary.environment / 0.1,
                              binary.cvss / 10,
                              binary.exploitability / 10,
                              binary.impact / 10,
                            ],
                          },
                        ],
                      }
                    : {
                        labels: [
                          [
                            `${Number(parseFloat(binary.cwss).toFixed(2))} / 4`,
                            `Weakness`,
                          ],
                          [
                            `${Number(
                              parseFloat(binary.attack).toFixed(2)
                            )} / 1`,
                            `Attack`,
                          ],
                          [
                            `${Number(
                              (binary.environment * 10).toFixed(2)
                            )} / 1 `,
                            `Environment`,
                          ],
                        ],
                        datasets: [
                          {
                            fill: true,
                            borderColor: calcColorString(binary.aggregate),
                            pointBackgroundColor: calcColorString(
                              binary.aggregate
                            ),
                            pointRadius: 0,
                            backgroundColor: calcColorString(binary.aggregate),
                            data: [
                              binary.cwss / 4,
                              binary.attack / 1,
                              binary.environment / 0.1,
                            ],
                          },
                        ],
                      }
                }
                options={{
                  responsive: true,
                  lineTension: 0.4,
                  scale: {
                    min: 0,
                    max: 1,

                    ticks: {
                      backdropColor: "rgba(0, 0, 0, 0)",
                      maxTicksLimit: 6,
                    },
                  },
                  scales: {
                    r: {
                      pointLabels: {
                        font: {
                          size: 12,
                        },
                      },
                      ticks: {
                        display: false,
                      },
                      grid: {
                        color: COLORS_STRING.BOR,
                      },
                      angleLines: {
                        color: COLORS_STRING.BOR,
                      },
                    },
                  },

                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

BinarySummaryChart.propTypes = {
  /**
   * The binary to display the chart for.
   */
  binary: PropTypes.instanceOf(Binary).isRequired,
};
