/*
  Helper functions that control the color palette of the application:

  --bg-color: #ffffff; 
  --sec-bg-color: #f5f8fa; 
  --pri-color: #0f1419; 
  --pri-color-light: #7a8791; 
  --bor-color: #cfd9de; 
  --sec-color: #005596; 

  dark mode
  --bg-color: #15202b;
  --sec-bg-color: #192734;
  --pri-color: #ffffff;
  --pri-color-light: rgb(136, 153, 166);
  --bor-color: #38444D;
  --sec-color: #4287f5; 
*/

export const COLORS = {
  RED: 0xeb4034,
  YELLOW: 0xfcba03,
  GREEN: 0x32a852,
  BLUE: 0x4287f5,
  PRI: 0xffffff,
  PRI_LIGHT: 0x8899a6,
};

export const COLORS_STRING = {
  RED: "#eb4034",
  YELLOW: "#fcba03",
  GREEN: "#32a852",
  BLUE: "#4287f5",
  BG: "#15202b",
  SEC_BG: "#192734",
  PRI: "rgb(136, 153, 166)",
  PRI_LIGHT: "rgb(136, 153, 166)",
  BOR: "#38444D",
};
