import "./binary_overview.css";

export default function BinaryOverview({
  nRed,
  nYellow,
  nGreen,
  fromDashboard,
}) {
  let red_text = "Critical";
  let yellow_text = "Vulnerable";
  let green_text = "Safe";
  if (!fromDashboard) {
    red_text += " Binaries";
    yellow_text += " Binaries";
    green_text += " Binaries";
  }
  let binary_overview = (
    <div className="binary-overview">
      <div className="overview-binaries-red">
        <h2>{nRed}</h2>
        <h3>{red_text}</h3>
      </div>
      <div className="overview-binaries-yellow">
        <h2>{nYellow}</h2>
        <h3>{yellow_text}</h3>
      </div>
      <div className="overview-binaries-green">
        <h2>{nGreen}</h2>
        <h3>{green_text}</h3>
      </div>
    </div>
  );
  if (fromDashboard) {
    return binary_overview;
  }
  return <div className="binary-overview-wrapper">{binary_overview}</div>;
}
