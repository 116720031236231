import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";
import { geoPath } from "d3-geo";

/**
 * The map that appears on the <CreateFacilityModal /> component.
 * Enables users to select a point on the map where their facility is
 * located.
 */
const MapChart = ({ latLng, onMapClick }) => {
  const [scale, setScale] = useState(1);

  return (
    <ComposableMap projection="geoMercator">
      <ZoomableGroup zoom={1} onMoveEnd={({ zoom }) => setScale(zoom)}>
        <Geographies geography={"./map_data.json"}>
          {({ geographies, projection }) =>
            geographies.map((geo) => {
              const onGeoEventFactory = (handleCoordinates) => {
                const gPath = geoPath().projection(projection);

                return (evt) => {
                  const dim = evt.target.getBoundingClientRect();
                  const cx = evt.clientX - dim.left;
                  const cy = evt.clientY - dim.top;

                  const [geoX, geoY] = gPath.bounds(geo)[0];

                  //we need root SVG element of our map
                  function composedPath(el) {
                    var path = [];

                    while (el) {
                      path.push(el);

                      if (el.tagName === "HTML") {
                        path.push(document);
                        path.push(window);

                        return path;
                      }

                      el = el.parentElement;
                    }
                  }
                  var path =
                    evt.nativeEvent.path ||
                    (evt.nativeEvent.composedPath &&
                      evt.nativeEvent.composedPath()) ||
                    composedPath(evt.nativeEvent.target);
                  const svg = path[4];

                  //adjust for SVG width on the page / internal rendering width
                  const adjustScale =
                    scale * (svg.clientWidth / svg.viewBox.baseVal.width);

                  // these are the coords in SVG coordinate system
                  const clickCoordsInsideSvg = [
                    geoX + cx / adjustScale,
                    geoY + cy / adjustScale,
                  ];

                  // 'unproject' the SVG coords to get lat and long
                  handleCoordinates(projection.invert(clickCoordsInsideSvg));
                };
              };

              return (
                <Geography
                  style={{
                    default: { outline: "none" },
                    hover: { outline: "none" },
                    pressed: { outline: "none" },
                  }}
                  key={geo.rsmKey}
                  geography={geo}
                  fill="var(--sec-bg-color)"
                  stroke="var(--bor-color)"
                  onClick={onGeoEventFactory((coordinates) => {
                    onMapClick(coordinates);
                  })}
                />
              );
            })
          }
        </Geographies>
        {latLng && (
          <Marker coordinates={latLng}>
            <circle
              style={{ cursor: "pointer" }}
              r={5}
              fill={"var(--sec-color)"}
              filter={"drop-shadow( 0px 0px 5px var(--sec-color))"}
            />
          </Marker>
        )}
      </ZoomableGroup>
    </ComposableMap>
  );
};

MapChart.propTypes = {
  /**
   * The location of the facility on the map.
   */
  latLng: PropTypes.arrayOf(PropTypes.number).isRequired,

  /**
   * Callback that sets latLng when the user clicks on the map.
   */
  onMapClick: PropTypes.func.isRequired,
}

export default MapChart;