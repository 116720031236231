/**
 * Used by the OpenAPI Page to transform Swagger data into a format
 * renderable by the page.
 */
export default class DataTransformer {
  constructor(routes) {
    this.schemas = routes.components.schemas;
    this.routes = routes.paths;
  }

  /**
   * Recursively generate the schema of a given request/response.
   */
  getSchema(schema_name) {
    //this.schemas;
    let schema_data = this.schemas[schema_name];

    let body = {};
    for (let prop in schema_data.properties) {
      let data = schema_data.properties[prop];
      if (data.hasOwnProperty("type") && data.type === "array") {
        if (data.items.hasOwnProperty("type")) {
          body[prop] = [data.items.type];
        } else {
          body[prop] = [
            JSON.parse(this.getSchema(data.items["$ref"].split("/")[3])),
          ];
        }
      } else if (data.hasOwnProperty("$ref")) {
        body[prop] = JSON.parse(this.getSchema(data["$ref"].split("/")[3]));
      } else {
        body[prop] = data.type;
      }
    }

    return JSON.stringify(body, null, 2);
  }

  /**
   * Transforms swaggerfile data into a format usable by the OpenAPI page.
   */
  transform_data() {
    var DATA = [];
    for (let route in this.routes) {
      let route_data = this.routes[route];
      let formatted_data = {};
      formatted_data.route = route;

      // all routes are either GET or POST for now
      if (route_data.hasOwnProperty("post")) {
        formatted_data.method = "POST";
        route_data = route_data.post;
      } else if (route_data.hasOwnProperty("get")) {
        formatted_data.method = "GET";
        route_data = route_data.get;
      } else {
        continue;
      }

      formatted_data.desc = route_data.summary;
      formatted_data.descExt = route_data.description;

      // request information
      formatted_data.parameters = [];
      if (route_data.hasOwnProperty("requestBody")) {
        let content = route_data.requestBody.content;
        if (content.hasOwnProperty("application/json")) {
          formatted_data.parameterType = "JSON Body";
          let schema = content["application/json"].schema;
          let value = {};
          for (let key in schema.properties) {
            value[key] = schema.properties[key].type;
          }
          formatted_data.parameters.push({
            name: "body",
            value: JSON.stringify(value, null, 2),
            type: "object",
            options: null,
          });
        } else if (content.hasOwnProperty("multipart/form-data")) {
          formatted_data.parameterType = "Form Data";
          let schema = content["multipart/form-data"].schema;
          for (let name in schema.properties) {
            formatted_data.parameters.push({
              name: name,
              value: schema.properties[name].hasOwnProperty("enum")
                ? schema.properties[name].enum[0]
                : schema.properties[name].type === "file"
                ? null
                : "",
              type: schema.properties[name].type,
              options: schema.properties[name].hasOwnProperty("enum")
                ? schema.properties[name].enum
                : null,
            });
          }
        }
      } else if (route_data.hasOwnProperty("parameters")) {
        formatted_data.parameterType = "GET Parameters";
        for (let param of route_data.parameters) {
          formatted_data.parameters.push({
            name: param.name,
            value: param.schema.hasOwnProperty("enum")
              ? param.schema.enum[0]
              : "",
            type: param.schema.type,
            options: param.schema.hasOwnProperty("enum")
              ? param.schema.enum
              : null,
            inPath: param.in === "path",
          });
        }
      }

      // response information
      formatted_data.exResponses = [];
      for (let code in route_data.responses) {
        let res = route_data.responses[code];

        let value = {};
        if (res.hasOwnProperty("description")) {
          value = res.description;
        } else if (
          res.content["application/json"].schema.hasOwnProperty("type") &&
          res.content["application/json"].schema.type === "array"
        ) {
          if (
            res.content["application/json"].schema.items.hasOwnProperty("type")
          ) {
            value = JSON.stringify([
              res.content["application/json"].schema.items.type,
            ]);
          } else {
            value = JSON.stringify(
              [
                JSON.parse(
                  this.getSchema(
                    res.content["application/json"].schema.items["$ref"].split(
                      "/"
                    )[3]
                  )
                ),
              ],
              null,
              2
            );
          }
        } else {
          value = this.getSchema(
            res.content["application/json"].schema.$ref.split("/")[3]
          );
        }

        formatted_data.exResponses.push({
          code,
          value,
        });
      }

      DATA.push(formatted_data);
    }
    return DATA;
  }
}
