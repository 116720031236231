import { useEffect, useState } from "react";
import { ASSET_REPORT_RESULT_TYPES, fetchAssetItems } from "./helpers";

// components
import RightHandSidebar from "../../../sidebar/right_hand_sidebar";
import Notifications from "../../../login_page/notifications";
import BigDrop from "../../../sidebar/big_drop";
import Headbar from "../../../headbar/headbar";
import Botbar from "../../../botbar/botbar";
import Sidebar from "../../../sidebar/sidebar";
import ReportBuilderSidebar from "./report_builder_sidebar";
import ReportBuilderPreview from "./report_builder_preview";

/**
 * Customizable report PDF component that lets users alter what
 * content is display / exported in a PDF report.
 */
export default function ReportBuilder_Asset() {
  // parse GET parameters
  const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
  let asset_id = urlParams.get("id");

  let [sidebarLoading, setSidebarLoading] = useState(true);

  let [items, setItems] = useState([]);
  let [asset, setAsset] = useState(null);
  let [binaries, setBinaries] = useState([]);
  let [audit, setAudit] = useState([]);

  /**
   * Fetch the data necessary to build the report from the API.
   */
  async function init() {
    const fetchDataCallback = (type, data) => {
      switch (type) {
        case ASSET_REPORT_RESULT_TYPES.ASSET:
          setAsset(data);
          return;
        case ASSET_REPORT_RESULT_TYPES.BINARIES:
          setBinaries(data);
          return;
        case ASSET_REPORT_RESULT_TYPES.AUDIT:
          setAudit(data);
          return;
        default:
          return;
      }
    };

    await fetchAssetItems(asset_id, fetchDataCallback);
    setSidebarLoading(false);
  }
  useEffect(() => {
    init();
  }, []);

  return (
    <BigDrop>
      <main>
        {/* Header */}
        <Notifications />
        <Headbar />
        <Botbar />

        {/* Get Started */}
        <div className="main-row2">
          <Sidebar page="reports" />
          <ReportBuilderPreview
            items={items}
            setItems={setItems}
            asset_id={asset_id}
            asset={asset}
            binaries={binaries}
            audit={audit}
          />
          <ReportBuilderSidebar
            loading={sidebarLoading}
            items={items}
            setItems={setItems}
            binaries={binaries}
          />
          <RightHandSidebar />
        </div>
      </main>
    </BigDrop>
  );
}
