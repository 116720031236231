import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import { TopRisk } from "../../constants";

// components
import TopRiskComponent from "./top_risk";

/**
 * A set of the most pressing vulnerabilities in a given organization.
 * Currently, this is broken into two sections:
 * 1. High Alerts: This consists of malware and CVEs which are in the
 *    'critical CVEs' list.
 * 2. Top Risks: These consist of CVEs which are not in the 'critical
 *    CVEs' list.
 * The 'critical CVEs' list is a set of CVEs which are considered most
 * dangerous at the current time.
 */
export default class TopRisks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        {this.props.highAlerts.length > 0 && (
          <h2
            style={{
              fontWeight: "700",
              fontSize: 16,
            }}
          >
            High Alerts
          </h2>
        )}

        {this.props.highAlerts.map((tr, i) => {
          return <TopRiskComponent key={i} top_risk={tr} highAlert />;
        })}

        {!this.props.topRisksLoading && (
          <h2
            style={{
              fontWeight: "700",
              fontSize: 16,
              marginTop: 20,
            }}
          >
            Top Risks
          </h2>
        )}

        {this.props.topRisksLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="lds-dual-ring"></div>
          </div>
        )}

        {this.props.topRisks.map((tr, i) => {
          return <TopRiskComponent key={i} top_risk={tr} />;
        })}

        {!this.props.topRisksLoading && this.props.topRisks.length === 0 && (
          <h3
            style={{
              marginTop: 10,
            }}
          >
            There are currently no top risks in this organization.
          </h3>
        )}
      </div>
    );
  }
}

TopRisks.propTypes = {
  /**
   * The set of top risks to display.
   */
  topRisks: PropTypes.arrayOf(PropTypes.instanceOf(TopRisk)).isRequired,

  /**
   * The set of high alerts to display.
   */
  highAlerts: PropTypes.arrayOf(PropTypes.instanceOf(TopRisk)).isRequired,

  /**
   * If this component's required data is loading or not.
   */
  topRisksLoading: PropTypes.bool.isRequired,
};
