import { useState, useEffect } from "react";
import "./asset_cpes_tab.css";
import { getAssetCPEs } from "../../../api";
import BinarySummaryInfoHelper from "../binary_summary_components/binary_summary_info_helper";
import CVEs from "../CVEs";

export default function AssetCPEsTab({ asset }) {
  let [cpes, setCPEs] = useState([]);

  const refreshCPEs = async () => {
    try {
      let cpes = await getAssetCPEs(asset.ref_id);
      setCPEs(cpes);
    } catch {}
  };
  useEffect(() => {
    refreshCPEs();
  }, []);

  return (
    <div className="asset-cpes-tab">
      <BinarySummaryInfoHelper
        text="Common Platform Enumeration (CPE) is a structured naming 
            scheme for information 
            technology systems, software, and packages. CPEs can be used
            to search for Common Vulnerabilities and Exposures (CVEs) 
            that affect the identified product. Our CPE detection assessment
            utilizes the Vendor, Product, and Version that you may optionally
            specify when starting a new asset analysis."
        link="https://nvd.nist.gov/products/cpe"
      />

      <div className="mapping">
        <h2>Asset Specification</h2>
        <h3>Vendor</h3>
        <div className="mapping-field">{asset.vendor ? asset.vendor : "-"}</div>
        <h3>Product</h3>
        <div className="mapping-field">
          {asset.product ? asset.product : "-"}
        </div>
        <h3>Version</h3>
        <div className="mapping-field">
          {asset.version ? asset.version : "-"}
        </div>
      </div>

      {cpes.length === 0 && (
        <div className="mapping">
          <h3 className="no-cpes-detected">No CPEs detected.</h3>
        </div>
      )}

      {cpes.map((cpe, i) => (
        <div className="mapping">
          <h3>[{i}] CPE Detected</h3>
          <h2>{cpe.cpe}</h2>
          {cpe.cves.length > 0 ? (
            <CVEs cves={cpe.cves} />
          ) : (
            <div className="mapping-field">No associated CVEs.</div>
          )}
        </div>
      ))}
    </div>
  );
}
