import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import { Param } from "../../constants";

/**
 * Component represents a parameter to an API endpoint on the OpenAPI
 * page.
 * Represents a file parameter.
 */
export default class ParamFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.pickFile = this.pickFile.bind(this);
    this.hiddenFileInput = React.createRef();
  }

  /**
   * Pick a file as the field to this parameter.
   */
  pickFile(acceptedFiles) {
    this.props.func(acceptedFiles[0]);
  }

  render() {
    return (
      <div className="left-row req-row">
        <div className="req-key">
          <h2>{this.props.param.name}</h2>
          <h3>{this.props.param.type}</h3>
        </div>
        <div className="req-field">
          <div className="up-init" style={{ width: "fit-content" }}>
            <button
              style={{
                height: 30,
                width: 80,
                padding: 0,
                margin: 0,
                marginLeft: -5,
                marginRight: 7,
              }}
              className="upload"
              onClick={() => this.hiddenFileInput.current.click()}
            >
              Choose File
            </button>
            <input
              type="file"
              ref={this.hiddenFileInput}
              onChange={(e) => {
                this.pickFile(e.target.files);
              }}
              style={{ display: "none" }}
            />
            <p>
              {this.props.param.value === null
                ? "No file chosen."
                : this.props.param.value.name}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

ParamFile.propTypes = {
  /**
   * The parameter object being displayed.
   */
  param: PropTypes.instanceOf(Param).isRequired,

  /**
   * Callback that runs when the user inputs data.
   */
  func: PropTypes.func.isRequired
}