import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import { Param } from "../../constants";

/**
 * Component represents a parameter to an API endpoint on the OpenAPI
 * page.
 * Represents an integer parameter.
 */
export default class ParamInt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  
  render() {
    return (
      <div className="left-row req-row">
        <div className="req-key">
          <h2>{this.props.param.name}</h2>
          <h3>{this.props.param.type}</h3>
        </div>
        <div className="req-field">
          <input
            type="number"
            step="1"
            value={this.props.param.value}
            placeholder="int..."
            onInput={(e) => this.props.func(e.target.value)}
            className="up-init"
            style={{ cursor: "text", width: "calc(100% - 20px)" }}
          />
        </div>
      </div>
    );
  }
}

ParamInt.propTypes = {
  /**
   * The parameter object being displayed.
   */
  param: PropTypes.instanceOf(Param).isRequired,

  /**
   * Callback that runs when the user inputs data.
   */
  func: PropTypes.func.isRequired
}