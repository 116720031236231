import React from "react";
import "../../index.css";
import PropTypes from "prop-types";
import { Binary } from "../../constants";
import { getMetadata } from "../../api";

/**
 * Represents a single completed binary analysis on the left side
 * list on the binary anlysis page.
 */
export default class BinaryLineItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popup: false,
      hovering: false,
    };
  }

  render() {
    return (
      <div
        onMouseEnter={() => this.setState({ hovering: true })}
        onMouseLeave={() => this.setState({ hovering: false })}
        onClick={async () => {
          if (this.props.unassessable) {
            let metadata = await getMetadata(
              this.props.asset_id,
              this.props.binary.name
            );

            if (
              metadata &&
              metadata.hasOwnProperty("mime_type") &&
              metadata.hasOwnProperty("type_str")
            ) {
              alert(
                `This file is ${metadata.type_str} ['${metadata.mime_type}'], which is not a binary executable. Therefore, it is unassessable and produces no binary assessment results.`
              );
            } else {
              alert(
                "This file is unassessable because it is not a binary executable. Therefore, it produces no binary assessment results."
              );
            }
            return;
          }

          this.props.selectUpload(
            this.props.asset_id,
            this.props.binary.name,
            this.props.binary.cwss.score,
            this.props.binary.cwss.attack,
            this.props.binary.cwss.base,
            this.props.binary.cwss.environment,
            this.props.binary.cwss.aggregate
          );
        }}
        className="left-row"
        style={{
          background:
            this.props.selectedUpload.id === this.props.asset_id &&
            this.props.selectedUpload.name === this.props.binary.name
              ? "var(--sec-bg-color)"
              : "var(--bg-color)",
        }}
      >
        {this.props.binary.percentage === 100 && (
          <div className={this.props.color}></div>
        )}
        {this.props.binary.percentage < 100 && (
          <h3 className="curr-analyze" style={{ marginRight: 10 }}>
            {this.props.binary.percentage}%
          </h3>
        )}
        <h3
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "70%",
            fontWeight: this.props.binary.new ? 700 : 600,
            color: this.props.binary.new
              ? "var(--pri-color)"
              : "var(--pri-color-light)",
          }}
        >
          {this.props.binary.name}
        </h3>
      </div>
    );
  }
}

BinaryLineItem.propTypes = {
  /**
   * Callback that selects a binary to view.
   */
  selectUpload: PropTypes.func.isRequired,

  /**
   * The id the the parent asset.
   */
  asset_id: PropTypes.string.isRequired,

  /**
   * The binary to display.
   */
  binary: PropTypes.instanceOf(Binary).isRequired,

  /**
   * The currently selected binary.
   */
  selectedUpload: PropTypes.instanceOf(Binary).isRequired,

  /**
   * The color of the binary based upon vulnerability score.
   */
  color: PropTypes.string.isRequired,

  /**
   * If the binary is unassessable, it is grayed out.
   */
  unassessable: PropTypes.bool,
};
