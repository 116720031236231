import { calcColor, calcIcon, calcText } from "../../../helpers";

export default function AssetSummaryTitle({ asset }) {
  return (
    <div
      className="row-2"
      style={{
        justifyContent: "flex-start",
        marginBottom: 30,
        padding: 20,
        borderRadius: 10,
        border: "2px solid var(--sec-bg-color)",
        width: "calc(100% - 40px)",
        overflow: "hidden",
      }}
    >
      <div
        className="score-g"
        style={{
          borderColor: calcColor(asset.cwss_score),
          background: calcColor(asset.cwss_score),
        }}
      >
        {calcIcon(asset.cwss_score)}
      </div>

      <div>
        <h2
          className="prev-file-name"
          style={{ fontSize: 24, marginBottom: 10 }}
        >
          {asset.name}
        </h2>
        <h3 style={{ marginTop: 5, fontSize: 16, lineHeight: 1.4 }}>
          {calcText(asset.cwss_score, "asset")}
        </h3>
      </div>
    </div>
  );
}
