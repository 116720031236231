import React from "react";
import { acceptEula, logout } from "../../api";
import "../../index.css";

// components
import EULA from "./eula";
import LoginHeadbar from "./login_headbar/login_headbar";
import Copyright from "./copyright/copyright";

/**
 * Page that appears after the user has created their password
 * and setup 2FA. Displays the EULA and enforces that the
 * user agrees to it before using the rest of the application.
 */
export default class EulaPage extends React.Component {
  constructor(props) {
    super(props);
    document.title = "BinLens - EULA";
    this.state = {};
  }

  render() {
    return (
      <div className="login-page">
        <LoginHeadbar logout />
        <h1>EULA</h1>

        <div className="login-center">
          <EULA />

          <div className="row">
            <button onClick={logout} className="login-decline-button">
              Decline
            </button>
            <button
              onClick={() => {
                acceptEula();
                window.location.assign("#/dashboard");
              }}
            >
              Accept
            </button>
          </div>
        </div>

        <div className="login-bot-row">
          <a
            onClick={() => {
              var link = document.createElement("a");
              link.href = "/ObjectSecurity_BinLens_EULA_7_26_2022.pdf";
              link.download =
                "ObjectSecurity_BinLens_EULA_7_26_2022.pdf";
              link.dispatchEvent(new MouseEvent("click"));
            }}
          >
            Download EULA
          </a>
        </div>

        <Copyright />
      </div>
    );
  }
}
