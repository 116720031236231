import { useState, useEffect } from "react";
import "./alerts.css";
import { Tooltip } from "react-tippy";
import { getAlerts, getUserProfile, readAlert } from "../../api";

// icons
import { AlertTriangle, X } from "react-feather";

// components
import Alert from "./alert";
import CreateAlert from "./create_alert";

/**
 * This component displays a set of alerts to the user.
 * Types of alerts include:
 *
 * 1. Asset analysis completion.
 * 2. Flash alerts propagated by the SUPERADMIN.
 * 3. RSS feed updates
 *
 * Additionally, this component may optionally allow the
 * SUPERADMIN to add new alerts that are propagated to all users
 * in the current organization.
 */
export default function Alerts() {
  let [alerts, setAlerts] = useState([]);
  let [popup, setPopup] = useState(false);
  let [createAlert, setCreateAlert] = useState(false);
  let [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const init = async () => {
    let profile = await getUserProfile(window.localStorage.getItem("userid"));
    if (!profile) return;
    if (profile.roles[profile.org_id].includes("superadmin"))
      setIsSuperAdmin(true);
    // fetch and set the list of alerts
    let alerts = await getAlerts();
    setAlerts(alerts);
  };

  // fetch the set of alerts for the current user
  useEffect(() => {
    const removePopup = () => setPopup(false);
    window.addEventListener("mousedown", removePopup);
    init();
    return () => window.removeEventListener("mousedown", removePopup);
  }, []);

  let num_not_read = 0;
  let userid = window.localStorage.getItem("userid");
  for (let alert of alerts) {
    if (!alert.readby.includes(userid)) num_not_read++;
  }

  return (
    <div className="headbar-alerts">
      <Tooltip
        // options
        title="Alerts"
        position="bottom"
        trigger="mouseenter"
        arrow
        size="small"
      >
        <div
          className="headbar-alerts-button"
          onClick={async () => {
            setPopup(true);

            if (num_not_read > 0) {
              for (let alert of alerts) {
                if (!alert.readby.includes(userid)) {
                  await readAlert(alert.ref_id);
                }
              }
              init();
            }
          }}
        >
          <AlertTriangle className="icon" />
          {num_not_read > 0 && (
            <div className="num-not-read">{num_not_read}</div>
          )}
        </div>
      </Tooltip>

      {popup && (
        <div className="alerts-popup">
          <h2>Alerts</h2>
          <X className="new-analysis-close" />

          <div className="alerts-scroller">
            {alerts.map((alert) => {
              return <Alert alert={alert} />;
            })}
            {alerts.length <= 0 && (
              <div className="no-alerts">
                <AlertTriangle className="icon" />
                <h3>No alerts yet.</h3>
              </div>
            )}
          </div>

          <div className="alerts-footer">
            {isSuperAdmin && (
              <button
                onMouseDown={() => {
                  setCreateAlert(true);
                }}
              >
                Create Alert
              </button>
            )}
          </div>
        </div>
      )}

      {createAlert && (
        <CreateAlert close={() => setCreateAlert(false)} refresh={init} />
      )}
    </div>
  );
}
