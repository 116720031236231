/*
This file contains the set of all possible asset types, a function
to get the icon associated with each, as well as a function to
get a "human-readable" version of the asset type string.
This file is used across the project, including:
- The Top Risks component.
- The Asset Line Item component.
- The File Drop component.
*/

import {
  Activity,
  Box,
  Cpu,
  Monitor,
  Smartphone,
  HardDrive,
  Database,
} from "react-feather";

// Asset type logic -->

export const ASSET_TYPES = [
  "HMI",
  "PLC",
  "SCADA",
  "IoT",
  "IIoT",
  "Calibrator",
  "Mobile Device",
  "Medical Device",
  "Protocol Config",
  "DCS",
  "VFD",
  "VSD",
  "Historian",
  "Robotic",
];

/**
 * Get the icon used for each type of asset.
 */
export function getAssetIcon(type, className, style, onClick = null) {
  switch (type) {
    case "Mobile Device":
      return (
        <Smartphone className={className} style={style} onClick={onClick} />
      );
    case "ICS":
    case "VFD":
    case "VSD":
      return (
        <HardDrive className={className} style={style} onClick={onClick} />
      );
    case "IoT":
    case "IIoT":
    case "PLC":
    case "Robotic":
      return <Cpu className={className} style={style} onClick={onClick} />;
    case "HMI":
    case "SCADA":
    case "DCS":
      return <Monitor className={className} style={style} onClick={onClick} />;
    case "Medical Device":
    case "Protocol Config":
    case "Calibrator":
      return <Activity className={className} style={style} onClick={onClick} />;
    case "Historian":
      return <Database className={className} style={style} onClick={onClick}  />
    default:
      return <Box className={className} style={style} onClick={onClick} />;
  }
}

/**
 * Get a human-readable version of an asset type
 * string enumeration.
 */
export function getAssetTypeReadable(type) {
  switch (type) {
    case "Mobile Device":
    case "Medical Device":
      return type;
    case "ICS":
      return "Industrial Control System";
    case "VFD":
      return "Variable-Frequency Drive";
    case "VSD":
      return "Variable-Speed Drive";
    case "IoT":
      return "'Internet of Things' Device";
    case "IIoT":
      return "'Industrial Internet of Things' Device";
    case "PLC":
      return "Programmable Logic Controller";
    case "HMI":
      return "Human Machine Interface";
    case "SCADA":
      return "Supervisory Control and Data Acquisition System";
    case "DCS":
      return "Distributed Control System";
    case "Protocol Config":
      return "Protocol Configuration Device";
    case "Calibrator":
      return "Calibration Device";
    case "Historian":
      return "Data Historian";
    case "Robotic":
      return "Robotic Device";
    default:
      return "generic collection of binaries";
  }
}

// <--
