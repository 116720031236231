import {
  getBinaries,
  getVulscore,
  getBAP,
  getBinaryEncryption,
  getCVEs,
  getCWEInfo,
  getCWEs,
  getEntropy,
  getLineage,
  getManalyze,
  getMetadata,
  getDangerousFunctions,
  getCrypto,
  getRuntimeEncryption,
  getWeakPointers,
  isFeatureEnabled,
} from "../../../../api";
import { PASS_FAIL_NAMES } from "../../../binary_analysis_page/pass_fail";
import {
  add_isa_3_3_field,
  add_isa_4_2_field,
  add_nist_800_53_field,
  add_nist_800_82_field,
  add_owasp_field,
  ISA_3_3_MAPPING,
  ISA_4_2_MAPPING,
} from "../../../binary_analysis_page/mappings/mapping_datastructures";
import { DANGEROUS_FN_MAP } from "../../../../helpers";

// enum of all callback return types for fetchBinaryItems
export const BINARY_REPORT_RESULT_TYPES = {
  BINARY: "BINARY",
  METADATA: "METADATA",
  MANALYZE: "MANALYZE",
  BINARY_ENCRYPTION: "BINARY_ENCRYPTION",
  CWES: "CWES",
  CVES: "CVES",
  THREATAI: "THREATAI",
  DANGEROUS_FUNCTIONS: "DANGEROUS_FUNCTIONS",
  CRYPTO: "CRYPTO",
  RUNTIME_ENCRYPTION: "RUNTIME_ENCRYPTION",
  WEAK_POINTERS: "WEAK_POINTERS",
};

/**
 * Fetches all BE data required to paint a custom report.
 * Upon data fetch, responds with a callback that can be used to set
 * state in the calling component.
 */
export async function fetchBinaryItems(asset_id, binary_name, callback) {
  // get data to render title component and score graph component
  let binaries = await getBinaries(asset_id);
  let _binary = binaries.assessable.filter((b) => b.name === binary_name)[0];
  let vulscore = await getVulscore(asset_id, binary_name);
  _binary.attack = parseFloat(_binary.cwss.attack);
  _binary.environment = parseFloat(_binary.cwss.environment);
  _binary.aggregate = _binary.cwss.aggregate;
  _binary.cwss = parseFloat(_binary.cwss.score);
  _binary.cvss = vulscore.base;
  _binary.exploitability = vulscore.exploitability;
  _binary.impact = vulscore.impact;
  callback(BINARY_REPORT_RESULT_TYPES.BINARY, _binary);

  // get data to render metadata component
  let _metadata = await getMetadata(asset_id, binary_name);
  let _entropy = await getEntropy(asset_id, binary_name);
  if (_entropy) _metadata.entropy = _entropy;
  callback(BINARY_REPORT_RESULT_TYPES.METADATA, _metadata);

  let _manalyze = await getManalyze(asset_id, binary_name);
  if (_manalyze && Object.keys(_manalyze).length === 0) _manalyze = null;
  callback(BINARY_REPORT_RESULT_TYPES.METADATA, _metadata);

  let _binaryEncryption = await getBinaryEncryption(asset_id, binary_name);
  callback(BINARY_REPORT_RESULT_TYPES.BINARY_ENCRYPTION, _binaryEncryption);

  // get data to render dynamic components (items list)
  let _cwes = await getCWEs(asset_id, binary_name);
  let cweIds = [];
  for (let c of _cwes) {
    let id = c.cwe.split("-")[1];
    cweIds.push(id);
  }
  let cweInfo = await getCWEInfo(cweIds);
  // optionally add mapping field depending on invoicing logic/subscription level
  let is_feature_enabled = await isFeatureEnabled();
  for (let c of _cwes) {
    let id = c.cwe.split("-")[1];
    c.info = cweInfo[id] ? cweInfo[id] : "";
    c.mappings = [];
    if (is_feature_enabled["OWASP 10:2021"]) c = add_owasp_field(c);
    if (is_feature_enabled["ISA/IEC 62443-3-3"]) c = add_isa_3_3_field(c);
    if (is_feature_enabled["ISA/IEC 62443-4-2"]) c = add_isa_4_2_field(c);
    if (is_feature_enabled["NIST 800-53"]) c = add_nist_800_53_field(c);
    if (is_feature_enabled["NIST 800-82"]) c = add_nist_800_82_field(c);
  }
  callback(BINARY_REPORT_RESULT_TYPES.CWES, _cwes);

  let _cves = await getCVEs(asset_id, binary_name);
  callback(BINARY_REPORT_RESULT_TYPES.CVES, _cves ? _cves : []);

  let _threatAI = await getBAP(asset_id, binary_name);
  for (let tai of _threatAI) {
    tai.NAME = PASS_FAIL_NAMES[tai.NAME];
  }
  let lineage = await getLineage(asset_id, binary_name);
  let lineage_mappings = [];
  if (!lineage) {
    if (is_feature_enabled["ISA/IEC 62443-3-3"])
      lineage_mappings.push({
        type: "ISA/IEC 62443-3-3",
        requirements: ISA_3_3_MAPPING[0].req,
      });
    if (is_feature_enabled["ISA/IEC 62443-4-2"])
      lineage_mappings.push({
        type: "ISA/IEC 62443-4-2",
        requirements: ISA_4_2_MAPPING[0].req,
      });
  }
  _threatAI.push({
    NAME: "Lineage Analysis",
    MSG: lineage
      ? "Suspicious code and/or evasive behavior not detected. This binary exhibits clearly benign behaviors."
      : "Suspicious code and/or evasive behavior detected. This binary does not exhibit clearly benign behaviors - it exhibits characteristics that may be malicious.",
    STATUS: lineage ? "PASS" : "FAIL",
    mappings: lineage_mappings,
  });
  callback(BINARY_REPORT_RESULT_TYPES.THREATAI, _threatAI);

  // dangerous functions
  let dangerousFnsPre = await getDangerousFunctions(asset_id, binary_name);
  let _dangerousFns = {};
  for (let df in dangerousFnsPre) {
    if (!DANGEROUS_FN_MAP.hasOwnProperty(df)) continue;
    if (dangerousFnsPre[df].length > 0) _dangerousFns[df] = dangerousFnsPre[df];
  }
  callback(BINARY_REPORT_RESULT_TYPES.DANGEROUS_FUNCTIONS, _dangerousFns);

  // cryptogrpahic keys
  let _crypto = await getCrypto(asset_id, binary_name);
  callback(BINARY_REPORT_RESULT_TYPES.CRYPTO, _crypto);

  // runtime encryption
  let _runtimeEncryption = await getRuntimeEncryption(asset_id, binary_name);
  callback(BINARY_REPORT_RESULT_TYPES.RUNTIME_ENCRYPTION, _runtimeEncryption);

  // get weak points for display on the custom report preview
  let _weakPointers = await getWeakPointers(asset_id, binary_name);
  callback(BINARY_REPORT_RESULT_TYPES.WEAK_POINTERS, _weakPointers);
}
