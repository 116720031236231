import { DangrController, DangrTab } from "dangr-ui";
import "./symbex_tab.css";
import {
  getSymbexLogs,
  getSymbexNumLogs,
  getSymbexResults,
  getSymbexStatus,
  getUserProfile,
  stopAssessment,
} from "../../../api";

/**
 * This tab renders symbolic execution results yielded
 * by the dangr binary analysis framework.
 * Example results include stack-based buffer overflows,
 * segfaults, etc.
 */
export default function SymbexTab({ binary }: SymbexTabProps) {
  let controller = new BinLensDangrController(binary.id, binary.name);
  return (
    <div className="symbex-tab">
      <DangrTab controller={controller} />
    </div>
  );
}

type SymbexTabProps = {
  binary: Binary;
};

type Binary = {
  id: string; // parent asset id
  name: string; // binary name
};

// ---

/**
 * Allows dangr-ui/DangrTab to query the BinLens backend microservices
 * (specifically rbng-rbui) to fetch analysis results data for
 * display.
 */
class BinLensDangrController implements DangrController {
  assetId: string;
  binary: string;

  constructor(assetId: string, binary: string) {
    this.assetId = assetId;
    this.binary = binary;
  }

  async getResults() {
    return getSymbexResults(this.assetId, this.binary);
  }

  async getStatus() {
    return getSymbexStatus(this.assetId, this.binary);
  }

  async getLogs(offset: number, limit: number) {
    return getSymbexLogs(this.assetId, this.binary, offset, limit);
  }

  async getNumLogs() {
    return getSymbexNumLogs(this.assetId, this.binary);
  };

  async stopAnalysis() {
    // current org_id is attached the the user's profile
    const profile = await getUserProfile(window.localStorage.getItem("userid"));
    if (!profile) return;
    await stopAssessment(profile.org_id, this.assetId, this.binary, "symbex");
  }
}
