import React from "react";
import "../../index.css";
import "./set_password_page.css";
import { changePassword, login0, redeemUserInviteLink } from "../../api";
import { TextField } from "@mui/material";

// components
import Copyright from "./copyright/copyright";
import LoginHeadbar from "./login_headbar/login_headbar";

/**
 * Page of the application that allows new users to set their password.
 * Required the user to enter the password twice before continuing on
 * to the <SetupGoogleAuth /> component.
 */
export default class SetPasswordPage extends React.Component {
  constructor(props) {
    super(props);

    document.title = "BinLens - Login";

    const urlParams = new URLSearchParams(window.location.href.split("?")[1]);

    this.invite = urlParams.get("invite");

    this.state = {
      username: "",
      invalidInvite: false,
      loading: false,
      failed: false,
      password: "",
      reTypedPassword: "",
    };

    this.firstLogin = this.firstLogin.bind(this);
    this.init = this.init.bind(this);
    this.enterClick = this.enterClick.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    try {
      let res = await redeemUserInviteLink(this.invite);

      // return user profile information and allow the user the ability to change
      // their password
      this.token = res.access_token;
      this.userId = res.id;
      this.setState({ username: res.username });
    } catch {
      this.setState({ invalidInvite: true });
    }
  }

  enterClick(e) {
    if (e.key === "Enter") {
      this.firstLogin();
    }
  }

  /**
   * Login the user.
   * Clears the cache, then submits the specified username and password
   * to the backend.
   */
  async firstLogin() {
    if (
      this.state.password !== this.state.reTypedPassword ||
      this.state.password === ""
    ) {
      alert("Please set your password!");
      return;
    }

    // reset local storage
    window.localStorage.removeItem("analyzingUploads");
    window.localStorage.removeItem("generatedReports");
    window.localStorage.removeItem("collections");
    window.localStorage.removeItem("pastUploads");
    window.localStorage.removeItem("dropzoneId");
    window.localStorage.removeItem("firstLoad");
    window.localStorage.removeItem("firstToken");

    window.localStorage.removeItem("username");
    window.localStorage.removeItem("email");
    window.localStorage.removeItem("userid");
    window.localStorage.removeItem("dropUploads");
    window.localStorage.setItem("auth", this.token);

    this.setState({ loading: true });

    try {
      // update the user's password
      await changePassword(this.userId, this.state.password);

      // check if MFA/Google authenticator is required
      let res = await login0(this.state.username, this.state.password);
      if (res.hasOwnProperty("details")) {
        window.localStorage.setItem("auth", res.access_token);
        window.location.assign("#/eula");
        return;
      }

      window.location.assign("#/setupgoogleauth");
    } catch (e) {
      this.setState({ loading: false, fail: "Failed to set password." });
      return;
    }
  }

  render() {
    return (
      <div className="login-page">
        <LoginHeadbar />

        {this.state.invalidInvite && (
          <h2 className="expired-invite-link">Your invite link has expired.</h2>
        )}

        {!this.state.invalidInvite && this.state.username && (
          <>
            <h1>Hello {this.state.username}</h1>
            <div className="login-center">
              <TextField
                value={this.state.password}
                onKeyDown={this.enterClick}
                onChange={(e) => this.setState({ password: e.target.value })}
                label="Password"
                type="password"
                fullWidth
                className="username-input"
              />
              <TextField
                value={this.state.reTypedPassword}
                onKeyDown={this.enterClick}
                onChange={(e) =>
                  this.setState({ reTypedPassword: e.target.value })
                }
                label="Re-type Password"
                type="password"
                fullWidth
              />
              <p>
                Set your password to continue.
                <br />
                <br />
                Ensure that you store the password somewhere safe. If you lose
                or forget your password, please contact the SuperAdmin of your
                deployment, or ObjectSecurity.
              </p>

              <div className="row">
                {this.state.loading && <div className="lds-dual-ring"></div>}
                {!this.state.loading && <p>{this.state.fail}</p>}

                <button onClick={this.firstLogin}>Sign in</button>
              </div>
            </div>
          </>
        )}

        <Copyright />
      </div>
    );
  }
}
