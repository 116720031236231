// Basic data structures.
export class Facility {}

export class Asset {}

export class Binary {}

export class Alert {}

// Assessment results.
export class PassFail {}

export class CVE {}

export class CWE {}

// Dashboard-specific data.
export class Audit {}

export class TopRisk {}

// User management
export class User {}

// OpenAPI Page
export class Param {}
