import moment from "moment";
import { getExpiration } from "../../api";
import Notifications from "./notifications";

const MINIMUM_DAYS = 31;

/**
 * Check that the expiration is soon.
 * If it is soon, create a notification.
 */
export async function checkExpiration() {
    let expiration = await getExpiration()
    let days_from = moment(new Date(expiration.timeout * 1000)).diff(new Date(),'days')
    if (days_from < MINIMUM_DAYS){
        Notifications._this.addRemindExtend(days_from);
    }
}
