import React from "react";
import "../../index.css";
import {
  getAllFacilities,
  getBinaries,
  getDefaultFacility,
  updateFacility,
  isFeatureEnabled,
  getAsset,
} from "../../api";
import "react-tippy/dist/tippy.css";
import Joyride from "react-joyride";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";
import { calcColor } from "../../helpers";
import AssetFetcher, { FETCHABLE } from "../../asset_fetcher";

// icons
import { ChevronDown, ChevronUp } from "react-feather";

// components
import BinarySummary from "./binary_summary";
import Headbar from "../headbar/headbar";
import Sidebar from "../sidebar/sidebar";
import Botbar from "../botbar/botbar";
import Notifications from "../login_page/notifications";
import AssetLineItem from "./asset_line_item";
import AssetSummary from "./asset_summary";
import FacilitySummary from "./facility_summary";
import BigDrop from "../sidebar/big_drop";
import FacilityDropdown from "./facility_dropdown";
import RightHandSidebar from "../sidebar/right_hand_sidebar";
import BinaryAnalysisSearchbar from "./binary_analysis_searchbar";
import BinaryLineItem from "./binary_line_item";

// react-joyride user-onboarding steps
const steps = [
  {
    target: ".past-uploads-wrap",
    content: "We are now on the Binary Analysis Page.",
    disableBeacon: true,
    placement: "center",
  },
  {
    target: ".past-uploads-wrap",
    content:
      "Here is where all of the analyzed assets and binaries in the currently viewed facility will appear.",
    disableBeacon: true,
    placement: "right",
  },
  {
    target: ".report-prev",
    content:
      "After clicking on an asset, facility, or binary, you can see a break down of it on the right.",
    disableBeacon: true,
    placement: "left",
  },
  {
    target: "#facility-selector",
    content:
      "Click here to toggle between facilities, or to create a new facility.",
    disableBeacon: true,
    placement: "right",
  },
];

/**
 * Binary analysis page in the application.
 * Users can upload new files for analysis and see past results.
 */
export default class BinaryAnalysisPage extends React.Component {
  constructor(props) {
    super(props);

    document.title = "BinLens - Binary Analysis";

    let params = window.location.href.split("?")[1];
    const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
    this.viewingBinary = urlParams.get("binary");
    this.viewingFacility = urlParams.get("facility_id");
    this.viewingAsset = urlParams.get("asset");

    let searchText = "";
    if (this.viewingAsset) {
      searchText = urlParams.get("asset_name");
    }

    this.state = {
      selectedFacility: null,
      facilities: [],
      facilityLoading: true,
      sortby: "date",
      onboard: params !== undefined && params === "onboard",
      searchText,
      assets: [],
      selectedUpload: { id: -1, name: "" },
      loading: true,
      selectedCollection: null,
      panel: "FACILITY", // | "HELP" | "ANALYSIS" | "COLLECTION"
      facilityDropdown: false,
      is_feature_enabled: {
        "OWASP 10:2021": false,
        "ISA/IEC 62443-3-3": false,
        "ISA/IEC 62443-4-2": false,
        "NIST 800-53": false,
        "NIST 800-82": false,
      },
      assetType: "",
      fetchable: null,
    };

    this.init = this.init.bind(this);
    this.init_fromOrgSwap = this.init_fromOrgSwap.bind(this);
    this.selectUpload = this.selectUpload.bind(this);
    this.popupOff = this.popupOff.bind(this);
    this.renderLoading = this.renderLoading.bind(this);
    this.refresh = this.refresh.bind(this);
    this.refreshAsset = this.refreshAsset.bind(this);
    this.updateProgress = this.updateProgress.bind(this);

    BinaryAnalysisPage._this = this;
  }

  popupOff() {
    this.setState({ facilityDropdown: false });
  }

  // used to update past-upload list after new binary uploaded + analyzed
  static _this = null;

  /**
   *
   */
  componentDidMount() {
    window.addEventListener("mousedown", this.popupOff, false);
    BinaryAnalysisPage._this = this;
    this.init();
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.popupOff, false);
    BinaryAnalysisPage._this = null;
  }

  /**
   * Check backend for new uploads.
   */
  async init() {
    this.setState({ loading: true, assets: [] });

    // get facility information
    let facilities = await getAllFacilities();
    let defaultFacility = await getDefaultFacility();
    let selectedFacility = this.viewingFacility
      ? facilities.filter((f) => {
          return f.id === this.viewingFacility;
        })[0]
      : facilities.filter((f) => {
          return f.id === defaultFacility;
        })[0];

    // if this facility doesn't exist (i.e. it was deleted), then
    // we shouldn't render a selected facility
    if (!selectedFacility) {
      alert("This facility no longer exists!");
      window.location.assign("#/dashboard");
    }
    this.setState({
      selectedFacility,
      facilities,
      facilityLoading: false,
    });

    this.assetFetcher = new AssetFetcher(
      document.getElementById("asset-scroller"),
      (assets, self, done) => {
        this.setState({ assets, loading: !done });
        if (this.viewingAsset) {
          if (this.assetFetcher === self) {
            let asset = assets.filter((a) => {
              return a.ref_id === this.viewingAsset;
            })[0];
            getBinaries(asset.ref_id).then((binaries) => {
              this.setState({
                panel: "COLLECTION",
                selectedCollection: {
                  ...asset,
                  binaries,
                },
              });
            });
          }
        }
      },
      selectedFacility.id,
      this.state.searchText,
      this.state.sortby,
      this.state.assetType,
      this.state.fetchable
        ? this.state.fetchable
        : FETCHABLE.ASSET_IN_FACILITY_BY_NAME
    );
    this.assetFetcher.loadAssets();

    let is_feature_enabled = await isFeatureEnabled();
    this.setState({ is_feature_enabled });
  }

  async refresh() {
    this.assetFetcher = null;
    this.setState({ loading: true, assets: [] }, async () => {
      // get facility information
      let facilities = await getAllFacilities();
      let defaultFacility = await getDefaultFacility();
      let selectedFacility = facilities
        .map((f) => f.id)
        .includes(this.state.selectedFacility.id)
        ? facilities.filter((f) => {
            return f.id === this.state.selectedFacility.id;
          })[0]
        : facilities.filter((f) => {
            return f.id === defaultFacility;
          })[0];
      this.setState({
        selectedFacility,
        facilities,
        facilityLoading: false,
      });

      this.assetFetcher = new AssetFetcher(
        document.getElementById("asset-scroller"),
        (assets, self, done) => {
          if (this.assetFetcher === self)
            this.setState({ assets, loading: !done });
        },
        selectedFacility.id,
        this.state.searchText,
        this.state.sortby,
        this.state.assetType,
        this.state.fetchable
          ? this.state.fetchable
          : FETCHABLE.ASSET_IN_FACILITY_BY_NAME
      );
      this.assetFetcher.loadAssets();
    });
  }

  async init_fromOrgSwap() {
    this.assetFetcher = null;
    this.setState(
      {
        selectedFacility: null,
        facilities: [],
        facilityLoading: true,
        onboard: false,
        searchText: "",
        assets: [],
        selectedUpload: { id: -1, name: "" },
        loading: true,
        selectedCollection: null,
        panel: "FACILITY",
      },
      async () => {
        // get facility information
        let facilities = await getAllFacilities();
        let defaultFacility = await getDefaultFacility();
        let selectedFacility = facilities.filter((f) => {
          return f.id === defaultFacility;
        })[0];
        this.setState({
          selectedFacility,
          facilities,
          facilityLoading: false,
        });

        this.assetFetcher = new AssetFetcher(
          document.getElementById("asset-scroller"),
          (assets, self, done) => {
            if (this.assetFetcher === self)
              this.setState({ assets, loading: !done });
          },
          selectedFacility.id,
          this.state.searchText,
          this.state.sortby,
          this.state.assetType,
          this.state.fetchable
            ? this.state.fetchable
            : FETCHABLE.ASSET_IN_FACILITY_BY_NAME
        );
        this.assetFetcher.loadAssets();
      }
    );
  }

  async refreshAsset(updated_asset_id) {
    let updated_asset = await this.assetFetcher.update_asset(updated_asset_id);
    this.setState({
      selectedCollection: updated_asset,
    });
  }

  /**
   * This function is statically called from the AnalysisProgressBar component.
   * It updates any in-progress bins with their new percentage.
   */
  async updateProgress() {
    let assets = [...this.state.assets];

    // check for assets that are in progress, but that do not appear in the
    // analyzingAssets queue
    // these should be checked for completion
    for (let i = 0; i < assets.length; i++) {
      if (assets[i].state === "in progress") {
        try {
          let updated_asset = await getAsset(assets[i].ref_id);
          assets[i] = { ...updated_asset };
        } catch (error) {
          console.error(error);
        }
      }
    }

    this.setState({ assets });
  }

  /**
   * Render the page.
   */
  render() {
    return (
      <BigDrop>
        {this.state.onboard && (
          <button
            style={{
              position: "fixed",
              top: 40,
              right: 30,
              zIndex: 2001,
              background: "var(--contrast-color)",
              color: "white",
              borderRadius: 40,
            }}
            onClick={() => this.setState({ onboard: false })}
          >
            Cancel Tutorial
          </button>
        )}
        <Joyride
          locale={{
            back: "Back",
            close: "Close",
            last: "Done",
            next: "Next",
            open: "Open the dialog",
            skip: "Skip",
          }}
          disableScrolling
          continuous
          steps={steps}
          run={this.state.onboard}
          callback={(data) => {
            if (data.action === "reset") {
              window.location.assign(`#/deepdive?onboard`);
            }
            if (data.action === "close" && data.type === "step:after") {
              // This explicitly stops the tour (otherwise it displays a "beacon" to resume the tour)
              //this.setState({ onboard: false });
            }
          }}
          styles={{
            options: {
              arrowColor: "var(--sec-bg-color)",
              backgroundColor: "var(--sec-bg-color)",
              borderColor: "var(--bor-color)",
              primaryColor: "var(--sec-color)",
              textColor: "var(--pri-color)",
            },
          }}
        />

        <main>
          <Notifications />

          <Headbar />
          <Botbar />

          <div className="main-row">
            <Sidebar page="binary" />

            <div className="content bin-anal-content" style={{ maxWidth: 403 }}>
              <div className="left-row top-of-content" style={{ height: 44 }}>
                {!this.state.facilityLoading && (
                  <div
                    id="facility-selector"
                    onClick={() =>
                      this.setState({
                        panel: "FACILITY",
                        facilityDropdown: true,
                      })
                    }
                    className="facility-name"
                    style={{
                      minWidth: "fit-content",
                      maxWidth: "100%",
                      display: "flex",
                      padding: 0,
                      paddingRight: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      position: "relative",
                      border: this.state.facilityDropdown
                        ? "1px solid var(--bor-color)"
                        : "1px solid transparent",

                      background: this.state.facilityDropdown
                        ? "var(--sec-bg-color)"
                        : "",
                      borderBottomLeftRadius: this.state.facilityDropdown
                        ? 0
                        : 5,
                      borderBottomRightRadius: this.state.facilityDropdown
                        ? 0
                        : 5,
                      cursor: this.state.facilityDropdown ? "auto" : "pointer",
                    }}
                  >
                    {this.state.facilityDropdown && (
                      <div
                        style={{
                          width: "100%",
                          background: "var(--sec-bg-color)",
                          height: 2,
                          position: "absolute",
                          bottom: -2,
                          left: 0,
                          zIndex: 31,
                        }}
                      ></div>
                    )}
                    <div
                      className="facility-row-map"
                      style={{
                        borderLeft: this.state.facilityDropdown
                          ? "1px solid transparent"
                          : "1px solid var(--bor-color)",
                        borderTop: this.state.facilityDropdown
                          ? "1px solid transparent"
                          : "1px solid var(--bor-color)",
                        borderTopRightRadius: this.state.facilityDropdown
                          ? 0
                          : 5,
                        borderBottomLeftRadius: this.state.facilityDropdown
                          ? 0
                          : 5,
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: 1,
                          left: 1,
                          width: "calc(100% - 2px)",
                          height: "calc(100% - 2px)",
                          overflow: "hidden",
                          borderTopRightRadius: 5,
                          borderBottomRightRadius: 5,
                        }}
                      >
                        <ComposableMap projection="geoMercator">
                          <ZoomableGroup
                            zoom={5}
                            center={this.state.selectedFacility.latLng}
                          >
                            <Geographies geography={"./map_data.json"}>
                              {({ geographies, projection }) =>
                                geographies.map((geo) => {
                                  return (
                                    <Geography
                                      style={{
                                        default: { outline: "none" },
                                        hover: { outline: "none" },
                                        pressed: { outline: "none" },
                                      }}
                                      key={geo.rsmKey}
                                      geography={geo}
                                      fill="var(--bg-color)"
                                      stroke="var(--bor-color)"
                                    />
                                  );
                                })
                              }
                            </Geographies>
                            <Marker
                              coordinates={this.state.selectedFacility.latLng}
                            >
                              <circle
                                style={{ cursor: "pointer" }}
                                r={10}
                                fill={calcColor(
                                  this.state.selectedFacility
                                    .vulnerability_score
                                )}
                                filter={calcColor(
                                  this.state.selectedFacility
                                    .vulnerability_score
                                )}
                              />
                            </Marker>
                          </ZoomableGroup>
                        </ComposableMap>
                      </div>
                    </div>
                    <h3
                      style={{
                        fontSize: 16,
                        color: "var(--pri-color)",
                        fontWeight: 700,
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: 200,
                      }}
                    >
                      {this.state.selectedFacility.name}
                    </h3>
                    {!this.state.facilityDropdown && (
                      <ChevronDown
                        className="user"
                        style={{
                          height: 16,
                          width: 16,

                          marginRight: -5,
                        }}
                      />
                    )}
                    {this.state.facilityDropdown && (
                      <ChevronUp
                        className="user"
                        style={{
                          height: 16,
                          width: 16,

                          marginRight: -5,
                        }}
                      />
                    )}

                    {this.state.facilityDropdown && (
                      <FacilityDropdown
                        selectFacility={(selectedFacility) => {
                          this.setState(
                            {
                              panel: "FACILITY",
                              selectedFacility,
                              facilityDropdown: false,
                              loading: true,
                            },
                            this.refresh
                          );

                          if (selectedFacility.new) {
                            updateFacility(
                              selectedFacility.id,
                              selectedFacility.name,
                              selectedFacility.description,
                              selectedFacility.latLng
                            );
                          }
                        }}
                      />
                    )}
                  </div>
                )}
              </div>

              {
                <BinaryAnalysisSearchbar
                  fetchable={this.state.fetchable}
                  setFetchable={(fetchable) =>
                    this.setState({ fetchable }, this.refresh)
                  }
                  searchText={this.state.searchText}
                  setSearchText={(searchText) => this.setState({ searchText })}
                  assetType={this.state.assetType}
                  setAssetType={(assetType) =>
                    this.setState(
                      {
                        assetType: assetType === "All Assets" ? "" : assetType,
                      },
                      this.refresh
                    )
                  }
                  sortBy={this.state.sortby}
                  setSortBy={(sortby) => {
                    this.setState({ sortby }, () => this.refresh());
                  }}
                  refresh={this.refresh}
                />
              }

              <div
                className="past-uploads-wrap"
                onScroll={() => {
                  if (this.assetFetcher) this.assetFetcher.loadAssets();
                }}
                style={{
                  height: "calc(100vh - 241px)",
                }}
                id="asset-scroller"
              >
                {(!this.state.fetchable ||
                  this.state.fetchable ===
                    FETCHABLE.ASSET_IN_FACILITY_BY_NAME ||
                  this.state.fetchable ===
                    FETCHABLE.ASSET_IN_FACILITY_BY_NAME ||
                  this.state.fetchable ===
                    FETCHABLE.ASSET_IN_FACILITY_BY_TAG) &&
                  this.state.assets.map((asset, i) => {
                    return (
                      <AssetLineItem
                        viewed={asset.viewed}
                        panel={this.state.panel}
                        selected={
                          this.state.panel === "COLLECTION" &&
                          asset.ref_id === this.state.selectedCollection.ref_id
                        }
                        key={asset.ref_id}
                        asset={asset}
                        percentage={asset.percentage}
                        loading={this.state.loading}
                        selectedUpload={this.state.selectedUpload}
                        selectUpload={this.selectUpload}
                        clickFunction={(selectedCollection) =>
                          this.setState({
                            panel: "COLLECTION",
                            selectedCollection,
                          })
                        }
                        isUnassessable={asset.state === "unassessable"}
                        refreshAsset={this.refreshAsset}
                      />
                    );
                  })}

                {(this.state.fetchable === FETCHABLE.BINARY_BY_NAME ||
                  this.state.fetchable === FETCHABLE.BINARY_BY_CWE) && (
                  <div className="list-of-files">
                    {this.state.assets.map((binary, i) => {
                      let color = "light-g";
                      if (!binary.hasOwnProperty("cwss")) color = "light-gr";
                      else if (binary.cwss.aggregate === "RED")
                        color = "light-r";
                      else if (binary.cwss.aggregate === "YELLOW")
                        color = "light-y";

                      return (
                        <BinaryLineItem
                          key={i}
                          selectUpload={this.selectUpload}
                          asset_id={binary.asset_id}
                          loading={this.state.loading}
                          selectedUpload={this.state.selectedUpload}
                          color={color}
                          binary={binary}
                          unassessable={color === "light-gr"}
                        />
                      );
                    })}
                  </div>
                )}

                {this.renderLoading()}
              </div>
            </div>

            {this.state.panel === "ANALYSIS" && (
              <BinarySummary
                binary={this.state.selectedUpload}
                is_feature_enabled={this.state.is_feature_enabled}
              />
            )}

            {this.state.panel === "FACILITY" && (
              <FacilitySummary
                facility={this.state.selectedFacility}
                backFunction={() => {
                  this.refresh();
                  Notifications._this.addDeleteFacility();
                }}
              />
            )}

            {this.state.panel === "COLLECTION" && (
              <AssetSummary
                asset={this.state.selectedCollection}
                updateDescription={(new_description) => {
                  let assets = [...this.state.assets];
                  assets.filter((a) => {
                    return a.ref_id === this.state.selectedCollection.ref_id;
                  })[0].description = new_description;
                  this.setState({ assets });
                  this.setState({
                    selectedCollection: {
                      ...this.state.selectedCollection,
                      description: new_description,
                    },
                  });
                }}
                refreshAsset={this.refreshAsset}
              />
            )}

            <RightHandSidebar />
          </div>
        </main>
      </BigDrop>
    );
  }

  /**
   * Selects a binary analysis for viewing in the right panel.
   */
  selectUpload(id, name, cwss, attack, base, environment, aggregate) {
    this.setState({
      selectedUpload: { id, name, cwss, attack, base, environment, aggregate },
      panel: "ANALYSIS",
    });
  }

  /**
   * Renders the loading spinner below the list of analyses.
   */
  renderLoading() {
    return (
      <div key={-1} className="row" style={{ marginTop: 20 }}>
        {!this.state.loading && (
          <h3 style={{ color: "var(--bor-color)" }}>
            {this.state.assets.length > 0
              ? "Up to date."
              : `No ${
                  !this.state.fetchable ||
                  this.state.fetchable ===
                    FETCHABLE.ASSET_IN_FACILITY_BY_NAME ||
                  this.state.fetchable ===
                    FETCHABLE.ASSET_IN_FACILITY_BY_NAME ||
                  this.state.fetchable === FETCHABLE.ASSET_IN_FACILITY_BY_TAG
                    ? "assets"
                    : "binaries"
                } found in this facility.`}
          </h3>
        )}
        {this.state.loading && <div className="lds-dual-ring"></div>}
      </div>
    );
  }
}
