import React from "react";
import "../../index.css";
import "./dashboard.css";

import "react-tippy/dist/tippy.css";
import { COLORS_STRING } from "../../colors";
import { Chart as ChartJS } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import Joyride from "react-joyride";

// icons
import { User, UserPlus } from "react-feather";

// components
import Headbar from "../headbar/headbar";
import Sidebar from "../sidebar/sidebar";
import Botbar from "../botbar/botbar";
import Notifications from "../login_page/notifications";
import TriTip from "./tri_tip";
import BigDrop from "../sidebar/big_drop";
import { getDashboard, getOrgAuditLog, getUserProfile } from "../../api";
import AuditHistory from "../binary_analysis_page/asset_summary_components/audit_history";
import { calcColor, calcHeader } from "../../helpers";
import TopRisks from "./top_risks";
import RightHandSidebar from "../sidebar/right_hand_sidebar";
import BinaryTable from "./binary_table/binary_table";
import BinaryOverview from "../binary_analysis_page/asset_summary_components/binary_overview";

// react-joyride user-onboarding steps
const steps = [
  {
    target: "#new-analysis-button",
    content:
      "Click here to analyze an asset and its binaries for vulnerabilities.",
    disableBeacon: true,
    placement: "right",
  },
  {
    target: "#all-sidebar-links",
    content:
      "Navigate to other pages here. You are currently on the Dashboard.",
    disableBeacon: true,
    placement: "right",
  },
  {
    target: "#binary-analysis-page-link",
    content:
      "The Binary Analysis page is particularly important. Completed binary analyses will appear here in detail.",
    disableBeacon: true,
    placement: "right",
  },
];

/**
 * The Dashboard of the application.
 * Displays a summary of the currently selected Organization, consisting
 * of the following datapoints:
 * 1. A Radar-Chart of various organization-level metadata.
 * 2. A Doughnut-Chart of assets scored by vulnerability level.
 * 3. A map of all facilities in the organization.
 * 4. A High Alerts and Top Risks section, displaying the most pressing vulnerabilities in the organization.
 * 5. A History section, displaying an asset audit log.
 */
export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    Notification.requestPermission();

    document.title = "BinLens - Dashboard";

    ChartJS.defaults.color = COLORS_STRING.PRI;

    let params = window.location.href.split("?")[1];
    let onboard = false;
    if (params) {
      onboard = params === "tutorial";
    }

    this.state = {
      facilities: [],

      // asset doughnut
      viewingAssets: false,

      assets: [],
      assets_total: 0,

      // onboarding
      onboard,

      top_risks: [],
      high_alerts: [],

      vulnerabilities_published: "-",
      vulnerabilities_unpublished: "-",

      // loading spinner logic
      loading: true,

      // high-level org info
      myRole: null,
      orgName: "",
      orgDesc: "",

      // audit log/org history
      audit: null,
      auditLoading: true,
    };
    this.chartRef = React.createRef();
  }

  static _this = null;

  componentDidMount() {
    Dashboard._this = this;
    this.init();
    this.resizeListener = window.addEventListener("resize", () =>
      this.setState({
        triTipOn: false,
      })
    );
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
    Dashboard._this = null;
  }

  async init() {
    // reset state
    this.setState({
      loading: true,
      topRisks: [],
      highAlerts: [],
      myRole: null,
      orgName: "",
      orgDesc: "",
      audit: null,
      auditLoading: true,
      facilities: [],
      triTipOn: false,
    });
    let dashboardData = await getDashboard();
    this.setState({ ...dashboardData, loading: false });

    let profile = await getUserProfile(window.localStorage.getItem("userid"));
    if (!profile) return;

    let myRole = "user";
    if (profile.roles[profile.org_id].includes("superadmin"))
      myRole = "superadmin";
    else if (profile.roles[profile.org_id].includes("admin")) myRole = "admin";
    this.setState({
      myRole,
      orgName: profile.org_details[profile.org_id].name,
      orgDesc: profile.org_details[profile.org_id].description,
    });

    // audit log/org history
    let audit = await getOrgAuditLog();
    this.setState({ audit, auditLoading: false });
  }

  /**
   * Render the page.
   */
  render() {
    // capitalize the username
    let username = window.localStorage.getItem("username");
    if (username) {
      username =
        username.substring(0, 1).toUpperCase() +
        username.substring(1, username.length);
    }

    return (
      <BigDrop>
        {this.state.onboard && (
          <button
            style={{
              position: "fixed",
              top: 40,
              right: 30,
              zIndex: 2001,
              background: "var(--contrast-color)",
              color: "white",
              borderRadius: 40,
            }}
            onClick={() => this.setState({ onboard: false })}
          >
            Cancel Tutorial
          </button>
        )}
        <Joyride
          locale={{
            back: "Back",
            close: "Close",
            last: "Next",
            next: "Next",
            open: "Open the dialog",
            skip: "Skip",
          }}
          disableScrolling
          continuous
          steps={steps}
          run={this.state.onboard}
          callback={(data) => {
            if (data.action === "reset") {
              window.location.assign(`#/binary?onboard`);
            }
            if (data.action === "close" && data.type === "step:after") {
              // This explicitly stops the tour (otherwise it displays a "beacon" to resume the tour)
              //this.setState({ onboard: false });
            }
          }}
          styles={{
            options: {
              arrowColor: "var(--sec-bg-color)",
              backgroundColor: "var(--sec-bg-color)",
              borderColor: "var(--bor-color)",
              primaryColor: "var(--sec-color)",
              textColor: "var(--pri-color)",
            },
          }}
        />

        <main>
          <Notifications />

          <Headbar />
          <Botbar />

          <div className="main-row">
            <Sidebar page="dashboard" />

            <div
              id="dash-media"
              className="content bin-anal-content"
              style={{
                display: "flex",
              }}
            >
              <div
                className="dash-media-child"
                style={{
                  width: "calc(70% - 60px)",
                  padding: 30,
                  paddingBottom: 0,
                  overflowX: "hidden",
                  overflowY: "scroll",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <h1
                      style={{
                        fontWeight: "800",
                        fontStyle: "normal",
                        marginBottom: 10,
                      }}
                    >
                      Hello, {username}
                    </h1>
                    <h3
                      style={{
                        fontWeight: "600",
                        fontSize: 16,
                        display: "flex",
                      }}
                    >
                      Here's a summary of {this.state.orgName}.
                    </h3>
                  </div>

                  {this.state.myRole === "admin" && (
                    <h3
                      style={{
                        border: "1px solid var(--green-color)",
                        color: "var(--green-color)",
                        borderRadius: 20,
                        padding: 10,
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 20,
                        background: "rgba(50, 168, 82, 0.1)",
                      }}
                    >
                      <UserPlus
                        style={{ width: 20, height: 20, marginRight: 5 }}
                      />
                      Admin
                    </h3>
                  )}
                  {this.state.myRole === "user" && (
                    <h3
                      style={{
                        border: "1px solid var(--sec-color)",
                        color: "var(--sec-color)",
                        borderRadius: 20,
                        padding: 10,
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 20,
                        background: "rgba(66, 135, 245, 0.1)",
                      }}
                    >
                      <User style={{ width: 20, height: 20, marginRight: 5 }} />
                      General User
                    </h3>
                  )}
                  {this.state.myRole === "superadmin" && (
                    <h3
                      style={{
                        border: "1px solid var(--yellow-color)",
                        color: "var(--yellow-color)",
                        borderRadius: 20,
                        padding: 10,
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 20,
                        background: "rgba(252, 186, 3, 0.1)",
                      }}
                    >
                      <UserPlus
                        style={{ width: 20, height: 20, marginRight: 5 }}
                      />
                      Superadmin
                    </h3>
                  )}
                </div>

                <p
                  style={{
                    marginTop: 10,
                    fontSize: 16,
                    fontWeight: 600,
                    color: "var(--pri-color-light)",
                  }}
                >
                  {this.state.orgDesc}
                </p>
                <div
                  style={{
                    width: "calc(100% + 20px)",

                    marginTop: 20,
                    display: "flex",

                    gap: 20,
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      height: 380,
                      width: "calc(50% - 20px)",
                      borderRadius: 15,
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        background: "var(--sec-bg-color)",
                        width: "100%",
                        borderRadius: 15,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 180,
                      }}
                    >
                      <h3
                        style={{
                          fontWeight: "700",
                          fontSize: 14,
                          position: "absolute",
                          top: 20,
                          left: 20,
                          color: "var(--pri-color)",
                        }}
                      >
                        O R G A N I Z A T I O N
                      </h3>

                      <div className="org-cves-cwes">
                        <div className="org-cves">
                          <h2>{this.state.vulnerabilities_published}</h2>
                          <h3>CVEs</h3>
                        </div>
                        <hr />
                        <div className="org-cwes">
                          <h2>{this.state.vulnerabilities_unpublished}</h2>
                          <h3>CWEs</h3>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        background: "var(--sec-bg-color)",
                        width: "100%",
                        borderRadius: 15,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 20,
                        height: 180,
                      }}
                    >
                      <h3
                        style={{
                          fontWeight: "700",
                          fontSize: 14,
                          position: "absolute",
                          top: 20,
                          left: 20,
                          color: "var(--pri-color)",
                        }}
                      >
                        B I N A R I E S
                      </h3>
                      <BinaryOverview
                        nRed={this.state.binaries_vulnerable}
                        nYellow={this.state.binaries_yellow}
                        nGreen={this.state.binaries_green}
                        fromDashboard={true}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      background: "var(--sec-bg-color)",
                      height: 380,
                      width: "calc(50% - 20px)",
                      borderRadius: 15,
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h3
                      style={{
                        fontWeight: "700",
                        fontSize: 14,
                        position: "absolute",
                        top: 20,
                        color: "var(--pri-color)",
                        left: 20,
                      }}
                    >
                      A S S E T S
                    </h3>

                    {this.state.assets_total !== 0 && (
                      <div
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          position: "absolute",
                          textAlign: "center",
                        }}
                      >
                        <h3
                          style={{
                            fontWeight: "600",
                            fontSize: 16,
                            marginBottom: 15,
                          }}
                        >
                          Total
                        </h3>
                        <h1
                          style={{
                            fontWeight: "800",
                            fontStyle: "normal",
                            marginBottom: 10,
                          }}
                        >
                          {this.state.assets_total}
                        </h1>
                      </div>
                    )}

                    {!this.state.loading && this.state.assets_total === 0 && (
                      <div
                        className="work-prep"
                        style={{ marginTop: 10, zIndex: 100 }}
                      >
                        <h2>Nothing's Here Yet.</h2>
                        <p style={{ width: 700, marginBottom: -30 }}>
                          You have yet to complete your first analysis!
                          <br />
                          Click the "Get Started" button to begin.
                        </p>
                        <button
                          className="onboard-start"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.setState({ onboard: true });
                          }}
                        >
                          Get Started
                        </button>
                      </div>
                    )}

                    {this.state.assets_total > 0 && (
                      <div
                        style={{
                          width: "100%",
                          maxWidth: 250,
                          alignSelf: "center",
                          height: 300,

                          position: "relative",
                          marginTop: 60,
                        }}
                      >
                        <Doughnut
                          ref={this.chartRef}
                          onClick={(event) => {
                            window.location.assign(`#/binary`);
                          }}
                          style={{ cursor: "pointer" }}
                          data={{
                            labels: ["Red", "Yellow", "Green"],
                            datasets: [
                              {
                                borderColor: COLORS_STRING.SEC_BG,
                                borderWidth: 10,

                                pointRadius: 0,

                                lineTension: 0.4,
                                borderRadius: 20,
                                backgroundColor: [
                                  COLORS_STRING.RED,
                                  COLORS_STRING.YELLOW,
                                  COLORS_STRING.GREEN,
                                ],

                                data: [
                                  this.state.assets_nRed,
                                  this.state.assets_nYellow,
                                  this.state.assets_nGreen,
                                ],
                              },
                            ],
                          }}
                          options={{
                            animation: false,
                            cutout: 90,
                            responsive: true,
                            scale: {
                              min: 0,
                              max: 1,

                              ticks: {
                                backdropColor: "rgba(0, 0, 0, 0)",
                                maxTicksLimit: 6,
                              },
                            },

                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {!this.state.loading && <BinaryTable />}
                  <div
                    style={{
                      background: "var(--sec-bg-color)",
                      height: 600,
                      width: "calc(100% - 20px)",
                      borderRadius: 15,
                      position: "relative",
                      overflow: "hidden",
                      marginBottom: 30,
                    }}
                  >
                    <h3
                      style={{
                        fontWeight: "700",
                        fontSize: 14,
                        position: "absolute",
                        top: 20,
                        left: 20,
                        color: "var(--pri-color)",
                      }}
                    >
                      F A C I L I T I E S
                    </h3>

                    <button
                      style={{
                        fontWeight: "700",
                        fontSize: 14,
                        position: "absolute",
                        top: 20,
                        right: 20,
                        color: "white",
                        background: "var(--sec-color)",
                      }}
                      onClick={() => {
                        if (Sidebar._this)
                          Sidebar._this.setState({ createFacilityModal: true });
                      }}
                    >
                      + New Facility
                    </button>

                    {this.state.triTipOn && (
                      <TriTip
                        title={this.state.selectedFacility.name}
                        subtitle={calcHeader(
                          this.state.selectedFacility.vulnerability_score
                        )}
                        light={this.state.selectedFacility.vulnerability_score}
                        position={this.state.triTipPosition}
                        delay={"0.2s"}
                        top={this.state.triTipTop}
                        left={this.state.triTipLeft}
                        id={this.state.selectedFacility.id}
                      />
                    )}
                    <ComposableMap
                      projection="geoMercator"
                      style={{ borderRadius: 10 }}
                    >
                      <Geographies geography={"./map_data.json"}>
                        {({ geographies }) =>
                          geographies.map((geo) => (
                            <Geography
                              style={{
                                default: { outline: "none" },
                                hover: { outline: "none" },
                                pressed: { outline: "none" },
                              }}
                              key={geo.rsmKey}
                              geography={geo}
                              fill="var(--bg-color)"
                              stroke="var(--bor-color)"
                            />
                          ))
                        }
                      </Geographies>
                      {this.state.facilities.map((f, i) => {
                        return (
                          <Marker
                            key={i}
                            coordinates={f.latLng}
                            onClick={(e) => {
                              this.setState({
                                triTipOn: true,
                                triTipPosition: `${
                                  e.nativeEvent.offsetY < 100 ? "TOP" : "BOTTOM"
                                }-${
                                  e.nativeEvent.offsetX < 100 ? "LEFT" : "RIGHT"
                                }`,
                                triTipLeft: e.nativeEvent.offsetX - 10,
                                triTipTop: e.nativeEvent.offsetY - 10,
                                selectedFacility: f,
                              });
                            }}
                          >
                            <circle
                              style={{ cursor: "pointer" }}
                              r={5}
                              fill={calcColor(f.vulnerability_score)}
                              filter={`drop-shadow( 0px 0px 5px ${calcColor(
                                f.vulnerability_score
                              )})`}
                            />
                          </Marker>
                        );
                      })}
                    </ComposableMap>
                  </div>
                </div>
              </div>

              <div
                className="dash-media-child"
                style={{
                  padding: 30,
                  width: "30%",
                  overflowY: "scroll",
                  minHeight: "calc(100vh - 146px)",
                  maxHeight: "calc(100vh - 146px)",
                  top: 0,
                  height: "fit-content",
                }}
              >
                <TopRisks
                  topRisks={this.state.top_risks}
                  highAlerts={this.state.high_alerts}
                  topRisksLoading={this.state.loading}
                />

                {!this.state.loading && (
                  <h2
                    style={{
                      fontWeight: "700",
                      fontSize: 16,
                      marginTop: 40,
                    }}
                  >
                    History
                  </h2>
                )}

                {!this.state.loading && this.state.auditLoading && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 20,
                    }}
                  >
                    <div className="lds-dual-ring"></div>
                  </div>
                )}

                {!this.state.auditLoading && (
                  <AuditHistory max={5} audit={this.state.audit} />
                )}

                {!this.state.auditLoading && this.state.audit.length === 0 && (
                  <h3
                    style={{
                      marginTop: 10,
                    }}
                  >
                    There is currently no history for this organization.
                  </h3>
                )}
              </div>
            </div>

            <RightHandSidebar
              tutorialClick={() => this.setState({ onboard: true })}
            />
          </div>
        </main>
      </BigDrop>
    );
  }
}
