import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import "./release_notes.css";
import { VERSION } from "../../helpers";

const NEW = [
  "Added SymbEx assessment. The SymbEx assessment uses symbolic execution to detect and report memory-safety violations and other forms of undefined behavior in binary programs at a significantly lower false-postive rate compared to other competing approaches.",
  "Added HeapOverflow SymbEx analysis. Detects instances of CWE-122: Heap-Based Buffer Overflow and CWE-415: Double Free.",
  "Added StackOverflow SymbEx analysis. Detects instances of CWE-121: Stack-Based Buffer Overflow.",
  "Added StringFormat SymbEx analysis. Detects instances of CWE-134: Use of Externally-Controlled Format String.",
  "Added SymbolicIP SymbEx analysis. Detects instances of CWE-121: Stack-Based Buffer Overflow that overwrite the instruction pointer.",
  "Added SymbolicWrite SymbEx analysis. Detects instances of CWE-129: Improper Validation of Array Index and CWE-823: Use of Out-of-range Pointer Offset.",
];
const UPDATED = ["Fixed various minor bugs and performance issues."];
const REMOVED = ["None."];

/**
 * Release notes modal that appears on the login page.
 * Displays what has been added, updated, and removed from this
 * release version.
 */
export default class ReleaseNotesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "NEW", // | UPDATED | REMOVED
    };
  }

  render() {
    return (
      <div className="release-notes-div">
        <h2 className="version-number">{VERSION}</h2>
        <div className="row">
          <h4
            onClick={() => this.setState({ page: "NEW" })}
            className={this.state.page === "NEW" ? "selected-rel" : "rele"}
          >
            New
          </h4>
          <h4
            onClick={() => this.setState({ page: "UPDATED" })}
            className={this.state.page === "UPDATED" ? "selected-rel" : "rele"}
          >
            Updated
          </h4>
          <h4
            onClick={() => this.setState({ page: "REMOVED" })}
            className={this.state.page === "REMOVED" ? "selected-rel" : "rele"}
          >
            Removed
          </h4>
        </div>
        {this.state.page === "NEW" && (
          <ul className="">
            {NEW.map((n) => (
              <li>{n}</li>
            ))}
          </ul>
        )}
        {this.state.page === "UPDATED" && (
          <ul>
            {UPDATED.map((u) => (
              <li>{u}</li>
            ))}
          </ul>
        )}
        {this.state.page === "REMOVED" && (
          <ul>
            {REMOVED.map((r) => (
              <li>{r}</li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

ReleaseNotesModal.propTypes = {
  /**
   * Callback that occurs when the user attempts to close the release
   * notes modal.
   */
  pressFunction: PropTypes.func.isRequired,
};
