import "../report_builder_sidebar.css";

export default function ReportBuilderSidebar({
  loading,
  binaries,
  setItems,
  items,
}) {
  const item_ids = items.map((i) => i.id);
  const all_bins_on = binaries.every((b) => item_ids.includes(b.name));

  function addItem(item) {
    if (items.filter((i) => i.id === item.id).length > 0) return;
    setItems((items) => [...items, item]);
    let scroller = document.getElementById("fake-report-document-scroller");
    scroller.scrollTop = scroller.scrollHeight;
  }

  /**
   * Toggle all bins on or off.
   * If there are any bin that are off, toggle all on.
   * All bins have to be on for all to toggle off.
   */
  const toggleAllBins = () => {
    if (all_bins_on) {
      setItems([]);
      return;
    }

    binaries.forEach((b) => {
      if (!item_ids.includes(b.name)) {
        setItems((items) => [
          ...items,
          {
            title: b.name,
            desc: "A binary.",
            id: b.name,
            severity: b.cwss.aggregate,
            cwss: b.cwss.base,
          },
        ]);
      }
    });
  };

  return (
    <div className="report-builder-sidebar">
      <div className="row toggle-all-row">
        <h2>Binaries</h2>
        <button
          onClick={toggleAllBins}
          className={
            all_bins_on
              ? "report-builder-toggle-all report-builder-toggle-all-on"
              : "report-builder-toggle-all"
          }
        >
          Toggle All
        </button>
      </div>

      <div className="report-builder-sidebar-scroller">
        {binaries &&
          binaries.map((binary) => (
            <div
              className={
                item_ids.includes(binary.name)
                  ? "report-builder-sidebar-item report-builder-sidebar-item-unsel"
                  : "report-builder-sidebar-item"
              }
              onClick={() => {
                addItem({
                  title: binary.name,
                  desc: "A binary.",
                  id: binary.name,
                  severity: binary.cwss.aggregate,
                  cwss: binary.cwss.base,
                });
              }}
            >
              <h2>{binary.name}</h2>

              <div className="cause-field">
                Vulnerability Score: {binary.cwss.aggregate}
              </div>
            </div>
          ))}

        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <div className="lds-dual-ring"></div>
          </div>
        )}
      </div>
    </div>
  );
}
