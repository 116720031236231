import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import { Param } from "../../constants";

// icons
import { ChevronDown } from "react-feather";

/**
 * Component represents a parameter to an API endpoint on the OpenAPI
 * page.
 * Represents a string parameter, chosen from a set of options.
 */
export default class ParamStringOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popup: false,
    };
    this.popupOff = this.popupOff.bind(this);
  }

  popupOff() {
    this.setState({ popup: false });
  }

  componentDidMount() {
    window.addEventListener("click", this.popupOff, false);
  }

  /**
   * Unmount click listener.
   */
  componentWillUnmount() {
    window.removeEventListener("click", this.popupOff, false);
  }

  render() {
    return (
      <div className="left-row req-row">
        <div className="req-key">
          <h2>{this.props.param.name}</h2>
          <h3>{this.props.param.type}</h3>
        </div>
        <div className="req-field">
          <div
            onClick={(e) => {
              e.stopPropagation();
              this.setState({
                popup: true,
              });
            }}
            className="up-init"
            style={{ width: 130 }}
          >
            <p>{this.props.param.value}</p>
            <ChevronDown className="user" />
            {this.state.popup && (
              <div
                className="collection-options"
                style={{
                  left: -1,
                  top: 37,
                  width: 150,
                }}
              >
                {this.props.param.options.map((o, i) => {
                  return (
                    <h3
                      key={i}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ popup: false });
                        this.props.func(o);
                      }}
                    >
                      {o}
                    </h3>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ParamStringOptions.propTypes = {
  /**
   * The parameter object being displayed.
   */
  param: PropTypes.instanceOf(Param).isRequired,

  /**
   * Callback that runs when the user inputs data.
   */
  func: PropTypes.func.isRequired
}