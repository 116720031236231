import PropTypes from "prop-types";
import "./asset_type_filter.css";
import { Modal } from "react-bootstrap";
import { ASSET_TYPES } from "../../../asset_types";

// icons
import { Type, X } from "react-feather";

/**
 * A popup that enables the user to select an asset type.
 * Is used currently when updating an existing asset's type,
 * but could be used in other places too.
 */
export default function AssetTypeFilter({
  open,
  close,
  setAssetType,
  selectedType,
  updater,
}) {
  let types = ["All Assets", ...ASSET_TYPES];
  if (updater) {
    types.splice(0, 1);
  }

  return (
    <Modal
      show={open}
      animation={false}
      className="modal-bg"
      enforceFocus={false}
    >
      <Modal.Body
        className="modal2 file-drop-modal"
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="asset-type-filter">
          <div className="new-analysis-base-row">
            <Type className="new-analysis-icon" />
            <h2>{updater ? "Update" : "Select"} Asset Type</h2>
          </div>
          <X className="new-analysis-close" onClick={close} />

          <div className="new-analysis-base-row">
            {types.map((type) => {
              return (
                <div
                  className={
                    selectedType === type
                      ? "new-analysis-option-selected"
                      : "new-analysis-option"
                  }
                  onClick={() => {
                    setAssetType(type);
                    close();
                  }}
                >
                  {type}
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

AssetTypeFilter.propTypes = {
  /**
   * Function to open the popup.
   */
  open: PropTypes.func.isRequired,

  /**
   * Function to close the popup.
   */
  close: PropTypes.func.isRequired,

  /**
   * Function called when an asset type is selected.
   */
  setAssetType: PropTypes.func.isRequired,

  /**
   * The currently selected asset type that is highlighted.
   */
  selectedType: PropTypes.string.isRequired,

  /**
   * If the popup is being called to update an asset or
   * not.
   */
  updater: PropTypes.bool,
};
