export * from "./user";
export * from "./org";
export * from "./deployment";
export * from "./dropzone";
export * from "./asset";
export * from "./queue";
export * from "./facility";
export * from "./binary";
export * from "./result";
export * from "./admin";
export * from "./delta";
export * from "./symbex";

export function getBasePath() {
  if (process.env.NODE_ENV === "development") return "http://localhost:15004";
  return window.location.protocol + "//" + window.location.host;
}
