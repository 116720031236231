import TextareaAutosize from "react-textarea-autosize";

export default function FacilitySummaryDescription({ facility }) {
  return (
    <div
      id="facility-media"
      style={{
        display: "flex",

        border: "2px solid var(--sec-bg-color)",
        padding: 30,
        borderRadius: 10,
      }}
    >
      <div
        className="facility-media-child"
        style={{
          whiteSpace: "pre-wrap",
          width: "100%",
        }}
      >
        <h2 style={{ fontSize: 16, marginBottom: 15, marginTop: 10 }}>
          Description
        </h2>

        <TextareaAutosize
          style={{
            textAlign: "left",
            whiteSpace: "pre-wrap",
            width: "100%",
            fontFamily: `"Mulish", sans-serif`,
            fontSize: 15,
            outline: "none !important",
            background: "var(--bg-color)",
            color: "var(--pri-color)",
          }}
          value={facility.description}
          placeholder="Add a description to the collection..."
        />
      </div>
    </div>
  );
}
