/*
    This component defines a table that display a set recently
    scanned binaries.

    Each binary displays its color score, name, data analyzed, # CVEs,
    and # CWEs.

    This component appears on the Dashboard Page.
*/

import { useState, useEffect } from "react";
import "./binary_table.css";
import { getAssets, getBinaries } from "../../../api";

// components
import BinaryTableRow from "./binary_table_row";

// constants
const MAX_BINS = 10;

export default function BinaryTable() {
  let [loading, setLoading] = useState(true);
  let [binaries, setBinaries] = useState([]);
  /**
   * Grab the most recent binaries until we have exceeded
   * the MAX_BINS cap.
   *
   * TODO this function could be updated to account for the
   * following edge cases:
   * 1. There exists only unfinished or unassessable binaries
   *    in each loaded asset.
   * 2. ...others
   * These edge cases can be resolved by recursively calling init()
   * multiple times, depending on the number of assets loaded each
   * iteration.
   */
  const init = async () => {
    let new_bins = [];

    let assets = await getAssets(false, 0, MAX_BINS, null, null);
    if (!assets) {
      setLoading(false);
      return;
    }
    for (let asset of assets) {
      let bins = await getBinaries(asset.ref_id);
      for (let binary of bins.assessable) {
        if (binary.state && binary.state === "completed") {
          new_bins.push({
            ...binary,
            asset_id: asset.ref_id,
            date: asset.started.split(" ")[0],
            asset_name: asset.name,
            facility_id: asset.facility_id,
          });
          if (new_bins.length >= MAX_BINS) {
            setBinaries(new_bins);
            setLoading(false);
            return;
          }
        }
      }
    }
    setBinaries(new_bins);
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div className="lds-dual-ring"></div>
      </div>
    );
  if (binaries.length === 0) return null;
  return (
    <div className="binary-table">
      <h2 className="binary-table-title">R E C E N T &nbsp; B I N A R I E S</h2>
      <div className="binary-table-row">
        <div className="binary-table-cell">
          <h2>Vulnerability</h2>
        </div>
        <div className="binary-table-cell">
          <h2>Name</h2>
        </div>
        <div className="binary-table-cell">
          <h2>CVEs</h2>
        </div>
        <div className="binary-table-cell">
          <h2>CWEs</h2>
        </div>
        <div className="binary-table-cell">
          <h2>Date Analyzed</h2>
        </div>
      </div>
      {binaries.map((binary) => (
        <BinaryTableRow
          binary={binary}
          key={`${binary.asset_id}_${binary.name}`}
        />
      ))}
    </div>
  );
}
