import { useState, useEffect, createRef, useRef } from "react";
import { createPortal } from "react-dom";
import "./binary_summary_header.css";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

// icons
import { Terminal, FileText, ChevronRight } from "react-feather";

// the amount of  width taken up by other components
const TAKEN_WIDTH = 81 + 403 + 110 + 160 + 41;

/**
 * Header of the BinarySummary panel.
 * Enables users to swap between various pages of the BinarySummary.
 * Additionally lets users open the Binary in the DeepDive viewer,
 * and generate a report for the binary.
 *
 * If the horizontal width is too small to contain all of the tabs, the
 * rightmost tabs will appear in a dropdown menu instead.
 */
export default function BinarySummaryHeader({ pages, page, setPage, binary }) {
  // TODO determine page tab order based on localStorage
  // (i.e. the order the user had their tabs in last)
  let [allPages, setAllPages] = useState([...pages]);

  // the pages to be viewed horizontally vs vertically
  let [horPages, setHorPages] = useState([...pages]);
  let [vertPages, setVertPages] = useState([]);
  let [dropdownOn, setDropdownOn] = useState(false);

  // generate refs for each tab
  let tabRefs = [];
  for (let _ of allPages) {
    tabRefs.push(createRef());
  }

  // figure out how much max space there is
  const maxSpace = () => window.innerWidth - TAKEN_WIDTH;

  // figure out how much space each tab takes up
  // generates an array of integers
  const tabSpace = () => {
    let tab_space = [];
    for (let i = 0; i < allPages.length; i++) {
      //tab_space.push(tabRefs[i].current.offsetWidth);
      tab_space.push(200);
    }
    return tab_space;
  };

  // figure out how many tabs can fit on the horizontal bar
  // split the "pages" array into two
  const splitPages = () => {
    let max_space = maxSpace();
    let tab_space = tabSpace();
    let hor_pages = [];
    let vert_pages = [];
    for (let i = 0; i < allPages.length; i++) {
      if (max_space > 0) {
        hor_pages.push(allPages[i]);
      } else {
        vert_pages.push(allPages[i]);
      }
      max_space -= tab_space[i];
    }
    if (vert_pages.includes(page)) {
      let swap_page = hor_pages.pop();
      hor_pages.push(page);
      vert_pages = vert_pages.filter((p) => p !== page);
      vert_pages.unshift(swap_page);
      setAllPages([...hor_pages, ...vert_pages]);
    }

    setHorPages(hor_pages);
    setVertPages(vert_pages);
  };

  // mount window resize listener
  const resize = () => {
    splitPages();
  };
  const dropdownOff = () => {
    setDropdownOn(false);
  };
  useEffect(() => {
    window.addEventListener("resize", resize);
    window.addEventListener("mousedown", dropdownOff);
    return () => {
      window.removeEventListener("resize", resize);
      window.removeEventListener("mousedown", dropdownOff);
    };
  }, [binary]);
  useEffect(() => {
    window.removeEventListener("resize", resize);
    window.addEventListener("resize", resize);
    resize();
  }, [page]);

  const useDraggableInPortal = () => {
    const self = useRef({}).current;

    useEffect(() => {
      const div = document.createElement("div");
      div.style.position = "absolute";
      div.style.pointerEvents = "none";
      div.style.top = "0";
      div.style.width = "100%";
      div.style.height = "100%";
      self.elt = div;
      document.body.appendChild(div);
      return () => {
        document.body.removeChild(div);
      };
    }, [self]);

    return (render) =>
      (provided, ...args) => {
        const element = render(provided, ...args);
        if (provided.draggableProps.style.position === "fixed") {
          return createPortal(element, self.elt);
        }
        return element;
      };
  };
  const renderDraggable = useDraggableInPortal();

  return (
    <div className="toggle-header">
      <DragDropContext
        onDragEnd={(result) => {
          if (!result.destination) {
            return;
          }
          setAllPages((allPages) => {
            const [removed] = allPages.splice(result.source.index, 1);
            allPages.splice(result.destination.index, 0, removed);
            return allPages;
          });
          resize();
        }}
      >
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {horPages.map((p, i) => {
                return (
                  <Draggable ref={tabRefs[i]} draggableId={p} index={i} key={p}>
                    {renderDraggable((provided) => (
                      <div
                        className={
                          page === p
                            ? "toggle-header-item sel-toggle-header-item"
                            : "toggle-header-item"
                        }
                        onClick={() => {
                          setPage(p);
                        }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <h3
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {p}
                        </h3>

                        {page === p && <div className="blocker"></div>}

                        {provided.placeholder}
                      </div>
                    ))}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {vertPages.length > 0 && (
        <div className="vert-pages-holder">
          <button
            onClick={() => setDropdownOn(true)}
            className="vert-pages-on-button"
          >
            <ChevronRight className="vert-pages-icon" />
            <ChevronRight className="vert-pages-icon" />
          </button>
          {dropdownOn && (
            <div className="vert-pages">
              {vertPages.map((p, i) => (
                <div
                  key={i}
                  className="vert-page"
                  onMouseDown={(e) => {
                    setDropdownOn(false);
                    setPage(p);
                  }}
                >
                  {p}
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      <div className="side-buttons">
        <button
          style={{ width: 110 }}
          className="upload"
          onClick={() => {
            window.location.assign(
              `#/deepdive?name=${binary.name}&id=${binary.id}`
            );
          }}
        >
          <h3>Deep Dive</h3>
          <Terminal className="edit-icon" />
        </button>

        {binary.aggregate && (
          <button
            style={{ width: 160 }}
            className="upload"
            onClick={() => {
              window.location.assign(
                `#/report/builder/binary?asset_id=${binary.id}&binary=${binary.name}&fromBinaryAnalysis`
              );
            }}
          >
            <h3>Generate Report</h3>
            <FileText className="edit-icon" />
          </button>
        )}
      </div>
    </div>
  );
}
