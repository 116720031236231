import { getBasePath } from ".";

/**
 * Creates a new organization whos sole member is the superadmin.
 * Is accessible only by the superadmin.
 */
export async function createOrganization(name, description) {
  let res = await fetch(getBasePath() + `/org/add`, {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      name,
      description,
    }),
  });
  let parsed = await res.text();
  return parsed;
}

/**
 * Updates an existing organization.
 */
export async function updateOrganization(name, description, org_id) {
  let res = await fetch(getBasePath() + `/org/update`, {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      name,
      description,
      org_id,
    }),
  });
  let parsed = await res.text();
  return parsed;
}

/**
 * Get the audit log history of the user's current organization.
 */
export async function getOrgAuditLog() {
  let res = await fetch(getBasePath() + `/org/audit`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  let parsed = await res.json();
  return parsed;
}

/**
 * Get all the flash alerts in an organization.
 */
export async function getAlerts() {
  let res = await fetch(getBasePath() + "/org/alerts", {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  let parsed = await res.json();
  return parsed;
}

/**
 * Create a new flash alerts.
 * This function only works if the calling user is
 * the SUPERADMIN.
 */
export async function createAlert(alert) {
  let body = new FormData();
  body.append("alert", alert);
  let res = await fetch(getBasePath() + "/org/createalert", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  let parsed = await res.text();
  return parsed;
}

/**
 * Mark an alert a read.
 */
export async function readAlert(alert_id) {
  let body = new FormData();
  body.append("alert_id", alert_id);
  let res = await fetch(getBasePath() + "/org/readalert", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  let parsed = await res.text();
  return parsed;
}

/**
 * Get the data used to generate the dashboard.
 */
export async function getDashboard() {
  let res = await fetch(getBasePath() + `/org/dashboard`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  let parsed = await res.json();

  parsed.facilities = parsed.facilities.map((f) => {
    if (Array.isArray(f.latLng)) return f;
    let parts = f.latLng.split(",");
    f.latLng = [parseFloat(parts[0]), parseFloat(parts[1])];
    return f;
  });
  return parsed;
}
