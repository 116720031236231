import { useState } from "react";
import "./create_superadmin_page.css";
import { TextField } from "@mui/material";
import Copyright from "../copyright/copyright";
import LoginHeadbar from "../login_headbar/login_headbar";
import { createSuperadmin, login0 } from "../../../api";

/**
 * Allows the user to configure the SUPERADMIN account on
 * deplyoments that do not yet have a SUPERADMIN.
 */
export default function CreateSuperadminPage() {
  let [username, setUsername] = useState("");
  let [email, setEmail] = useState("");
  let [password1, setPassword1] = useState("");
  let [password2, setPassword2] = useState("");

  document.title = "BinLens - Create Superadmin Account";

  const enterClick = (e) => {
    if (e.key === "Enter") {
      complete();
    }
  };

  const complete = async () => {
    if (username === "" || password1 === "" || password2 === "") {
      alert("Please fill out every form field.");
      return;
    }
    if (!validUsername(username)) {
      alert("Please fill out a valid username.");
      return;
    }
    if (!validEmail(email)) {
      alert("Please fill out a valid Email.");
      return;
    }
    if (strongPasswordScore(password1) < 4) {
      alert("Ensure the Strong Password policy is met.");
      return;
    }
    if (password1 !== password2) {
      alert("Please ensure both passwords you entered are the same.");
      return;
    }
    let res = await createSuperadmin(username, email, password1);
    if (!res.ok) {
      alert("The superadmin account could not be created!");
      return;
    }
    try {
      let parsed = await res.json();
      window.localStorage.setItem("auth", parsed.access_token);

      res = await login0(username, password1);
      if (res.hasOwnProperty("details")) {
        if (!res.eula) {
          window.location.assign("#/eula");
          return;
        }
        window.location.assign("#/dashboard");
        return;
      }
      window.location.assign("#/setupgoogleauth");
    } catch {
      alert(
        "SUPERADMIN account created, but something went wrong with initial login."
      );
    }
  };

  return (
    <div className="login-page">
      <LoginHeadbar />
      <h1>Create Superadmin Account</h1>
      <div className="login-center create-superadmin-center">
        <p>
          Please configure the SUPERADMIN account for this deployment.
          <br />
          <br />
          You will be unable to reset these credentials, so please store them in
          a safe location.
        </p>

        <TextField
          onKeyDown={enterClick}
          className={
            username === ""
              ? "enter-username"
              : validUsername(username)
              ? "enter-username enter-username-valid"
              : "enter-username enter-username-invalid"
          }
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          label="Username"
          fullWidth
        />

        <TextField
          onKeyDown={enterClick}
          className={
            email === ""
              ? "enter-username"
              : validEmail(email)
              ? "enter-username enter-username-valid"
              : "enter-username enter-username-invalid"
          }
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label="Email"
          fullWidth
        />

        <p>
          To conform with our Strong Password policy, you are required to use a
          sufficiently strong password.
          <br />
          <br /> The password must be longer than 7 characters. It must include
          atleast 1 digit, 1 uppercase letter, and 1 lowercase letter.
        </p>

        <PasswordEntry
          password1={password1}
          setPassword1={setPassword1}
          password2={password2}
          setPassword2={setPassword2}
          enterClick={enterClick}
        />

        <button onClick={complete} className="create-superadmin-account-button">
          Create Account
        </button>
      </div>

      <Copyright />
    </div>
  );
}

function PasswordEntry({
  password1,
  setPassword1,
  password2,
  setPassword2,
  enterClick,
}) {
  const pass_score = strongPasswordScore(password1);
  return (
    <>
      {password1 !== "" && (
        <>
          {pass_score < 4 && (
            <p className="invalid-username">Password Not Strong Enough</p>
          )}
          {pass_score >= 4 && <p className="valid-username">Valid Password</p>}
        </>
      )}
      {password1 !== "" && (
        <div className="pass-strength">
          {pass_score > 0 && (
            <div
              className="pass-strength-on"
              style={{
                background: pass_score >= 4 ? "var(--green-color)" : "",
              }}
            ></div>
          )}
          {pass_score <= 0 && <div className="pass-strength-off"></div>}

          {pass_score > 1 && (
            <div
              style={{
                background: pass_score >= 4 ? "var(--green-color)" : "",
              }}
              className="pass-strength-on"
            ></div>
          )}
          {pass_score <= 1 && <div className="pass-strength-off"></div>}

          {pass_score > 2 && (
            <div
              style={{
                background: pass_score >= 4 ? "var(--green-color)" : "",
              }}
              className="pass-strength-on"
            ></div>
          )}
          {pass_score <= 2 && <div className="pass-strength-off"></div>}

          {pass_score > 3 && (
            <div
              style={{
                background: pass_score >= 4 ? "var(--green-color)" : "",
              }}
              className="pass-strength-on"
            ></div>
          )}
          {pass_score <= 3 && <div className="pass-strength-off"></div>}
        </div>
      )}
      <TextField
        onKeyDown={enterClick}
        className={
          password1 === ""
            ? "enter-pass1"
            : pass_score >= 4
            ? "enter-pass1 enter-pass1-valid"
            : "enter-pass1 enter-pass1-invalid"
        }
        value={password1}
        onChange={(e) => setPassword1(e.target.value)}
        label="New Password"
        fullWidth
        type={"password"}
      />

      {password2 !== "" && (
        <>
          {password1 !== password2 && (
            <p className="invalid-username">Passwords Must Match</p>
          )}
          {password1 === password2 && (
            <p className="valid-username">Passwords Match</p>
          )}
        </>
      )}
      <TextField
        onKeyDown={enterClick}
        className={
          password2 === ""
            ? "enter-pass2"
            : password1 === password2
            ? "enter-pass2 enter-pass2-valid"
            : "enter-pass2 enter-pass2-invalid"
        }
        value={password2}
        onChange={(e) => setPassword2(e.target.value)}
        label="Re-Enter Password"
        fullWidth
        type={"password"}
      />
    </>
  );
}

/**
 * The Superadmin name may only contain letters and digits.
 */
function validUsername(username) {
  return username.match(/^[a-zA-Z0-9]+$/);
}

function validEmail(email) {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}

/**
 * Determine the strong password score for a given
 * password.
 * Valid passwords must score 4 or more.
 */
const strongPasswordScore = (password) => {
  let score = 0;

  if (password.length >= 7) score++;
  if (password.search(/\d/) !== -1) score++;
  if (password.search(/[a-z]/) !== -1) score++;
  if (password.search(/[A-Z]/) !== -1) score++;

  return score;
};
