import { getBasePath } from ".";

/**
 * Get the analyzation progress of all currently analyzing assets
 * in an organization.
 */
export async function getAssetProgress() {
  let res = await fetch(getBasePath() + `/queue/progress`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
      "Content-Type": "application/json",
    }),
  });
  let parsed = await res.json();
  return parsed;
}

export async function reprioritizeAssessment(
  org_id,
  asset_id,
  bin_id,
  tool_id,
  new_idx
) {
  let body = new FormData();
  body.append("org_id", org_id);
  body.append("asset_id", asset_id);
  body.append("bin_id", bin_id);
  body.append("tool_id", tool_id);
  body.append("new_idx", new_idx);

  await fetch(getBasePath() + "/queue/assessment/reprioritize", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
}

export async function reprioritizeBinary(
  org_id,
  asset_id,
  bin_id,
  other_org_id,
  other_asset_id,
  other_bin_id,
  before
) {
  let body = new FormData();
  body.append("org_id", org_id);
  body.append("asset_id", asset_id);
  body.append("bin_id", bin_id);
  body.append("other_org_id", other_org_id);
  body.append("other_asset_id", other_asset_id);
  body.append("other_bin_id", other_bin_id);
  body.append("before", before);

  await fetch(getBasePath() + "/queue/binary/reprioritize", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
}

export async function reprioritizeAsset(org_id, asset_id, new_idx) {
  let body = new FormData();
  body.append("org_id", org_id);
  body.append("asset_id", asset_id);
  body.append("new_idx", new_idx);
  await fetch(getBasePath() + "/queue/asset/reprioritize", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
}

export async function stopAssessment(org_id, asset_id, bin_id, tool_id) {
  let body = new FormData();
  body.append("org_id", org_id);
  body.append("asset_id", asset_id);
  body.append("bin_id", bin_id);
  body.append("tool_id", tool_id);

  await fetch(getBasePath() + "/queue/assessment/stop", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
}

export async function stopAsset(org_id, asset_id) {
  let body = new FormData();
  body.append("org_id", org_id);
  body.append("asset_id", asset_id);
  let res = await fetch(getBasePath() + "/queue/asset/stop", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  let parsed = await res.text();
  return parsed;
}

export async function deleteBinaryFile(org_id, asset_id, bin_id) {
  let body = new FormData();
  body.append("org_id", org_id);
  body.append("asset_id", asset_id);
  body.append("bin_id", bin_id);
  await fetch(getBasePath() + "/queue/binary/stop", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
}
