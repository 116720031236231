import { useState } from "react";
import PropTypes from "prop-types";
import { ISA_Mapping } from "./isa_mapping";
import { map_to_isa_3_3 } from "./mapping_datastructures";
import BinarySummaryInfoHelper from "../binary_summary_components/binary_summary_info_helper";
import { CheckCircle, Search } from "react-feather";
import { Binary } from "../../../constants";

/**
 * This component displays mappings to ISA 62443-3-3 compliance.
 * It is diplayed as a tab in the Binary Summary Panel.
 */
export default function ISA_3_3({ asset_id, binary, cwes, lineage }) {
  let [search, setSearch] = useState("");

  let sorted_cwes = map_to_isa_3_3(cwes, lineage);
  if (sorted_cwes.length === 0)
    return (
      <div style={{ marginTop: -10 }}>
        <h4 style={{ fontWeight: 500 }}>
          No ISA/IEC 62443 3-3 mappings detected in this binary.
        </h4>
        <CheckCircle className="big-icon" />
      </div>
    );
  return (
    <div style={{ paddingTop: 50 }}>
      <h4 className="mappings-search">
        <div className="search-row">
          <Search className="user-icon" />
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={`Search ${sorted_cwes.length} mappings...`}
          ></input>
        </div>
      </h4>
      <BinarySummaryInfoHelper
        text="The ISA/IEC 62443 series of standards and technical 
              reports are arranged into separate groups, with each 
              having a different security focus. ISA/IEC 62443-3-3 
              defines security for industrial automation and control 
              systems; specifically system security requirements 
              and security levels."
      />
      {sorted_cwes.map((cwe) => {
        if (!cwe.cwe.toLowerCase().includes(search.toLowerCase())) return null;
        return (
          <ISA_Mapping
            asset_id={asset_id}
            binary={binary}
            cwe={cwe}
            requirements={cwe.requirements}
          />
        );
      })}
    </div>
  );
}

ISA_3_3.propTypes = {
  /**
   * The ID of the asset being mapped from.
   */
  asset_id: PropTypes.string.isRequired,

  /**
   * The binary being mapped from.
   */
  binary: PropTypes.instanceOf(Binary).isRequired,

  /**
   * List of CWEs discovered in the binary.
   * Mappings are generated from CWEs.
   */
  cwes: PropTypes.arrayOf(PropTypes.object).isRequired,

  /**
   * Lineage analysis result.
   * Maps to a specific compliance line-item.
   */
  lineage: PropTypes.bool.isRequired,
};
