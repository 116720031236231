import React from "react";
import PropTypes from "prop-types";
import { logout } from "../../api";
import "../../index.css";

/**
 * Modal that appears when a user clicks the logout button from the header
 * bar. Allows the user to logout of the application.
 */
export default class LogoutModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="logout-modal">
        <button onClick={logout} className="upload">
          Logout
        </button>
        <button onClick={this.props.backFunction} className="next-off">
          Cancel
        </button>
      </div>
    );
  }
}

LogoutModal.propTypes = {
  /**
   * Callback that runs when the user closes the modal.
   */
  backFunction: PropTypes.func.isRequired,
};
