import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import AssetFetcher from "../../asset_fetcher";

// icons
// icons
import { Search } from "react-feather";

// components
import DeepDiveAsset from "./deep_dive_asset";
import { Binary } from "../../constants";

/**
 * The set of all assets to choose from on the Deep Dive Page.
 * Displays as a popup which appears when a user clicks on the "+"
 * button in either the Disassembler and Decompiler.
 * Upon clicking an asset in the list, the user is prompted to
 * select a binary. Upon click a binary, the binary's corresponding
 * disassembled/decompiled view appears.
 */
export default class DeepDiveAssets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      assets: [],
      loading: true,
      searchText: "",
    };

    this.init = this.init.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    this.assetFetcher = null;
    this.setState({ assets: [], loading: true });
    this.assetFetcher = new AssetFetcher(
      document.getElementById(this.props.id),
      (assets, self) => {
        // only set state if this is our asset fetcher!
        if (this.assetFetcher === self)
          this.setState({ assets, loading: false });
      },
      null,
      this.state.searchText,
      "date"
    );
    this.assetFetcher.loadAssets();
  }

  render() {
    return (
      <div
        className="collection-options"
        style={{
          left: 0,
          top: 32,
          width: 300,
          height: "auto",
          paddingBottom: 0,
          display: this.props.open ? "block" : "none",
        }}
      >
        <div
          className="searchbar"
          style={{
            width: 280,
            marginLeft: 10,
            marginTop: 5,
          }}
        >
          <div className="internal-searchbar" style={{ height: 18 }}>
            <Search className="search" />
            <input
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                this.setState({ searchText: e.target.value });
                if (this.searchTimeout) clearTimeout(this.searchTimeout);
                this.searchTimeout = setTimeout(this.init, 500);
              }}
              value={this.state.searchText}
              placeholder="Search Assets..."
            ></input>
          </div>
        </div>
        {this.state.loading && (
          <div
            style={{
              width: 280,
              display: "flex",
              justifyContent: "center",
              paddingBottom: 20,
            }}
          >
          <div className="lds-dual-ring"></div>
          </div>
        )}
        <div
          className="select-deep"
          id={this.props.id}
          onScroll={() => {
            if (this.assetFetcher) this.assetFetcher.loadAssets();
          }}
        >
          {this.state.assets.map((a, i) => {
            if (a.state === "unassessable") return null;
            return (
              <DeepDiveAsset
                key={i}
                asset={a}
                shownFiles={this.props.shownFiles}
                selectBinary={this.props.selectBinary}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

DeepDiveAssets.propTypes = {
  /**
   * Is the popup open or not.
   */
  open: PropTypes.bool.isRequired,

  /**
   * The set of binaries currently displaying as tabs in the
   * decompiler/disassembler.
   */
  shownFiles: PropTypes.arrayOf(PropTypes.instanceOf(Binary)).isRequired,

  /**
   * Callback that selects a binary to view.
   */
  selectBinary: PropTypes.func.isRequired,
};
