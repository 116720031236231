import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import "./eula.css";
import { getExpiration } from "../../api";
import { unixToReadable } from "../../helpers";

/**
 * EULA for the application.
 * Appears on the EULAPpage or a toggleable state on
 * the Login 0 Page.
 */
export default class EULA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doneReading: false,
      expiration: null,
    };

    this.init = this.init.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    let expiration = await getExpiration();
    this.setState({ expiration });
  }

  render() {
    return (
      <div
        className="release-notes-div eula-written"
        style={{ width: "fit-content" }}
      >
        <div
          style={{
            overflowX: "hidden",
            maxHeight: 200,
            width: "calc(100% + 40px)",
            overflowY: "scroll",
          }}
          className="eula-content"
          onScroll={(e) => {
            if (!this.state.doneReading) {
              this.setState({ doneReading: true });
            }
          }}
        >
          <h1>
            BinLens Binary Vulnerability Analysis and Reporting Platform VM in
            the Cloud End User License Agreement (EULA)
          </h1>

          {/* Custom EULA fields. */}
          {this.state.expiration &&
            "maxBinaries" in this.state.expiration &&
            "maxAssets" in this.state.expiration &&
            "timeout" in this.state.expiration &&
            "is_feature_enabled" in this.state.expiration && (
              <div className="expiration-eula-info">
                <h2>Max Allowed Binaries</h2>
                <p>{this.state.expiration.maxBinaries}</p>

                <h2>Max Allowed Assets</h2>
                <p>{this.state.expiration.maxAssets}</p>

                <h2>Expiration Date</h2>
                <p>{unixToReadable(this.state.expiration.timeout)}</p>

                <h2>Add-Ons</h2>
                <p>
                  OWASP 10:2021:{" "}
                  {this.state.expiration.is_feature_enabled["OWASP 10:2021"]
                    ? "Enabled"
                    : "Disabled"}
                  <br />
                  ISA/IEC 62443-3-3:{" "}
                  {"" +
                  this.state.expiration.is_feature_enabled["ISA/IEC 62443-3-3"]
                    ? "Enabled"
                    : "Disabled"}
                  <br />
                  ISA/IEC 62443-4-2:{" "}
                  {this.state.expiration.is_feature_enabled["ISA/IEC 62443-4-2"]
                    ? "Enabled"
                    : "Disabled"}
                  NIST 800-53:{" "}
                  {this.state.expiration.is_feature_enabled["NIST 800-53"]
                    ? "Enabled"
                    : "Disabled"}
                  NIST 800-82:{" "}
                  {this.state.expiration.is_feature_enabled["NIST 800-82"]
                    ? "Enabled"
                    : "Disabled"}
                </p>
              </div>
            )}

          <p>
            PLEASE READ THIS SOFTWARE LICENSE AGREEMENT (“LICENSE”) CAREFULLY
            BEFORE USING OBJECTSECURITY BinLens SOFTWARE. BY USING THE
            OBJECTSECURITY SOFTWARE, YOU ARE AGREEING TO BE BOUND BY THE TERMS
            OF THIS LICENSE.
            <br />
            <br />
            Timestamp Recorded in BinLens at Login:
            <br />
            <br />
            IF YOU DO NOT AGREE TO THE TERMS OF THIS LICENSE, DO NOT USE THE
            OBJECTSECURITY SOFTWARE AND CLICK “DISAGREE”.
            <br />
            <br />
            IF YOU AGREE TO THE TERMS OF THIS LICENSE, CLICK “AGREE”.
          </p>

          <p>
            Software made available through the ObjectSecurity VM in the Cloud
            services offering is licensed, not sold, to you. Your license to
            each software instance is subject to your acceptance of this
            Licensed Application End User License Agreement (“Standard EULA”).
            Your license to any ObjectSecurity software platform offering under
            this Standard EULA is granted by ObjectSecurity, and your license to
            any Third-Party App under this Standard EULA is granted by the
            Application Provider of that Third-Party App. Any software
            application or platform that is subject to this Standard EULA is
            referred to herein as the “Licensed Application.” The Application
            Provider or ObjectSecurity as applicable (“Licensor”) reserves all
            rights in and to the Licensed Application not expressly granted to
            you under this Standard EULA.
          </p>

          <h2>Alpha and Beta Version (Pre-Release) Testing</h2>
          <p>
            If you are given access to an Alpha or Beta version of
            BinLens, you are accepting to participate in
            “Testing” a pre-release, which provides access to confidential
            materials or services currently in development by ObjectSecurity.
            ObjectSecurity wishes to benefit from your experiences as a
            Pre-Release Tester of ObjectSecurity’s materials or service. As a
            Pre-Release Tester, you agree to:
          </p>

          <ul>
            <li>
              Actively use and evaluate the ObjectSecurity OT/ICS Cyber AI
              Vulnerability Analysis and Report Platform.
            </li>
            <li>
              Provide the types of feedback requested and respond to questions.
            </li>
            <li>Give ObjectSecurity all rights to any feedback you submit.</li>
            <li>
              Not disclose to those who are not your employees that you are
              participating in this test.
            </li>
            <li>Not show the materials to those who are not your employees.</li>
            <li>
              Not share copies, pictures, or videos of the test materials in any
              form.
            </li>
            <li>
              Return the test materials (ObjectSecurity will provide a pre-paid
              method for the return of the test materials), if requested.
            </li>
          </ul>
          <p>
            Any feedback, ideas, modifications, suggestions, improvements, and
            the like made by you concerning the Pre-Release Test software
            (“Supportive Information”) will be the property of ObjectSecurity.
            You agree to assign, and at this moment give, all rights, titles,
            and interests worldwide in the Supportive Information and the
            related intellectual property rights to ObjectSecurity and agree to
            assist ObjectSecurity, at ObjectSecurity’s expense, in perfecting
            and enforcing such rights.
          </p>
          <p>
            ObjectSecurity may disclose or use Supportive Information for any
            purposes whatsoever without any obligation to you. You agree to pay
            all incidental costs (such as costs for Internet and phone services,
            accessories, cabling, etc.) associated with the testing of the
            Pre-Release Test and incurred during your possession of the
            Pre-Release Test software.
          </p>

          <h1>
            No Service Level Agreement (SLA) is available for Pre-Release
            versions of BinLens.
          </h1>

          <ol>
            <li>
              <b>Confidentiality</b>

              <ol type="a">
                <li>
                  You acknowledge that as a Pre-Release Tester, you may have
                  access to, and ObjectSecurity may disclose to you, certain
                  valuable information belonging to and relating to
                  ObjectSecurity which ObjectSecurity considers confidential,
                  including, but not limited to, information concerning the
                  Pre-Release Test software, the Alpha Test software’s
                  trademark(s) and trade name(s), computer programs, user
                  manuals, sales and marketing plans, business plans, processes,
                  customer lists, and other trade secrets (“Confidential
                  Information”). You shall use the Confidential Information
                  solely for testing purposes and, for a period of five (5)
                  years from your receipt of the Confidential Information, shall
                  not disclose, without ObjectSecurity’s written consent, such
                  Confidential Information to third parties or use such
                  Confidential Information for its own benefit or for the
                  benefit of third parties.
                </li>
                <li>
                  Suppose you are a company or other entity. In that case, you
                  shall disclose Confidential Information only to those of its
                  employees who need to know such information for the
                  agreed-upon Pre-Release testing and shall ensure that its
                  employees observe the confidentiality obligations in this
                  Section 5. You acknowledge that the Pre-Release Test software
                  contains Confidential Information developed or acquired by
                  ObjectSecurity and that all rights therein and in other
                  ObjectSecurity Confidential Information remain in
                  ObjectSecurity. Then you will not disclose that it is
                  evaluating or testing or has evaluated or tested the Alpha
                  Test software to any third party without ObjectSecurity’s
                  prior written consent. In addition, you agree to treat any
                  communications and reports prepared under this Agreement,
                  including, but not limited to, those prepared following
                  Section 4.1, as Confidential Information and will not divulge
                  the existence or content of such communications or reports to
                  any third party without ObjectSecurity’s prior written
                  consent.
                </li>
                <li>
                  This Agreement shall impose no obligation of confidentiality
                  upon you concerning any portion of the Confidential
                  Information which: (i) now or hereafter, through no act or
                  failure to act on your part, becomes generally known or
                  available; (ii) is known to you at the time you receive same
                  from ObjectSecurity as evidenced by written records; (iii) is
                  hereafter furnished to you by a third party as a matter of
                  right and without restriction on disclosure.
                </li>
              </ol>
            </li>

            <li>
              <b>
                Proprietary Rights; No Right to Copy, to Modify, or to
                Disassemble.
              </b>
              <ol type="a">
                <li>
                  The software provided by ObjectSecurity and all copies thereof
                  are proprietary to and the property of ObjectSecurity. All
                  applicable rights in all copyrights, trademarks, trade
                  secrets, trade names, patents, and other intellectual property
                  rights in or associated with the software are and will remain
                  in ObjectSecurity. you shall have no such intellectual
                  property rights in the software.
                </li>
                <li>
                  You may not copy or reproduce the software without
                  ObjectSecurity’s prior written consent, except as reasonably
                  needed to perform its obligations hereunder and subject to the
                  following restrictions. you may not copy or reproduce any
                  software or documentation provided by ObjectSecurity, without
                  ObjectSecurity’s prior written consent, except as is
                  reasonably needed to perform your obligations under this
                  Agreement. Each copy of software or documentation made by you
                  must contain ObjectSecurity’s proprietary and copyright
                  notices in the same form as the original. You shall not remove
                  or deface any portion of any legend provided on any part of
                  the software.
                </li>
                <li>
                  You agree to secure and protect the software and all copies
                  thereof in a manner consistent with the maintenance of
                  ObjectSecurity’s rights therein and to take appropriate
                  actions by instruction or Agreement with any of its employees
                  or agents permitted access to it to satisfy its obligations
                  hereunder.
                </li>
                <li>
                  You shall not reverse engineer, alter, modify, disassemble or
                  decompile the software, or any part thereof, without
                  ObjectSecurity’s prior written consent.
                </li>
              </ol>
            </li>
            <li>
              <b>Disclaimer of Warranty</b>
              <p>
                By its nature, the Pre-Release Test software may contain errors,
                bugs, and other problems that could cause a system failure.
                ObjectSecurity may not yet complete the testing and quality
                assurance of the Pre-Release Test software. Because the Alpha
                Test software is subject to change, ObjectSecurity reserves the
                right to alter the Pre-Release Test software at any time. Any
                reliance on the Pre-Release Test software is at your own risk.
                PARTICIPANT ACCEPTS THE ALPHA TEST MATERIAL “AS IS.”
                OBJECTSECURITY MAKES NO WARRANTY OF ANY KIND REGARDING THE BETA
                TEST MATERIAL. OBJECTSECURITY AT THIS MOMENT EXPRESSLY DISCLAIMS
                ALL IMPLIED AND STATUTORY WARRANTIES, INCLUDING, BUT NOT LIMITED
                TO, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD PARTIES RIGHTS.
              </p>
            </li>
            <li>
              <b>Term and Termination.</b>
              <ol type="a">
                <li>
                  The term of this Agreement shall begin on the date set forth
                  above (or, if no date is given, then the date both parties
                  accept this Agreement by written signature) and shall continue
                  until terminated as set forth below. Upon termination of this
                  Agreement for any reason, the 5-year obligation to protect
                  Confidential Information, as outlined in Section 5.1, shall
                  survive such termination.
                </li>
                <li>
                  This Agreement may be terminated at any time for any reason by
                  either party giving ten (10) days prior written notice to the
                  other party, subject to Section 8.3 below. A particular
                  Pre-Release Test software Agreement shall automatically
                  terminate upon the general release to the public of the final
                  product derived from the Pre-Release Test software by
                  ObjectSecurity, or sooner upon ten days prior written notice
                  by either party. The parties acknowledge that ObjectSecurity
                  is under no obligation to release any final product or Alpha
                  Test software to the public.
                </li>
                <li>
                  Upon termination of this Agreement or a particular Pre-Release
                  Test software Agreement, you agree to (a) return the
                  Pre-Release Test software and all copies thereof to
                  ObjectSecurity, if requested by ObjectSecurity in writing to
                  do so, within seven (7) days after such termination, or (b) if
                  requested by ObjectSecurity to do so, certify to
                  ObjectSecurity in writing that the Pre-Release Test software
                  and all copies thereof have been destroyed, or (c) purchase
                  the Pre-Release Test software as outlined in Section 4.3. The
                  provisions of and the obligations of the parties under,
                  Sections 4.3, 4.4, 5, 6, 7, 8, and 9, and any other
                  requirements that would typically survive, shall survive the
                  termination of this Agreement.
                </li>
              </ol>
            </li>
            <li>
              <b>Limitation of Liability</b>
              <p>
                NO WARRANTY: YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT THE USE OF
                THE LICENSED APPLICATION IS AT YOUR SOLE RISK. TO THE MAXIMUM
                EXTENT PERMITTED BY APPLICABLE LAW, THE LICENSED APPLICATION AND
                ANY SERVICES PERFORMED OR PROVIDED BY THE LICENSED APPLICATION
                ARE PROVIDED "AS IS" AND “AS AVAILABLE,” WITH ALL FAULTS AND
                WITHOUT WARRANTY OF ANY KIND, AND THE LICENSOR HEREBY DISCLAIMS
                ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE LICENSED
                APPLICATION AND ANY SERVICES, EITHER EXPRESS, IMPLIED, OR
                STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES
                AND/OR CONDITIONS OF MERCHANTABILITY, OF SATISFACTORY QUALITY,
                OF FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, OF QUIET
                ENJOYMENT, AND OF NON-INFRINGEMENT OF THIRD-PARTY RIGHTS. NO
                ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY THE LICENSOR OR
                ITS AUTHORIZED REPRESENTATIVE SHALL CREATE A WARRANTY. SHOULD
                THE LICENSED APPLICATION OR SERVICES PROVE DEFECTIVE, YOU ASSUME
                THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR
                CORRECTION. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF
                IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY RIGHTS
                OF A CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT
                APPLY TO YOU.
              </p>
              <p>
                TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL THE
                LICENSOR BE LIABLE FOR PERSONAL INJURY OR ANY INCIDENTAL,
                SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER,
                INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS
                OF DATA, BUSINESS INTERRUPTION, OR ANY OTHER COMMERCIAL DAMAGES
                OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OF OR INABILITY
                TO USE THE LICENSED APPLICATION, HOWEVER, CAUSED, REGARDLESS OF
                THE THEORY OF LIABILITY (CONTRACT, TORT, OR OTHERWISE) AND EVEN
                IF LICENSOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR
                PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO
                THIS LIMITATION MAY NOT APPLY TO YOU. In no event shall
                Licensor’s total liability to you for all damages (other than as
                may be required by applicable law in cases involving personal
                injury) exceed the amount of fifty dollars ($50.00). The
                foregoing limitations will apply even if the above-stated remedy
                fails in its essential purpose.
              </p>
            </li>
            <li>
              <b>Exporting Restrictions; United States Government Legends</b>
              <p>
                Software that is provided to you may be subject to United States
                Export Restrictions. You agree not to export or re-export any
                software or to accompany documentation in violation of any
                applicable laws and regulations of the United States or the
                country where you obtained them. The software, firmware, or
                other parts of the software covered by this Agreement may
                contain robust data encryption code, not being exported outside
                the United States or Canada. You agree not to export or
                re-export, either physically or electronically, encrypted
                software or accompanying documentation without obtaining written
                authorization from the U.S. Department of Commerce.
              </p>
            </li>
            <li>
              <b>Waiver</b>
              <p>
                A waiver of any default hereunder or any of the terms and
                conditions of this Agreement shall not be deemed to be a
                continuing waiver or a waiver of any other default or any other
                term or condition. Still, it shall apply solely to the instance
                to which such waiver is directed.
              </p>
            </li>
            <li>
              <b>Assignment; Severability</b>
              <p>
                You agree not to assign any rights under this Agreement; any
                attempted assignment shall be null and void and shall result in
                the termination of this Agreement. Suppose any part of this
                Agreement shall be invalid or unenforceable. In that case, such
                invalidity or unenforceability shall not affect the validity or
                enforceability of any other part or provision of this Agreement
                which shall remain in full force and effect.
              </p>
            </li>
            <li>
              <b>Governing Law</b>
              <p>
                This Agreement shall be governed by and construed following the
                laws of the State of California, excluding any such laws that
                might direct the application of the laws of another
                jurisdiction. The parties agree that the federal or state courts
                located in the State of California shall have exclusive
                jurisdiction to hear any dispute under this Agreement.
              </p>
            </li>
            <li>
              <b>Entire Agreement</b>
              <p>
                This Agreement represents the entire Agreement between the
                parties regarding the subject matter hereof and supersedes any
                and all prior agreements between the parties, whether written or
                oral, regarding the subject matter hereof. This Agreement may
                not be modified or amended except by the written acceptance of
                both parties.
              </p>
            </li>
          </ol>
        </div>
      </div>
    );
  }
}

EULA.propTypes = {
  /**
   * Callback that runs when the user accepts the EULA.
   */
  pressFunction: PropTypes.func.isRequired,

  /**
   * If this prop exists, the EULA is important.
   * The EULA is important if it is instantiated from the
   * EULA Page: the use must agree to it before using the
   * application.
   */
  important: PropTypes.bool,
};
