import { getAllAssetsInFacility, getAllFacilities } from "../../../../api";

export const FACILITY_REPORT_RESULT_TYPES = {
  FACILITY: "FACILITY",
  ASSETS: "ASSETS",
};

export async function fetchFacilityItems(facility_id, callback) {
  let facilities = await getAllFacilities();
  let facility = facilities.filter((f) => f.id === facility_id)[0];
  callback(FACILITY_REPORT_RESULT_TYPES.FACILITY, facility);

  let assets = await getAllAssetsInFacility(
    facility_id,
    false,
    0,
    100, // TODO remove this limit??
    null,
    null
  );
  if (!assets) assets = [];
  callback(FACILITY_REPORT_RESULT_TYPES.ASSETS, assets);
}
