import { useState, useEffect } from "react";
import DiscoveryLocation from "../../mappings/discovery_location";

/**
 * Displays all instances of a detected CWE, including their
 * file location/address using the DiscoveryLocation component.
 */
export default function CWEInstances({
  cwe,
  asset_id,
  binary,
  swapTab,
  // are we rendering the normal instances, or the major findings?
  major_findings,
}) {
  let [idx, setIdx] = useState(0);

  /**
   * When the selected CWE changes, reset this component to display
   * the first detected instance.
   */
  const reset = () => {
    setIdx(0);
  };
  useEffect(reset, [cwe]);

  if (!cwe) return null;
  let instances = major_findings ? cwe.major_findings : cwe.instances;
  if (instances.length === 0 || idx >= instances.length) return null;
  // sort instances by highest->lowest priority assessor tool
  instances = instances.sort(sortByTool);

  return (
    <div className="cwe-instances mapping">
      <h3>{major_findings ? "Major CWE Findings" : "Total CWE Instances"}</h3>
      <h2>{instances.length} Detected</h2>
      <h3>Message</h3>
      <div className="mapping-field">{instances[idx].msg}</div>
      <SwapTabButton cwe={instances[idx]} swapTab={swapTab} />
      <DiscoveryLocation
        asset_id={asset_id}
        binary={binary}
        locations={instances}
        setPageCallback={(page) => {
          setIdx(page);
        }}
      />
    </div>
  );
}

function sortByTool(a, b) {
  return toolToNum(b.src) - toolToNum(a.src);
}

function toolToNum(tool) {
  if (tool === "weak_pointers") return 1.0;
  if (tool.includes("bap_")) return 0.5;
  return 0.0;
}

/**
 * Some CWEs are detected based on the output from assessment
 * that display the majority of their result on other tabs
 * of the Binary Analysis Page. Such CWEs will indicate this
 * in their `msg` field.
 * This function detects these CWEs and renders a button component
 * that lets the user swap tabs.
 */
function SwapTabButton({ cwe, swapTab }) {
  if (cwe.src === "symbex") {
    return (
      <button
        onClick={() => {
          swapTab("SymbEx");
        }}
        className="cwe-swap-tab-button"
      >
        View on SymbEx Tab
      </button>
    );
  }
  if (cwe.msg.includes("ThreatAI")) {
    return (
      <button
        onClick={() => {
          swapTab("ThreatAI");
        }}
        className="cwe-swap-tab-button"
      >
        View on ThreatAI Tab
      </button>
    );
  }
  if (cwe.msg.includes("Weak Pointer")) {
    return (
      <button
        onClick={() => {
          swapTab("Weak Pointers");
        }}
        className="cwe-swap-tab-button"
      >
        View on Weak Pointers Tab
      </button>
    );
  }
  return null;
}
