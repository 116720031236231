import { getAsset, getAssetAuditLog, getBinaries } from "../../../../api";

export const ASSET_REPORT_RESULT_TYPES = {
  ASSET: "ASSET",
  BINARIES: "BINARIES",
  AUDIT: "AUDIT",
};

export async function fetchAssetItems(asset_id, callback) {
  let asset = await getAsset(asset_id);
  callback(ASSET_REPORT_RESULT_TYPES.ASSET, asset);
  let binaries = await getBinaries(asset_id);
  callback(ASSET_REPORT_RESULT_TYPES.BINARIES, binaries.assessable);
  let audit = await getAssetAuditLog(asset_id);
  callback(ASSET_REPORT_RESULT_TYPES.AUDIT, audit);
}
