import { getBasePath } from ".";
import Notifications from "../components/login_page/notifications";

export async function login0(email, password) {
  global.email = email;
  let body = new FormData();
  body.append("username", email);
  body.append("password", password);
  let res = await fetch(getBasePath() + "/user/login", {
    method: "POST",
    body,
  });
  let parsed = await res.json();

  window.localStorage.setItem("username", email);
  window.localStorage.setItem("email", parsed.email);
  window.localStorage.setItem("userid", parsed.id);

  return parsed;
}

export async function genTOTP(user_id) {
  let res = await fetch(getBasePath() + `/user/generate_totp`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  let parsed = await res.json();
  return parsed;
}

export async function sendTOTP(pincode) {
  let body = new FormData();
  body.append("pincode", pincode);
  let res = await fetch(getBasePath() + `/user/validate_totp`, {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  let parsed = await res.json();
  window.localStorage.setItem("auth", parsed.access_token);
  window.localStorage.setItem("username", parsed.username);
  window.localStorage.setItem("email", parsed.email);
  window.localStorage.setItem("userid", parsed.id);
  return parsed;
}

export async function getAllUsers() {
  let res = await fetch(getBasePath() + "/user/all", {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  let parsed = await res.json();
  return parsed;
}

export async function createUser(username, password, email, org_id) {
  let res = await fetch(getBasePath() + "/user/add", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      email,
      password,
      username,
      org_id,
    }),
  });
  return res;
}

/**
 * Grants a certain user a certain role in a certain organization.
 */
export async function addRoles(roles, userid, orgid) {
  let body = new FormData();
  body.append("roles", JSON.stringify(roles));
  body.append("userid", userid);
  body.append("orgid", orgid);
  let res = await fetch(getBasePath() + `/user/addroles`, {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  let parsed = await res.json();
  return parsed;
}

/**
 * Removes a certain role from a user in a certain organization.
 */
export async function removeRoles(roles, userid, orgid) {
  let body = new FormData();
  body.append("roles", JSON.stringify(roles));
  body.append("userid", userid);
  body.append("orgid", orgid);
  let res = await fetch(getBasePath() + `/user/removeroles`, {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  let parsed = await res.json();
  return parsed;
}

export async function changePassword(userId, password) {
  let res = await fetch(getBasePath() + "/user/password", {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      userId,
      password,
    }),
  });
  let parsed = await res.text();
  return parsed;
}

/**
 * Get an invite link that lets a new user login to the application.
 */
export async function getUserInviteLink(user_id) {
  let res = await fetch(getBasePath() + `/user/invite/${user_id}`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  let parsed = await res.json();
  return parsed.invite;
}

/**
 * Redeem an invite link that lets a user set their password and email
 * via the sign-up process.
 */
export async function redeemUserInviteLink(invite) {
  let res = await fetch(getBasePath() + `/user/redeem/${invite}`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  let parsed = await res.json();
  return parsed;
}

/**
 * Swap a user to another organization.
 * This function should be followed up by reloading using '/assessor/getassets'.
 */
export async function swapOrganizations(userid, orgid) {
  let body = new FormData();
  body.append("userid", userid);
  body.append("orgid", orgid);
  let res = await fetch(getBasePath() + `/user/setorg`, {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
    body,
  });
  let parsed = await res.json();
  return parsed;
}

export async function acceptEula() {
  let res = await fetch(getBasePath() + `/user/eula`, {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  let parsed = await res.json();
  return parsed;
}

/**
 * Get a user's profile which includes their roles and dropzone id.
 */
export async function getUserRole(userId) {
  let res = await fetch(getBasePath() + `/user/profile/${userId}`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });

  let parsed = await res.json();
  let role = "user";
  for (let org of Object.values(parsed.roles)) {
    for (let roleN of org) {
      if (roleN === "superadmin") {
        role = "superadmin";
        break;
      }
    }
  }

  return role;
}

/**
 * Get a user's profile which includes their roles and dropzone id.
 */
export async function getUserProfile(userId) {
  let res = await fetch(getBasePath() + `/user/profile/${userId}`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + window.localStorage.getItem("auth"),
    }),
  });
  try {
    let parsed = await res.json();
    return parsed;
  } catch {
    logout();
    Notifications._this.addExpiration();
    return null;
  }
}

export async function createSuperadmin(username, email, password) {
  let res = await fetch(getBasePath() + "/user/add_superadmin", {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      username,
      email,
      password,
    }),
  });
  return res;
}

export async function superadminExists() {
  try {
    let res = await fetch(getBasePath() + "/user/superadmin_exists", {
      method: "GET",
    });
    let parsed = await res.json();
    return parsed.superadmin_exists;
  } catch {
    return true;
  }
}

export async function sendPasswordResetEmail(email) {
  let res = await fetch(getBasePath() + "/user/invite/reset", {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      email,
      base_url: window.location.origin + "/#",
    }),
  });
  return await res.text();
}

// ------------------------------------------------

export function logout() {
  window.localStorage.removeItem("analyzingUploads");
  window.localStorage.removeItem("generatedReports");
  window.localStorage.removeItem("collections");
  window.localStorage.removeItem("pastUploads");
  window.localStorage.removeItem("dropzoneId");
  window.localStorage.removeItem("firstLoad");
  window.localStorage.removeItem("config");
  window.localStorage.removeItem("firstToken");
  window.localStorage.removeItem("dropUploads");
  window.localStorage.removeItem("auth", "");
  window.localStorage.removeItem("username", "");
  window.localStorage.removeItem("email", "");
  window.localStorage.removeItem("userid", "");
  window.localStorage.removeItem("darkMode", "");

  window.location.assign("#/login");
}
