import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import { Modal } from "react-bootstrap";

// icons
import { X } from "react-feather";
import { createOrganization } from "../../api";

// components
import UserManagementTable from "./user_management_table";
import OrganizationsToggle from "../headbar/organizations_toggle";
import Notifications from "../login_page/notifications";

/**
 * Modal that appears when an organization is being created.
 * To create an organization, the following information is required:
 * 1. Name.
 * 2. Description.
 * Organization ID is randomly generated by the Back-End.
 */
export default class CreateOrganizationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      description: "",
    };

    this.createOrganizationClick = this.createOrganizationClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.modal !== prevProps.modal) {
      this.setState({
        name: "",
        description: "",
      });
    }
  }

  async createOrganizationClick() {
    // create a new organization with the superadmin as
    // the only user
    let res = await createOrganization(this.state.name, this.state.description);

    if (!res || res === "name taken") {
      Notifications._this.addNameTaken();
      return;
    }

    this.props.backFunction();
    UserManagementTable._this.refresh();
    OrganizationsToggle._this.init();
  }

  /**
   * Render the component.
   */
  render() {
    return (
      <Modal
        enforceFocus={false}
        show={this.props.modal}
        animation={false}
        className="modal-bg"
      >
        <Modal.Body className="modal2 create-user-modal-top">
          <div className="login-page-old create-user-modal">
            <div
              className="sec-button x-user"
              onClick={this.props.backFunction}
            >
              <X className="user" />
            </div>

            <h2 className="man-user-title perm-title set-side-title">
              ORGANIZATION NAME
            </h2>
            <input
              placeholder="Organization Name..."
              value={this.state.name}
              onChange={(e) => this.setState({ name: e.target.value })}
            ></input>

            <h2 className="man-user-title perm-title set-side-title">
              DESCRIPTION
            </h2>
            <textarea
              className="create-org-textarea"
              placeholder="Description..."
              value={this.state.description}
              onChange={(e) => this.setState({ description: e.target.value })}
            ></textarea>

            <button onClick={this.createOrganizationClick} className="upload">
              Create Organization
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

CreateOrganizationModal.propTypes = {
  /**
   * Is the modal appearing or not.
   */
  modal: PropTypes.bool.isRequired,

  /**
   * Callback that turns the modal off.
   */
  backFunction: PropTypes.func.isRequired,
};
