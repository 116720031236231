import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../../index.css";
import "./asset_summary.css";
import { Asset } from "../../constants";
import AssetSummaryHeader from "./asset_summary_header";
import AssetBaseTab from "./asset_summary_components/asset_base_tab";
import AssetCPEsTab from "./asset_summary_components/asset_cpes_tab";

export const PAGES = {
  BASE: "Base",
  CPEs: "CPEs",
};

/**
 * Summarize an asset on the right side panel
 * of the binary analysis page.
 *
 * Displays asset name, metadata, description, biggest risks, and
 * history.
 */
export default function AssetSummary({
  asset,
  refreshAsset,
  updateDescription,
}) {
  let [page, setPage] = useState(PAGES.BASE);

  useEffect(() => {
    setPage(PAGES.BASE);
  }, [asset]);

  return (
    <div className="report-prev">
      <div className="filter">
        <AssetSummaryHeader asset={asset} page={page} setPage={setPage} />
        {page === PAGES.BASE && (
          <AssetBaseTab
            asset={asset}
            refreshAsset={refreshAsset}
            updateDescription={updateDescription}
          />
        )}
        {page === PAGES.CPEs && <AssetCPEsTab asset={asset} />}
      </div>
    </div>
  );
}

AssetSummary.propTypes = {
  /**
   * The asset to summarize.
   */
  asset: PropTypes.instanceOf(Asset).isRequired,
};
