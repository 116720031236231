/*
    This component appears on the right side of the file drop modal
    and enables the user to choose how they wish to upload their files
    (normal or S3Ingress).
*/
import { useRef } from "react";
import { VERSION } from "../../../helpers";

export default function UploadOption({ dropFiles, ingressFromS3 }) {
  let hiddenFileInput = useRef();

  return (
    <div className="new-analysis-centerpiece">
      <button onClick={() => hiddenFileInput.current.click()}>
        Upload Files
      </button>
      <input
        type="file"
        multiple
        ref={hiddenFileInput}
        onChange={(e) => {
          dropFiles(e.target.files);
        }}
        style={{ display: "none" }}
      />
      <div className="upload-option-or">
        <hr />
        <h3>OR</h3>
        <hr />
      </div>
      <button onClick={ingressFromS3}>Ingress Files From S3</button>
      <h3
        style={{
          textAlign: "left",
          padding: 20,
          lineHeight: 1.4,
          marginTop: 20,
        }}
      >
        For the current BinLens release {VERSION}, the maximum number of binary
        files that may exist in a single asset is <b>128</b>. If you upload more
        than this number of files, the remaining files will be uploaded as a
        separate asset.
      </h3>
    </div>
  );
}
