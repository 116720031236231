import { TOP_LEVEL_CWE_ID } from ".";
import { LINEAGE } from "./lineage";
const MAX_MATCHED_CWES = 5;

export function determineCWEPath(cwe_num) {
  let path = [];
  let current = cwe_num;
  while (current !== "All CWEs") {
    path.unshift(current);
    current = LINEAGE[current].parents[0];
  }
  return path;
}

/**
 * Based upon the search text entered by the user,
 * provides a series of matches
 */
export function matchCWEs(text) {
  if (text === "") return [];
  let matched_cwes = [];
  for (let [cwe, obj] of Object.entries(LINEAGE)) {
    if (matchCWE(text, cwe)) {
      matched_cwes.push(`CWE-${cwe}: ${obj.description}`);
      if (matched_cwes.length >= MAX_MATCHED_CWES) break;
    }
  }
  return matched_cwes;
}

/**
 * Things the user may type:
 * - CWE-120
 * - cwe-120
 * - cWe-120
 * - 120
 * - CWE-1
 * - 1
 * - ...etc.
 */
function matchCWE(text, cwe) {
  // the top level CWE is an invalid search...
  // the user can just click the "All CWEs" button
  if (cwe === TOP_LEVEL_CWE_ID) return false;
  let matches = text.match(/(\d+)/);
  if (matches) {
    return cwe.includes(matches[0]);
  }
  return true;
}
