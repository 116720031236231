// TODO This data-structure is also store on the backend...
// It may be worth deduplicating it to have it stored in
// only one location.
export const LINEAGE = {
  "All CWEs": {
    description: "All CWEs",
    parents: [],
    children: [
      "284",
      "435",
      "664",
      "682",
      "691",
      "693",
      "697",
      "703",
      "707",
      "710",
    ],
  },
  284: {
    description: "Improper Access Control",
    parents: ["All CWEs"],
    children: [
      "1191",
      "1220",
      "1224",
      "1231",
      "1233",
      "1242",
      "1252",
      "1259",
      "1260",
      "1262",
      "1263",
      "1267",
      "1270",
      "1274",
      "1276",
      "1280",
      "1283",
      "1290",
      "1292",
      "1294",
      "1296",
      "1311",
      "1312",
      "1313",
      "1315",
      "1316",
      "1317",
      "1334",
      "269",
      "282",
      "285",
      "286",
      "287",
      "346",
      "749",
      "923",
    ],
  },
  1191: {
    description:
      "On-Chip Debug and Test Interface With Improper Access Control",
    parents: ["284"],
    children: [],
  },
  1220: {
    description: "Insufficient Granularity of Access Control",
    parents: ["284"],
    children: ["1222"],
  },
  1222: {
    description:
      "Insufficient Granularity of Address Regions Protected by Register Locks",
    parents: ["1220"],
    children: [],
  },
  1224: {
    description: "Improper Restriction of Write-Once Bit Fields",
    parents: ["284"],
    children: [],
  },
  1231: {
    description: "Improper Prevention of Lock Bit Modification",
    parents: ["284"],
    children: [],
  },
  1233: {
    description:
      "Security-Sensitive Hardware Controls with Missing Lock Bit Protection",
    parents: ["284", "667"],
    children: [],
  },
  1242: {
    description: "Inclusion of Undocumented Features or Chicken Bits",
    parents: ["284"],
    children: [],
  },
  1252: {
    description:
      "CPU Hardware Not Configured to Support Exclusivity of Write and Execute Operations",
    parents: ["284"],
    children: [],
  },
  1259: {
    description: "Improper Restriction of Security Token Assignment",
    parents: ["284"],
    children: [],
  },
  1260: {
    description: "Improper Handling of Overlap Between Protected Memory Ranges",
    parents: ["284"],
    children: [],
  },
  1262: {
    description: "Improper Access Control for Register Interface",
    parents: ["284"],
    children: [],
  },
  1263: {
    description: "Improper Physical Access Control",
    parents: ["284"],
    children: ["1243"],
  },
  1243: {
    description:
      "Sensitive Non-Volatile Information Not Protected During Debug",
    parents: ["1263"],
    children: [],
  },
  1267: {
    description: "Policy Uses Obsolete Encoding",
    parents: ["284"],
    children: [],
  },
  1270: {
    description: "Generation of Incorrect Security Tokens",
    parents: ["284"],
    children: [],
  },
  1274: {
    description:
      "Improper Access Control for Volatile Memory Containing Boot Code",
    parents: ["284"],
    children: [],
  },
  1276: {
    description: "Hardware Child Block Incorrectly Connected to Parent System",
    parents: ["284"],
    children: [],
  },
  1280: {
    description: "Access Control Check Implemented After Asset is Accessed",
    parents: ["284", "696"],
    children: [],
  },
  1283: {
    description: "Mutable Attestation or Measurement Reporting Data",
    parents: ["284"],
    children: [],
  },
  1290: {
    description: "Incorrect Decoding of Security Identifiers ",
    parents: ["284"],
    children: [],
  },
  1292: {
    description: "Incorrect Conversion of Security Identifiers",
    parents: ["284"],
    children: [],
  },
  1294: {
    description: "Insecure Security Identifier Mechanism",
    parents: ["284"],
    children: ["1302"],
  },
  1302: {
    description: "Missing Security Identifier",
    parents: ["1294"],
    children: [],
  },
  1296: {
    description: "Incorrect Chaining or Granularity of Debug Components",
    parents: ["284"],
    children: [],
  },
  1311: {
    description: "Improper Translation of Security Attributes by Fabric Bridge",
    parents: ["284"],
    children: [],
  },
  1312: {
    description:
      "Missing Protection for Mirrored Regions in On-Chip Fabric Firewall",
    parents: ["284"],
    children: [],
  },
  1313: {
    description: "Hardware Allows Activation of Test or Debug Logic at Runtime",
    parents: ["284"],
    children: [],
  },
  1315: {
    description:
      "Improper Setting of Bus Controlling Capability in Fabric End-point",
    parents: ["284"],
    children: [],
  },
  1316: {
    description:
      "Fabric-Address Map Allows Programming of Unwarranted Overlaps of Protected and Unprotected Ranges",
    parents: ["284"],
    children: [],
  },
  1317: {
    description: "Improper Access Control in Fabric Bridge",
    parents: ["284"],
    children: [],
  },
  1334: {
    description: "Unauthorized Error Injection Can Degrade Hardware Redundancy",
    parents: ["284"],
    children: [],
  },
  269: {
    description: "Improper Privilege Management",
    parents: ["284"],
    children: ["250", "266", "267", "268", "270", "271", "274", "648"],
  },
  250: {
    description: "Execution with Unnecessary Privileges",
    parents: ["269", "657"],
    children: [],
  },
  266: {
    description: "Incorrect Privilege Assignment",
    parents: ["269"],
    children: ["1022", "520", "556", "9"],
  },
  1022: {
    description:
      "Use of Web Link to Untrusted Target with window.opener Access",
    parents: ["266"],
    children: [],
  },
  520: {
    description: ".NET Misconfiguration: Use of Impersonation",
    parents: ["266"],
    children: [],
  },
  556: {
    description: "ASP.NET Misconfiguration: Use of Identity Impersonation",
    parents: ["266"],
    children: [],
  },
  9: {
    description:
      "J2EE Misconfiguration: Weak Access Permissions for EJB Methods",
    parents: ["266"],
    children: [],
  },
  267: {
    description: "Privilege Defined With Unsafe Actions",
    parents: ["269"],
    children: ["623"],
  },
  623: {
    description: "Unsafe ActiveX Control Marked Safe For Scripting",
    parents: ["267"],
    children: [],
  },
  268: {
    description: "Privilege Chaining",
    parents: ["269"],
    children: [],
  },
  270: {
    description: "Privilege Context Switching Error",
    parents: ["269"],
    children: [],
  },
  271: {
    description: "Privilege Dropping / Lowering Errors",
    parents: ["269"],
    children: ["272", "273"],
  },
  272: {
    description: "Least Privilege Violation",
    parents: ["271"],
    children: [],
  },
  273: {
    description: "Improper Check for Dropped Privileges",
    parents: ["271", "754"],
    children: [],
  },
  274: {
    description: "Improper Handling of Insufficient Privileges",
    parents: ["269", "755"],
    children: [],
  },
  648: {
    description: "Incorrect Use of Privileged APIs",
    parents: ["269"],
    children: [],
  },
  282: {
    description: "Improper Ownership Management",
    parents: ["284"],
    children: ["283", "708"],
  },
  283: {
    description: "Unverified Ownership",
    parents: ["282"],
    children: [],
  },
  708: {
    description: "Incorrect Ownership Assignment",
    parents: ["282"],
    children: [],
  },
  285: {
    description: "Improper Authorization",
    parents: ["284"],
    children: [
      "1230",
      "1297",
      "1328",
      "552",
      "732",
      "862",
      "863",
      "926",
      "927",
    ],
  },
  1230: {
    description: "Exposure of Sensitive Information Through Metadata",
    parents: ["285"],
    children: ["202", "612"],
  },
  202: {
    description: "Exposure of Sensitive Information Through Data Queries",
    parents: ["1230"],
    children: [],
  },
  612: {
    description:
      "Improper Authorization of Index Containing Sensitive Information",
    parents: ["1230"],
    children: [],
  },
  1297: {
    description:
      "Unprotected Confidential Information on Device is Accessible by OSAT Vendors",
    parents: ["285"],
    children: [],
  },
  1328: {
    description: "Security Version Number Mutable to Older Versions",
    parents: ["285"],
    children: [],
  },
  552: {
    description: "Files or Directories Accessible to External Parties",
    parents: ["285", "668"],
    children: ["219", "220", "527", "528", "529", "530", "539", "553"],
  },
  219: {
    description: "Storage of File with Sensitive Data Under Web Root",
    parents: ["552"],
    children: ["433"],
  },
  433: {
    description: "Unparsed Raw Web Content Delivery",
    parents: ["219"],
    children: [],
  },
  220: {
    description: "Storage of File With Sensitive Data Under FTP Root",
    parents: ["552"],
    children: [],
  },
  527: {
    description:
      "Exposure of Version-Control Repository to an Unauthorized Control Sphere",
    parents: ["552"],
    children: [],
  },
  528: {
    description: "Exposure of Core Dump File to an Unauthorized Control Sphere",
    parents: ["552"],
    children: [],
  },
  529: {
    description:
      "Exposure of Access Control List Files to an Unauthorized Control Sphere",
    parents: ["552"],
    children: [],
  },
  530: {
    description: "Exposure of Backup File to an Unauthorized Control Sphere",
    parents: ["552"],
    children: [],
  },
  539: {
    description: "Use of Persistent Cookies Containing Sensitive Information",
    parents: ["552"],
    children: [],
  },
  553: {
    description: "Command Shell in Externally Accessible Directory",
    parents: ["552"],
    children: [],
  },
  732: {
    description: "Incorrect Permission Assignment for Critical Resource",
    parents: ["285", "668"],
    children: ["1004", "276", "277", "278", "279", "281", "766"],
  },
  1004: {
    description: "Sensitive Cookie Without 'HttpOnly' Flag",
    parents: ["732"],
    children: [],
  },
  276: {
    description: "Incorrect Default Permissions",
    parents: ["732"],
    children: [],
  },
  277: {
    description: "Insecure Inherited Permissions",
    parents: ["732"],
    children: [],
  },
  278: {
    description: "Insecure Preserved Inherited Permissions",
    parents: ["732"],
    children: [],
  },
  279: {
    description: "Incorrect Execution-Assigned Permissions",
    parents: ["732"],
    children: [],
  },
  281: {
    description: "Improper Preservation of Permissions",
    parents: ["732"],
    children: [],
  },
  766: {
    description: "Critical Data Element Declared Public",
    parents: ["732", "1061"],
    children: [],
  },
  862: {
    description: "Missing Authorization",
    parents: ["285"],
    children: ["1314", "425", "638", "939"],
  },
  1314: {
    description: "Missing Write Protection for Parametric Data Values",
    parents: ["862"],
    children: [],
  },
  425: {
    description: "Direct Request ('Forced Browsing')",
    parents: ["862", "424", "288"],
    children: [],
  },
  638: {
    description: "Not Using Complete Mediation",
    parents: ["862", "657"],
    children: ["424"],
  },
  424: {
    description: "Improper Protection of Alternate Path",
    parents: ["638", "693"],
    children: ["425"],
  },
  939: {
    description: "Improper Authorization in Handler for Custom URL Scheme",
    parents: ["862"],
    children: [],
  },
  863: {
    description: "Incorrect Authorization",
    parents: ["285"],
    children: ["551", "639", "647", "804", "942"],
  },
  551: {
    description:
      "Incorrect Behavior Order: Authorization Before Parsing and Canonicalization",
    parents: ["863", "696"],
    children: [],
  },
  639: {
    description: "Authorization Bypass Through User-Controlled Key",
    parents: ["863"],
    children: ["566"],
  },
  566: {
    description: "Authorization Bypass Through User-Controlled SQL Primary Key",
    parents: ["639"],
    children: [],
  },
  647: {
    description: "Use of Non-Canonical URL Paths for Authorization Decisions",
    parents: ["863"],
    children: [],
  },
  804: {
    description: "Guessable CAPTCHA",
    parents: ["863", "1390"],
    children: [],
  },
  942: {
    description: "Permissive Cross-domain Policy with Untrusted Domains",
    parents: ["863", "923", "183"],
    children: [],
  },
  926: {
    description: "Improper Export of Android Application Components",
    parents: ["285"],
    children: [],
  },
  927: {
    description: "Use of Implicit Intent for Sensitive Communication",
    parents: ["285", "668"],
    children: [],
  },
  286: {
    description: "Incorrect User Management",
    parents: ["284"],
    children: ["842"],
  },
  842: {
    description: "Placement of User into Incorrect Group",
    parents: ["286"],
    children: [],
  },
  287: {
    description: "Improper Authentication",
    parents: ["284"],
    children: ["1390", "295", "306", "645"],
  },
  1390: {
    description: "Weak Authentication",
    parents: ["287"],
    children: [
      "804",
      "1391",
      "262",
      "263",
      "289",
      "290",
      "294",
      "301",
      "302",
      "303",
      "305",
      "307",
      "308",
      "309",
      "522",
      "593",
      "603",
      "620",
      "640",
      "836",
    ],
  },
  1391: {
    description: "Use of Weak Credentials",
    parents: ["1390"],
    children: ["1392", "521", "798"],
  },
  1392: {
    description: "Use of Default Credentials",
    parents: ["1391"],
    children: ["1393", "1394"],
  },
  1393: {
    description: "Use of Default Password",
    parents: ["1392"],
    children: [],
  },
  1394: {
    description: "Use of Default Cryptographic Key",
    parents: ["1392"],
    children: [],
  },
  521: {
    description: "Weak Password Requirements",
    parents: ["1391"],
    children: ["258"],
  },
  258: {
    description: "Empty Password in Configuration File",
    parents: ["521", "260"],
    children: [],
  },
  798: {
    description: "Use of Hard-coded Credentials",
    parents: ["1391", "344", "671"],
    children: ["259", "321"],
  },
  259: {
    description: "Use of Hard-coded Password",
    parents: ["798"],
    children: [],
  },
  321: {
    description: "Use of Hard-coded Cryptographic Key",
    parents: ["798"],
    children: [],
  },
  262: {
    description: "Not Using Password Aging",
    parents: ["1390"],
    children: [],
  },
  263: {
    description: "Password Aging with Long Expiration",
    parents: ["1390"],
    children: [],
  },
  289: {
    description: "Authentication Bypass by Alternate Name",
    parents: ["1390"],
    children: [],
  },
  290: {
    description: "Authentication Bypass by Spoofing",
    parents: ["1390"],
    children: ["291", "293", "350"],
  },
  291: {
    description: "Reliance on IP Address for Authentication",
    parents: ["290", "923", "471"],
    children: [],
  },
  293: {
    description: "Using Referer Field for Authentication",
    parents: ["290"],
    children: [],
  },
  350: {
    description:
      "Reliance on Reverse DNS Resolution for a Security-Critical Action",
    parents: ["290", "807"],
    children: [],
  },
  294: {
    description: "Authentication Bypass by Capture-replay",
    parents: ["1390"],
    children: [],
  },
  301: {
    description: "Reflection Attack in an Authentication Protocol",
    parents: ["1390"],
    children: [],
  },
  302: {
    description: "Authentication Bypass by Assumed-Immutable Data",
    parents: ["1390", "807"],
    children: [],
  },
  303: {
    description: "Incorrect Implementation of Authentication Algorithm",
    parents: ["1390"],
    children: ["304"],
  },
  304: {
    description: "Missing Critical Step in Authentication",
    parents: ["303", "573"],
    children: [],
  },
  305: {
    description: "Authentication Bypass by Primary Weakness",
    parents: ["1390"],
    children: [],
  },
  307: {
    description: "Improper Restriction of Excessive Authentication Attempts",
    parents: ["1390", "799"],
    children: [],
  },
  308: {
    description: "Use of Single-factor Authentication",
    parents: ["1390", "654"],
    children: [],
  },
  309: {
    description: "Use of Password System for Primary Authentication",
    parents: ["1390", "654"],
    children: [],
  },
  522: {
    description: "Insufficiently Protected Credentials",
    parents: ["1390", "668"],
    children: ["256", "257", "260", "261", "523", "549"],
  },
  256: {
    description: "Plaintext Storage of a Password",
    parents: ["522"],
    children: [],
  },
  257: {
    description: "Storing Passwords in a Recoverable Format",
    parents: ["522"],
    children: [],
  },
  260: {
    description: "Password in Configuration File",
    parents: ["522"],
    children: ["258", "13", "555"],
  },
  13: {
    description: "ASP.NET Misconfiguration: Password in Configuration File",
    parents: ["260"],
    children: [],
  },
  555: {
    description:
      "J2EE Misconfiguration: Plaintext Password in Configuration File",
    parents: ["260"],
    children: [],
  },
  261: {
    description: "Weak Encoding for Password",
    parents: ["522"],
    children: [],
  },
  523: {
    description: "Unprotected Transport of Credentials",
    parents: ["522"],
    children: [],
  },
  549: {
    description: "Missing Password Field Masking",
    parents: ["522"],
    children: [],
  },
  593: {
    description:
      "Authentication Bypass: OpenSSL CTX Object Modified after SSL Objects are Created",
    parents: ["1390", "666"],
    children: [],
  },
  603: {
    description: "Use of Client-Side Authentication",
    parents: ["1390", "602"],
    children: [],
  },
  620: {
    description: "Unverified Password Change",
    parents: ["1390"],
    children: [],
  },
  640: {
    description: "Weak Password Recovery Mechanism for Forgotten Password",
    parents: ["1390"],
    children: [],
  },
  836: {
    description: "Use of Password Hash Instead of Password for Authentication",
    parents: ["1390"],
    children: [],
  },
  295: {
    description: "Improper Certificate Validation",
    parents: ["287"],
    children: ["296", "297", "298", "299", "599"],
  },
  296: {
    description: "Improper Following of a Certificate's Chain of Trust",
    parents: ["295", "573"],
    children: [],
  },
  297: {
    description: "Improper Validation of Certificate with Host Mismatch",
    parents: ["295", "923"],
    children: [],
  },
  298: {
    description: "Improper Validation of Certificate Expiration",
    parents: ["295", "672"],
    children: [],
  },
  299: {
    description: "Improper Check for Certificate Revocation",
    parents: ["295", "404"],
    children: ["370"],
  },
  370: {
    description: "Missing Check for Certificate Revocation after Initial Check",
    parents: ["299"],
    children: [],
  },
  599: {
    description: "Missing Validation of OpenSSL Certificate",
    parents: ["295"],
    children: [],
  },
  306: {
    description: "Missing Authentication for Critical Function",
    parents: ["287"],
    children: ["288", "322"],
  },
  288: {
    description: "Authentication Bypass Using an Alternate Path or Channel",
    parents: ["306"],
    children: ["425"],
  },
  322: {
    description: "Key Exchange without Entity Authentication",
    parents: ["306"],
    children: [],
  },
  645: {
    description: "Overly Restrictive Account Lockout Mechanism",
    parents: ["287"],
    children: [],
  },
  346: {
    description: "Origin Validation Error",
    parents: ["284", "345"],
    children: ["1385", "940"],
  },
  1385: {
    description: "Missing Origin Validation in WebSockets",
    parents: ["346"],
    children: [],
  },
  940: {
    description: "Improper Verification of Source of a Communication Channel",
    parents: ["346", "923"],
    children: ["925"],
  },
  925: {
    description: "Improper Verification of Intent by Broadcast Receiver",
    parents: ["940"],
    children: [],
  },
  749: {
    description: "Exposed Dangerous Method or Function",
    parents: ["284"],
    children: ["618", "782"],
  },
  618: {
    description: "Exposed Unsafe ActiveX Method",
    parents: ["749"],
    children: [],
  },
  782: {
    description: "Exposed IOCTL with Insufficient Access Control",
    parents: ["749"],
    children: [],
  },
  923: {
    description:
      "Improper Restriction of Communication Channel to Intended Endpoints",
    parents: ["284"],
    children: ["942", "291", "297", "940", "1275", "300", "419", "420", "941"],
  },
  1275: {
    description: "Sensitive Cookie with Improper SameSite Attribute",
    parents: ["923"],
    children: [],
  },
  300: {
    description: "Channel Accessible by Non-Endpoint",
    parents: ["923"],
    children: [],
  },
  419: {
    description: "Unprotected Primary Channel",
    parents: ["923"],
    children: [],
  },
  420: {
    description: "Unprotected Alternate Channel",
    parents: ["923"],
    children: ["421", "422"],
  },
  421: {
    description: "Race Condition During Access to Alternate Channel",
    parents: ["420", "362"],
    children: [],
  },
  422: {
    description: "Unprotected Windows Messaging Channel ('Shatter')",
    parents: ["420", "360"],
    children: [],
  },
  941: {
    description: "Incorrectly Specified Destination in a Communication Channel",
    parents: ["923"],
    children: [],
  },
  435: {
    description:
      "Improper Interaction Between Multiple Correctly-Behaving Entities",
    parents: ["All CWEs"],
    children: ["1038", "188", "436", "439"],
  },
  1038: {
    description: "Insecure Automated Optimizations",
    parents: ["435", "758"],
    children: ["1037", "733"],
  },
  1037: {
    description:
      "Processor Optimization Removal or Modification of Security-critical Code",
    parents: ["1038"],
    children: [],
  },
  733: {
    description:
      "Compiler Optimization Removal or Modification of Security-critical Code",
    parents: ["1038"],
    children: ["14"],
  },
  14: {
    description: "Compiler Removal of Code to Clear Buffers",
    parents: ["733"],
    children: [],
  },
  188: {
    description: "Reliance on Data/Memory Layout",
    parents: ["435", "1105"],
    children: ["198"],
  },
  198: {
    description: "Use of Incorrect Byte Ordering",
    parents: ["188"],
    children: [],
  },
  436: {
    description: "Interpretation Conflict",
    parents: ["435"],
    children: ["113", "115", "437", "626", "650", "86"],
  },
  113: {
    description:
      "Improper Neutralization of CRLF Sequences in HTTP Headers ('HTTP Request/Response Splitting')",
    parents: ["436", "93"],
    children: [],
  },
  115: {
    description: "Misinterpretation of Input",
    parents: ["436"],
    children: [],
  },
  437: {
    description: "Incomplete Model of Endpoint Features",
    parents: ["436"],
    children: [],
  },
  626: {
    description: "Null Byte Interaction Error (Poison Null Byte)",
    parents: ["436", "147"],
    children: [],
  },
  650: {
    description: "Trusting HTTP Permission Methods on the Server Side",
    parents: ["436"],
    children: [],
  },
  86: {
    description:
      "Improper Neutralization of Invalid Characters in Identifiers in Web Pages",
    parents: ["436", "79"],
    children: [],
  },
  439: {
    description: "Behavioral Change in New Version or Environment",
    parents: ["435"],
    children: [],
  },
  664: {
    description: "Improper Control of a Resource Through its Lifetime",
    parents: ["All CWEs"],
    children: [
      "118",
      "1229",
      "1250",
      "1329",
      "221",
      "372",
      "400",
      "404",
      "410",
      "471",
      "487",
      "495",
      "496",
      "501",
      "580",
      "610",
      "662",
      "665",
      "666",
      "668",
      "669",
      "673",
      "704",
      "706",
      "911",
      "913",
      "922",
    ],
  },
  118: {
    description: "Incorrect Access of Indexable Resource ('Range Error')",
    parents: ["664"],
    children: ["119"],
  },
  119: {
    description:
      "Improper Restriction of Operations within the Bounds of a Memory Buffer",
    parents: ["118"],
    children: [
      "120",
      "125",
      "466",
      "786",
      "787",
      "788",
      "805",
      "822",
      "823",
      "824",
      "825",
    ],
  },
  120: {
    description:
      "Buffer Copy without Checking Size of Input ('Classic Buffer Overflow')",
    parents: ["119"],
    children: ["785"],
  },
  785: {
    description:
      "Use of Path Manipulation Function without Maximum-sized Buffer",
    parents: ["120", "676"],
    children: [],
  },
  125: {
    description: "Out-of-bounds Read",
    parents: ["119"],
    children: ["126", "127"],
  },
  126: {
    description: "Buffer Over-read",
    parents: ["125", "788"],
    children: [],
  },
  127: {
    description: "Buffer Under-read",
    parents: ["125", "786"],
    children: [],
  },
  466: {
    description: "Return of Pointer Value Outside of Expected Range",
    parents: ["119"],
    children: [],
  },
  786: {
    description: "Access of Memory Location Before Start of Buffer",
    parents: ["119"],
    children: ["127", "124"],
  },
  124: {
    description: "Buffer Underwrite ('Buffer Underflow')",
    parents: ["786", "787"],
    children: [],
  },
  787: {
    description: "Out-of-bounds Write",
    parents: ["119"],
    children: ["124", "121", "122", "123"],
  },
  121: {
    description: "Stack-based Buffer Overflow",
    parents: ["787", "788"],
    children: [],
  },
  122: {
    description: "Heap-based Buffer Overflow",
    parents: ["787", "788"],
    children: [],
  },
  123: {
    description: "Write-what-where Condition",
    parents: ["787"],
    children: [],
  },
  788: {
    description: "Access of Memory Location After End of Buffer",
    parents: ["119"],
    children: ["126", "121", "122"],
  },
  805: {
    description: "Buffer Access with Incorrect Length Value",
    parents: ["119"],
    children: ["806"],
  },
  806: {
    description: "Buffer Access Using Size of Source Buffer",
    parents: ["805"],
    children: [],
  },
  822: {
    description: "Untrusted Pointer Dereference",
    parents: ["119"],
    children: [],
  },
  823: {
    description: "Use of Out-of-range Pointer Offset",
    parents: ["119"],
    children: [],
  },
  824: {
    description: "Access of Uninitialized Pointer",
    parents: ["119"],
    children: [],
  },
  825: {
    description: "Expired Pointer Dereference",
    parents: ["119", "672"],
    children: ["415", "416"],
  },
  415: {
    description: "Double Free",
    parents: ["825", "666", "1341"],
    children: [],
  },
  416: {
    description: "Use After Free",
    parents: ["825"],
    children: [],
  },
  1229: {
    description: "Creation of Emergent Resource",
    parents: ["664"],
    children: ["514"],
  },
  514: {
    description: "Covert Channel",
    parents: ["1229"],
    children: ["385", "515"],
  },
  385: {
    description: "Covert Timing Channel",
    parents: ["514"],
    children: [],
  },
  515: {
    description: "Covert Storage Channel",
    parents: ["514"],
    children: [],
  },
  1250: {
    description:
      "Improper Preservation of Consistency Between Independent Representations of Shared State",
    parents: ["664"],
    children: ["1249", "1251"],
  },
  1249: {
    description:
      "Application-Level Admin Tool with Inconsistent View of Underlying Operating System",
    parents: ["1250"],
    children: [],
  },
  1251: {
    description: "Mirrored Regions with Different Values",
    parents: ["1250"],
    children: [],
  },
  1329: {
    description: "Reliance on Component That is Not Updateable",
    parents: ["664", "1357"],
    children: ["1310"],
  },
  1310: {
    description: "Missing Ability to Patch ROM Code",
    parents: ["1329"],
    children: [],
  },
  221: {
    description: "Information Loss or Omission",
    parents: ["664"],
    children: ["222", "223", "224", "356", "396", "397", "451"],
  },
  222: {
    description: "Truncation of Security-relevant Information",
    parents: ["221"],
    children: [],
  },
  223: {
    description: "Omission of Security-relevant Information",
    parents: ["221"],
    children: ["778"],
  },
  778: {
    description: "Insufficient Logging",
    parents: ["223", "693"],
    children: [],
  },
  224: {
    description: "Obscured Security-relevant Information by Alternate Name",
    parents: ["221"],
    children: [],
  },
  356: {
    description: "Product UI does not Warn User of Unsafe Actions",
    parents: ["221"],
    children: [],
  },
  396: {
    description: "Declaration of Catch for Generic Exception",
    parents: ["221", "705", "755"],
    children: [],
  },
  397: {
    description: "Declaration of Throws for Generic Exception",
    parents: ["221", "705", "703"],
    children: [],
  },
  451: {
    description:
      "User Interface (UI) Misrepresentation of Critical Information",
    parents: ["221", "684"],
    children: ["1007", "1021"],
  },
  1007: {
    description:
      "Insufficient Visual Distinction of Homoglyphs Presented to User",
    parents: ["451"],
    children: [],
  },
  1021: {
    description: "Improper Restriction of Rendered UI Layers or Frames",
    parents: ["451", "441"],
    children: [],
  },
  372: {
    description: "Incomplete Internal State Distinction",
    parents: ["664"],
    children: [],
  },
  400: {
    description: "Uncontrolled Resource Consumption",
    parents: ["664"],
    children: ["1235", "1246", "405", "770", "771", "779", "920"],
  },
  1235: {
    description:
      "Incorrect Use of Autoboxing and Unboxing for Performance Critical Operations",
    parents: ["400"],
    children: [],
  },
  1246: {
    description:
      "Improper Write Handling in Limited-write Non-Volatile Memories",
    parents: ["400"],
    children: [],
  },
  405: {
    description: "Asymmetric Resource Consumption (Amplification)",
    parents: ["400"],
    children: ["1073", "1176", "406", "407", "408", "409", "776"],
  },
  1073: {
    description:
      "Non-SQL Invokable Control Element with Excessive Number of Data Resource Accesses",
    parents: ["405"],
    children: [],
  },
  1176: {
    description: "Inefficient CPU Computation",
    parents: ["405"],
    children: ["1046", "1063"],
  },
  1046: {
    description: "Creation of Immutable Text Using String Concatenation",
    parents: ["1176"],
    children: [],
  },
  1063: {
    description: "Creation of Class Instance within a Static Code Block",
    parents: ["1176"],
    children: [],
  },
  406: {
    description:
      "Insufficient Control of Network Message Volume (Network Amplification)",
    parents: ["405"],
    children: [],
  },
  407: {
    description: "Inefficient Algorithmic Complexity",
    parents: ["405"],
    children: ["1333"],
  },
  1333: {
    description: "Inefficient Regular Expression Complexity",
    parents: ["407"],
    children: [],
  },
  408: {
    description: "Incorrect Behavior Order: Early Amplification",
    parents: ["405", "696"],
    children: [],
  },
  409: {
    description:
      "Improper Handling of Highly Compressed Data (Data Amplification)",
    parents: ["405"],
    children: [],
  },
  776: {
    description:
      "Improper Restriction of Recursive Entity References in DTDs ('XML Entity Expansion')",
    parents: ["405", "674"],
    children: [],
  },
  770: {
    description: "Allocation of Resources Without Limits or Throttling",
    parents: ["400", "665"],
    children: ["1325", "774", "789"],
  },
  1325: {
    description: "Improperly Controlled Sequential Memory Allocation",
    parents: ["770"],
    children: [],
  },
  774: {
    description:
      "Allocation of File Descriptors or Handles Without Limits or Throttling",
    parents: ["770"],
    children: [],
  },
  789: {
    description: "Memory Allocation with Excessive Size Value",
    parents: ["770"],
    children: [],
  },
  771: {
    description: "Missing Reference to Active Allocated Resource",
    parents: ["400"],
    children: ["773"],
  },
  773: {
    description: "Missing Reference to Active File Descriptor or Handle",
    parents: ["771"],
    children: [],
  },
  779: {
    description: "Logging of Excessive Data",
    parents: ["400"],
    children: [],
  },
  920: {
    description: "Improper Restriction of Power Consumption",
    parents: ["400"],
    children: [],
  },
  404: {
    description: "Improper Resource Shutdown or Release",
    parents: ["664"],
    children: ["299", "1266", "459", "763", "772"],
  },
  1266: {
    description:
      "Improper Scrubbing of Sensitive Data from Decommissioned Device",
    parents: ["404"],
    children: [],
  },
  459: {
    description: "Incomplete Cleanup",
    parents: ["404"],
    children: ["226", "460", "568"],
  },
  226: {
    description: "Sensitive Information in Resource Not Removed Before Reuse",
    parents: ["459", "212"],
    children: ["1239", "1272", "1301", "1342", "244"],
  },
  1239: {
    description: "Improper Zeroization of Hardware Register",
    parents: ["226"],
    children: [],
  },
  1272: {
    description:
      "Sensitive Information Uncleared Before Debug/Power State Transition",
    parents: ["226"],
    children: [],
  },
  1301: {
    description:
      "Insufficient or Incomplete Data Removal within Hardware Component",
    parents: ["226"],
    children: ["1330"],
  },
  1330: {
    description: "Remanent Data Readable after Memory Erase",
    parents: ["1301"],
    children: [],
  },
  1342: {
    description:
      "Information Exposure through Microarchitectural State after Transient Execution",
    parents: ["226"],
    children: [],
  },
  244: {
    description:
      "Improper Clearing of Heap Memory Before Release ('Heap Inspection')",
    parents: ["226"],
    children: [],
  },
  460: {
    description: "Improper Cleanup on Thrown Exception",
    parents: ["459", "755"],
    children: [],
  },
  568: {
    description: "finalize() Method Without super.finalize()",
    parents: ["459", "573"],
    children: [],
  },
  763: {
    description: "Release of Invalid Pointer or Reference",
    parents: ["404"],
    children: ["761", "762"],
  },
  761: {
    description: "Free of Pointer not at Start of Buffer",
    parents: ["763"],
    children: [],
  },
  762: {
    description: "Mismatched Memory Management Routines",
    parents: ["763"],
    children: ["590"],
  },
  590: {
    description: "Free of Memory not on the Heap",
    parents: ["762"],
    children: [],
  },
  772: {
    description: "Missing Release of Resource after Effective Lifetime",
    parents: ["404"],
    children: ["401", "775"],
  },
  401: {
    description: "Missing Release of Memory after Effective Lifetime",
    parents: ["772"],
    children: [],
  },
  775: {
    description:
      "Missing Release of File Descriptor or Handle after Effective Lifetime",
    parents: ["772"],
    children: [],
  },
  410: {
    description: "Insufficient Resource Pool",
    parents: ["664"],
    children: [],
  },
  471: {
    description: "Modification of Assumed-Immutable Data (MAID)",
    parents: ["664"],
    children: ["291", "472", "473", "607"],
  },
  472: {
    description: "External Control of Assumed-Immutable Web Parameter",
    parents: ["471", "642"],
    children: [],
  },
  473: {
    description: "PHP External Variable Modification",
    parents: ["471"],
    children: [],
  },
  607: {
    description: "Public Static Final Field References Mutable Object",
    parents: ["471"],
    children: [],
  },
  487: {
    description: "Reliance on Package-level Scope",
    parents: ["664"],
    children: [],
  },
  495: {
    description: "Private Data Structure Returned From A Public Method",
    parents: ["664"],
    children: [],
  },
  496: {
    description: "Public Data Assigned to Private Array-Typed Field",
    parents: ["664"],
    children: [],
  },
  501: {
    description: "Trust Boundary Violation",
    parents: ["664"],
    children: [],
  },
  580: {
    description: "clone() Method Without super.clone()",
    parents: ["664", "573"],
    children: [],
  },
  610: {
    description:
      "Externally Controlled Reference to a Resource in Another Sphere",
    parents: ["664"],
    children: ["15", "384", "441", "470", "601", "611", "73"],
  },
  15: {
    description: "External Control of System or Configuration Setting",
    parents: ["610", "642"],
    children: [],
  },
  384: {
    description: "Session Fixation",
    parents: ["610"],
    children: [],
  },
  441: {
    description: "Unintended Proxy or Intermediary ('Confused Deputy')",
    parents: ["610"],
    children: ["1021", "918"],
  },
  918: {
    description: "Server-Side Request Forgery (SSRF)",
    parents: ["441"],
    children: [],
  },
  470: {
    description:
      "Use of Externally-Controlled Input to Select Classes or Code ('Unsafe Reflection')",
    parents: ["610", "913"],
    children: [],
  },
  601: {
    description: "URL Redirection to Untrusted Site ('Open Redirect')",
    parents: ["610"],
    children: [],
  },
  611: {
    description: "Improper Restriction of XML External Entity Reference",
    parents: ["610"],
    children: [],
  },
  73: {
    description: "External Control of File Name or Path",
    parents: ["610", "642"],
    children: ["114"],
  },
  114: {
    description: "Process Control",
    parents: ["73"],
    children: [],
  },
  662: {
    description: "Improper Synchronization",
    parents: ["664", "691"],
    children: ["663", "667", "820", "821"],
  },
  663: {
    description: "Use of a Non-reentrant Function in a Concurrent Context",
    parents: ["662"],
    children: ["479", "558"],
  },
  479: {
    description: "Signal Handler Use of a Non-reentrant Function",
    parents: ["663", "828"],
    children: [],
  },
  558: {
    description: "Use of getlogin() in Multithreaded Application",
    parents: ["663"],
    children: [],
  },
  667: {
    description: "Improper Locking",
    parents: ["662"],
    children: [
      "1233",
      "1232",
      "1234",
      "412",
      "413",
      "414",
      "609",
      "764",
      "765",
      "832",
      "833",
    ],
  },
  1232: {
    description: "Improper Lock Behavior After Power State Transition",
    parents: ["667"],
    children: [],
  },
  1234: {
    description: "Hardware Internal or Debug Modes Allow Override of Locks",
    parents: ["667"],
    children: [],
  },
  412: {
    description: "Unrestricted Externally Accessible Lock",
    parents: ["667"],
    children: [],
  },
  413: {
    description: "Improper Resource Locking",
    parents: ["667"],
    children: ["591"],
  },
  591: {
    description: "Sensitive Data Storage in Improperly Locked Memory",
    parents: ["413"],
    children: [],
  },
  414: {
    description: "Missing Lock Check",
    parents: ["667"],
    children: [],
  },
  609: {
    description: "Double-Checked Locking",
    parents: ["667"],
    children: [],
  },
  764: {
    description: "Multiple Locks of a Critical Resource",
    parents: ["667", "675"],
    children: [],
  },
  765: {
    description: "Multiple Unlocks of a Critical Resource",
    parents: ["667", "675"],
    children: [],
  },
  832: {
    description: "Unlock of a Resource that is not Locked",
    parents: ["667"],
    children: [],
  },
  833: {
    description: "Deadlock",
    parents: ["667"],
    children: [],
  },
  820: {
    description: "Missing Synchronization",
    parents: ["662"],
    children: ["1096", "543", "567"],
  },
  1096: {
    description:
      "Singleton Class Instance Creation without Proper Locking or Synchronization",
    parents: ["820"],
    children: [],
  },
  543: {
    description:
      "Use of Singleton Pattern Without Synchronization in a Multithreaded Context",
    parents: ["820"],
    children: [],
  },
  567: {
    description:
      "Unsynchronized Access to Shared Data in a Multithreaded Context",
    parents: ["820"],
    children: [],
  },
  821: {
    description: "Incorrect Synchronization",
    parents: ["662"],
    children: ["1088", "1264", "572", "574"],
  },
  1088: {
    description: "Synchronous Access of Remote Resource without Timeout",
    parents: ["821"],
    children: [],
  },
  1264: {
    description:
      "Hardware Logic with Insecure De-Synchronization between Control and Data Channels",
    parents: ["821"],
    children: [],
  },
  572: {
    description: "Call to Thread run() instead of start()",
    parents: ["821"],
    children: [],
  },
  574: {
    description: "EJB Bad Practices: Use of Synchronization Primitives",
    parents: ["821", "695"],
    children: [],
  },
  665: {
    description: "Improper Initialization",
    parents: ["664"],
    children: [
      "770",
      "1051",
      "1188",
      "1221",
      "1279",
      "454",
      "455",
      "908",
      "909",
    ],
  },
  1051: {
    description:
      "Initialization with Hard-Coded Network Resource Configuration Data",
    parents: ["665"],
    children: [],
  },
  1188: {
    description: "Insecure Default Initialization of Resource",
    parents: ["665"],
    children: ["453"],
  },
  453: {
    description: "Insecure Default Variable Initialization",
    parents: ["1188"],
    children: [],
  },
  1221: {
    description: "Incorrect Register Defaults or Module Parameters",
    parents: ["665"],
    children: [],
  },
  1279: {
    description:
      "Cryptographic Operations are run Before Supporting Units are Ready",
    parents: ["665"],
    children: [],
  },
  454: {
    description: "External Initialization of Trusted Variables or Data Stores",
    parents: ["665"],
    children: [],
  },
  455: {
    description: "Non-exit on Failed Initialization",
    parents: ["665", "705", "636"],
    children: [],
  },
  908: {
    description: "Use of Uninitialized Resource",
    parents: ["665"],
    children: ["457"],
  },
  457: {
    description: "Use of Uninitialized Variable",
    parents: ["908"],
    children: [],
  },
  909: {
    description: "Missing Initialization of Resource",
    parents: ["665"],
    children: ["1271", "456"],
  },
  1271: {
    description:
      "Uninitialized Value on Reset for Registers Holding Security Settings",
    parents: ["909"],
    children: [],
  },
  456: {
    description: "Missing Initialization of a Variable",
    parents: ["909"],
    children: [],
  },
  666: {
    description: "Operation on Resource in Wrong Phase of Lifetime",
    parents: ["664"],
    children: ["593", "415", "605", "672", "826"],
  },
  605: {
    description: "Multiple Binds to the Same Port",
    parents: ["666", "675"],
    children: [],
  },
  672: {
    description: "Operation on a Resource after Expiration or Release",
    parents: ["666"],
    children: ["298", "825", "324", "613", "910"],
  },
  324: {
    description: "Use of a Key Past its Expiration Date",
    parents: ["672"],
    children: [],
  },
  613: {
    description: "Insufficient Session Expiration",
    parents: ["672"],
    children: [],
  },
  910: {
    description: "Use of Expired File Descriptor",
    parents: ["672"],
    children: [],
  },
  826: {
    description: "Premature Release of Resource During Expected Lifetime",
    parents: ["666"],
    children: [],
  },
  668: {
    description: "Exposure of Resource to Wrong Sphere",
    parents: ["664"],
    children: [
      "552",
      "732",
      "927",
      "522",
      "1189",
      "1282",
      "1327",
      "1331",
      "134",
      "200",
      "22",
      "374",
      "375",
      "377",
      "402",
      "427",
      "428",
      "488",
      "491",
      "492",
      "493",
      "498",
      "499",
      "524",
      "582",
      "583",
      "608",
      "642",
      "767",
      "8",
    ],
  },
  1189: {
    description:
      "Improper Isolation of Shared Resources on System-on-a-Chip (SoC)",
    parents: ["668", "653"],
    children: ["1303"],
  },
  1303: {
    description: "Non-Transparent Sharing of Microarchitectural Resources",
    parents: ["1189", "203"],
    children: [],
  },
  1282: {
    description: "Assumed-Immutable Data is Stored in Writable Memory",
    parents: ["668"],
    children: [],
  },
  1327: {
    description: "Binding to an Unrestricted IP Address",
    parents: ["668"],
    children: [],
  },
  1331: {
    description:
      "Improper Isolation of Shared Resources in Network On Chip (NoC)",
    parents: ["668", "653"],
    children: [],
  },
  134: {
    description: "Use of Externally-Controlled Format String",
    parents: ["668"],
    children: [],
  },
  200: {
    description: "Exposure of Sensitive Information to an Unauthorized Actor",
    parents: ["668"],
    children: [
      "1258",
      "1273",
      "1295",
      "201",
      "203",
      "209",
      "213",
      "215",
      "359",
      "497",
      "538",
    ],
  },
  1258: {
    description:
      "Exposure of Sensitive System Information Due to Uncleared Debug Information",
    parents: ["200", "212"],
    children: [],
  },
  1273: {
    description: "Device Unlock Credential Sharing",
    parents: ["200"],
    children: [],
  },
  1295: {
    description: "Debug Messages Revealing Unnecessary Information",
    parents: ["200"],
    children: [],
  },
  201: {
    description: "Insertion of Sensitive Information Into Sent Data",
    parents: ["200"],
    children: ["598"],
  },
  598: {
    description: "Use of GET Request Method With Sensitive Query Strings",
    parents: ["201"],
    children: [],
  },
  203: {
    description: "Observable Discrepancy",
    parents: ["200"],
    children: ["1303", "1300", "204", "205", "208"],
  },
  1300: {
    description: "Improper Protection of Physical Side Channels",
    parents: ["203"],
    children: ["1255"],
  },
  1255: {
    description: "Comparison Logic is Vulnerable to Power Side-Channel Attacks",
    parents: ["1300"],
    children: [],
  },
  204: {
    description: "Observable Response Discrepancy",
    parents: ["203"],
    children: [],
  },
  205: {
    description: "Observable Behavioral Discrepancy",
    parents: ["203"],
    children: ["206", "207"],
  },
  206: {
    description: "Observable Internal Behavioral Discrepancy",
    parents: ["205"],
    children: [],
  },
  207: {
    description: "Observable Behavioral Discrepancy With Equivalent Products",
    parents: ["205"],
    children: [],
  },
  208: {
    description: "Observable Timing Discrepancy",
    parents: ["203"],
    children: ["1254"],
  },
  1254: {
    description: "Incorrect Comparison Logic Granularity",
    parents: ["208", "697"],
    children: [],
  },
  209: {
    description: "Generation of Error Message Containing Sensitive Information",
    parents: ["200", "755"],
    children: ["210", "211", "550"],
  },
  210: {
    description:
      "Self-generated Error Message Containing Sensitive Information",
    parents: ["209"],
    children: [],
  },
  211: {
    description:
      "Externally-Generated Error Message Containing Sensitive Information",
    parents: ["209"],
    children: ["535", "536", "537"],
  },
  535: {
    description: "Exposure of Information Through Shell Error Message",
    parents: ["211"],
    children: [],
  },
  536: {
    description:
      "Servlet Runtime Error Message Containing Sensitive Information",
    parents: ["211"],
    children: [],
  },
  537: {
    description: "Java Runtime Error Message Containing Sensitive Information",
    parents: ["211"],
    children: [],
  },
  550: {
    description:
      "Server-generated Error Message Containing Sensitive Information",
    parents: ["209"],
    children: [],
  },
  213: {
    description:
      "Exposure of Sensitive Information Due to Incompatible Policies",
    parents: ["200"],
    children: [],
  },
  215: {
    description: "Insertion of Sensitive Information Into Debugging Code",
    parents: ["200"],
    children: [],
  },
  359: {
    description:
      "Exposure of Private Personal Information to an Unauthorized Actor",
    parents: ["200"],
    children: [],
  },
  497: {
    description:
      "Exposure of Sensitive System Information to an Unauthorized Control Sphere",
    parents: ["200"],
    children: ["214", "548"],
  },
  214: {
    description: "Invocation of Process Using Visible Sensitive Information",
    parents: ["497"],
    children: [],
  },
  548: {
    description: "Exposure of Information Through Directory Listing",
    parents: ["497"],
    children: [],
  },
  538: {
    description:
      "Insertion of Sensitive Information into Externally-Accessible File or Directory",
    parents: ["200"],
    children: ["532", "540", "651"],
  },
  532: {
    description: "Insertion of Sensitive Information into Log File",
    parents: ["538"],
    children: [],
  },
  540: {
    description: "Inclusion of Sensitive Information in Source Code",
    parents: ["538"],
    children: ["531", "541", "615"],
  },
  531: {
    description: "Inclusion of Sensitive Information in Test Code",
    parents: ["540"],
    children: [],
  },
  541: {
    description: "Inclusion of Sensitive Information in an Include File",
    parents: ["540"],
    children: [],
  },
  615: {
    description: "Inclusion of Sensitive Information in Source Code Comments",
    parents: ["540"],
    children: [],
  },
  651: {
    description: "Exposure of WSDL File Containing Sensitive Information",
    parents: ["538"],
    children: [],
  },
  22: {
    description:
      "Improper Limitation of a Pathname to a Restricted Directory ('Path Traversal')",
    parents: ["668", "706"],
    children: ["23", "36"],
  },
  23: {
    description: "Relative Path Traversal",
    parents: ["22"],
    children: [
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
      "32",
      "33",
      "34",
      "35",
    ],
  },
  24: {
    description: "Path Traversal: '../filedir'",
    parents: ["23"],
    children: [],
  },
  25: {
    description: "Path Traversal: '/../filedir'",
    parents: ["23"],
    children: [],
  },
  26: {
    description: "Path Traversal: '/dir/../filename'",
    parents: ["23"],
    children: [],
  },
  27: {
    description: "Path Traversal: 'dir/../../filename'",
    parents: ["23"],
    children: [],
  },
  28: {
    description: "Path Traversal: '..\\filedir'",
    parents: ["23"],
    children: [],
  },
  29: {
    description: "Path Traversal: '\\..\\filename'",
    parents: ["23"],
    children: [],
  },
  30: {
    description: "Path Traversal: '\\dir\\..\\filename'",
    parents: ["23"],
    children: [],
  },
  31: {
    description: "Path Traversal: 'dir\\..\\..\\filename'",
    parents: ["23"],
    children: [],
  },
  32: {
    description: "Path Traversal: '...' (Triple Dot)",
    parents: ["23"],
    children: [],
  },
  33: {
    description: "Path Traversal: '....' (Multiple Dot)",
    parents: ["23"],
    children: [],
  },
  34: {
    description: "Path Traversal: '....//'",
    parents: ["23"],
    children: [],
  },
  35: {
    description: "Path Traversal: '.../...//'",
    parents: ["23"],
    children: [],
  },
  36: {
    description: "Absolute Path Traversal",
    parents: ["22"],
    children: ["37", "38", "39", "40"],
  },
  37: {
    description: "Path Traversal: '/absolute/pathname/here'",
    parents: ["36", "160"],
    children: [],
  },
  38: {
    description: "Path Traversal: '\\absolute\\pathname\\here'",
    parents: ["36"],
    children: [],
  },
  39: {
    description: "Path Traversal: 'C:dirname'",
    parents: ["36"],
    children: [],
  },
  40: {
    description: "Path Traversal: '\\\\UNC\\share\\name\\' (Windows UNC Share)",
    parents: ["36"],
    children: [],
  },
  374: {
    description: "Passing Mutable Objects to an Untrusted Method",
    parents: ["668"],
    children: [],
  },
  375: {
    description: "Returning a Mutable Object to an Untrusted Caller",
    parents: ["668"],
    children: [],
  },
  377: {
    description: "Insecure Temporary File",
    parents: ["668"],
    children: ["378", "379"],
  },
  378: {
    description: "Creation of Temporary File With Insecure Permissions",
    parents: ["377"],
    children: [],
  },
  379: {
    description:
      "Creation of Temporary File in Directory with Insecure Permissions",
    parents: ["377"],
    children: [],
  },
  402: {
    description:
      "Transmission of Private Resources into a New Sphere ('Resource Leak')",
    parents: ["668"],
    children: ["403", "619"],
  },
  403: {
    description:
      "Exposure of File Descriptor to Unintended Control Sphere ('File Descriptor Leak')",
    parents: ["402"],
    children: [],
  },
  619: {
    description: "Dangling Database Cursor ('Cursor Injection')",
    parents: ["402"],
    children: [],
  },
  427: {
    description: "Uncontrolled Search Path Element",
    parents: ["668"],
    children: [],
  },
  428: {
    description: "Unquoted Search Path or Element",
    parents: ["668"],
    children: [],
  },
  488: {
    description: "Exposure of Data Element to Wrong Session",
    parents: ["668"],
    children: [],
  },
  491: {
    description: "Public cloneable() Method Without Final ('Object Hijack')",
    parents: ["668"],
    children: [],
  },
  492: {
    description: "Use of Inner Class Containing Sensitive Data",
    parents: ["668"],
    children: [],
  },
  493: {
    description: "Critical Public Variable Without Final Modifier",
    parents: ["668"],
    children: ["500"],
  },
  500: {
    description: "Public Static Field Not Marked Final",
    parents: ["493"],
    children: [],
  },
  498: {
    description: "Cloneable Class Containing Sensitive Information",
    parents: ["668"],
    children: [],
  },
  499: {
    description: "Serializable Class Containing Sensitive Data",
    parents: ["668"],
    children: [],
  },
  524: {
    description: "Use of Cache Containing Sensitive Information",
    parents: ["668"],
    children: ["525"],
  },
  525: {
    description: "Use of Web Browser Cache Containing Sensitive Information",
    parents: ["524"],
    children: [],
  },
  582: {
    description: "Array Declared Public, Final, and Static",
    parents: ["668"],
    children: [],
  },
  583: {
    description: "finalize() Method Declared Public",
    parents: ["668"],
    children: [],
  },
  608: {
    description: "Struts: Non-private Field in ActionForm Class",
    parents: ["668"],
    children: [],
  },
  642: {
    description: "External Control of Critical State Data",
    parents: ["668"],
    children: ["472", "15", "73", "426", "565"],
  },
  426: {
    description: "Untrusted Search Path",
    parents: ["642", "673"],
    children: [],
  },
  565: {
    description:
      "Reliance on Cookies without Validation and Integrity Checking",
    parents: ["642", "602"],
    children: ["784"],
  },
  784: {
    description:
      "Reliance on Cookies without Validation and Integrity Checking in a Security Decision",
    parents: ["565", "807"],
    children: [],
  },
  767: {
    description: "Access to Critical Private Variable via Public Method",
    parents: ["668"],
    children: [],
  },
  8: {
    description: "J2EE Misconfiguration: Entity Bean Declared Remote",
    parents: ["668"],
    children: [],
  },
  669: {
    description: "Incorrect Resource Transfer Between Spheres",
    parents: ["664"],
    children: ["212", "243", "434", "494", "829"],
  },
  212: {
    description:
      "Improper Removal of Sensitive Information Before Storage or Transfer",
    parents: ["669"],
    children: ["226", "1258"],
  },
  243: {
    description: "Creation of chroot Jail Without Changing Working Directory",
    parents: ["669", "573"],
    children: [],
  },
  434: {
    description: "Unrestricted Upload of File with Dangerous Type",
    parents: ["669"],
    children: [],
  },
  494: {
    description: "Download of Code Without Integrity Check",
    parents: ["669", "345"],
    children: [],
  },
  829: {
    description: "Inclusion of Functionality from Untrusted Control Sphere",
    parents: ["669"],
    children: ["827", "830", "98"],
  },
  827: {
    description: "Improper Control of Document Type Definition",
    parents: ["829", "706"],
    children: [],
  },
  830: {
    description: "Inclusion of Web Functionality from an Untrusted Source",
    parents: ["829"],
    children: [],
  },
  98: {
    description:
      "Improper Control of Filename for Include/Require Statement in PHP Program ('PHP Remote File Inclusion')",
    parents: ["829", "706"],
    children: [],
  },
  673: {
    description: "External Influence of Sphere Definition",
    parents: ["664"],
    children: ["426"],
  },
  704: {
    description: "Incorrect Type Conversion or Cast",
    parents: ["664"],
    children: ["1389", "588", "681", "843"],
  },
  1389: {
    description: "Incorrect Parsing of Numbers with Different Radices",
    parents: ["704"],
    children: [],
  },
  588: {
    description: "Attempt to Access Child of a Non-structure Pointer",
    parents: ["704", "758"],
    children: [],
  },
  681: {
    description: "Incorrect Conversion between Numeric Types",
    parents: ["704"],
    children: ["192", "194", "195", "196", "197"],
  },
  192: {
    description: "Integer Coercion Error",
    parents: ["681"],
    children: [],
  },
  194: {
    description: "Unexpected Sign Extension",
    parents: ["681"],
    children: [],
  },
  195: {
    description: "Signed to Unsigned Conversion Error",
    parents: ["681"],
    children: [],
  },
  196: {
    description: "Unsigned to Signed Conversion Error",
    parents: ["681"],
    children: [],
  },
  197: {
    description: "Numeric Truncation Error",
    parents: ["681"],
    children: [],
  },
  843: {
    description:
      "Access of Resource Using Incompatible Type ('Type Confusion')",
    parents: ["704"],
    children: [],
  },
  706: {
    description: "Use of Incorrectly-Resolved Name or Reference",
    parents: ["664"],
    children: ["22", "827", "98", "178", "386", "41", "59", "66"],
  },
  178: {
    description: "Improper Handling of Case Sensitivity",
    parents: ["706"],
    children: [],
  },
  386: {
    description: "Symbolic Name not Mapping to Correct Object",
    parents: ["706"],
    children: [],
  },
  41: {
    description: "Improper Resolution of Path Equivalence",
    parents: ["706"],
    children: [
      "42",
      "44",
      "46",
      "47",
      "48",
      "49",
      "50",
      "51",
      "52",
      "53",
      "54",
      "55",
      "56",
      "57",
      "58",
    ],
  },
  42: {
    description: "Path Equivalence: 'filename.' (Trailing Dot)",
    parents: ["41", "162"],
    children: ["43"],
  },
  43: {
    description: "Path Equivalence: 'filename....' (Multiple Trailing Dot)",
    parents: ["42", "163"],
    children: [],
  },
  44: {
    description: "Path Equivalence: 'file.name' (Internal Dot)",
    parents: ["41"],
    children: ["45"],
  },
  45: {
    description: "Path Equivalence: 'file...name' (Multiple Internal Dot)",
    parents: ["44", "165"],
    children: [],
  },
  46: {
    description: "Path Equivalence: 'filename ' (Trailing Space)",
    parents: ["41", "162"],
    children: [],
  },
  47: {
    description: "Path Equivalence: ' filename' (Leading Space)",
    parents: ["41"],
    children: [],
  },
  48: {
    description: "Path Equivalence: 'file name' (Internal Whitespace)",
    parents: ["41"],
    children: [],
  },
  49: {
    description: "Path Equivalence: 'filename/' (Trailing Slash)",
    parents: ["41", "162"],
    children: [],
  },
  50: {
    description: "Path Equivalence: '//multiple/leading/slash'",
    parents: ["41", "161"],
    children: [],
  },
  51: {
    description: "Path Equivalence: '/multiple//internal/slash'",
    parents: ["41"],
    children: [],
  },
  52: {
    description: "Path Equivalence: '/multiple/trailing/slash//'",
    parents: ["41", "163"],
    children: [],
  },
  53: {
    description: "Path Equivalence: '\\multiple\\\\internal\\backslash'",
    parents: ["41", "165"],
    children: [],
  },
  54: {
    description: "Path Equivalence: 'filedir\\' (Trailing Backslash)",
    parents: ["41", "162"],
    children: [],
  },
  55: {
    description: "Path Equivalence: '/./' (Single Dot Directory)",
    parents: ["41"],
    children: [],
  },
  56: {
    description: "Path Equivalence: 'filedir*' (Wildcard)",
    parents: ["41", "155"],
    children: [],
  },
  57: {
    description: "Path Equivalence: 'fakedir/../realdir/filename'",
    parents: ["41"],
    children: [],
  },
  58: {
    description: "Path Equivalence: Windows 8.3 Filename",
    parents: ["41"],
    children: [],
  },
  59: {
    description:
      "Improper Link Resolution Before File Access ('Link Following')",
    parents: ["706"],
    children: ["1386", "61", "62", "64", "65"],
  },
  1386: {
    description: "Insecure Operation on Windows Junction / Mount Point",
    parents: ["59"],
    children: [],
  },
  61: {
    description: "UNIX Symbolic Link (Symlink) Following",
    parents: ["59"],
    children: [],
  },
  62: {
    description: "UNIX Hard Link",
    parents: ["59"],
    children: [],
  },
  64: {
    description: "Windows Shortcut Following (.LNK)",
    parents: ["59"],
    children: [],
  },
  65: {
    description: "Windows Hard Link",
    parents: ["59"],
    children: [],
  },
  66: {
    description:
      "Improper Handling of File Names that Identify Virtual Resources",
    parents: ["706"],
    children: ["67", "69", "72"],
  },
  67: {
    description: "Improper Handling of Windows Device Names",
    parents: ["66"],
    children: [],
  },
  69: {
    description: "Improper Handling of Windows ::DATA Alternate Data Stream",
    parents: ["66"],
    children: [],
  },
  72: {
    description: "Improper Handling of Apple HFS+ Alternate Data Stream Path",
    parents: ["66"],
    children: [],
  },
  911: {
    description: "Improper Update of Reference Count",
    parents: ["664"],
    children: [],
  },
  913: {
    description: "Improper Control of Dynamically-Managed Code Resources",
    parents: ["664"],
    children: ["470", "502", "914", "915", "94"],
  },
  502: {
    description: "Deserialization of Untrusted Data",
    parents: ["913"],
    children: [],
  },
  914: {
    description: "Improper Control of Dynamically-Identified Variables",
    parents: ["913", "99"],
    children: ["621", "627"],
  },
  621: {
    description: "Variable Extraction Error",
    parents: ["914"],
    children: [],
  },
  627: {
    description: "Dynamic Variable Evaluation",
    parents: ["914"],
    children: [],
  },
  915: {
    description:
      "Improperly Controlled Modification of Dynamically-Determined Object Attributes",
    parents: ["913"],
    children: ["1321"],
  },
  1321: {
    description:
      "Improperly Controlled Modification of Object Prototype Attributes ('Prototype Pollution')",
    parents: ["915"],
    children: [],
  },
  94: {
    description: "Improper Control of Generation of Code ('Code Injection')",
    parents: ["913", "74"],
    children: ["1336", "95", "96"],
  },
  1336: {
    description:
      "Improper Neutralization of Special Elements Used in a Template Engine",
    parents: ["94"],
    children: [],
  },
  95: {
    description:
      "Improper Neutralization of Directives in Dynamically Evaluated Code ('Eval Injection')",
    parents: ["94"],
    children: [],
  },
  96: {
    description:
      "Improper Neutralization of Directives in Statically Saved Code ('Static Code Injection')",
    parents: ["94"],
    children: ["97"],
  },
  97: {
    description:
      "Improper Neutralization of Server-Side Includes (SSI) Within a Web Page",
    parents: ["96"],
    children: [],
  },
  922: {
    description: "Insecure Storage of Sensitive Information",
    parents: ["664"],
    children: ["312", "921"],
  },
  312: {
    description: "Cleartext Storage of Sensitive Information",
    parents: ["922", "311"],
    children: ["313", "314", "315", "316", "317", "318", "526"],
  },
  313: {
    description: "Cleartext Storage in a File or on Disk",
    parents: ["312"],
    children: [],
  },
  314: {
    description: "Cleartext Storage in the Registry",
    parents: ["312"],
    children: [],
  },
  315: {
    description: "Cleartext Storage of Sensitive Information in a Cookie",
    parents: ["312"],
    children: [],
  },
  316: {
    description: "Cleartext Storage of Sensitive Information in Memory",
    parents: ["312"],
    children: [],
  },
  317: {
    description: "Cleartext Storage of Sensitive Information in GUI",
    parents: ["312"],
    children: [],
  },
  318: {
    description: "Cleartext Storage of Sensitive Information in Executable",
    parents: ["312"],
    children: [],
  },
  526: {
    description:
      "Cleartext Storage of Sensitive Information in an Environment Variable",
    parents: ["312"],
    children: [],
  },
  921: {
    description:
      "Storage of Sensitive Data in a Mechanism without Access Control",
    parents: ["922"],
    children: [],
  },
  682: {
    description: "Incorrect Calculation",
    parents: ["All CWEs"],
    children: [
      "128",
      "131",
      "1335",
      "1339",
      "135",
      "190",
      "191",
      "193",
      "369",
      "468",
      "469",
    ],
  },
  128: {
    description: "Wrap-around Error",
    parents: ["682"],
    children: [],
  },
  131: {
    description: "Incorrect Calculation of Buffer Size",
    parents: ["682"],
    children: ["467"],
  },
  467: {
    description: "Use of sizeof() on a Pointer Type",
    parents: ["131"],
    children: [],
  },
  1335: {
    description: "Incorrect Bitwise Shift of Integer",
    parents: ["682"],
    children: [],
  },
  1339: {
    description: "Insufficient Precision or Accuracy of a Real Number",
    parents: ["682"],
    children: [],
  },
  135: {
    description: "Incorrect Calculation of Multi-Byte String Length",
    parents: ["682"],
    children: [],
  },
  190: {
    description: "Integer Overflow or Wraparound",
    parents: ["682"],
    children: ["680"],
  },
  680: {
    description: "Integer Overflow to Buffer Overflow",
    parents: ["190"],
    children: [],
  },
  191: {
    description: "Integer Underflow (Wrap or Wraparound)",
    parents: ["682"],
    children: [],
  },
  193: {
    description: "Off-by-one Error",
    parents: ["682"],
    children: [],
  },
  369: {
    description: "Divide By Zero",
    parents: ["682"],
    children: [],
  },
  468: {
    description: "Incorrect Pointer Scaling",
    parents: ["682"],
    children: [],
  },
  469: {
    description: "Use of Pointer Subtraction to Determine Size",
    parents: ["682"],
    children: [],
  },
  691: {
    description: "Insufficient Control Flow Management",
    parents: ["All CWEs"],
    children: [
      "662",
      "1265",
      "1281",
      "362",
      "430",
      "431",
      "670",
      "696",
      "705",
      "768",
      "799",
      "834",
      "841",
    ],
  },
  1265: {
    description:
      "Unintended Reentrant Invocation of Non-reentrant Code Via Nested Calls",
    parents: ["691"],
    children: [],
  },
  1281: {
    description:
      "Sequence of Processor Instructions Leads to Unexpected Behavior",
    parents: ["691"],
    children: [],
  },
  362: {
    description:
      "Concurrent Execution using Shared Resource with Improper Synchronization ('Race Condition')",
    parents: ["691"],
    children: ["421", "1223", "1298", "364", "366", "367", "368", "689"],
  },
  1223: {
    description: "Race Condition for Write-Once Attributes",
    parents: ["362"],
    children: [],
  },
  1298: {
    description: "Hardware Logic Contains Race Conditions",
    parents: ["362"],
    children: [],
  },
  364: {
    description: "Signal Handler Race Condition",
    parents: ["362"],
    children: ["432", "828", "831"],
  },
  432: {
    description:
      "Dangerous Signal Handler not Disabled During Sensitive Operations",
    parents: ["364"],
    children: [],
  },
  828: {
    description:
      "Signal Handler with Functionality that is not Asynchronous-Safe",
    parents: ["364"],
    children: ["479"],
  },
  831: {
    description: "Signal Handler Function Associated with Multiple Signals",
    parents: ["364"],
    children: [],
  },
  366: {
    description: "Race Condition within a Thread",
    parents: ["362"],
    children: [],
  },
  367: {
    description: "Time-of-check Time-of-use (TOCTOU) Race Condition",
    parents: ["362"],
    children: ["363"],
  },
  363: {
    description: "Race Condition Enabling Link Following",
    parents: ["367"],
    children: [],
  },
  368: {
    description: "Context Switching Race Condition",
    parents: ["362"],
    children: [],
  },
  689: {
    description: "Permission Race Condition During Resource Copy",
    parents: ["362"],
    children: [],
  },
  430: {
    description: "Deployment of Wrong Handler",
    parents: ["691"],
    children: [],
  },
  431: {
    description: "Missing Handler",
    parents: ["691"],
    children: [],
  },
  670: {
    description: "Always-Incorrect Control Flow Implementation",
    parents: ["691"],
    children: ["480", "483", "484", "617", "698", "783"],
  },
  480: {
    description: "Use of Incorrect Operator",
    parents: ["670"],
    children: ["481", "482", "597"],
  },
  481: {
    description: "Assigning instead of Comparing",
    parents: ["480"],
    children: [],
  },
  482: {
    description: "Comparing instead of Assigning",
    parents: ["480"],
    children: [],
  },
  597: {
    description: "Use of Wrong Operator in String Comparison",
    parents: ["480", "595"],
    children: [],
  },
  483: {
    description: "Incorrect Block Delimitation",
    parents: ["670"],
    children: [],
  },
  484: {
    description: "Omitted Break Statement in Switch",
    parents: ["670", "710"],
    children: [],
  },
  617: {
    description: "Reachable Assertion",
    parents: ["670"],
    children: [],
  },
  698: {
    description: "Execution After Redirect (EAR)",
    parents: ["670", "705"],
    children: [],
  },
  783: {
    description: "Operator Precedence Logic Error",
    parents: ["670"],
    children: [],
  },
  696: {
    description: "Incorrect Behavior Order",
    parents: ["691"],
    children: ["1280", "551", "408", "1190", "1193", "179"],
  },
  1190: {
    description: "DMA Device Enabled Too Early in Boot Phase",
    parents: ["696"],
    children: [],
  },
  1193: {
    description:
      "Power-On of Untrusted Execution Core Before Enabling Fabric Access Control",
    parents: ["696"],
    children: [],
  },
  179: {
    description: "Incorrect Behavior Order: Early Validation",
    parents: ["696", "20"],
    children: ["180", "181"],
  },
  180: {
    description: "Incorrect Behavior Order: Validate Before Canonicalize",
    parents: ["179"],
    children: [],
  },
  181: {
    description: "Incorrect Behavior Order: Validate Before Filter",
    parents: ["179"],
    children: [],
  },
  705: {
    description: "Incorrect Control Flow Scoping",
    parents: ["691"],
    children: ["396", "397", "455", "698", "248", "382", "395", "584"],
  },
  248: {
    description: "Uncaught Exception",
    parents: ["705", "755"],
    children: ["600"],
  },
  600: {
    description: "Uncaught Exception in Servlet ",
    parents: ["248"],
    children: [],
  },
  382: {
    description: "J2EE Bad Practices: Use of System.exit()",
    parents: ["705"],
    children: [],
  },
  395: {
    description:
      "Use of NullPointerException Catch to Detect NULL Pointer Dereference",
    parents: ["705", "755"],
    children: [],
  },
  584: {
    description: "Return Inside Finally Block",
    parents: ["705"],
    children: [],
  },
  768: {
    description: "Incorrect Short Circuit Evaluation",
    parents: ["691"],
    children: [],
  },
  799: {
    description: "Improper Control of Interaction Frequency",
    parents: ["691"],
    children: ["307", "837"],
  },
  837: {
    description: "Improper Enforcement of a Single, Unique Action",
    parents: ["799"],
    children: [],
  },
  834: {
    description: "Excessive Iteration",
    parents: ["691"],
    children: ["674", "835"],
  },
  674: {
    description: "Uncontrolled Recursion",
    parents: ["834"],
    children: ["776"],
  },
  835: {
    description: "Loop with Unreachable Exit Condition ('Infinite Loop')",
    parents: ["834"],
    children: [],
  },
  841: {
    description: "Improper Enforcement of Behavioral Workflow",
    parents: ["691"],
    children: [],
  },
  693: {
    description: "Protection Mechanism Failure",
    parents: ["All CWEs"],
    children: [
      "424",
      "778",
      "1039",
      "1248",
      "1253",
      "1269",
      "1278",
      "1291",
      "1318",
      "1319",
      "1326",
      "1338",
      "182",
      "184",
      "311",
      "326",
      "327",
      "330",
      "345",
      "357",
      "358",
      "602",
      "653",
      "654",
      "655",
      "656",
      "757",
      "807",
    ],
  },
  1039: {
    description:
      "Automated Recognition Mechanism with Inadequate Detection or Handling of Adversarial Input Perturbations",
    parents: ["693", "697"],
    children: [],
  },
  1248: {
    description:
      "Semiconductor Defects in Hardware Logic with Security-Sensitive Implications",
    parents: ["693"],
    children: [],
  },
  1253: {
    description: "Incorrect Selection of Fuse Values",
    parents: ["693"],
    children: [],
  },
  1269: {
    description: "Product Released in Non-Release Configuration",
    parents: ["693"],
    children: [],
  },
  1278: {
    description:
      "Missing Protection Against Hardware Reverse Engineering Using Integrated Circuit (IC) Imaging Techniques",
    parents: ["693"],
    children: [],
  },
  1291: {
    description: "Public Key Re-Use for Signing both Debug and Production Code",
    parents: ["693"],
    children: [],
  },
  1318: {
    description:
      "Missing Support for Security Features in On-chip Fabrics or Buses",
    parents: ["693"],
    children: [],
  },
  1319: {
    description:
      "Improper Protection against Electromagnetic Fault Injection (EM-FI)",
    parents: ["693"],
    children: [],
  },
  1326: {
    description: "Missing Immutable Root of Trust in Hardware",
    parents: ["693"],
    children: [],
  },
  1338: {
    description: "Improper Protections Against Hardware Overheating",
    parents: ["693"],
    children: [],
  },
  182: {
    description: "Collapse of Data into Unsafe Value",
    parents: ["693"],
    children: [],
  },
  184: {
    description: "Incomplete List of Disallowed Inputs",
    parents: ["693", "1023"],
    children: ["692"],
  },
  692: {
    description: "Incomplete Denylist to Cross-Site Scripting",
    parents: ["184"],
    children: [],
  },
  311: {
    description: "Missing Encryption of Sensitive Data",
    parents: ["693"],
    children: ["312", "319"],
  },
  319: {
    description: "Cleartext Transmission of Sensitive Information",
    parents: ["311"],
    children: ["5", "614"],
  },
  5: {
    description: "J2EE Misconfiguration: Data Transmission Without Encryption",
    parents: ["319"],
    children: [],
  },
  614: {
    description: "Sensitive Cookie in HTTPS Session Without 'Secure' Attribute",
    parents: ["319"],
    children: [],
  },
  326: {
    description: "Inadequate Encryption Strength",
    parents: ["693"],
    children: ["328"],
  },
  328: {
    description: "Use of Weak Hash",
    parents: ["326", "327"],
    children: ["916"],
  },
  916: {
    description: "Use of Password Hash With Insufficient Computational Effort",
    parents: ["328"],
    children: ["759", "760"],
  },
  759: {
    description: "Use of a One-Way Hash without a Salt",
    parents: ["916"],
    children: [],
  },
  760: {
    description: "Use of a One-Way Hash with a Predictable Salt",
    parents: ["916"],
    children: [],
  },
  327: {
    description: "Use of a Broken or Risky Cryptographic Algorithm",
    parents: ["693"],
    children: ["328", "1240", "780"],
  },
  1240: {
    description: "Use of a Cryptographic Primitive with a Risky Implementation",
    parents: ["327"],
    children: [],
  },
  780: {
    description: "Use of RSA Algorithm without OAEP",
    parents: ["327"],
    children: [],
  },
  330: {
    description: "Use of Insufficiently Random Values",
    parents: ["693"],
    children: ["1204", "1241", "331", "334", "335", "338", "340", "344"],
  },
  1204: {
    description: "Generation of Weak Initialization Vector (IV)",
    parents: ["330"],
    children: ["329"],
  },
  329: {
    description: "Generation of Predictable IV with CBC Mode",
    parents: ["1204", "573"],
    children: [],
  },
  1241: {
    description: "Use of Predictable Algorithm in Random Number Generator",
    parents: ["330"],
    children: [],
  },
  331: {
    description: "Insufficient Entropy",
    parents: ["330"],
    children: ["332", "333"],
  },
  332: {
    description: "Insufficient Entropy in PRNG",
    parents: ["331"],
    children: [],
  },
  333: {
    description: "Improper Handling of Insufficient Entropy in TRNG",
    parents: ["331", "703"],
    children: [],
  },
  334: {
    description: "Small Space of Random Values",
    parents: ["330"],
    children: ["6"],
  },
  6: {
    description: "J2EE Misconfiguration: Insufficient Session-ID Length",
    parents: ["334"],
    children: [],
  },
  335: {
    description:
      "Incorrect Usage of Seeds in Pseudo-Random Number Generator (PRNG)",
    parents: ["330"],
    children: ["336", "337", "339"],
  },
  336: {
    description: "Same Seed in Pseudo-Random Number Generator (PRNG)",
    parents: ["335"],
    children: [],
  },
  337: {
    description: "Predictable Seed in Pseudo-Random Number Generator (PRNG)",
    parents: ["335"],
    children: [],
  },
  339: {
    description: "Small Seed Space in PRNG",
    parents: ["335"],
    children: [],
  },
  338: {
    description:
      "Use of Cryptographically Weak Pseudo-Random Number Generator (PRNG)",
    parents: ["330"],
    children: [],
  },
  340: {
    description: "Generation of Predictable Numbers or Identifiers",
    parents: ["330"],
    children: ["341", "342", "343"],
  },
  341: {
    description: "Predictable from Observable State",
    parents: ["340"],
    children: [],
  },
  342: {
    description: "Predictable Exact Value from Previous Values",
    parents: ["340"],
    children: [],
  },
  343: {
    description: "Predictable Value Range from Previous Values",
    parents: ["340"],
    children: [],
  },
  344: {
    description: "Use of Invariant Value in Dynamically Changing Context",
    parents: ["330"],
    children: ["798", "323", "587"],
  },
  323: {
    description: "Reusing a Nonce, Key Pair in Encryption",
    parents: ["344"],
    children: [],
  },
  587: {
    description: "Assignment of a Fixed Address to a Pointer",
    parents: ["344", "758"],
    children: [],
  },
  345: {
    description: "Insufficient Verification of Data Authenticity",
    parents: ["693"],
    children: [
      "346",
      "494",
      "1293",
      "347",
      "348",
      "349",
      "351",
      "352",
      "353",
      "354",
      "360",
      "616",
      "646",
      "649",
      "924",
    ],
  },
  1293: {
    description: "Missing Source Correlation of Multiple Independent Data",
    parents: ["345"],
    children: [],
  },
  347: {
    description: "Improper Verification of Cryptographic Signature",
    parents: ["345"],
    children: [],
  },
  348: {
    description: "Use of Less Trusted Source",
    parents: ["345"],
    children: [],
  },
  349: {
    description: "Acceptance of Extraneous Untrusted Data With Trusted Data",
    parents: ["345"],
    children: [],
  },
  351: {
    description: "Insufficient Type Distinction",
    parents: ["345"],
    children: [],
  },
  352: {
    description: "Cross-Site Request Forgery (CSRF)",
    parents: ["345"],
    children: [],
  },
  353: {
    description: "Missing Support for Integrity Check",
    parents: ["345"],
    children: [],
  },
  354: {
    description: "Improper Validation of Integrity Check Value",
    parents: ["345", "754"],
    children: [],
  },
  360: {
    description: "Trust of System Event Data",
    parents: ["345"],
    children: ["422"],
  },
  616: {
    description: "Incomplete Identification of Uploaded File Variables (PHP)",
    parents: ["345"],
    children: [],
  },
  646: {
    description:
      "Reliance on File Name or Extension of Externally-Supplied File",
    parents: ["345"],
    children: [],
  },
  649: {
    description:
      "Reliance on Obfuscation or Encryption of Security-Relevant Inputs without Integrity Checking",
    parents: ["345"],
    children: [],
  },
  924: {
    description:
      "Improper Enforcement of Message Integrity During Transmission in a Communication Channel",
    parents: ["345"],
    children: [],
  },
  357: {
    description: "Insufficient UI Warning of Dangerous Operations",
    parents: ["693"],
    children: ["450"],
  },
  450: {
    description: "Multiple Interpretations of UI Input",
    parents: ["357"],
    children: [],
  },
  358: {
    description: "Improperly Implemented Security Check for Standard",
    parents: ["693", "573"],
    children: [],
  },
  602: {
    description: "Client-Side Enforcement of Server-Side Security",
    parents: ["693"],
    children: ["603", "565"],
  },
  653: {
    description: "Improper Isolation or Compartmentalization",
    parents: ["693", "657"],
    children: ["1189", "1331"],
  },
  654: {
    description: "Reliance on a Single Factor in a Security Decision",
    parents: ["693", "657"],
    children: ["308", "309"],
  },
  655: {
    description: "Insufficient Psychological Acceptability",
    parents: ["693", "657"],
    children: [],
  },
  656: {
    description: "Reliance on Security Through Obscurity",
    parents: ["693", "657"],
    children: [],
  },
  757: {
    description:
      "Selection of Less-Secure Algorithm During Negotiation ('Algorithm Downgrade')",
    parents: ["693"],
    children: [],
  },
  807: {
    description: "Reliance on Untrusted Inputs in a Security Decision",
    parents: ["693"],
    children: ["350", "302", "784"],
  },
  697: {
    description: "Incorrect Comparison",
    parents: ["All CWEs"],
    children: ["1254", "1039", "1023", "1024", "1025", "183", "185", "581"],
  },
  1023: {
    description: "Incomplete Comparison with Missing Factors",
    parents: ["697"],
    children: ["184", "187", "478", "839"],
  },
  187: {
    description: "Partial String Comparison",
    parents: ["1023"],
    children: [],
  },
  478: {
    description: "Missing Default Case in Multiple Condition Expression",
    parents: ["1023"],
    children: [],
  },
  839: {
    description: "Numeric Range Comparison Without Minimum Check",
    parents: ["1023"],
    children: [],
  },
  1024: {
    description: "Comparison of Incompatible Types",
    parents: ["697"],
    children: [],
  },
  1025: {
    description: "Comparison Using Wrong Factors",
    parents: ["697"],
    children: ["486", "595"],
  },
  486: {
    description: "Comparison of Classes by Name",
    parents: ["1025"],
    children: [],
  },
  595: {
    description: "Comparison of Object References Instead of Object Contents",
    parents: ["1025"],
    children: ["597"],
  },
  183: {
    description: "Permissive List of Allowed Inputs",
    parents: ["697"],
    children: ["942"],
  },
  185: {
    description: "Incorrect Regular Expression",
    parents: ["697"],
    children: ["186", "625"],
  },
  186: {
    description: "Overly Restrictive Regular Expression",
    parents: ["185"],
    children: [],
  },
  625: {
    description: "Permissive Regular Expression",
    parents: ["185"],
    children: ["777"],
  },
  777: {
    description: "Regular Expression without Anchors",
    parents: ["625"],
    children: [],
  },
  581: {
    description:
      "Object Model Violation: Just One of Equals and Hashcode Defined",
    parents: ["697", "573"],
    children: [],
  },
  703: {
    description: "Improper Check or Handling of Exceptional Conditions",
    parents: ["All CWEs"],
    children: [
      "397",
      "333",
      "1384",
      "166",
      "167",
      "168",
      "228",
      "393",
      "754",
      "755",
    ],
  },
  1384: {
    description: "Improper Handling of Physical or Environmental Conditions",
    parents: ["703"],
    children: ["1247", "1261", "1332"],
  },
  1247: {
    description: "Improper Protection Against Voltage and Clock Glitches",
    parents: ["1384"],
    children: [],
  },
  1261: {
    description: "Improper Handling of Single Event Upsets",
    parents: ["1384"],
    children: [],
  },
  1332: {
    description: "Improper Handling of Faults that Lead to Instruction Skips",
    parents: ["1384"],
    children: [],
  },
  166: {
    description: "Improper Handling of Missing Special Element",
    parents: ["703", "159"],
    children: [],
  },
  167: {
    description: "Improper Handling of Additional Special Element",
    parents: ["703", "159"],
    children: [],
  },
  168: {
    description: "Improper Handling of Inconsistent Special Elements",
    parents: ["703", "159"],
    children: [],
  },
  228: {
    description: "Improper Handling of Syntactically Invalid Structure",
    parents: ["703", "707"],
    children: ["229", "233", "237", "241"],
  },
  229: {
    description: "Improper Handling of Values",
    parents: ["228"],
    children: ["230", "231", "232"],
  },
  230: {
    description: "Improper Handling of Missing Values",
    parents: ["229"],
    children: [],
  },
  231: {
    description: "Improper Handling of Extra Values",
    parents: ["229"],
    children: [],
  },
  232: {
    description: "Improper Handling of Undefined Values",
    parents: ["229"],
    children: [],
  },
  233: {
    description: "Improper Handling of Parameters",
    parents: ["228"],
    children: ["234", "235", "236"],
  },
  234: {
    description: "Failure to Handle Missing Parameter",
    parents: ["233"],
    children: [],
  },
  235: {
    description: "Improper Handling of Extra Parameters",
    parents: ["233"],
    children: [],
  },
  236: {
    description: "Improper Handling of Undefined Parameters",
    parents: ["233"],
    children: [],
  },
  237: {
    description: "Improper Handling of Structural Elements",
    parents: ["228"],
    children: ["238", "239", "240"],
  },
  238: {
    description: "Improper Handling of Incomplete Structural Elements",
    parents: ["237"],
    children: [],
  },
  239: {
    description: "Failure to Handle Incomplete Element",
    parents: ["237"],
    children: [],
  },
  240: {
    description: "Improper Handling of Inconsistent Structural Elements",
    parents: ["237", "707"],
    children: ["130"],
  },
  130: {
    description: "Improper Handling of Length Parameter Inconsistency",
    parents: ["240"],
    children: [],
  },
  241: {
    description: "Improper Handling of Unexpected Data Type",
    parents: ["228"],
    children: [],
  },
  393: {
    description: "Return of Wrong Status Code",
    parents: ["703", "684"],
    children: [],
  },
  754: {
    description: "Improper Check for Unusual or Exceptional Conditions",
    parents: ["703"],
    children: ["273", "354", "252", "253", "391", "394", "476"],
  },
  252: {
    description: "Unchecked Return Value",
    parents: ["754"],
    children: ["690"],
  },
  690: {
    description: "Unchecked Return Value to NULL Pointer Dereference",
    parents: ["252"],
    children: [],
  },
  253: {
    description: "Incorrect Check of Function Return Value",
    parents: ["754", "573"],
    children: [],
  },
  391: {
    description: "Unchecked Error Condition",
    parents: ["754"],
    children: [],
  },
  394: {
    description: "Unexpected Status Code or Return Value",
    parents: ["754"],
    children: [],
  },
  476: {
    description: "NULL Pointer Dereference",
    parents: ["754", "710"],
    children: [],
  },
  755: {
    description: "Improper Handling of Exceptional Conditions",
    parents: ["703"],
    children: [
      "274",
      "396",
      "460",
      "209",
      "248",
      "395",
      "280",
      "390",
      "392",
      "544",
      "636",
      "756",
    ],
  },
  280: {
    description: "Improper Handling of Insufficient Permissions or Privileges ",
    parents: ["755"],
    children: [],
  },
  390: {
    description: "Detection of Error Condition Without Action",
    parents: ["755"],
    children: [],
  },
  392: {
    description: "Missing Report of Error Condition",
    parents: ["755", "684"],
    children: [],
  },
  544: {
    description: "Missing Standardized Error Handling Mechanism",
    parents: ["755"],
    children: [],
  },
  636: {
    description: "Not Failing Securely ('Failing Open')",
    parents: ["755", "657"],
    children: ["455"],
  },
  756: {
    description: "Missing Custom Error Page",
    parents: ["755"],
    children: ["12", "7"],
  },
  12: {
    description: "ASP.NET Misconfiguration: Missing Custom Error Page",
    parents: ["756"],
    children: [],
  },
  7: {
    description: "J2EE Misconfiguration: Missing Custom Error Page",
    parents: ["756"],
    children: [],
  },
  707: {
    description: "Improper Neutralization",
    parents: ["All CWEs"],
    children: ["228", "240", "116", "138", "170", "172", "20", "463", "74"],
  },
  116: {
    description: "Improper Encoding or Escaping of Output",
    parents: ["707"],
    children: ["117", "644", "838"],
  },
  117: {
    description: "Improper Output Neutralization for Logs",
    parents: ["116"],
    children: [],
  },
  644: {
    description: "Improper Neutralization of HTTP Headers for Scripting Syntax",
    parents: ["116"],
    children: [],
  },
  838: {
    description: "Inappropriate Encoding for Output Context",
    parents: ["116"],
    children: [],
  },
  138: {
    description: "Improper Neutralization of Special Elements",
    parents: ["707"],
    children: [
      "140",
      "147",
      "148",
      "149",
      "150",
      "151",
      "152",
      "153",
      "154",
      "155",
      "156",
      "157",
      "158",
      "159",
      "160",
      "162",
      "164",
      "464",
      "790",
    ],
  },
  140: {
    description: "Improper Neutralization of Delimiters",
    parents: ["138"],
    children: ["141", "142", "143", "144", "145", "146"],
  },
  141: {
    description: "Improper Neutralization of Parameter/Argument Delimiters",
    parents: ["140"],
    children: [],
  },
  142: {
    description: "Improper Neutralization of Value Delimiters",
    parents: ["140"],
    children: [],
  },
  143: {
    description: "Improper Neutralization of Record Delimiters",
    parents: ["140"],
    children: [],
  },
  144: {
    description: "Improper Neutralization of Line Delimiters",
    parents: ["140"],
    children: [],
  },
  145: {
    description: "Improper Neutralization of Section Delimiters",
    parents: ["140"],
    children: [],
  },
  146: {
    description: "Improper Neutralization of Expression/Command Delimiters",
    parents: ["140"],
    children: [],
  },
  147: {
    description: "Improper Neutralization of Input Terminators",
    parents: ["138"],
    children: ["626"],
  },
  148: {
    description: "Improper Neutralization of Input Leaders",
    parents: ["138"],
    children: [],
  },
  149: {
    description: "Improper Neutralization of Quoting Syntax",
    parents: ["138"],
    children: [],
  },
  150: {
    description:
      "Improper Neutralization of Escape, Meta, or Control Sequences",
    parents: ["138"],
    children: [],
  },
  151: {
    description: "Improper Neutralization of Comment Delimiters",
    parents: ["138"],
    children: [],
  },
  152: {
    description: "Improper Neutralization of Macro Symbols",
    parents: ["138"],
    children: [],
  },
  153: {
    description: "Improper Neutralization of Substitution Characters",
    parents: ["138"],
    children: [],
  },
  154: {
    description: "Improper Neutralization of Variable Name Delimiters",
    parents: ["138"],
    children: [],
  },
  155: {
    description: "Improper Neutralization of Wildcards or Matching Symbols",
    parents: ["138"],
    children: ["56"],
  },
  156: {
    description: "Improper Neutralization of Whitespace",
    parents: ["138"],
    children: [],
  },
  157: {
    description: "Failure to Sanitize Paired Delimiters",
    parents: ["138"],
    children: [],
  },
  158: {
    description: "Improper Neutralization of Null Byte or NUL Character",
    parents: ["138"],
    children: [],
  },
  159: {
    description: "Improper Handling of Invalid Use of Special Elements",
    parents: ["138"],
    children: ["166", "167", "168"],
  },
  160: {
    description: "Improper Neutralization of Leading Special Elements",
    parents: ["138"],
    children: ["37", "161"],
  },
  161: {
    description: "Improper Neutralization of Multiple Leading Special Elements",
    parents: ["160"],
    children: ["50"],
  },
  162: {
    description: "Improper Neutralization of Trailing Special Elements",
    parents: ["138"],
    children: ["42", "46", "49", "54", "163"],
  },
  163: {
    description:
      "Improper Neutralization of Multiple Trailing Special Elements",
    parents: ["162"],
    children: ["43", "52"],
  },
  164: {
    description: "Improper Neutralization of Internal Special Elements",
    parents: ["138"],
    children: ["165"],
  },
  165: {
    description:
      "Improper Neutralization of Multiple Internal Special Elements",
    parents: ["164"],
    children: ["45", "53"],
  },
  464: {
    description: "Addition of Data Structure Sentinel",
    parents: ["138"],
    children: [],
  },
  790: {
    description: "Improper Filtering of Special Elements",
    parents: ["138"],
    children: ["791"],
  },
  791: {
    description: "Incomplete Filtering of Special Elements",
    parents: ["790"],
    children: ["792", "795"],
  },
  792: {
    description:
      "Incomplete Filtering of One or More Instances of Special Elements",
    parents: ["791"],
    children: ["793", "794"],
  },
  793: {
    description: "Only Filtering One Instance of a Special Element",
    parents: ["792"],
    children: [],
  },
  794: {
    description:
      "Incomplete Filtering of Multiple Instances of Special Elements",
    parents: ["792"],
    children: [],
  },
  795: {
    description: "Only Filtering Special Elements at a Specified Location",
    parents: ["791"],
    children: ["796", "797"],
  },
  796: {
    description: "Only Filtering Special Elements Relative to a Marker",
    parents: ["795"],
    children: [],
  },
  797: {
    description: "Only Filtering Special Elements at an Absolute Position",
    parents: ["795"],
    children: [],
  },
  170: {
    description: "Improper Null Termination",
    parents: ["707"],
    children: [],
  },
  172: {
    description: "Encoding Error",
    parents: ["707"],
    children: ["173", "174", "175", "176", "177"],
  },
  173: {
    description: "Improper Handling of Alternate Encoding",
    parents: ["172"],
    children: [],
  },
  174: {
    description: "Double Decoding of the Same Data",
    parents: ["172", "675"],
    children: [],
  },
  175: {
    description: "Improper Handling of Mixed Encoding",
    parents: ["172"],
    children: [],
  },
  176: {
    description: "Improper Handling of Unicode Encoding",
    parents: ["172"],
    children: [],
  },
  177: {
    description: "Improper Handling of URL Encoding (Hex Encoding)",
    parents: ["172"],
    children: [],
  },
  20: {
    description: "Improper Input Validation",
    parents: ["707"],
    children: [
      "179",
      "1173",
      "1284",
      "1285",
      "1286",
      "1287",
      "1288",
      "1289",
      "622",
    ],
  },
  1173: {
    description: "Improper Use of Validation Framework",
    parents: ["20"],
    children: ["102", "105", "106", "108", "109", "1174", "554"],
  },
  102: {
    description: "Struts: Duplicate Validation Forms",
    parents: ["1173", "694"],
    children: [],
  },
  105: {
    description: "Struts: Form Field Without Validator",
    parents: ["1173"],
    children: [],
  },
  106: {
    description: "Struts: Plug-in Framework not in Use",
    parents: ["1173"],
    children: [],
  },
  108: {
    description: "Struts: Unvalidated Action Form",
    parents: ["1173"],
    children: [],
  },
  109: {
    description: "Struts: Validator Turned Off",
    parents: ["1173"],
    children: [],
  },
  1174: {
    description: "ASP.NET Misconfiguration: Improper Model Validation",
    parents: ["1173"],
    children: [],
  },
  554: {
    description:
      "ASP.NET Misconfiguration: Not Using Input Validation Framework",
    parents: ["1173"],
    children: [],
  },
  1284: {
    description: "Improper Validation of Specified Quantity in Input",
    parents: ["20"],
    children: ["606"],
  },
  606: {
    description: "Unchecked Input for Loop Condition",
    parents: ["1284"],
    children: [],
  },
  1285: {
    description:
      "Improper Validation of Specified Index, Position, or Offset in Input",
    parents: ["20"],
    children: ["129", "781"],
  },
  129: {
    description: "Improper Validation of Array Index",
    parents: ["1285"],
    children: [],
  },
  781: {
    description:
      "Improper Address Validation in IOCTL with METHOD_NEITHER I/O Control Code",
    parents: ["1285"],
    children: [],
  },
  1286: {
    description: "Improper Validation of Syntactic Correctness of Input",
    parents: ["20"],
    children: ["112"],
  },
  112: {
    description: "Missing XML Validation",
    parents: ["1286"],
    children: [],
  },
  1287: {
    description: "Improper Validation of Specified Type of Input",
    parents: ["20"],
    children: [],
  },
  1288: {
    description: "Improper Validation of Consistency within Input",
    parents: ["20"],
    children: [],
  },
  1289: {
    description: "Improper Validation of Unsafe Equivalence in Input",
    parents: ["20"],
    children: [],
  },
  622: {
    description: "Improper Validation of Function Hook Arguments",
    parents: ["20"],
    children: [],
  },
  463: {
    description: "Deletion of Data Structure Sentinel",
    parents: ["707"],
    children: [],
  },
  74: {
    description:
      "Improper Neutralization of Special Elements in Output Used by a Downstream Component ('Injection')",
    parents: ["707"],
    children: ["94", "1236", "75", "77", "79", "91", "93", "943", "99"],
  },
  1236: {
    description: "Improper Neutralization of Formula Elements in a CSV File",
    parents: ["74"],
    children: [],
  },
  75: {
    description:
      "Failure to Sanitize Special Elements into a Different Plane (Special Element Injection)",
    parents: ["74"],
    children: ["76"],
  },
  76: {
    description: "Improper Neutralization of Equivalent Special Elements",
    parents: ["75"],
    children: [],
  },
  77: {
    description:
      "Improper Neutralization of Special Elements used in a Command ('Command Injection')",
    parents: ["74"],
    children: ["624", "78", "917", "88"],
  },
  88: {
    description:
      "Improper Neutralization of Argument Delimiters in a Command ('Argument Injection')",
    parents: ["77"],
    children: [],
  },
  624: {
    description: "Executable Regular Expression Error",
    parents: ["77"],
    children: [],
  },
  78: {
    description:
      "Improper Neutralization of Special Elements used in an OS Command ('OS Command Injection')",
    parents: ["77"],
    children: [],
  },
  917: {
    description:
      "Improper Neutralization of Special Elements used in an Expression Language Statement ('Expression Language Injection')",
    parents: ["77"],
    children: [],
  },
  79: {
    description:
      "Improper Neutralization of Input During Web Page Generation ('Cross-site Scripting')",
    parents: ["74"],
    children: ["86", "80", "81", "83", "84", "85", "87"],
  },
  80: {
    description:
      "Improper Neutralization of Script-Related HTML Tags in a Web Page (Basic XSS)",
    parents: ["79"],
    children: [],
  },
  81: {
    description:
      "Improper Neutralization of Script in an Error Message Web Page",
    parents: ["79"],
    children: [],
  },
  83: {
    description:
      "Improper Neutralization of Script in Attributes in a Web Page",
    parents: ["79"],
    children: ["82"],
  },
  82: {
    description:
      "Improper Neutralization of Script in Attributes of IMG Tags in a Web Page",
    parents: ["83"],
    children: [],
  },
  84: {
    description: "Improper Neutralization of Encoded URI Schemes in a Web Page",
    parents: ["79"],
    children: [],
  },
  85: {
    description: "Doubled Character XSS Manipulations",
    parents: ["79"],
    children: [],
  },
  87: {
    description: "Improper Neutralization of Alternate XSS Syntax",
    parents: ["79"],
    children: [],
  },
  91: {
    description: "XML Injection (aka Blind XPath Injection)",
    parents: ["74"],
    children: ["643", "652"],
  },
  643: {
    description:
      "Improper Neutralization of Data within XPath Expressions ('XPath Injection')",
    parents: ["91", "943"],
    children: [],
  },
  652: {
    description:
      "Improper Neutralization of Data within XQuery Expressions ('XQuery Injection')",
    parents: ["91", "943"],
    children: [],
  },
  93: {
    description: "Improper Neutralization of CRLF Sequences ('CRLF Injection')",
    parents: ["74"],
    children: ["113"],
  },
  943: {
    description:
      "Improper Neutralization of Special Elements in Data Query Logic",
    parents: ["74"],
    children: ["643", "652", "89", "90"],
  },
  89: {
    description:
      "Improper Neutralization of Special Elements used in an SQL Command ('SQL Injection')",
    parents: ["943"],
    children: ["564"],
  },
  564: {
    description: "SQL Injection: Hibernate",
    parents: ["89"],
    children: [],
  },
  90: {
    description:
      "Improper Neutralization of Special Elements used in an LDAP Query ('LDAP Injection')",
    parents: ["943"],
    children: [],
  },
  99: {
    description:
      "Improper Control of Resource Identifiers ('Resource Injection')",
    parents: ["74"],
    children: ["914", "641", "694"],
  },
  641: {
    description: "Improper Restriction of Names for Files and Other Resources",
    parents: ["99"],
    children: [],
  },
  694: {
    description: "Use of Multiple Resources with Duplicate Identifier",
    parents: ["99", "573"],
    children: ["102", "462"],
  },
  462: {
    description: "Duplicate Key in Associative List (Alist)",
    parents: ["694"],
    children: [],
  },
  710: {
    description: "Improper Adherence to Coding Standards",
    parents: ["All CWEs"],
    children: [
      "484",
      "476",
      "1059",
      "1061",
      "1065",
      "1076",
      "1093",
      "1120",
      "1164",
      "1177",
      "1209",
      "1357",
      "477",
      "489",
      "570",
      "571",
      "573",
      "594",
      "657",
      "684",
      "758",
    ],
  },
  1059: {
    description: "Insufficient Technical Documentation",
    parents: ["710"],
    children: ["1053"],
  },
  1053: {
    description: "Missing Documentation for Design",
    parents: ["1059"],
    children: [],
  },
  1061: {
    description: "Insufficient Encapsulation",
    parents: ["710"],
    children: ["766", "1057", "1062", "1083", "1105"],
  },
  1057: {
    description:
      "Data Access Operations Outside of Expected Data Manager Component",
    parents: ["1061"],
    children: [],
  },
  1062: {
    description: "Parent Class with References to Child Class",
    parents: ["1061"],
    children: [],
  },
  1083: {
    description: "Data Access from Outside Expected Data Manager Component",
    parents: ["1061"],
    children: [],
  },
  1105: {
    description:
      "Insufficient Encapsulation of Machine-Dependent Functionality",
    parents: ["1061", "758"],
    children: ["188"],
  },
  1065: {
    description:
      "Runtime Resource Management Control Element in a Component Built to Run on Application Servers",
    parents: ["710"],
    children: [],
  },
  1076: {
    description: "Insufficient Adherence to Expected Conventions",
    parents: ["710"],
    children: ["1045", "1078", "1079", "1082", "1087", "1098", "586"],
  },
  1045: {
    description:
      "Parent Class with a Virtual Destructor and a Child Class without a Virtual Destructor",
    parents: ["1076"],
    children: [],
  },
  1078: {
    description: "Inappropriate Source Code Style or Formatting",
    parents: ["1076"],
    children: ["546", "547"],
  },
  1109: {
    description: "Use of Same Variable for Multiple Purposes",
    parents: ["1078"],
    children: [],
  },
  546: {
    description: "Suspicious Comment",
    parents: ["1078"],
    children: [],
  },
  547: {
    description: "Use of Hard-coded, Security-relevant Constants",
    parents: ["1078"],
    children: [],
  },
  1079: {
    description: "Parent Class without Virtual Destructor Method",
    parents: ["1076"],
    children: [],
  },
  1082: {
    description: "Class Instance Self Destruction Control Element",
    parents: ["1076"],
    children: [],
  },
  1087: {
    description: "Class with Virtual Method without a Virtual Destructor",
    parents: ["1076"],
    children: [],
  },
  1098: {
    description:
      "Data Element containing Pointer Item without Proper Copy Control Element",
    parents: ["1076"],
    children: [],
  },
  586: {
    description: "Explicit Call to Finalize()",
    parents: ["1076"],
    children: [],
  },
  1093: {
    description: "Excessively Complex Data Representation",
    parents: ["710"],
    children: [],
  },
  1120: {
    description: "Excessive Code Complexity",
    parents: ["710"],
    children: ["1047", "1060", "1119"],
  },
  1119: {
    description: "Excessive Use of Unconditional Branching",
    parents: ["1120"],
    children: [],
  },
  1047: {
    description: "Modules with Circular Dependencies",
    parents: ["1120"],
    children: [],
  },
  1060: {
    description: "Excessive Number of Inefficient Server-Side Data Accesses",
    parents: ["1120"],
    children: [],
  },
  1164: {
    description: "Irrelevant Code",
    parents: ["710"],
    children: ["107", "1071", "110", "561", "563"],
  },
  107: {
    description: "Struts: Unused Validation Form",
    parents: ["1164"],
    children: [],
  },
  1071: {
    description: "Empty Code Block",
    parents: ["1164"],
    children: ["1069", "585"],
  },
  1069: {
    description: "Empty Exception Block",
    parents: ["1071"],
    children: [],
  },
  585: {
    description: "Empty Synchronized Block",
    parents: ["1071"],
    children: [],
  },
  110: {
    description: "Struts: Validator Without Form Field",
    parents: ["1164"],
    children: [],
  },
  561: {
    description: "Dead Code",
    parents: ["1164"],
    children: [],
  },
  563: {
    description: "Assignment to Variable without Use",
    parents: ["1164"],
    children: [],
  },
  1177: {
    description: "Use of Prohibited Code",
    parents: ["710"],
    children: ["242", "676"],
  },
  242: {
    description: "Use of Inherently Dangerous Function",
    parents: ["1177"],
    children: [],
  },
  676: {
    description: "Use of Potentially Dangerous Function",
    parents: ["1177"],
    children: ["785"],
  },
  1209: {
    description: "Failure to Disable Reserved Bits",
    parents: ["710"],
    children: [],
  },
  1357: {
    description: "Reliance on Insufficiently Trustworthy Component",
    parents: ["710"],
    children: ["1329"],
  },
  477: {
    description: "Use of Obsolete Function",
    parents: ["710"],
    children: [],
  },
  489: {
    description: "Active Debug Code",
    parents: ["710"],
    children: ["11"],
  },
  11: {
    description: "ASP.NET Misconfiguration: Creating Debug Binary",
    parents: ["489"],
    children: [],
  },
  570: {
    description: "Expression is Always False",
    parents: ["710"],
    children: [],
  },
  571: {
    description: "Expression is Always True",
    parents: ["710"],
    children: [],
  },
  573: {
    description: "Improper Following of Specification by Caller",
    parents: ["710"],
    children: [
      "304",
      "296",
      "568",
      "580",
      "243",
      "329",
      "358",
      "581",
      "253",
      "694",
      "103",
      "104",
      "325",
      "475",
      "577",
      "578",
      "579",
      "628",
      "675",
      "695",
    ],
  },
  103: {
    description: "Struts: Incomplete validate() Method Definition",
    parents: ["573"],
    children: [],
  },
  104: {
    description: "Struts: Form Bean Does Not Extend Validation Class",
    parents: ["573"],
    children: [],
  },
  325: {
    description: "Missing Cryptographic Step",
    parents: ["573"],
    children: [],
  },
  475: {
    description: "Undefined Behavior for Input to API",
    parents: ["573"],
    children: [],
  },
  577: {
    description: "EJB Bad Practices: Use of Sockets",
    parents: ["573"],
    children: [],
  },
  578: {
    description: "EJB Bad Practices: Use of Class Loader",
    parents: ["573"],
    children: [],
  },
  579: {
    description:
      "J2EE Bad Practices: Non-serializable Object Stored in Session",
    parents: ["573"],
    children: [],
  },
  628: {
    description: "Function Call with Incorrectly Specified Arguments",
    parents: ["573"],
    children: ["683", "685", "686", "687", "688"],
  },
  683: {
    description: "Function Call With Incorrect Order of Arguments",
    parents: ["628"],
    children: [],
  },
  685: {
    description: "Function Call With Incorrect Number of Arguments",
    parents: ["628"],
    children: [],
  },
  686: {
    description: "Function Call With Incorrect Argument Type",
    parents: ["628"],
    children: [],
  },
  687: {
    description: "Function Call With Incorrectly Specified Argument Value",
    parents: ["628"],
    children: ["560"],
  },
  560: {
    description: "Use of umask() with chmod-style Argument",
    parents: ["687"],
    children: [],
  },
  688: {
    description:
      "Function Call With Incorrect Variable or Reference as Argument",
    parents: ["628"],
    children: [],
  },
  675: {
    description: "Multiple Operations on Resource in Single-Operation Context",
    parents: ["573"],
    children: ["764", "765", "605", "174", "1341"],
  },
  1341: {
    description: "Multiple Releases of Same Resource or Handle",
    parents: ["675"],
    children: ["415"],
  },
  695: {
    description: "Use of Low-Level Functionality",
    parents: ["573"],
    children: ["574", "111", "245", "246", "383", "575", "576"],
  },
  111: {
    description: "Direct Use of Unsafe JNI",
    parents: ["695"],
    children: [],
  },
  245: {
    description: "J2EE Bad Practices: Direct Management of Connections",
    parents: ["695"],
    children: [],
  },
  246: {
    description: "J2EE Bad Practices: Direct Use of Sockets",
    parents: ["695"],
    children: [],
  },
  383: {
    description: "J2EE Bad Practices: Direct Use of Threads",
    parents: ["695"],
    children: [],
  },
  575: {
    description: "EJB Bad Practices: Use of AWT Swing",
    parents: ["695"],
    children: [],
  },
  576: {
    description: "EJB Bad Practices: Use of Java I/O",
    parents: ["695"],
    children: [],
  },
  594: {
    description: "J2EE Framework: Saving Unserializable Objects to Disk",
    parents: ["710"],
    children: [],
  },
  657: {
    description: "Violation of Secure Design Principles",
    parents: ["710"],
    children: [
      "250",
      "638",
      "653",
      "654",
      "655",
      "656",
      "636",
      "1192",
      "1395",
      "637",
      "671",
    ],
  },
  1192: {
    description:
      "System-on-Chip (SoC) Using Components without Unique, Immutable Identifiers",
    parents: ["657"],
    children: [],
  },
  1395: {
    description: "Dependency on Vulnerable Third-Party Component",
    parents: ["657"],
    children: [],
  },
  637: {
    description:
      "Unnecessary Complexity in Protection Mechanism (Not Using 'Economy of Mechanism')",
    parents: ["657"],
    children: [],
  },
  671: {
    description: "Lack of Administrator Control over Security",
    parents: ["657"],
    children: ["798", "447"],
  },
  447: {
    description: "Unimplemented or Unsupported Feature in UI",
    parents: ["671", "446"],
    children: [],
  },
  684: {
    description: "Incorrect Provision of Specified Functionality",
    parents: ["710"],
    children: ["451", "393", "392", "1245", "440", "446", "912"],
  },
  1245: {
    description: "Improper Finite State Machines (FSMs) in Hardware Logic",
    parents: ["684"],
    children: [],
  },
  440: {
    description: "Expected Behavior Violation",
    parents: ["684"],
    children: [],
  },
  446: {
    description: "UI Discrepancy for Security Feature",
    parents: ["684"],
    children: ["447", "448", "449"],
  },
  448: {
    description: "Obsolete Feature in UI",
    parents: ["446"],
    children: [],
  },
  449: {
    description: "The UI Performs the Wrong Action",
    parents: ["446"],
    children: [],
  },
  912: {
    description: "Hidden Functionality",
    parents: ["684"],
    children: ["506"],
  },
  506: {
    description: "Embedded Malicious Code",
    parents: ["912"],
    children: ["507", "510", "511", "512"],
  },
  507: {
    description: "Trojan Horse",
    parents: ["506"],
    children: ["508", "509"],
  },
  508: {
    description: "Non-Replicating Malicious Code",
    parents: ["507"],
    children: [],
  },
  509: {
    description: "Replicating Malicious Code (Virus or Worm)",
    parents: ["507"],
    children: [],
  },
  510: {
    description: "Trapdoor",
    parents: ["506"],
    children: [],
  },
  511: {
    description: "Logic/Time Bomb",
    parents: ["506"],
    children: [],
  },
  512: {
    description: "Spyware",
    parents: ["506"],
    children: [],
  },
  758: {
    description:
      "Reliance on Undefined, Unspecified, or Implementation-Defined Behavior",
    parents: ["710"],
    children: ["1038", "588", "587", "1105", "474", "562"],
  },
  474: {
    description: "Use of Function with Inconsistent Implementations",
    parents: ["758"],
    children: ["589"],
  },
  589: {
    description: "Call to Non-ubiquitous API",
    parents: ["474"],
    children: [],
  },
  562: {
    description: "Return of Stack Variable Address",
    parents: ["758"],
    children: [],
  },
};
