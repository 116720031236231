/*
    Represents a single assessment on a single binary in an asset. 
   
    Displays like so:
	
        **DECOMPILE**
      Facility -> Asset -> Binary
  
    This component may be drag-and-dropped to re-prioritize the 
    assessment among the other assessments. The priority of this 
    assessment is displayed numerically on the left side of the component.

    This component has 4 enumerated statues: 
    1. Pending:	T   he assessment hasn't started yet.
    2. Running:	    The assessment is in-progress. The percentage completed, 
                    and estimated time remaining are displayed as a horizontal 
                    loading bar.
    3. Completed:	The assessment has completed and produced results. 
                    The component is grayed out in this this case and has 
                    a green check mark.
    4: Stopped: 	The assessment has been stopped by the user. The component 
                    is grayed out in this case.
    For (3) and (4), the component will be removed from view entirely once 
    the correlated binary has finished assessing.

    Upon hovering over this component, the right side of it will display a 
    trash/delete icon. Clicking this icon will display an alert asking the user 
    if they wish to stop the assessment/prevent it from running.

    This component also tells the user if there are pre-conditions required 
    before this assessment completes. If another assessments is required to 
    complete before the start of this one, then the user should be
    prevented from assigning the assessments an un-allowed order. 
*/
import { useEffect, useState } from "react";

// icons
import { X } from "react-feather";
import { stopAssessment } from "../../../api";

const RENAMES = {
  bap_recipe_restrictness_check: "Restricted Function Arguments",
  bap_recipe_av_rule_17: "Errno Indicator Check",
  bap_recipe_av_rule_174: "NULL Pointer Dereference Check",
  bap_recipe_av_rule_189: "GOTO Statement Check",
  bap_recipe_av_rule_19: "Setlocale and Localeconv Functions Check",
  bap_recipe_av_rule_20: "Setjmp and Longjmp Functions Check",
  bap_recipe_av_rule_21: "Signal.h Library Check",
  bap_recipe_av_rule_22: "Stdio.h Library Check",
  bap_recipe_av_rule_23: "Atof, Atoi, Atol Functions Check",
  bap_recipe_av_rule_24: "Abort, Exit, Getenv, System Functions Check",
  bap_recipe_av_rule_25: "Time.h Library Check",
  bap_recipe_av_rule_3: "Cyclomatic Complexity Number Check",
  bap_recipe_jpl_rule_11: "Reducible Control Flow Graph Check",
  bap_recipe_jpl_rule_14: "Unused Values Check",
  bap_recipe_jpl_rule_4: "Recursive Functions Check",
  bap_recipe_must_check_value: "Unchecked Return Value Check",
  bap_recipe_primus_checks:
    "Buffer Overflow, Information Flow, and Hardcoded Values Check",
  bap_recipe_untrusted_argument: "Untrusted Argument Check",
  bap_recipe_use_after_free: "Access Free Memory Check",
  bap_recipe_warn_unused: "Unused Function Results Check",
  bap_recipe_double_free: "Double Free Check",
  bap_recipe_heap_overflow: "Heap Overflow Check",
  bap_recipe_forbidden_symbol: "Forbidden Symbol Check",
  bap_recipe_spectre: "Spectre Check",
  bap_recipe_defective_symbol: "Defective Symbol Check",
  lineage: "Lineage Analysis",
  search_crypto: "Crypto Keys",
  cvebintool: "CVEs",
  weak_pointers: "Weak Pointers",
  disassemble: "Disassembler",
  decompile: "Decompiler",
  encryption_runtime: "Runtime Encryption Detection",
  encryption_binary: "Static Encryption Detection",
  clamav: "Malware Detection",
  manalyze: "PE Static Analysis",
  vulscore: "Vulnerability Score",
  dangerous_fns: "Dangerous Functions",
  entropy_etc: "Entropy Test",
  dump_bin_headers: "Dump Binary Headers",
  bap_cache_toolkit: "ThreatAI Pre-Assess",
  cobra: "CWEs: Signatures 1",
  ghidra: "CWEs: Signatures 2",
  flawfinder: "CWEs: Flaw Discover",
  cppcheck: "CWEs: C++ Static Analysis",
  memcheck: "CWEs: Memory Check",
  scanbuild: "CWEs: Build Scan",
  cwe_checker: "CWEs: Basic Check",
  ropgadget: "CWEs: Signatures 3",
  symbex: "Symbolic Execution",
};

// some assessments depend on others: if an assessment is stopped then its dependents
// will not run
// this fact should be communicated to the user
const ASSESSMENT_DEPENDENT_MESSAGES = {
  bap_cache_toolkit: [
    "bap_recipe_restrictness_check",
    "bap_recipe_av_rule_17",
    "bap_recipe_av_rule_174",
    "bap_recipe_av_rule_189",
    "bap_recipe_av_rule_19",
    "bap_recipe_av_rule_20",
    "bap_recipe_av_rule_21",
    "bap_recipe_av_rule_23",
    "bap_recipe_av_rule_24",
    "bap_recipe_av_rule_25",
    "bap_recipe_av_rule_3",
    "bap_recipe_jpl_rule_11",
    "bap_recipe_jpl_rule_14",
    "bap_recipe_jpl_rule_4",
    "bap_recipe_must_check_value",
    "bap_recipe_primus_checks",
    "bap_recipe_untrusted_argument",
    "bap_recipe_use_after_free",
    "bap_recipe_warn_unused",
    "bap_recipe_double_free",
    "bap_recipe_heap_overflow",
    "bap_recipe_forbidden_symbol",
    "bap_recipe_spectre",
    "bap_recipe_defective_symbol",
  ],
  decompile: [
    "cobra",
    "cppcheck",
    "flawfinder",
    "ghidra",
    "memcheck",
    "scanbuild",
  ],
  disassemble: ["decompile", "encryption_binary", "weak_pointers"],
  cvebintool: ["vulscore"],
};

/**
 * Generate the text that will display when an assessment is to be stopped.
 * This text references the set of dependent assessments that the user is also
 * indirectly stopping.
 */
const generateStopConfirmText = (tool_id) => {
  let ret_text = "Are you sure you want to stop/prevent this assessment?";

  const getDependentAssessment = (tool_id) => {
    if (!Object.keys(ASSESSMENT_DEPENDENT_MESSAGES).includes(tool_id))
      return [];
    let dependents = [];
    for (let a of ASSESSMENT_DEPENDENT_MESSAGES[tool_id]) {
      dependents.push(a);
      dependents = dependents.concat(getDependentAssessment(a));
    }
    return dependents;
  };

  let dependents = getDependentAssessment(tool_id);
  if (dependents.length == 0) return ret_text;

  ret_text +=
    "\n\nThis assessment is required for the following other assessments: ";
  ret_text += dependents.map((d) => RENAMES[d]).join(", ");
  ret_text += ".";

  return ret_text;
};

export default function AnalysisQueue_Assessment({
  assessmentProp,
  priority,
  refresh,
}) {
  let [assessment, setAssessment] = useState(assessmentProp);

  useEffect(() => {
    setAssessment(assessmentProp);
  }, [assessmentProp]);

  let name = assessment.id.tool_id;
  if (RENAMES.hasOwnProperty(name)) name = RENAMES[name];

  const renderProgressState = () => {
    switch (assessment.state) {
      case "RUNNING":
        // in seconds
        let date_diff = new Date() - new Date(assessment.start_time * 1000);
        date_diff /= 1000;

        // in minutes
        let time_remaining = Math.round((assessment.avg_time - date_diff) / 60);
        if (time_remaining < 0) time_remaining = 0;

        // ratio
        let progress = date_diff / assessment.avg_time;
        if (progress > 1) progress = 1;

        return (
          <div>
            <progress value={"" + progress} max={"1"}></progress>
            <h3>{time_remaining} minutes remaining</h3>
          </div>
        );
      case "STOPPED":
        return <h3>Stopped</h3>;
      case "STOPPING":
        return <h3>Stopping</h3>;
      case "COMPLETED":
        return <h3>Completed</h3>;
      case "PENDING":
      default:
        return <h3>Pending</h3>;
    }
  };

  let classes = ["analysis-queue-assessment"];
  switch (assessment.state) {
    case "STOPPED":
    case "STOPPING":
      classes.push("analysis-queue-assessment-stopped");
      break;
    case "COMPLETED":
      classes.push("analysis-queue-assessment-completed");
      break;

    default:
      break;
  }

  return (
    <div className={classes.join(" ")}>
      <h2>{priority + 1}.</h2>
      <div>
        <h2>{name}</h2>
        <div className="span">
          <h3>{assessment.asset_name}</h3>
          <h3> &rarr; {assessment.id.bin_id}</h3>
        </div>
      </div>
      <div className="analysis-queue-assessment-progress-state">
        {renderProgressState()}
      </div>

      {(assessment.state === "PENDING" || assessment.state === "RUNNING") && (
        <X
          className="delete-assessment-button"
          onClick={async () => {
            if (
              window.confirm(generateStopConfirmText(assessment.id.tool_id))
            ) {
              // set state assessment
              setAssessment({ ...assessment, state: "STOPPED" });

              await stopAssessment(
                assessment.id.org_id,
                assessment.id.asset_id,
                assessment.id.bin_id,
                assessment.id.tool_id
              );
            }
          }}
        />
      )}
    </div>
  );
}
