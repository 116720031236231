import { useState, useEffect } from "react";
import "./index.css";
import CWENavigator from "./cwe_navigator";
import CWETitle from "./cwe_title";
import CWEChildren from "./cwe_children";
import CWEInstances from "./cwe_instances";
import BinarySummaryInfoHelper from "../binary_summary_info_helper";
import { determineCWEPath } from "./helpers";

export const TOP_LEVEL_CWE_ID = "All CWEs";
const DEFAULT_PATH = [];

export default function UnifiedCWEs({
  cwes,
  asset_id,
  binary,
  preSearch,
  swapTab,
}) {
  // path to the currently selected CWE
  let [cwePath, setCWEPath] = useState(DEFAULT_PATH);

  /**
   * Always default to CWE-1000 when swapping between
   * binaries/tabs from a parent component.
   */
  const reset = () => {
    try {
      let path = determineCWEPath(preSearch.split("-")[1]);
      setCWEPath(path);
    } catch {
      setCWEPath(DEFAULT_PATH);
    }
  };
  useEffect(reset, [cwes]);

  /**
   * Gets a CWE object that is nested withing
   * the unified `cwes` prop.
   */
  const getNestedCWE = () => {
    let nested_cwe = cwes;
    for (let cwe_num of cwePath) {
      let child_found = false;
      for (let child of nested_cwe.children) {
        if (child.id === cwe_num) {
          nested_cwe = child;
          child_found = true;
          break;
        }
      }
      if (!child_found) return null;
    }
    return nested_cwe;
  };

  let nested_cwe = getNestedCWE();
  return (
    <div className="unified-cwes">
      <CWENavigator cwePath={cwePath} setCWEPath={setCWEPath} />
      {!nested_cwe && <CWENotFound cwe={cwePath[cwePath.length - 1]} />}
      <CWETitle cwe={nested_cwe} />
      {nested_cwe && nested_cwe.id === TOP_LEVEL_CWE_ID && (
        <BinarySummaryInfoHelper
          text="The Common Weakness Enumeration (CWE) is a 
          category system for hardware and software weaknesses 
          and vulnerabilities. It is sustained by a community 
          project with the goals of understanding flaws in software 
          and hardware and creating automated tools that can be used 
          to identify, fix, and prevent those flaws.
          BinLens currently detects approximately 120 unique CWEs across
          x86, ARM, MIPS, PowerPC, SPARC, MIPS, AARCH,
          and RISC-V architectures."
          link="https://cwe.mitre.org/about/index.html"
        />
      )}
      <CWEChildren cwe={nested_cwe} setCWEPath={setCWEPath} />
      <CWEInstances
        cwe={nested_cwe}
        asset_id={asset_id}
        binary={binary}
        swapTab={swapTab}
        major_findings={true}
      />
      <CWEInstances
        cwe={nested_cwe}
        asset_id={asset_id}
        binary={binary}
        swapTab={swapTab}
        major_findings={false}
      />
    </div>
  );
}

function CWENotFound({ cwe }) {
  return (
    <BinarySummaryInfoHelper
      text={`CWE-${cwe} was not detected in the target binary.`}
    />
  );
}
