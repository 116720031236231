import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import { Binary, CWE } from "../../constants";

// components
import VulnerabilityGridCWE from "./vulnerability_grid_cwe";

/**
 * Displays a grid of CWEs when viewing a binary report.
 */
export default class CWEs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: 0,
    };
  }

  render() {
    let totPage = this.props.cwes.length / 3;
    let paginations = [];
    for (let i = 0; i < totPage; i++) paginations.push(i + 1);

    return (
      <div
        style={{
          marginTop: 25,
        }}
      >
        {this.props.cwes.map((cwe) => {
          if (
            !cwe.cwe.toLowerCase().includes(this.props.cweSearch.toLowerCase())
          )
            return null;
          return (
            <VulnerabilityGridCWE
              key={cwe.cwe}
              long={this.props.long}
              cwe={cwe}
              binary={this.props.binary.name}
              asset_id={this.props.binary.id}
            />
          );
        })}
      </div>
    );
  }
}

CWEs.propTypes = {
  /**
   * List of CWEs to display.
   */
  cwes: PropTypes.arrayOf(PropTypes.instanceOf(CWE)).isRequired,

  /**
   * Search text to filter CWEs.
   */
  cweSearch: PropTypes.string.isRequired,

  /**
   * Is the CWE being displayed in long form or not?
   */
  long: PropTypes.bool,

  /**
   * The parent binary of the CWEs.
   */
  binary: PropTypes.instanceOf(Binary).isRequired,
};
