import "./index.css";
import BigDrop from "../sidebar/big_drop";
import Notifications from "../login_page/notifications";
import Headbar from "../headbar/headbar";
import Botbar from "../botbar/botbar";
import RightHandSidebar from "../sidebar/right_hand_sidebar";
import Sidebar from "../sidebar/sidebar";
import { ChevronLeft, Triangle } from "react-feather";

export function DeltaReportsPage({ children }) {
  return (
    <BigDrop>
      <main>
        <Notifications />
        <Headbar />
        <Botbar />
        <div className="main-row">
          <Sidebar page="delta" />
          <div className="delta-reports-page">{children}</div>
          <RightHandSidebar />
        </div>
      </main>
    </BigDrop>
  );
}

export function DeltaReportsPageHeader({ title, canGoBack }) {
  const renderIcon = () => {
    if (canGoBack)
      return (
        <ChevronLeft
          onClick={() => window.location.assign("#/delta")}
          className="icon"
        />
      );
    return <Triangle className="icon" />;
  };

  return (
    <div className="delta-report-form-header">
      {renderIcon()}
      <h2>{title}</h2>
    </div>
  );
}
