/*
    This file defines a component that enables the user to 

    This component appears on the right-side of the file drop modal.
*/
import { useState, useEffect } from "react";
import { getS3Files } from "../../../api";

// components
import FileCheck from "./file_check";

export default function ChooseFiles({
  back,
  ingress,
  accessKeyId,
  secretKeyId,
  bucket,
}) {
  let [files, setFiles] = useState([]);
  let [selectedFiles, setSelectedFiles] = useState([]);

  /**
   * Grab S3 files from the API and display them in this component.
   */
  const loadFilesFromS3 = async () => {
    try {
      let files = await getS3Files(accessKeyId, secretKeyId, bucket);
      setFiles(files);
    } catch (error) {
      alert("Failed to find the desired S3 bucket.");
      back();
    }
  };
  useEffect(() => {
    loadFilesFromS3();
  }, []);

  return (
    <div className="s3-choose-files">
      <div className="file-checks">
        {files.map((file) => (
          <FileCheck
            file={file}
            checked={selectedFiles.includes(file)}
            select={() => {
              if (selectedFiles.includes(file)) {
                setSelectedFiles((selectedFiles) => [
                  ...selectedFiles.filter((f) => f !== file),
                ]);
                return;
              }
              setSelectedFiles((selectedFiles) => [...selectedFiles, file]);
            }}
          />
        ))}
      </div>

      <div className="row">
        <button onClick={back}>Back</button>
        <button onClick={() => ingress(selectedFiles)}>Ingress</button>
      </div>
    </div>
  );
}
