import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import { User } from "../../constants";
import "./organization.css";

// icons
import { Edit2, Plus } from "react-feather";

// components
import ManagedUser from "./managed_user";
import UpdateOrganizationModal from "./update_organization_modal";

/**
 * An organization of users.
 * Each user has an organization specific role.
 * A set of <Organization /> components appears in the
 * <UserManagementTable /> component when the current user is
 * the SUPERADMIN or ADMIN.
 */
export default class Organization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateOrganizationModal: false,
    };
  }

  render() {
    return (
      <div className="organization-of-users">
        <div className="organization-of-users-head">
          <div
            className="organization-of-users-new"
            onClick={() =>
              this.setState({
                updateOrganizationModal: true,
              })
            }
            style={{
              marginLeft: 0,
            }}
          >
            <h2>
              <b>{this.props.name}</b>
            </h2>
            <Edit2
              className="icon"
              style={{
                marginLeft: 10,
                height: 15,
                width: 15,
              }}
            />
          </div>
          <div
            className="organization-of-users-new"
            onClick={() => this.props.createUserModal(this.props.id)}
          >
            <h3 style={{ fontWeight: 600 }}>Add User</h3>
            <Plus className="icon" />
          </div>
        </div>
        <UpdateOrganizationModal
          modal={this.state.updateOrganizationModal}
          backFunction={() => this.setState({ updateOrganizationModal: false })}
          org_id={this.props.id}
        />

        {this.props.users.map((u) => {
          if (
            !u.username.toLowerCase().includes(this.props.search.toLowerCase())
          )
            return null;
          return (
            <ManagedUser
              key={u.id}
              org_name={this.props.name}
              isMe={u.id === window.localStorage.getItem("userid")}
              isSuperadmin={this.props.isSuperadmin}
              username={u.username}
              role={u.role}
              id={u.id}
              org_id={this.props.id}
              email={u.email}
              editModal={this.props.editModal}
            />
          );
        })}
      </div>
    );
  }
}

Organization.propTypes = {
  /**
   * If the current user is SUPERADMIN.
   */
  isSuperadmin: PropTypes.bool.isRequired,

  /**
   * Search text that filters out users by name.
   */
  search: PropTypes.string.isRequired,

  /**
   * The ID of this organization.
   */
  id: PropTypes.string.isRequired,

  /**
   * The name of this organization.
   */
  name: PropTypes.string.isRequired,

  /**
   * The set of users in the organizaiton.
   */
  users: PropTypes.arrayOf(PropTypes.instanceOf(User)).isRequired,

  /**
   * Callback that toggles the user edit modal.
   */
  editModal: PropTypes.func.isRequired,

  /**
   * Callback that toggles the create edit modal.
   */
  createUserModal: PropTypes.func.isRequired,
};
