import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import "./create_facility_modal.css";
import { Modal } from "react-bootstrap";
import { createFacility } from "../../api";

// components
import MapChart from "./map_chart";
import Dashboard from "../dashboard/dashboard";

// icons
import { Globe, Home, X } from "react-feather";

/**
 * Modal that appears when a facility is being created.
 * To create an facility, the following information is required:
 * 1. Name.
 * 2. Description.
 * 3. Location (chosen by clicking on a map canvas).
 * Facility ID is randomly generated by the Back-End.
 */
export default class CreateFacilityModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: "",
        description: "",
        latLng: null,
      },
    };
    this.createFacility = this.createFacility.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.modal && prevProps.modal !== this.props.modal) {
      this.setState({
        form: {
          name: "",
          description: "",
          latLng: null,
        },
      });
    }
  }

  async createFacility() {
    if (this.state.form.name === "" || this.state.form.latLng === null) return;
    await createFacility(
      this.state.form.name,
      this.state.form.description,
      this.state.form.latLng
    );
    this.props.backFunction();
    if (Dashboard._this) Dashboard._this.init();
  }

  render() {
    return (
      <Modal
        show={this.props.modal}
        animation={false}
        className="modal-bg"
        enforceFocus={false}
      >
        <Modal.Body
          className="modal2 file-drop-modal"
          style={
            this.state.form.name !== ""
              ? {}
              : {
                  marginLeft: 152,
                }
          }
        >
          <div className="new-analysis-base-info facility-modal-base-info">
            <div className="new-analysis-base-row">
              <Home className="new-analysis-icon" />
              <h2>New Facility</h2>
            </div>

            <h3 className="new-analysis-question">
              What is the name of the facility?
            </h3>
            <input
              className="new-analysis-input"
              value={this.state.form.name}
              onChange={(e) =>
                this.setState({
                  form: {
                    name: e.target.value,
                    description: this.state.form.description,
                    latLng: this.state.form.latLng,
                  },
                })
              }
              type="text"
              placeholder="Facility Name..."
            />

            {this.state.form.name !== "" && (
              <div>
                <hr className="new-analysis-line"></hr>
                <h3 className="new-analysis-question">
                  Latitude and Longitude
                </h3>
                <div className="row">
                  <input
                    className="new-analysis-input"
                    value={
                      this.state.form.latLng ? this.state.form.latLng[1] : ""
                    }
                    onChange={(e) => {
                      let latLng = this.state.form.latLng;
                      if (!latLng) latLng = ["", ""];
                      latLng[1] = e.target.value;
                      this.setState({
                        form: {
                          name: this.state.form.name,
                          description: this.state.form.description,
                          latLng: [...latLng],
                        },
                      });
                    }}
                    type="text"
                    placeholder="Latitude..."
                  ></input>
                  <input
                    className="new-analysis-input"
                    value={
                      this.state.form.latLng ? this.state.form.latLng[0] : ""
                    }
                    onChange={(e) => {
                      let latLng = this.state.form.latLng;
                      if (!latLng) latLng = ["", ""];
                      latLng[0] = e.target.value;
                      this.setState({
                        form: {
                          name: this.state.form.name,
                          description: this.state.form.description,
                          latLng: [...latLng],
                        },
                      });
                    }}
                    type="text"
                    placeholder="Longitude..."
                  ></input>
                </div>
              </div>
            )}

            {this.state.form.name !== "" && (
              <div>
                <hr className="new-analysis-line"></hr>
                <h3 className="new-analysis-question">
                  Describe this facility...
                </h3>
                <textarea
                  rows={4}
                  className="new-analysis-input"
                  value={this.state.form.description}
                  onChange={(e) =>
                    this.setState({
                      form: {
                        name: this.state.form.name,
                        description: e.target.value,
                        latLng: this.state.form.latLng,
                      },
                    })
                  }
                  type="text"
                  placeholder="Facility Description..."
                ></textarea>
              </div>
            )}

            <button
              className={
                this.state.form.name !== "" && this.state.form.latLng !== null
                  ? "new-analysis-next-button"
                  : "new-analysis-next-button-off"
              }
              onClick={this.createFacility}
            >
              {this.state.form.name !== "" && this.state.form.latLng !== null
                ? "Create Facility"
                : "Please fill out form and location."}
            </button>
          </div>
          <div
            className="new-analysis-file-holder facility-modal-map-holder"
            style={
              this.state.form.name !== ""
                ? {
                    transform: "none",
                  }
                : {}
            }
          >
            <div
              className="new-analysis-base-row"
              style={{ padding: 20, width: "calc(100% - 40px)", zIndex: 10 }}
            >
              <Globe className="new-analysis-icon" />
              <h2>Facility Location</h2>
              <X
                className="new-analysis-close"
                onClick={this.props.backFunction}
              />
            </div>

            {this.state.form.name !== "" && (
              <div
                style={{
                  position: "absolute",
                  top: 1,
                  left: 1,
                  width: "calc(100% - 2px)",
                  height: 470,
                  overflow: "hidden",
                  borderTopRightRadius: 15,
                  borderBottomRightRadius: 15,
                }}
              >
                <MapChart
                  latLng={this.state.form.latLng}
                  onMapClick={(latLng) => {
                    this.setState({
                      form: {
                        name: this.state.form.name,
                        description: this.state.form.description,
                        latLng: latLng,
                      },
                    });
                  }}
                />
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

CreateFacilityModal.propTypes = {
  /**
   * Is the modal appearing or not.
   */
  modal: PropTypes.bool.isRequired,

  /**
   * Callback that turns the modal off.
   */
  backFunction: PropTypes.func.isRequired,
};
