import { useState } from "react";
import PropTypes from "prop-types";
import "../../index.css";
import "react-tippy/dist/tippy.css";
import { Binary, CWE } from "../../constants";

// components
import DiscoveryLocation from "./mappings/discovery_location";

// icons
import { Tool, Frown, Info, X } from "react-feather";

export default function VulnerabilityGridCWE({ long, cwe, binary, asset_id }) {
  let [page, setPage] = useState(null);
  let [msg, setMsg] = useState(cwe.msg);
  return (
    <div className="mapping">
      <div style={{ width: "100%" }}>
        <h3>Discovered CWE</h3>
        <h2>
          {cwe.cwe} {cwe.info.name ? "• " + cwe.info.name : ""}
        </h2>

        <h3>Description</h3>
        <div className="mapping-field">{msg}</div>

        {long && cwe.info.description && (
          <>
            <h3>Long Description</h3>
            <div className="mapping-field">{cwe.info.description}</div>
          </>
        )}

        {cwe.arr.length > 0 && (
          <DiscoveryLocation
            asset_id={asset_id}
            binary={binary}
            locations={cwe.arr}
            setPageCallback={(page) => {
              setMsg(cwe.arr[page].msg);
            }}
          />
        )}

        {cwe.info &&
          (cwe.info.mitigations.length > 0 ||
            cwe.info.examples.length > 0 ||
            cwe.info.consequences.length > 0) && (
            <>
              <h3
                style={{
                  marginTop: 30,
                }}
              >
                Extra Information
              </h3>
              <div
                className="row"
                style={{
                  justifyContent: "flex-start",
                }}
              >
                {cwe.info.mitigations.length > 0 && (
                  <Tool
                    onClick={() => setPage("MITI")}
                    className={
                      page === "MITI"
                        ? "extra-info-icon extra-info-icon-selected"
                        : "extra-info-icon"
                    }
                  />
                )}
                {cwe.info.consequences.length > 0 && (
                  <Frown
                    onClick={() => setPage("CONSEQ")}
                    className={
                      page === "CONSEQ"
                        ? "extra-info-icon extra-info-icon-selected"
                        : "extra-info-icon"
                    }
                  />
                )}
                {cwe.info.examples.length > 0 && (
                  <Info
                    onClick={() => setPage("EX")}
                    className={
                      page === "EX"
                        ? "extra-info-icon extra-info-icon-selected"
                        : "extra-info-icon"
                    }
                  />
                )}
              </div>
            </>
          )}

        {page === "MITI" && (
          <div
            className="mapping-field"
            style={{
              maxHeight: 200,
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <div className="row cwe-bot-but" style={{ width: "100%" }}>
              <X
                onClick={() => setPage(null)}
                className="report-ic"
                style={{ marginLeft: "auto", marginRight: 10 }}
              />
            </div>
            MITIGATIONS
            <ol>
              {(cwe.info ? cwe.info.mitigations : []).map((m) => {
                return (
                  <li key={m}>
                    {m}
                    <br />
                    <br />
                  </li>
                );
              })}
            </ol>
          </div>
        )}

        {page === "CONSEQ" && (
          <div
            className="mapping-field"
            style={{
              maxHeight: 200,
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <div className="row cwe-bot-but" style={{ width: "100%" }}>
              <X
                onClick={() => setPage(null)}
                className="report-ic"
                style={{ marginLeft: "auto", marginRight: 10 }}
              />
            </div>
            CONSEQUENCES
            <ol>
              {(cwe.info ? cwe.info.consequences : []).map((m) => {
                return <li key={m}>{m}</li>;
              })}
            </ol>
          </div>
        )}

        {page === "EX" && (
          <div
            className="mapping-field"
            style={{
              maxHeight: 200,
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <div className="row cwe-bot-but" style={{ width: "100%" }}>
              <X
                onClick={() => setPage(null)}
                className="report-ic"
                style={{ marginLeft: "auto", marginRight: 10 }}
              />
            </div>
            EXAMPLES
            <ol>
              {(cwe.info ? cwe.info.examples : []).map((m) => {
                return <li key={m.desc}>{m.desc}</li>;
              })}
            </ol>
          </div>
        )}
      </div>
    </div>
  );
}

VulnerabilityGridCWE.propTypes = {
  /**
   * The CWE to display.
   */
  cwe: PropTypes.instanceOf(CWE).isRequired,

  /**
   * The parent binary of the CWE.
   */
  binary: PropTypes.instanceOf(Binary).isRequired,

  /**
   * Is the CWE being displayed in long form or not?
   */
  long: PropTypes.bool,
};
