import "./index.css";
import { DeltaReportsPage, DeltaReportsPageHeader } from "..";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getDeltaReportStatus, downloadDeltaReport } from "../../../api";
import { Hexagon } from "react-feather";

export default function ViewDeltaReportPage() {
  return (
    <DeltaReportsPage>
      <DeltaReportDisplay />
    </DeltaReportsPage>
  );
}

function DeltaReportDisplay() {
  const { delta_report_id } = useParams();
  let [displayState, setDisplayState] = useState("LOADING");
  let [file, setFile] = useState(null);

  const refresh = async () => {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    while (true) {
      let status = await getDeltaReportStatus(delta_report_id);
      if (status === "COMPLETE") {
        break;
      }
      if (status === "FAILURE") {
        setDisplayState("FAILURE");
        return;
      }
      await delay(1000);
    }
    let file = await downloadDeltaReport(delta_report_id);
    setFile(file);
    setDisplayState("COMPLETE");
  };
  useEffect(() => {
    refresh();
  }, []);

  const renderContent = () => {
    switch (displayState) {
      case "COMPLETE":
        return (
          <embed
            src={file}
            type="application/pdf"
            width={"100%"}
            height={"100%"}
          />
        );
      case "FAILURE":
        return (
          <div className="delta-report-display-failure">
            <Hexagon className="icon" />
            <h3>This report does not exist.</h3>
          </div>
        );
      case "LOADING":
      default:
        return (
          <div className="delta-report-display-loading">
            <div className="lds-dual-ring"></div>
            <h3>Binary Delta Report loading.</h3>
          </div>
        );
    }
  };

  return (
    <div className="delta-report-display">
      <DeltaReportsPageHeader
        title="Viewing Binary Delta Report"
        canGoBack={true}
      />
      {renderContent()}
    </div>
  );
}
