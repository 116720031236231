import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../report_builder_component.css";

// icons
import { X } from "react-feather";

// components
import DiscoveryLocation from "../../../binary_analysis_page/mappings/discovery_location";
import { Binary } from "../../../../constants";
import WeakPointer from "../../../binary_analysis_page/binary_summary_components/weak_pointers/weak_pointer";
import DangerousFunction from "../../../binary_analysis_page/binary_summary_components/dangerous_function";

/**
 * Customizable report PDF component that lets users alter what
 * content is display / exported in a PDF report.
 * This component represents a single item in the list of all
 * custom report items.
 * This can represent a CWE, CVE, etc...
 */
export default function ReportBuilderComponent({
  item,
  binary,
  asset_id,
  setItems,
  done,
}) {
  let [hovering, setHovering] = useState(false);

  // used for CWE message
  let [desc, setDesc] = useState(item.desc);

  // some component types are not done by default
  useEffect(() => {
    switch (item.type) {
      case "CWE":
      case "WEAK_POINTER":
        return;
      default:
        if (done) done();
    }
  }, []);

  return (
    <div
      className="report-builder-component"
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <X
        className="report-builder-component-close"
        style={{
          opacity: hovering ? 1 : 0,
        }}
        onClick={() =>
          setItems((items) => items.filter((i) => i.id !== item.id))
        }
      />

      <h3>Discovered Vulnerability</h3>
      <h2>{item.title}</h2>
      {desc && (
        <>
          <h3>Description</h3>
          <p>{desc}</p>
        </>
      )}

      {item.type === "CVE" && (
        <>
          <h3>Severity</h3>
          <div className="cause-field">{item.severity}</div>
        </>
      )}
      {item.type === "CWE" && (
        <>
          {item.mappings.map((mapping) => {
            return (
              <>
                <h3>Mapping</h3>
                <p>{mapping.type}</p>
                {mapping.type === "OWASP 10:2021" && (
                  <div className="cause-field">
                    {mapping.owasp_desc}
                    <br />
                    Static Support: {"" + mapping.static_support}
                    <br />
                    Dynamic Support: {"" + mapping.dynamic_support}
                    <br />
                    Veracode Severity: {mapping.veracode_severity}
                  </div>
                )}
                {(mapping.type === "ISA/IEC 62443-3-3" ||
                  mapping.type === "ISA/IEC 62443-4-2") && (
                  <div className="cause-field">
                    {mapping.requirements.map((req) => {
                      return (
                        <p>
                          {req.items.map((item) => {
                            return <div>{item}</div>;
                          })}
                          <div>{req.desc}</div>
                        </p>
                      );
                    })}
                  </div>
                )}
                {mapping.type === "NIST 800-53" && (
                  <div className="cause-field">
                    <ul>
                      {mapping.line_items.map((li) => (
                        <li>{li}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {mapping.type === "NIST 800-82" && (
                  <div className="cause-field">
                    <ul>
                      {mapping.line_items.map((li) => (
                        <li>{li}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </>
            );
          })}

          <DiscoveryLocation
            asset_id={asset_id}
            binary={binary}
            locations={item.arr}
            fromReportPDF
            setPageCallback={(page) => setDesc(item.arr[page].msg)}
            done={done}
          />
        </>
      )}
      {item.type === "THREATAI" && (
        <>
          <h3>Status</h3>
          <div className="cause-field">{item.status}</div>

          {item.mappings &&
            item.mappings.map((mapping) => {
              return (
                <>
                  <h3>Mapping</h3>
                  <p>{mapping.type}</p>
                  {mapping.type === "OWASP 10:2021" && (
                    <div className="cause-field">
                      {mapping.owasp_desc}
                      <br />
                      Static Support: {"" + mapping.static_support}
                      <br />
                      Dynamic Support: {"" + mapping.dynamic_support}
                      <br />
                      Veracode Severity: {mapping.veracode_severity}
                    </div>
                  )}
                  {(mapping.type === "ISA/IEC 62443-3-3" ||
                    mapping.type === "ISA/IEC 62443-4-2") && (
                    <div className="cause-field">
                      {mapping.requirements.map((req) => {
                        return (
                          <p>
                            {req.items.map((item) => {
                              return <div>{item}</div>;
                            })}
                            <div>{req.desc}</div>
                          </p>
                        );
                      })}
                    </div>
                  )}
                </>
              );
            })}
        </>
      )}

      {item.type === "DANGEROUS_FUNCTION" && (
        <DangerousFunction fn={item.title} samples={item.samples} />
      )}

      {item.type === "CRYPTO" && (
        <>
          <h3>Key Value:</h3>
          <div className="cause-field">{item.value}</div>
        </>
      )}

      {item.type === "WEAK_POINTER" && (
        <>
          <WeakPointer
            asset_id={asset_id}
            binary={binary}
            weakPointer={item.id}
            confidence={item.confidence}
            paths={item.paths}
            fromCustomReport={true}
            done={done}
          />
        </>
      )}
    </div>
  );
}

ReportBuilderComponent.propTypes = {
  /**
   * The item to display.
   * Displayed items have a status, description,
   * type, title, etc.
   */
  item: PropTypes.object.isRequired,

  /**
   * The ID of the parent binary.
   */
  binary: PropTypes.instanceOf(Binary).isRequired,

  /**
   * The ID of the parent asset.
   */
  asset_id: PropTypes.string.isRequired,

  /**
   * Callback function that lets this component set the
   * parent's list of all items.
   * Is used to remove this item when the "X" button is
   * clicked.
   */
  setItems: PropTypes.func.isRequired,
};
