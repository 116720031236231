import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import { getAllUsers, getUserProfile } from "../../api";

// components
import Organization from "./organization";
import ManagedUser from "./managed_user";

/**
 * Table of users on a server. Lets an administrator add, delete,
 * and edit users.
 */
export default class UserManagementTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      userId: "",
      username: "",
      loading: true,
      isSuperadmin: false,
      organizations: [],
      org_details: {},
    };

    UserManagementTable._this = this;

    this.refresh = this.refresh.bind(this);
    this.buildOrganizations = this.buildOrganizations.bind(this);
  }

  static _this = null;

  /**
   * Get the set of all users to manage, if the user is an admin.
   */
  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    // check if the user is a superadmin
    let profile = await getUserProfile(window.localStorage.getItem("userid"));
    if (!profile) return;
    let isSuperadmin = false;
    let isAdmin = false;
    let adminOrgs = [];
    for (let org_id in profile.roles) {
      let org = profile.roles[org_id];
      for (let role of org) {
        if (role === "superadmin") {
          isSuperadmin = true;
          break;
        } else if (role === "admin") {
          isAdmin = true;
          adminOrgs.push(org_id);
        }
      }
    }

    this.props.setIsSuperadmin(isSuperadmin);
    this.props.setIsAdmin(isAdmin);

    this.setState({
      isSuperadmin,
      isAdmin,
      org_details: profile.org_details,
      loading: true,
      organizations: [],
    });
    if (isSuperadmin) {
      let users = await getAllUsers();

      // build organizations JSON from users JSON
      let organizations = this.buildOrganizations(users);

      this.setState({ organizations, loading: false });
      return;
    } else if (isAdmin) {
      let users = await getAllUsers();
      let organizations = this.buildOrganizations(users);

      organizations = organizations.filter((o) => {
        return adminOrgs.includes(o.org_id);
      });

      this.setState({ organizations, loading: false });
      return;
    }
    this.setState({ loading: false });
  }

  /**
   * Constructs a renderable organizations JSON based upon the
   * set of users on a server
   */
  buildOrganizations(users) {
    let organizations = {};
    for (let u of users) {
      for (let org_id in u.roles) {
        let roles = u.roles[org_id];

        // add organization if it doesn't already exist
        if (!organizations.hasOwnProperty(org_id)) {
          organizations[org_id] = {
            org_id,
            users: [],
          };
        }

        let uPushObj = {
          username: u.username,
          email: u.email,
          id: u.id,
          role: "user",
        };
        if (roles.includes("superadmin")) {
          uPushObj.role = "superadmin";
        } else if (roles.includes("admin")) {
          uPushObj.role = "admin";
        }
        organizations[org_id].users.push(uPushObj);
      }
    }
    return Object.values(organizations);
  }

  /**
   * Render the component.
   */
  render() {
    return (
      <div>
        {!this.state.loading &&
          !this.state.isSuperadmin &&
          !this.state.isAdmin && (
            <div style={{ border: "1px solid var(--bor-color)" }}>
              <ManagedUser
                isMe={true}
                isSuperadmin={false}
                username={window.localStorage.getItem("username")}
                role={"user"}
                id={window.localStorage.getItem("userid")}
                org_id={null}
                email={window.localStorage.getItem("email")}
                editModal={(userId, username) =>
                  this.setState({
                    userId,
                    username,
                  })
                }
              />
            </div>
          )}

        {this.state.organizations.map((o) => {
          return (
            <Organization
              key={o.org_id}
              isSuperadmin={this.state.isSuperadmin}
              search={this.props.search}
              id={o.org_id}
              name={this.state.org_details[o.org_id].name}
              users={o.users}
              editModal={(userId, username) =>
                this.setState({
                  userId,
                  username,
                })
              }
              createUserModal={this.props.createUserModal}
            />
          );
        })}

        <div className="row" style={{ marginTop: 20 }}>
          {!this.state.loading && (
            <h3 style={{ color: "var(--bor-color)" }}>Up to date.</h3>
          )}
          {this.state.loading && <div className="lds-dual-ring"></div>}
        </div>
      </div>
    );
  }
}

UserManagementTable.propTypes = {
  /**
   * Search text that filters out users by name.
   */
  search: PropTypes.string.isRequired,

  /**
   * Callback that toggles on the Create User Modal.
   * TODO: This should be deprecated ...
   * this modal should be a child component.
   */
  createUserModal: PropTypes.func.isRequired,

  /**
   * Callback that determines if the current user is superadmin.
   * TODO: This should be deprecated ...
   * user role should be determined in the parent component
   */
  setIsSuperadmin: PropTypes.func.isRequired,

  /**
   * Callback that determines if the current user is an admin.
   * TODO: This should be deprecated ...
   * user role should be determined in the parent component
   */
  setIsAdmin: PropTypes.func.isRequired,
};
