import React from "react";
import PropTypes from "prop-types";
import "../../index.css";

// components
import UserManagementTable from "./user_management_table";
import Notifications from "../login_page/notifications";

// icons
import { Trash, Copy, UserPlus, User, ChevronDown, Check } from "react-feather";
import { addRoles, getUserInviteLink, removeRoles } from "../../api";

/**
 * A single user row in the User Management Table on the Settings Page.
 *
 * If the current user is an ADMIN, they can:
 * 1. Generate an invite link for a user.
 * 2. Remove a user from the org.
 * 3. Add a user to the org.
 *
 * If the current user is the SUPERADMIN, they can:
 * 1. Change a user's role.
 * 2. Everything an ADMIN can do.
 */
export default class ManagedUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roleEditing: false,
    };
    this.listener = null;
    this.changeUserRole = this.changeUserRole.bind(this);
    this.removeUserFromOrg = this.removeUserFromOrg.bind(this);
    this.popupOff = this.popupOff.bind(this);
  }

  popupOff() {
    this.setState({ roleEditing: false });
  }

  componentDidMount() {
    window.addEventListener("mousedown", this.popupOff, false);
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.popupOff, false);
  }

  async changeUserRole(from, to) {
    if (from === to) return;
    if (to === "user") to = "operator";
    await removeRoles(["admin", "operator"], this.props.id, this.props.org_id);
    await addRoles([to], this.props.id, this.props.org_id);
    UserManagementTable._this.refresh();
  }

  async removeUserFromOrg() {
    if (this.props.isMe) {
      alert("You cannot remove yourself!");
      return;
    }
    if (
      window.confirm(
        `Are you sure you want to remove user ${this.props.username} from organization ${this.props.org_name}?`
      )
    ) {
      await removeRoles(
        ["admin", "operator"],
        this.props.id,
        this.props.org_id
      );
      UserManagementTable._this.refresh();
    }
  }

  render() {
    return (
      <div className="new-managed-user">
        <h2 style={{ minWidth: 140, maxWidth: 140 }}>{this.props.username}</h2>
        <h3 style={{ minWidth: 220, maxWidth: 220 }}>{this.props.email}</h3>
        <h3
          className="role-editor"
          onClick={() => {
            if (this.props.role === "superadmin" || !this.props.isSuperadmin)
              return;
            this.setState({ roleEditing: true });
          }}
          style={{
            minWidth: 140,
            maxWidth: 140,
            color: this.state.roleEditing
              ? "var(--sec-color)"
              : "var(--pri-color-light)",
            border:
              this.props.role !== "superadmin" && this.props.isSuperadmin
                ? "1px solid var(--bor-color)"
                : "1px solid transparent",
            background:
              this.props.role !== "superadmin" && this.props.isSuperadmin
                ? "var(--sec-bg-color)"
                : "transparent",
            padding: 5,
            borderRadius: 5,
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            boxShadow: !this.state.roleEditing
              ? "none"
              : "0px 16px 10px 0px rgb(0 0 0 / 14%), 0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%)",
          }}
        >
          {this.props.role}
          {this.props.role !== "superadmin" && this.props.isSuperadmin && (
            <ChevronDown
              className="icon"
              style={{ marginLeft: "auto", width: 15, height: 15 }}
            />
          )}
          {this.state.roleEditing && (
            <div
              style={{
                position: "absolute",
                background: "var(--sec-bg-color)",
                border: "1px solid var(--bor-color)",
                borderTopWidth: !this.props.bottom ? 2 : 1,
                borderBottomWidth: this.props.bottom ? 2 : 1,
                borderBottomRightRadius: !this.props.bottom ? 10 : 0,
                borderBottomLeftRadius: !this.props.bottom ? 10 : 0,
                borderTopRightRadius: this.props.bottom ? 10 : 0,
                borderTopLeftRadius: this.props.bottom ? 10 : 0,

                borderTopColor: "var(--bor-color)",
                bottom: this.props.bottom ? 115 : 2,
                zIndex: 10,
                paddingTop: 10,
                paddingBottom: 10,
                left: -1,
                width: "100%",
                transform: "translateY(100%)",
                boxShadow: this.props.bottom
                  ? "0px 16px -10px 0px rgb(0 0 0 / 14%), 0px 11px -18px 0px rgb(0 0 0 / 12%), 0px 13px -5px -1px rgb(0 0 0 / 20%)"
                  : "0px 16px 10px 0px rgb(0 0 0 / 14%), 0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%)",
              }}
            >
              <div
                className="admin-option"
                style={{ display: "flex", alignItems: "center", padding: 10 }}
                onMouseDown={() =>
                  this.changeUserRole(this.props.role, "admin")
                }
              >
                <Check
                  style={{
                    marginRight: 5,
                    width: 15,
                    height: 15,
                    stroke:
                      this.props.role === "admin"
                        ? "var(--pri-color-light)"
                        : "transparent",
                  }}
                />
                <h3>admin</h3>
                <UserPlus
                  style={{
                    marginLeft: "auto",
                    width: 15,
                    height: 15,
                    stroke: "var(--pri-color-light)",
                  }}
                />
              </div>
              <div
                className="admin-option"
                style={{ display: "flex", alignItems: "center", padding: 10 }}
                onMouseDown={() => this.changeUserRole(this.props.role, "user")}
              >
                <Check
                  style={{
                    marginRight: 5,
                    width: 15,
                    height: 15,
                    stroke:
                      this.props.role === "user"
                        ? "var(--pri-color-light)"
                        : "transparent",
                  }}
                />
                <h3>user</h3>
                <User
                  style={{
                    marginLeft: "auto",
                    width: 15,
                    height: 15,
                    stroke: "var(--pri-color-light)",
                  }}
                />
              </div>
            </div>
          )}
        </h3>

        {(this.props.isMe ||
          this.props.isSuperadmin ||
          this.props.role === "user") && (
          <div
            className="new-managed-user-option"
            onClick={() => {
              if (Notifications._this) Notifications._this.addClip();

              getUserInviteLink(this.props.id).then((link) => {
                let copy_link = `${
                  window.location.protocol + "//" + window.location.host
                }/#/setpassword?invite=${link}`;
                if (window.location.protocol !== "https:") {
                  alert(copy_link);
                  return;
                }
                navigator.clipboard.writeText(copy_link);
              });
            }}
          >
            <h3>Copy Invite Link</h3>
            <Copy className="icon" />
          </div>
        )}

        {(this.props.isMe ||
          this.props.isSuperadmin ||
          this.props.role === "user") && (
          <div
            className={
              this.props.isMe
                ? "new-managed-user-option-off"
                : "new-managed-user-option"
            }
            onClick={this.removeUserFromOrg}
          >
            <h3>Remove Access</h3>
            <Trash className="icon" />
          </div>
        )}
      </div>
    );
  }
}

ManagedUser.propTypes = {
  /**
   * The username of the user in the row.
   */
  username: PropTypes.string.isRequired,

  /**
   * The role of the user in the row.
   */
  role: PropTypes.oneOf(["superadmin", "admin", "user"]),

  /**
   * The ID of the user in the row.
   */
  id: PropTypes.string.isRequired,

  /**
   * The email of the user in the row.
   */
  email: PropTypes.string.isRequired,

  /**
   * The ID of the user's parent organization.
   */
  org_id: PropTypes.string,

  /**
   * The name of the user's parent organization.
   */
  org_name: PropTypes.string,

  /**
   * If the row is the current user.
   */
  isMe: PropTypes.bool.isRequired,

  /**
   * If the current user is SUPERADMIN.
   */
  isSuperadmin: PropTypes.bool.isRequired,

  /**
   * Callback that toggles the user edit modal.
   */
  editModal: PropTypes.func.isRequired,
};
