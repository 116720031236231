import { useState, useEffect } from "react";
import "./deep_dive_line.css";
import "../dashboard/tri_tip.css";
import { getDeepDiveFunc } from "../../api";

// constants
const LINE_HEIGHT = 15; // the height in px of a single line

/**
 * The popup that is displayed when a line is hovered/clicked.
 * May contain the following information:
 * - Line number.
 * - Containing function name.
 * - 'Jump to ->' button that displays the correlated function on
 *   the other side of the deep dive viewer.
 * - Discovered vulnerability ID and discription.
 */
function DeepDiveLinePopup({ line, asset, bin, assessment, jump_to_other }) {
  // loading containing function name or not...
  let [loading, setLoading] = useState(true);
  let [containingFunctionName, setContainingFunctionName] = useState(null);

  /**
   * Load the function containing function name from the API.
   */
  const loadContainingFunctionName = async () => {
    try {
      let cfn = await getDeepDiveFunc(asset, bin, assessment, line.idx + 1);
      setContainingFunctionName(cfn);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    loadContainingFunctionName();
  }, []);

  return (
    <div className="cate-tooltip gree deep-dive-line-popup">
      <h3>Line {line.idx}</h3>
      {loading && (
        <div
          style={{ marginTop: 30 }}
          className="lds-dual-ring lds-dual-ring-center"
        ></div>
      )}
      {!loading && (
        <div className="row">
          <h3>
            <i>f()</i>
          </h3>
          {containingFunctionName && (
            <>
              <h2>{containingFunctionName}</h2>
              <a
                onMouseDown={(e) => {
                  e.stopPropagation();
                  jump_to_other(containingFunctionName);
                }}
              >
                Jump to &rarr;
              </a>
            </>
          )}
          {!containingFunctionName && <h2>Not found.</h2>}
        </div>
      )}
      {line.vuln && (
        <>
          <hr />
          <div className="left-row">
            <div className="light-r"></div>
            <h2>{line.vuln.id}</h2>
          </div>
          <h3>{line.vuln.msg}</h3>
        </>
      )}
      <div className="top-left-arrow"></div>
      <div className="top-left-arrow2"></div>
    </div>
  );
}

/**
 * Shaded component that is positioined absolutely over each line of the
 * deep dive viewer.
 *
 * If the line contains a vulnerability, this component will always be visible.
 * If the line does not contain a vulnerability, this component will only
 * be visible when hovered.
 *
 * Upon clicking on this components, a
 */
export default function DeepDiveLine({
  line,
  asset,
  bin,
  assessment,
  jump_to_other,
}) {
  let [popup, setPopup] = useState(false);

  /**
   * set a listener that hides the popup if the user clicks anywhere but
   * this component itself
   */
  useEffect(() => {
    const listener = window.addEventListener("mousedown", () =>
      setPopup(false)
    );

    // remove this listener when the component is unmounted
    return () => {
      window.removeEventListener("mousedown", listener);
    };
  }, []);

  // remove popup on scroll
  useEffect(() => {
    setPopup(false);
  }, [line]);

  let classes = [];
  if (line.vuln) {
    classes.push("deep-dive-line");
    if (popup) classes.push("deep-dive-line-highlighted");
  } else {
    classes.push("deep-dive-line-invs");
    if (popup) classes.push("deep-dive-line-invs-highlighted");
  }

  return (
    <div
      className={classes.join(" ")}
      style={{
        top: line.idx * LINE_HEIGHT + LINE_HEIGHT + 6,
      }}
      onClick={() => {
        setPopup(true);
      }}
    >
      {popup && (
        <DeepDiveLinePopup
          line={line}
          asset={asset}
          bin={bin}
          assessment={assessment}
          jump_to_other={jump_to_other}
        />
      )}
    </div>
  );
}
