/*
    This file defines a component that enables the user to the user to 
    check an individual file on or off during the S3 ingress process.

    This component appears on the right-side of the file drop modal.
*/

// icons
import { Circle, CheckCircle } from "react-feather";

export default function FileCheck({ file, checked, select }) {
  if (checked)
    return (
      <div className="file-check file-check-selected" onClick={select}>
        <CheckCircle className="icon" /> <h3>{file}</h3>
      </div>
    );
  return (
    <div className="file-check" onClick={select}>
      <Circle className="icon" />
      <h3>{file}</h3>
    </div>
  );
}
