import {
  getAssets,
  getAllAssetsInFacility,
  getAsset,
  getBinaries,
  getBinariesByName,
  getBinariesByCWE,
  getAllAssetsInFacility_byTag,
} from "./api";

const LIMIT = 10;

// AssetFetcher can be configured to fetch multiple different datastructures
// from the backend in different ways
export const FETCHABLE = {
  ASSET_BY_NAME: "ASSET_BY_NAME",

  // all of these expect facility_id !== null
  ASSET_IN_FACILITY_BY_NAME: "ASSETS",
  ASSET_IN_FACILITY_BY_TAG: "TAGS",
  BINARY_BY_NAME: "BINARIES",
  BINARY_BY_CWE: "CWES",
};

/**
 * Handles paginated asset loading.
 * Loads in more assets as the user scrolls.
 */
export default class AssetFetcher {
  constructor(
    scroll_target, // ID of the DOM element being scrolled
    load_callback, // called when assets have loaded

    facility_id = null, // not needed for ASSET_BY_NAME

    search = "", // text to search for
    sortby = "", // sorting by vulnerability or date
    asset_type = null, // the type of asset to filter for

    fetchable = FETCHABLE.ASSET_BY_NAME
  ) {
    this.scroll_target = scroll_target;
    this.facility_id = facility_id;
    this.load_callback = load_callback;

    this.fetchable = fetchable;
    this.asset_type = asset_type;
    this.sortby = sortby;
    this.search = search;
    this.assets = [];
    this.offset = 0;
    this.allDone = false;
    this.alreadyLoading = false;

    // bind functions
    this.loadAssets = this.loadAssets.bind(this);
  }

  /**
   * Load more assets based upon scroll target.
   */
  async loadAssets() {
    if (this.allDone || this.alreadyLoading) return;
    this.alreadyLoading = true;
    const bottom =
      this.scroll_target.scrollHeight - this.scroll_target.scrollTop ===
      this.scroll_target.clientHeight;

    if (bottom) {
      let allAssets = [];

      switch (this.fetchable) {
        case FETCHABLE.BINARY_BY_NAME:
          allAssets = await getBinariesByName(
            this.facility_id,
            this.sortby !== "date",
            this.offset,
            LIMIT,
            this.search,
            this.asset_type
          );
          break;
        case FETCHABLE.BINARY_BY_CWE:
          allAssets = await getBinariesByCWE(
            this.facility_id,
            this.sortby !== "date",
            this.offset,
            LIMIT,
            this.search,
            this.asset_type
          );
          break;
        case FETCHABLE.ASSET_IN_FACILITY_BY_TAG:
          allAssets = await getAllAssetsInFacility_byTag(
            this.facility_id,
            this.sortby !== "date",
            this.offset,
            LIMIT,
            this.search,
            this.asset_type
          );
          break;
        case FETCHABLE.ASSET_IN_FACILITY_BY_NAME:
          allAssets = await getAllAssetsInFacility(
            this.facility_id,
            this.sortby !== "date",
            this.offset,
            LIMIT,
            this.search,
            this.asset_type
          );
          break;
        case FETCHABLE.ASSET_BY_NAME:
        default:
          allAssets = await getAssets(
            this.sortby !== "date",
            this.offset,
            LIMIT,
            this.search,
            this.asset_type
          );
          break;
      }

      if (!allAssets) {
        this.allDone = true;
        this.alreadyLoading = false;
        this.load_callback(this.assets, this, true);
        return;
      }
      let assets = [...this.assets];
      for (let asset of allAssets) {
        if (asset.state === "unassessable") assets.push(asset);
        else if (asset.state === "in progress") assets.push(asset);
        else if (asset.percentage === 100) assets.push(asset);
      }
      this.assets = assets;
      this.offset = this.offset + LIMIT;
      this.alreadyLoading = false;
      this.load_callback(this.assets, this, false);
      this.loadAssets();
    }
    this.load_callback(this.assets, this, false);
    this.alreadyLoading = false;
  }

  /**
   * Update an individual asset's data.
   * This function is called when the user is updating an asset's data locally.
   * I.e. when the user changes the asset's type, name, or tags.
   */
  async update_asset(updated_asset_id) {
    let updated_asset = await getAsset(updated_asset_id);
    let binaries = await getBinaries(updated_asset_id);
    updated_asset = {
      ...updated_asset,
      binaries,
    };
    for (let i = 0; i < this.assets.length; i++) {
      if (this.assets[i].ref_id === updated_asset.ref_id) {
        this.assets[i] = updated_asset;
        break;
      }
    }
    this.load_callback(this.assets, this);
    return updated_asset;
  }
}
